<template>
    <div class="form-group m-form__group w-100 mt-4">
        <input type="hidden" name="questions" id="questions" :value="questionsJSON">
        <div class="row">
            <div class="col-md-6">
                <h4 class="mb-4">
                    <em class="flaticon-add-circular-button mr-1"></em>
                    Incluidas
                </h4>
                <table class="table table-striped table-bordered m-table">
                    <thead>
                    <tr>
                        <th width="10%" class="text-center">{{ 'forms_poll.required'|trans }}</th>
                        <th>{{ 'forms_poll.question'|trans }}</th>
                        <th :width="actionsWidth"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(question, indexQuestion) in questions">
                        <td class="text-center align-middle">
                            <label class="m-checkbox m-checkbox--brand pl-4 pb-2">
                                <input type="checkbox" v-model="question.is_required" :disabled="nbReadOnly"
                                       :id="'question-required-' + indexQuestion">
                                <span></span>
                            </label>
                        </td>
                        <td class="align-middle">{{ question.questionName }}</td>
                        <td>
                            <div class="btn-group m-btn-group btn-block" role="group">
                                <button type="button" v-if="indexQuestion" :disabled="nbReadOnly"
                                        class="btn btn-secondary m-btn m-btn--icon btn-sm m-btn--icon-only"
                                        @click="moveQuestion(indexQuestion, true)"
                                        :id="'move-up-question-' + indexQuestion">
                                    <i class="fa fa-arrow-up"></i>
                                </button>
                                <button type="button" v-if="indexQuestion < questions.length-1" :disabled="nbReadOnly"
                                        class="btn btn-secondary m-btn m-btn--icon btn-sm m-btn--icon-only"
                                        @click="moveQuestion(indexQuestion, false)"
                                        :id="'move-down-question-' + indexQuestion">
                                    <i class="fa fa-arrow-down"></i>
                                </button>
                                <button type="button" :disabled="nbReadOnly"
                                        class="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only"
                                        @click="deleteQuestion(indexQuestion)"
                                        :id="'delete-question-' + indexQuestion">
                                    <i class="fa fa-fw fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-6">
                <h4 class="mb-4">
                    <em class="flaticon-questions-circular-button mr-1"></em>
                    Repositorio
                </h4>
                <table class="table table-striped table-bordered m-table">
                    <thead>
                    <tr>
                        <th>{{ 'forms_poll.question'|trans }}</th>
                        <th>{{ 'forms_poll.type'|trans }}</th>
                        <th width="10%"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(question, indexQuestion) in rep_questions">
                        <td>{{ question.name }}</td>
                        <td>{{ question.typeName }}</td>
                        <td class="actions text-center">
                            <button type="button" :disabled="isAdd(question.id) || nbReadOnly"
                                    class="btn btn-primary m-btn m-btn--icon btn-sm m-btn--icon-only"
                                    @click="addQuestion(indexQuestion)"
                                    :id="'add-question-' + indexQuestion">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'poll_component',
    props: {
      rep_questions: {
        type: Array,
        default: () => [],
      },
      poll_questions: {
        type: Array,
        default: () => [],
      },
    },
    created () {
      this.questions = this.poll_questions
    },
    computed: {
      questionsJSON () {
        return JSON.stringify(this.questions)
      },
      actionsWidth () {
        return this.questions.length < 2 ? '10%' : '20%'
      },
    },
    data () {
      return {
        questions: [],
      }
    },
    methods: {
      // Añade una nueva pregunta a la lista de
      // preguntas de la encuesta
      //
      addQuestion: function (index) {
        this.questions.push({
          questionName: this.rep_questions[index].name,
          is_required: false,
          question_id: this.rep_questions[index].id,
        })
      },

      // Devuelve true o false en funcion si la pregunta
      // referenciada por el id ha sido ya añadida a la
      // lista de preguntas
      //
      isAdd: function (id) { return this.questions.some(el => el.question_id === id) },

      // Mueve la pregunta en la lista de preguntas de
      // la encuesta en funcion del parametro up, si
      // este es positivo la movera hacia arriba
      //
      moveQuestion: function (index, up) {
        let aind = (up) ? index - 1 : index + 1

        let now = { ...this.questions[index] }
        let next = { ...this.questions[aind] }

        this.$set(this.questions, index, next)
        this.$set(this.questions, aind, now)
      },

      // Elimina la pregunta referenciada por index de la lista
      // de preguntas de la encuesta
      //
      deleteQuestion: function (index) { this.questions.splice(index, 1) },
    },
  }
</script>

