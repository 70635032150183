var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-md-8 mb-10" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "label",
          {
            staticClass:
              "form-check form-switch form-check-custom form-check-solid"
          },
          [
            _c("input", {
              attrs: {
                type: "hidden",
                name: "posted_value",
                id: "posted_value"
              },
              domProps: { value: _vm.posted ? 1 : 0 }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.posted,
                  expression: "posted"
                }
              ],
              staticClass: "form-check-input",
              attrs: { type: "checkbox", name: "posted", id: "posted" },
              domProps: {
                value: _vm.posted,
                checked: Array.isArray(_vm.posted)
                  ? _vm._i(_vm.posted, _vm.posted) > -1
                  : _vm.posted
              },
              on: {
                change: function($event) {
                  var $$a = _vm.posted,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.posted,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.posted = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.posted = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.posted = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "form-check-label" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.traduccionPosted) +
                  "\n                "
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "label",
          {
            staticClass:
              "form-check form-switch form-check-custom form-check-solid"
          },
          [
            _c("input", {
              attrs: {
                type: "hidden",
                name: "featured_value",
                id: "featured_value"
              },
              domProps: { value: _vm.featured ? 1 : 0 }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.featured,
                  expression: "featured"
                }
              ],
              staticClass: "form-check-input",
              attrs: {
                type: "checkbox",
                name: "featured",
                id: "featured",
                disabled: _vm.disabled
              },
              domProps: {
                value: _vm.featured,
                checked: Array.isArray(_vm.featured)
                  ? _vm._i(_vm.featured, _vm.featured) > -1
                  : _vm.featured
              },
              on: {
                change: function($event) {
                  var $$a = _vm.featured,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.featured,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.featured = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.featured = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.featured = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "form-check-label" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.traduccionFeatured) +
                  "\n                "
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }