// TODO: EDITOR VISUAL Generalizar
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('ticketEditor', {
      getScopeData: 'getScopeData',
    }),
  },

  methods: {
    renderVariable (suggestion) {
      if (!this.getScopeData) return suggestion

      let newString = suggestion
      for (let variable in this.getScopeData) {
        if (this.getScopeData.hasOwnProperty(variable) && this.getScopeData[variable])
          newString = newString.replace(new RegExp('{' + variable + '}', 'g'), this.getScopeData[variable])
      }
      return newString
    },
  },
}
