<template>
    <div class="d-flex align-middle justify-content-center align-items-center">
        <div v-if="pageItemsCount">
            <span>{{ pageItemsCount }}</span>
        </div>
        <div class="ml-auto">
            <ul class="pagination m-0">
                <li class="paginate_button page-item first" :class="{'disabled': !links.prev}">
                    <button type="button" @click="setPageNumber(1)" class="page-link">
                        <em class="la la-angle-double-left"/>
                    </button>
                </li>
                <li class="paginate_button page-item previous" :class="{'disabled': !links.prev}">
                    <button type="button" @click="setPageNumber(currentPage - 1)" class="page-link">
                        <em class="la la-angle-left"/>
                    </button>
                </li>

                <li v-for="pageElement of pagination(meta.current_page, meta.last_page)"
                    class="paginate_button page-item"
                    :class="{'active': pageElement === meta.current_page}">
                    <button v-if="isNumber(pageElement)" @click="setPageNumber(pageElement)"
                            type="button" tabindex="0" class="page-link">
                        {{ pageElement }}
                        <span v-if="pageElement === loadingPageNumber && loadingData"
                              class="m-loader m-loader--danger in-pagination-loader"></span>
                    </button>
                    <span v-else class="page-link">...</span>
                </li>

                <li class="paginate_button page-item next" :class="{'disabled': !links.next}">
                    <button @click="setPageNumber(currentPage + 1)" type="button" class="page-link">
                        <em class="la la-angle-right"/>
                    </button>
                </li>
                <li class="paginate_button page-item last" :class="{'disabled': !links.next}">
                    <button @click="setPageNumber(meta.last_page)" type="button" class="page-link">
                        <em class="la la-angle-double-right"/>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'paginator',
    props: {
      currentPage: { type: Number, required: true },
      links: { type: Object, required: true },
      meta: { type: Object, required: true },
      loadingPageNumber: { type: Number, required: true },
      loadingData: { type: Boolean, required: false, default: false },
      pageItemsCount: { type: String, required: false, default: null }
    },
    methods: {
      setPageNumber (page) {
        this.$emit('set-page', page)
      },

      // Helpers
      isNumber (value) { return !isNaN(value) },

      pagination (currentPage, numberOfPages) {
        const delta = 2, range = [], rangeWithDots = []
        let length

        range.push(1)

        if (numberOfPages <= 1) {
          return range
        }

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
          if (i < numberOfPages && i > 1) {
            range.push(i)
          }
        }
        range.push(numberOfPages)

        for (let i of range) {
          if (length) {
            if (i - length === 2) {
              rangeWithDots.push(length + 1)
            } else if (i - length !== 1) {
              rangeWithDots.push('...')
            }
          }
          rangeWithDots.push(i)
          length = i
        }

        return rangeWithDots
      },
    }
  }
</script>

<style scoped>

</style>