var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "report-preview",
      staticStyle: { width: "100%", height: "100vh", border: "none" },
      attrs: { id: "reportContainer" }
    },
    [
      _c(
        "div",
        { staticClass: "text-center", on: { click: _vm.toggleFullScreen } },
        [
          _c(
            "a",
            {
              staticClass: "btn btn-primary m-btn--icon",
              attrs: {
                href: "javascript:",
                role: "button",
                id: "full-screen-report",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              }
            },
            [
              _c("i", {
                staticClass: "mr-2 fa fa-fw",
                class: [
                  _vm.isFullScreen ? "fa-window-restore" : "fa-window-maximize"
                ]
              }),
              _vm._v(
                "\n            Previsualizar informe a pantalla completa\n        "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { width: "100%", height: "100vh", border: "none" } },
        [
          _c("iframe", {
            staticClass: "js-report-iframe",
            class: [_vm.isFullScreen ? "full-screen-report" : ""],
            staticStyle: { width: "100%", height: "100vh", border: "none" },
            attrs: { title: "Visor de Informe" }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }