var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { enctype: "multipart/form-data" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.importQuestions()
          }
        }
      },
      [
        _c(
          "modal-component",
          {
            attrs: {
              visible: _vm.showModalImport,
              buttonClose: false,
              classesModalDialog: ["modal-dialog-centered", "modal-xl"]
            },
            on: {
              "update:visible": function($event) {
                _vm.showModalImport = $event
              }
            }
          },
          [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [
                  _vm._v("Selecciona archivo zip con Excel y carpeta images")
                ]),
                _vm._v(" "),
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    type: "file",
                    name: "import_questions_file",
                    id: "import_questions_file"
                  },
                  on: { change: _vm.setFile }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("closeImportModal")
                    }
                  }
                },
                [_vm._v("Cerrar")]
              ),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                [_vm._v("Guardar")]
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }