<template>
    <div>
        <button type="button" class="form-control m-input btn btn-secondary"
                id="sort" @click="showModal = true">
            Ordenar
        </button>

        <modal-component
                :theRef="'sortableModal'"
                :id="`sortable${_uid}`"
                :visible.sync="showModal"
                :backdrop="true"
                :classesBody="['text-center js-body-sortable']"
                :classesModalDialog="['modal-dialog-centered']"
        >
            <template #title>
                Ordenar
            </template>
            <template>
                <SlickList class="o-ordered-list" lockAxis="y" v-model="itemsSort">
                    <SlickItem class="draggable-handle border-bottom p-4 o-ordered-list__item text-start"
                               style="cursor:grab"
                               v-for="(item, index) in itemsSort" :index="index" :key="index">
                        <i class="bi bi-grip-vertical align-middle" style="font-size: 1.4rem;"></i>
                        <strong>{{ item.name }}</strong>
                    </SlickItem>
                </SlickList>
            </template>
            <template #footer>
                <button type="button" class="btn btn-secondary" @click="showModal = false" id="close-modal">
                    Cerrar
                </button>
                <button type="button" class="btn btn-primary" @click="save()" id="save">Guardar</button>
            </template>
        </modal-component>
    </div>
</template>

<script>
  import { SlickItem, SlickList } from 'vue-slicksort'
  import 'jquery-ui/ui/disable-selection'

  export default {
    name: 'sortable-component',
    components: {
      SlickItem,
      SlickList
    },
    data () {
      return {
        itemsSort: [],
        showModal: false,
      }
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      modelClass: {
        type: String,
        default: () => null
      }
    },
    created () {
      this.itemsSort = this.items
    },
    methods: {
      async save () {
        let items = this.itemsSort.map((item) => item.id)
        await axios.post(`/api/entity/order`, {
          entity: this.modelClass,
          items: items,
        })
        this.showModal = false
        window.location.reload()
      }
    },
  }
</script>

<style>
</style>
