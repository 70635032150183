<template>
  <div class="form-group m-form__group col-md-12 mb-2">
    <div class="row">
      <div class="col-md-4">
        <label class="form-label">Perfil profesional*</label>
        <multiselect
          v-model="professionalProfileSelect"
          :options="professionalProfiles"
          label="name"
          track-by="id"
          @input="updateProfessionalProfile"
          for="professional-profile"
        >
        </multiselect>
        <input
          type="hidden"
          name="professionalProfileData"
          :value="jsonProfessionalProfileData"
        />
        <nb-error-block path="professional_profile_id"></nb-error-block>
      </div>

      <div class="col-md-4">
        <label class="form-label">Especialidad</label>
        <multiselect
          v-model="specialtySelect"
          :options="specialtiesData"
          label="name"
          track-by="name"
        >
        </multiselect>
        <input type="hidden" name="specialtyData" :value="jsonSpecialtyData" />
        <nb-error-block path="specialty_id"></nb-error-block>
      </div>
      <div class="col-md-4">
        <label class="form-label">Puesto</label>
        <multiselect
          v-model="positionSelect"
          :options="positionsData"
          label="name"
          track-by="id"
        >
        </multiselect>
        <input type="hidden" name="position_id" :value="positionSelect ? positionSelect.id : null" />
        <nb-error-block path="position_id"></nb-error-block>
      </div>
    </div>
  </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";

export default {
  name: "ProfessionalSpecialtiesSelector",
  components: { NbErrorBlock },

  props: {
    professionalProfiles: Array,
    specialties: Array,
    professionalProfile: Number,
    specialty: Number,
    position: Number,
  },
  data() {
    return {
      professionalProfileSelect: null,
      specialtySelect: null,
      specialtiesData: [],
      positionsData: [],
      positionSelect: null,
    };
  },
  created() {
    this.professionalProfileSelect = this.professionalProfiles.find(
      (p) => p.id === Number(this.professionalProfile)
    );
    this.specialtiesData = this.specialties.filter(
      (specialty) =>
        specialty.professional_profile_id === Number(this.professionalProfile)
    );
    this.specialtySelect = this.specialtiesData.find(
      (s) => s.id === Number(this.specialty)
    );
    this.positionsData  = this.professionalProfileSelect ? this.professionalProfileSelect.positions : [];
    this.positionSelect = this.positionsData.find(
      (p) => p.id === Number(this.position)
    );
  },
  computed: {
    jsonProfessionalProfileData() {
      if(this.professionalProfileSelect) {
        return this.professionalProfileSelect.id;
      }
      return null;
    },
    jsonSpecialtyData() {
      if (this.specialtySelect) {
        return this.specialtySelect.id;
      }
      return null;
    },
  },
  methods: {
    updateProfessionalProfile() {
      this.specialtySelect = null;
      this.positionSelect = null;

      this.specialtiesData = this.specialties.filter(
        (specialty) =>
          specialty.professional_profile_id ===
          this.professionalProfileSelect.id
      );

      this.positionsData = this.professionalProfileSelect.positions;

    },
  },
};
</script>
