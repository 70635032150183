<template>
    <div class="container-xxl">
        <div class="row gy-2 ">
            <div class="col-lg-10 col-xl-8 col-xxl-6 offset-lg-1 offset-xl-2 offset-xxl-3"  v-for="section in sections" :key="'section-' + section.id">
                <div class="card card-xl-stretch">
                    <div class="card-body">
                        <menu-item :item="section" :favorited="isFavorite(section)" :show-arrow="false"></menu-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import MenuItem from '@/js/components/panel/menu-item'

export default {
    name: 'FavoriteCards',
    components: {
        MenuItem,
    },
    props: {
        sections: {type: Array, default: () => []},
    },
    computed: {
        ...mapGetters('Menu', {
            favorites: 'getFavorites'
        }),
        favoritesArray() {
            return Object.entries(this.favorites).map(favorite => favorite[1].attributes.slug)
        },
    },
    methods: {
        isFavorite(section) {
            return this.favoritesArray.includes(section.attributes.slug)
        },
    }
}
</script>
