function parseBindings (bindings) {
  let config = {}

  const values = bindings.value

  if (!values) {return config}

  for (let property of Object.getOwnPropertyNames(values)) {
    config[property] = values[property]
  }

  return config
}

function buildTooltip (el, bindings, node) {
  new bootstrap.Tooltip(el, parseBindings(bindings));
}

function updateTooltip (bindings, el, node) {
  if (bindings.value !== bindings.oldValue) {
    buildTooltip(el, bindings, node)
  }
}

export default {
  bind (el, bindings, node) {
    buildTooltip(el, bindings, node)
  },
  inserted (el, bindings, node) {
    buildTooltip(el, bindings, node)
  },
  update (el, bindings, node) {
    updateTooltip(bindings, el, node)
  },
  componentUpdated (el, bindings, node) {
    updateTooltip(bindings, el, node)
  },
  unbind (el) {
    bootstrap.Tooltip.getInstance(el).dispose()
  },
}
