<template>
    <div class="col-12">
        <input name="reportRoles" type="hidden" :value="selectedsJSON">



        <div class="table-responsive m--padding-left-30 m--padding-right-30 mt-4">
            <table class="table table-striped m-table">
                <thead>
                    <tr>
                        <th>Asignado</th>
                        <th>Nombre</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="role in rolesData" :key="'role-' + role.id">
                        <td>
                            <span class="m-switch m-switch--icon">
                                <label>
                                    <input type="checkbox" 
                                           :disabled="nbReadOnly" v-model="role.selected">
                                    <span></span>
                                </label>
                                <label class="label-hidden">Asignar informe</label>
                            </span>
                        </td>
                        <td>{{ role.visible_name }}</td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>

<script>
export default {
  props: {
    roles: { type: Array, required: true },
  },

  data () {
      return {
          rolesData: this.roles.map(role => Object.assign({}, role))
      }
  },

  computed: {
    selectedsJSON () {
      return JSON.stringify(this.rolesData.filter(role => role.selected).map(role => role.id))
    }
  }
}
</script>