<template>
    <div>
        <a href="javascript:" class="menu-link px-2" data-bs-toggle="tooltip" title="Support Center"
           data-placement="left" @click="visibleModal = !visibleModal">
            Información
            <em class="m-nav__link-icon flaticon-info m--icon-font-size-lg2 ml-1 align-middle"></em>
        </a>
        <modal-component
                :visible.sync="visibleModal"
                @close-modal="closeModal"
                :backdrop="true"
                :classes-modal-dialog="['c-support-center']"
        >
            <template #title>
                Centro de ayuda
            </template>
            <template>
                <div>

                </div>
            </template>
            <template #footer>
                <div class="modal-footer-buttons">
                    <div class="pull-right">
                        <button type="button" id="close" class="btn btn-danger m-btn--icon"
                                @click="closeModal">
                            Cerrar
                        </button>
                    </div>
                </div>
            </template>
        </modal-component>
    </div>
</template>

<script>
  export default {
    name: 'support-center',
    data () {
      return {
        visibleModal: false
      }
    },
    methods: {
      closeModal () {
        this.visibleModal = false
      },
    }
  }
</script>

<style scoped>

</style>
