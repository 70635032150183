var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-md-12 mb-4" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("input", {
            attrs: { type: "hidden", name: "professionalProfileData" },
            domProps: { value: _vm.jsonProfessionalProfileData }
          }),
          _vm._v(" "),
          _c("label", { staticClass: "form-label" }, [
            _vm._v("Perfiles profesionales")
          ]),
          _vm._v(" "),
          _c("multiselect", {
            attrs: {
              options: _vm.professionalProfilesData,
              label: "name",
              "track-by": "name",
              multiple: true
            },
            model: {
              value: _vm.professionalProfilesSelect,
              callback: function($$v) {
                _vm.professionalProfilesSelect = $$v
              },
              expression: "professionalProfilesSelect"
            }
          }),
          _vm._v(" "),
          _c("nb-error-block", { attrs: { path: "professionalProfiles" } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("label", { staticClass: "form-label" }, [
            _vm._v("Especialidades")
          ]),
          _vm._v(" "),
          _c("multiselect", {
            attrs: {
              options: _vm.specialtiesData,
              label: "name",
              "track-by": "name",
              multiple: true
            },
            model: {
              value: _vm.specialtiesSelect,
              callback: function($$v) {
                _vm.specialtiesSelect = $$v
              },
              expression: "specialtiesSelect"
            }
          }),
          _vm._v(" "),
          _c("nb-error-block", { attrs: { path: "specialties" } }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "specialtyData" },
            domProps: { value: _vm.jsonSpecialtyData }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }