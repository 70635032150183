/* ===========================================================
 * trumbowyg.trackable_links.js
 * Trackable links plugin for Trumbowyg
 * ===========================================================
 */

(function ($) {
  'use strict'

  let defaultOptions = {}

  function buildButtonDef (trumbowyg) {
    return {
      fn: function () {
        let $text = trumbowyg.range.startContainer.parentElement
        trumbowyg.saveRange()
        const selectedText = trumbowyg.getRangeText()

        // Esto es el contenido del formulario del modal.
        let html = `
        <div class="highlight-form-group">
          <label>
              <select required id="trackable-type-select" class="highlight-form-control tipo"
                      style="position: absolute;top: 0;right: 0;height: 27px;line-height: 27px; border: 1px solid #DEDEDE;background: #fff;font-size: 14px;max-width: 330px; width: 70%;padding: 0 7px;transition: all 150ms;">
                  <option hidden disabled selected value> -- Elige una opción --</option>
                  <option value="event">Evento</option>
                  <option value="session">Sesión</option>
              </select>
              <span class="trumbowyg-input-infos">
                  <span>Tipo de enlace</span>
              </span>
          </label>

          <label>
              <input required id="link-text-input" type="text" value="">
              <span class="trumbowyg-input-infos">
                  <span>Texto</span>
              </span>
          </label>
        </div>`

        const $modal = trumbowyg.openModal('Insertar enlace trazable', [html])

        if (selectedText) {
          $('#link-text-input').prop('disabled', true)
        }

        $('.trumbowyg-modal-submit').html('Insertar')

        /**
         * Manages confirm button of the modal.
         * Creates the URL and injects it in the template.
         */
        $modal.on('tbwconfirm', function (e) {
          let href = front_base_url + '/es/'

          switch ($modal.find('#trackable-type-select').val()) {
            // TODO: CAMPAÑA Ver como dejar esto en proyecto base
            /*case 'event':
              href += eventData.organization.slug + '/' + eventData.slug +
                '?utm_type=event&md_campaign=[campaign_token]&utm_tracked_id=' + eventData.id
              break*/
          }

          trumbowyg.restoreRange()
          if (!selectedText) {
            trumbowyg.execCmd('insertHTML', '<a href="' + href + '">' + $modal.find('#link-text-input').val() + '</a>')
          } else if ($($text).is('a') && $($text).html() === selectedText) {
            $($text).attr('href', href)
          } else {
            trumbowyg.execCmd('insertHTML', '<a href="' + href + '">' + selectedText + '</a>')
          }

          trumbowyg.closeModal()
          return false
        })

        /**
         * Manages cancel button of the modal. It only closes the modal.
         */
        $modal.on('tbwcancel', function (e) {
          trumbowyg.closeModal()
        })

        function getDefaultOption () {
          return $('<option hidden disabled selected value/>').text('-- Elige una opción --')
        }

        /**
         * Manages change in trackable type select
         * Loads the events and generates the options in the html selector of events.
         * Also enables/disables dependent fields.
         */
        $('#trackable-type-select').change(async function () {
          // TODO: CAMPAÑA Ver como dejar esto en proyecto base
          /*const select = $('#event-select').empty().append(getDefaultOption())
          response.data.forEach(event => {
            select.append($('<option />').val(event.id).text(event.title).data('event', event))
          })*/
        })

        /**
         * Manages change in event select
         * Loads the sessions and generates the options in the html selector of sessions.
         * Also enables/disables the session selector.
         */
        // TODO: CAMPAÑA Ver como dejar esto en proyecto base
        /*$('#event-select').change(async function () {
        })*/

        return true
      },
      tag: 'trackable_link',
      title: 'Insertar enlace de conversión',
      ico: 'trackable_link',
      hasIcon: true,
      text: 'Insertar enlace de conversión',
    }
  }

  $.extend(true, $.trumbowyg, {
    plugins: {
      trackable_links: {
        init: function (trumbowyg) {
          // Fill current Trumbowyg instance with the plugin default options
          trumbowyg.o.plugins.trackable_links = $.extend(true, {},
            defaultOptions,
            trumbowyg.o.plugins.trackable_links || {},
          )

          // If the plugin is a button
          trumbowyg.addBtnDef('trackable_links', buildButtonDef(trumbowyg))
        },
        // Return a list of button names which are active on current element
        tagHandler: function (element, trumbowyg) {
          return []
        },
        destroy: function (trumbowyg) {
        },
      },
    },
  })
})(jQuery)
