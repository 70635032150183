var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-12 my-4" }, [
    _c(
      "div",
      { staticClass: "form-group mb-4" },
      [
        _c("repository-button", {
          attrs: {
            "allowed-mime-types": _vm.allowedMimeTypes,
            "short-version": true,
            "class-field": "col-md-12 px-0",
            multiple: "true",
            field: "sponsorsData",
            "group-id": "1",
            "group-type": "App\\Models\\Rep\\FileGroup"
          },
          model: {
            value: _vm.selectedImage,
            callback: function($$v) {
              _vm.selectedImage = $$v
            },
            expression: "selectedImage"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }