var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group m-form__group col-12 my-4 accordion",
      attrs: { id: "kt_accordion_1" }
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "generalFeaturesData" },
        domProps: { value: _vm.jsonGeneralFeaturesData }
      }),
      _vm._v(" "),
      _vm._l(_vm.dataGeneralFeatures, function(generalFeature, i) {
        return _c("div", { key: i, staticClass: "accordion-item" }, [
          _c(
            "h2",
            {
              staticClass: "accordion-header",
              attrs: { id: "kt_accordion_1_header_" + i }
            },
            [
              _c(
                "button",
                {
                  class: [
                    "accordion-button",
                    "text-dark",
                    "fs-4",
                    "fw-semibold",
                    { collapsed: i !== _vm.dataGeneralFeatures.length - 1 }
                  ],
                  attrs: {
                    type: "button",
                    "data-bs-toggle": "collapse",
                    "data-bs-target": "#kt_accordion_1_body_" + i,
                    "aria-expanded": "true",
                    "aria-controls": "kt_accordion_1_body_1"
                  }
                },
                [
                  !_vm.lang
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-icon btn-danger btn-sm m-2 me-5",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.remove(i)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-trash" })]
                      )
                    : _vm._e(),
                  _vm._v(
                    "\n              Destacado " +
                      _vm._s(i + 1) +
                      "\n          "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "accordion-collapse collapse",
                { show: i === _vm.dataGeneralFeatures.length - 1 }
              ],
              attrs: {
                id: "kt_accordion_1_body_" + i,
                "aria-labelledby": "kt_accordion_1_header_" + i,
                "data-bs-parent": "#kt_accordion_1"
              }
            },
            [
              _c("div", { staticClass: "accordion-body" }, [
                _c("div", { staticClass: "m-4" }, [
                  !_vm.lang
                    ? _c("div", { staticClass: "form-group mb-4" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Color de fondo")
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: generalFeature.backgroundColor,
                                expression: "generalFeature.backgroundColor"
                              }
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  generalFeature,
                                  "backgroundColor",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.backgroundColorOptions, function(
                            backgroundColor
                          ) {
                            return _c(
                              "option",
                              {
                                key: backgroundColor.id,
                                domProps: { value: backgroundColor.id }
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(backgroundColor.name) +
                                    "\n                  "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group mb-4 trumbowyg-small" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Texto")
                      ]),
                      _vm._v(" "),
                      _c("trumbowyg", {
                        style: "height: 120px;",
                        attrs: { config: _vm.configs },
                        on: {
                          input: function($event) {
                            return _vm.$emit("input", generalFeature.text)
                          }
                        },
                        model: {
                          value: generalFeature.text,
                          callback: function($$v) {
                            _vm.$set(generalFeature, "text", $$v)
                          },
                          expression: "generalFeature.text"
                        }
                      }),
                      _vm._v(" "),
                      _vm.lang
                        ? _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.generalFeaturesDefault[i].text
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.lang
                    ? _c(
                        "div",
                        { staticClass: "form-group mb-4" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v("Imagen")
                          ]),
                          _vm._v(" "),
                          _c("repository-button", {
                            attrs: {
                              "allowed-mime-types": _vm.allowedMimeTypes,
                              "short-version": true,
                              "class-field": "col-md-12 px-0",
                              "group-id": "1",
                              "group-type": "App\\Models\\Rep\\FileGroup"
                            },
                            on: {
                              "clear-value": function($event) {
                                return _vm.clearValue(i)
                              }
                            },
                            model: {
                              value: generalFeature.image,
                              callback: function($$v) {
                                _vm.$set(generalFeature, "image", $$v)
                              },
                              expression: "generalFeature.image"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.lang
                    ? _c("div", { staticClass: "form-group mb-4" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Alineación de imagen")
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: generalFeature.alignment,
                                expression: "generalFeature.alignment"
                              }
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  generalFeature,
                                  "alignment",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.alignmentsOptions, function(alignment) {
                            return _c(
                              "option",
                              {
                                key: alignment.id,
                                domProps: { value: alignment.id }
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(alignment.name) +
                                    "\n                  "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-4 row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Texto de botón")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: generalFeature.buttonText,
                            expression: "generalFeature.buttonText"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: generalFeature.buttonText },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              generalFeature,
                              "buttonText",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.lang
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.generalFeaturesDefault[i].buttonText)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Url de botón")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: generalFeature.buttonUrl,
                            expression: "generalFeature.buttonUrl"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          disabled: _vm.lang !== null ? true : false,
                          type: "url"
                        },
                        domProps: { value: generalFeature.buttonUrl },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              generalFeature,
                              "buttonUrl",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              ])
            ]
          )
        ])
      }),
      _vm._v(" "),
      !_vm.lang
        ? _c(
            "button",
            {
              staticClass: "btn btn-secondary mt-9 mb-4",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.addFeatureBlock()
                }
              }
            },
            [
              _vm._v("\n    Añadir destacado\n    "),
              _c("i", { staticClass: "fa fa-plus-circle ms-3" })
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }