<template>
    <div class="col-12" v-show="showBox">
        <div class="row m--padding-report">
            <div v-for="(groupingId, index) in groupingIds" class="form-group m-form__group col-md-3"
                 v-if="!live || customizableGroupings[index]">
                <div class="d-flex flex-row" v-if="!live">
                    <input :value="customizableGroupings[index] ? 1 : 0" :name="`grouping_checkbox[${index}]`"
                           type="hidden">
                    <div class="p-2">
                        <label class="m-checkbox">
                            <input :disabled="disabledGroup(index)" v-model="customizableGroupings[index]"
                                   type="checkbox" :id="'customize-group-' + index">
                            {{ 'report_groupings.level' | trans({level: index + 1}) }}
                            <span></span>
                        </label>
                    </div>
                </div>
                <label v-else>
                    {{ 'report_groupings.level' | trans({level: index + 1}) }}
                </label>
                <select :name="`${prefix}[${index}]`" v-model="groupingIds[index]" :disabled="disabledGroup(index)"
                        class="form-control" :id="'group-' + prefix + index">
                    <option :value="null">{{ 'report_groupings.none' | trans }}</option>
                    <option v-for="grouping in defaultGroupings" :value="grouping.id">{{ grouping.name }}</option>
                </select>
                <nb-error-block :path="`filters.${index}`"></nb-error-block>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'report-groupings',
    props: {
      groupingLevels: {
        type: Array,
        default: []
      },
      defaultGroupings: {
        type: Array,
        default: []
      },
      groupings: {
        type: Array,
        default: []
      },
      live: {
        type: Boolean,
        default: false
      },
      prefix: {
        type: String,
        default: ''
      },
    },
    data () {
      return {
        customizableGroupings: this.groupingLevels,
        groupingIds: [],
        showBox: true
      }
    },
    computed: {
      filters () {
        return []
      }
    },
    methods: {
      init () {
        for (let level = 1; level <= this.groupingLevels.length; level++) {
          let group = _.first(_.filter(this.groupings, { 'level': level }))

          if (!group) {
            group = { groupable_id: null }
          }

          this.groupingIds.push(group.groupable_id)
        }

        if (this.live) {
          let index, show = false

          for (index in this.customizableGroupings) {
            if (this.customizableGroupings[index] === true) {
              show = true
            }
          }

          this.showBox = show
        }
      },
      disabledGroup (index) {
        if (this.nbReadOnly) {
          return true
        }

        if (index > 0 && this.groupingIds[index - 1] === null) {
          if (!this.live) {
            return true
          }
        }

        return false
      }
    },
    created () {
      this.init()
    },
    watch: {
      groupingIds: function () {
        let index

        for (index in this.groupingIds) {
          if (index > 0 && this.groupingIds[index - 1] === null) {
            if (!this.live) {
              this.customizableGroupings[index] = false
              this.groupingIds[index] = null
            } else {
              this.groupingIds[index] = null
            }
          }
        }
      }
    }
  }
</script>

<style lang="scss">
    .m--padding-report {
        .m-form__group {
            padding-top: 15px !important;
            padding-bottom: 15px !important;
        }
    }
</style>
