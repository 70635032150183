var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "form-group m-form__group col-12" }, [
        _c(
          "label",
          {
            staticClass: "m-checkbox m-checkbox--brand mb-0",
            attrs: { disabled: _vm.nbReadOnly }
          },
          [
            _c("input", {
              attrs: { type: "hidden", name: "enable_scheduling" },
              domProps: { value: !!_vm.enableScheduling }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.enableScheduling,
                  expression: "enableScheduling"
                }
              ],
              attrs: {
                type: "checkbox",
                checked: "checked",
                disabled: _vm.nbReadOnly,
                id: "schedule-enabled"
              },
              domProps: {
                checked: Array.isArray(_vm.enableScheduling)
                  ? _vm._i(_vm.enableScheduling, null) > -1
                  : _vm.enableScheduling
              },
              on: {
                change: function($event) {
                  var $$a = _vm.enableScheduling,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.enableScheduling = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.enableScheduling = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.enableScheduling = $$c
                  }
                }
              }
            }),
            _vm._v(
              "\n                ¿Activar programación de envío?\n                "
            ),
            _c("span")
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "m-separator m-separator--dashed m-separator--lg mt-3 mb-4"
        })
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row", class: { "is-disabled": !_vm.enableScheduling } },
      [
        _c(
          "div",
          { staticClass: "form-group m-form__group col-12" },
          [
            _c("label", [_vm._v("Emails")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scheduleEmails,
                  expression: "scheduleEmails"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                name: "schedule_emails",
                disabled: _vm.nbReadOnly,
                "aria-describedby": "schedule_email_help",
                placeholder: "...@",
                id: "schedule-emails"
              },
              domProps: { value: _vm.scheduleEmails },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.scheduleEmails = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("nb-error-block", { attrs: { path: "schedule_emails" } }),
            _vm._v(" "),
            _c(
              "small",
              {
                staticClass: "form-text text-muted mb-2",
                attrs: { id: "schedule_email_help" }
              },
              [
                _vm._v(
                  "\n                Introduce los destinatarios del informe separados por punto y coma (;).\n            "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group m-form__group col-4" },
          [
            _c("label", [_vm._v("Organización")]),
            _vm._v(" "),
            _vm._l(_vm.organizationIds, function(organizationId) {
              return _c("input", {
                key: organizationId,
                attrs: { type: "hidden", name: "schedule_organization_ids[]" },
                domProps: { value: organizationId }
              })
            }),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.organizations,
                "track-by": "id",
                disabled: _vm.nbReadOnly,
                label: "name",
                multiple: true,
                id: "organizations"
              },
              model: {
                value: _vm.scheduleOrganizations,
                callback: function($$v) {
                  _vm.scheduleOrganizations = $$v
                },
                expression: "scheduleOrganizations"
              }
            }),
            _vm._v(" "),
            _c("nb-error-block", {
              attrs: { path: "schedule_organization_id" }
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group m-form__group col-4" },
          [
            _c("label", [_vm._v("Inicio de actividad")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scheduleStart,
                  expression: "scheduleStart"
                }
              ],
              staticClass: "form-control mr-4",
              attrs: {
                type: "datetime-local",
                disabled: _vm.nbReadOnly,
                placeholder: "Inicio",
                name: "schedule_start",
                title: "Inicio",
                id: "schedule-start"
              },
              domProps: { value: _vm.scheduleStart },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.scheduleStart = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("nb-error-block", { attrs: { path: "schedule_start" } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group m-form__group col-4" },
          [
            _c(
              "label",
              {
                staticClass: "m-checkbox m-checkbox--brand mb-2",
                attrs: { disabled: _vm.nbReadOnly }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.enableScheduleEnd,
                      expression: "enableScheduleEnd"
                    }
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "checkbox",
                    name: "enable_schedule_end",
                    disabled: _vm.nbReadOnly,
                    id: "enable-schedule-end"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.enableScheduleEnd)
                      ? _vm._i(_vm.enableScheduleEnd, null) > -1
                      : _vm.enableScheduleEnd
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.enableScheduleEnd,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.enableScheduleEnd = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.enableScheduleEnd = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.enableScheduleEnd = $$c
                      }
                    }
                  }
                }),
                _vm._v(
                  "\n                Finalización de actividad\n                "
                ),
                _c("span")
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scheduleEnd,
                  expression: "scheduleEnd"
                }
              ],
              attrs: { type: "hidden", name: "schedule_end" },
              domProps: { value: _vm.scheduleEnd },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.scheduleEnd = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.scheduleEnd,
                  expression: "scheduleEnd"
                }
              ],
              staticClass: "form-control mr-4",
              attrs: {
                type: "datetime-local",
                disabled: !_vm.enableScheduleEnd || _vm.nbReadOnly,
                id: "schedule-end"
              },
              domProps: { value: _vm.scheduleEnd },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.scheduleEnd = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("nb-error-block", { attrs: { path: "schedule_end" } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group m-form__group col-4" },
          [
            _c(
              "label",
              {
                staticClass: "m-checkbox m-checkbox--brand mb-2",
                attrs: { disabled: _vm.nbReadOnly }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.enableExecutionTimes,
                      expression: "enableExecutionTimes"
                    }
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "checkbox",
                    name: "enable_execution_times",
                    disabled: _vm.nbReadOnly,
                    id: "enable-executions"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.enableExecutionTimes)
                      ? _vm._i(_vm.enableExecutionTimes, null) > -1
                      : _vm.enableExecutionTimes
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.enableExecutionTimes,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.enableExecutionTimes = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.enableExecutionTimes = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.enableExecutionTimes = $$c
                      }
                    }
                  }
                }),
                _vm._v(
                  "\n                Número de repeticiones\n                "
                ),
                _c("span")
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scheduleMaxExecutionTimes,
                    expression: "scheduleMaxExecutionTimes"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "number",
                  id: "max-executions",
                  placeholder: "",
                  step: "1",
                  min: "1",
                  disabled: !_vm.enableExecutionTimes || _vm.nbReadOnly
                },
                domProps: { value: _vm.scheduleMaxExecutionTimes },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.scheduleMaxExecutionTimes = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.scheduleMaxExecutionTimes,
                    expression: "scheduleMaxExecutionTimes",
                    modifiers: { number: true }
                  }
                ],
                attrs: { type: "hidden", name: "schedule_max_execution_times" },
                domProps: { value: _vm.scheduleMaxExecutionTimes },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.scheduleMaxExecutionTimes = _vm._n($event.target.value)
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.scheduleMaxExecutionTimes
                ? _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-brand",
                        attrs: {
                          type: "button",
                          title: "Reiniciar contador",
                          id: "reset-executions",
                          disabled:
                            _vm.scheduleExecutionsCounter === 0 ||
                            _vm.nbReadOnly
                        },
                        on: { click: _vm.resetExecutionsCounter }
                      },
                      [_c("i", { staticClass: "la la-refresh" })]
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.scheduleMaxExecutionTimes
              ? _c("div", [
                  _c("span", { staticClass: "small" }, [
                    _vm._v("\n                    Se han realizado "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.scheduleExecutionsCounter))
                    ]),
                    _vm._v(" envíos.\n                    Quedan "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.scheduleMaxExecutionTimes -
                            _vm.scheduleExecutionsCounter
                        )
                      )
                    ]),
                    _vm._v(
                      "\n                    para finalizar.\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.scheduleExecutionsCounter,
                        expression: "scheduleExecutionsCounter",
                        modifiers: { number: true }
                      }
                    ],
                    attrs: {
                      type: "hidden",
                      name: "schedule_executions_counter"
                    },
                    domProps: { value: _vm.scheduleExecutionsCounter },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.scheduleExecutionsCounter = _vm._n(
                          $event.target.value
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("nb-error-block", {
              attrs: { path: "schedule_max_execution_times" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group m-form__group col-8" }, [
          _c("input", {
            attrs: { type: "hidden", name: "schedule_frequency" },
            domProps: { value: _vm.selectedScheduleFrequencyValue }
          }),
          _vm._v(" "),
          _c("label", [_vm._v("Intervalos de repetición:")]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group m-input-group" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.selectedScheduleFrequency.amount,
                  expression: "selectedScheduleFrequency.amount",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-control m-input",
              attrs: {
                type: "number",
                min: "1",
                disabled: _vm.nbReadOnly,
                placeholder: "1",
                id: "schedule-frequency"
              },
              domProps: { value: _vm.selectedScheduleFrequency.amount },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.selectedScheduleFrequency,
                    "amount",
                    _vm._n($event.target.value)
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "input-group-append" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.selectedScheduleFrequency.type,
                      expression: "selectedScheduleFrequency.type",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "form-select form-select-solid select--append",
                  attrs: {
                    title: "Repetición",
                    disabled: _vm.nbReadOnly,
                    id: "schedule-frequency-type"
                  },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return _vm._n(val)
                        })
                      _vm.$set(
                        _vm.selectedScheduleFrequency,
                        "type",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "0" } }, [_vm._v("día/s")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("semana/s")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("mes/es")])
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group m-form__group col-8" }, [
          _c("label", [_vm._v("Días de la semana")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-group m-btn-group btn-block",
              attrs: { role: "group" }
            },
            _vm._l(_vm.weekDays, function(day) {
              return _c(
                "button",
                {
                  staticClass: "btn",
                  class: {
                    "btn-outline-primary": _vm.viewDay(day.id),
                    "btn-outline-metal": !_vm.viewDay(day.id)
                  },
                  attrs: {
                    type: "button",
                    id: "days-" + day.id,
                    disabled: _vm.nbReadOnly
                  },
                  on: {
                    click: function($event) {
                      return _vm.markDay(day)
                    }
                  }
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.viewDay(day.id),
                        expression: "viewDay(day.id)"
                      }
                    ],
                    staticClass: "la la-check mr-1"
                  }),
                  _vm._v(
                    "\n                    " +
                      _vm._s(day.value) +
                      "\n                "
                  )
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group m-form__group col-4" }, [
          _c("input", {
            attrs: { type: "hidden", name: "schedule_conditions" },
            domProps: { value: _vm.selectedConditionsValue }
          }),
          _vm._v(" "),
          _c("label", [
            _vm._v("\n                Hora "),
            _c("i", {
              directives: [{ name: "tooltip", rawName: "v-tooltip" }],
              staticClass: "fa fa-fw fa-info-circle",
              attrs: { title: "La hora de recepción puede variar." }
            })
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedTime,
                expression: "selectedTime"
              }
            ],
            staticClass: "form-control m-input",
            attrs: { type: "time", disabled: _vm.nbReadOnly, id: "time" },
            domProps: { value: _vm.selectedTime },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.selectedTime = $event.target.value
              }
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("Repetir cada")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }