import loadDirectives from '@/js/vue/utils/loadDirectives'
import loadPrototypes from '@/js/vue/utils/loadPrototypes'
import loadPlugins from '@/js/vue/utils/loadPlugins'
import loadFilters from '@/js/vue/utils/loadFilters'
import MultiSelect from '@/js/vendor-extensions/vue-multiselect/vue-multiselect-es'
import VueDraggableResizable from 'vue-draggable-resizable'
import NbErrorBlock from '@/js/components/error_block/nb-error-block'
import VueSimpleContextMenu from 'vue-simple-context-menu'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default function initVue () {
  loadPrototypes()
  loadPlugins()
  loadFilters()
  loadDirectives()

  // register components globally
  Vue.component('multiselect', MultiSelect)
  Vue.component('vue-draggable-resizable', VueDraggableResizable)
  Vue.component('nb-error-block', NbErrorBlock)
  Vue.component('vue-simple-context-menu', VueSimpleContextMenu)
  Vue.component('v-swatches', VSwatches)

  // Registrar todos los componentes automáticamente
  const files = require.context('../', true, /\.vue$/i)
  files.keys().map(key => {
    const name = _.last(key.split('/')).split('.')[0]
    return Vue.component(name, files(key))
  })

  // Make errors accessible globally thought a global mixin
  Vue.mixin({
    props: {
      nbErrors: { type: Object, default: () => ({}) },
      nbReadOnly: { type: Boolean, default: false },
    },
    beforeMount () {
      if (Object.keys(this.nbErrors).length) {
        this.$store.commit('addNbErrors', { ...this.nbErrors })
      }
    },
    methods: {
      /**
       * @param path key del path al error
       * @returns {*}
       */
      renderNbError (path) {
        const errorIndex = this.$store.getters.getNbErrors.findIndex(e => e.path === path)
        if (errorIndex !== -1) { return this.$store.getters.getNbErrors[errorIndex].errors }

        return false
      },
    },
  })
}
