var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "lineWeight" } }, [_vm._v("Ancho de linea")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control  form-control-sm",
        attrs: {
          type: "number",
          id: "lineWeight",
          placeholder: "Ancho de linea"
        },
        domProps: { value: _vm.activeElement.barcode.width },
        on: {
          input: function($event) {
            return _vm.updateBarcode($event, "width")
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "value" } }, [_vm._v("Valor")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control-sm",
        attrs: { type: "number", id: "value", placeholder: "Valor de muestra" },
        domProps: { value: _vm.activeElement.barcode.value },
        on: {
          input: function($event) {
            return _vm.updateBarcode($event, "value")
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group mb-1" }, [
      _c("label", { attrs: { for: "rotation" } }, [
        _vm._v("Rotación de texto")
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          staticClass: "form-select form-select-solid form-select-sm",
          attrs: { id: "rotation" },
          domProps: { value: _vm.activeElement.style.rotation },
          on: {
            input: function($event) {
              return _vm.updateStyle($event, "rotation")
            }
          }
        },
        [
          _c("option", { domProps: { value: 0 } }, [_vm._v("0")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 90 } }, [_vm._v("90")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 180 } }, [_vm._v("180")]),
          _vm._v(" "),
          _c("option", { domProps: { value: 270 } }, [_vm._v("270")])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }