<template>
    <div class="c-survey-component form-group m-form__group w-100 mt-4">
        <input type="hidden" name="questions" id="questions" :value="questionsJSON">
        <div class="row">
            <div class="col-12 row">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <h4 class="col-6 c-survey-component__title m-0">
                        Preguntas
                    </h4>
                    <button type="button" class="btn btn-primary m-btn m-btn--icon" @click="addQuestion"
                            :disabled="nbReadOnly" id="add-option">
                        <span>
                            <i class="fa fa-plus"></i>
                            <span>Añadir pregunta</span>
                        </span>
                    </button>
                </div>

                <list-questions-component
                        v-model="order_questions"
                        :lockToContainerEdges="true"
                        :useDragHandle="true"
                        lockAxis="y"
                        :helperClass="'sorting-select'">
                    <item-question-component
                            v-for="(question, index) in order_questions"
                            :index="index"
                            :key="index"
                            :item="question"
                            :question="question"
                            :indexQuestion="index"
                            :question_types="question_types"
                            class="accordion"
                            @deleteQuestion="deleteQuestion"
                            @click="select = index"/>
                </list-questions-component>

            </div>
        </div>
    </div>
</template>

<script>
  import { sortBy } from 'lodash'
  import ListQuestionsComponent from '@/js/components/survey/ListQuestionsComponent'
  import ItemQuestionComponent from '@/js/components/survey/ItemQuestionComponent'

  export default {
    name: 'SurveyComponent',
    components: {
      ListQuestionsComponent,
      ItemQuestionComponent,
    },
    props: {
      rep_questions: { type: Array, default: () => [] },
      questions: { type: Array, default: () => [] },
      question_types: { type: Array, default: () => [] },
      lang: { type: String, default: null },
    },
    created () {
      this.order_questions = sortBy(this.questions, ['order'])
      this.order_questions.map((q, i) => {
        q.order = i + 1
        this.$set(q, 'is_open', false)
      })
      if (this.order_questions.length) {
        this.select = 0
      }
    },
    computed: {
      questionsJSON () {
        return JSON.stringify(this.order_questions)
      },
      actionsWidth () {
        return this.questions.length < 2 ? '10%' : '20%'
      },
    },
    data () {
      return {
        select: null,
        order_questions: [],
        object_select: null,
        index: 0,
      }
    },
    watch: {
      order_questions () {
        this.order_questions.map((question, index) => question.order = index + 1)
        this.select = this.order_questions.indexOf(this.object_select)
      },
      select (index) {
        if (this.order_questions[index]) {
          this.object_select = this.order_questions[index]
        }
      },
    },
    methods: {
      // Añade una nueva pregunta a la lista de
      // preguntas de la encuesta
      addQuestion: function () {
        const type = this.question_types[0]
        this.order_questions.push({
          default_value: '',
          id: null,
          is_required: false,
          name: '',
          options: [],
          order: this.order_questions.map(q => q.order).reduce((a, b) => a > b ? a : b, 0) + 1,
          question: '',
          question_type: type,
          question_type_id: type.id,
          survey_id: null,
          translations: [],
          is_open: false,
        })
      },

      // Elimina la pregunta referenciada por index de la lista
      // de preguntas de la encuesta
      deleteQuestion: function (index) {
        let length = this.order_questions.length
        this.order_questions.splice(index, 1)
        if (this.select === index) {
          if (this.order_questions.length === 0) this.select = null
          else this.select = (this.select - 1 + length) % length
        }
      },
    },
  }
</script>
