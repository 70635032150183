var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible && _vm.footerWarning
    ? _c(
        "div",
        {
          staticClass: "alert alert-danger alert-dismissible fade show",
          staticStyle: { "padding-right": "30px !important" },
          attrs: { role: "alert" }
        },
        [
          _c("button", {
            staticClass: "close",
            staticStyle: { "margin-right": "-10px" },
            attrs: {
              type: "button",
              "data-bs-dismiss": "alert",
              "aria-label": "Close"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                _vm.visible = false
              }
            }
          }),
          _vm._v(" "),
          _c("strong", { staticStyle: { "margin-right": "20px" } }, [
            _vm._v(_vm._s(_vm.footerWarning))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }