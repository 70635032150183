var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    { class: { "tr-child": !!_vm.entity.parent_id } },
    [
      _c("td", [
        _c("span", { staticClass: "entity-name" }, [
          _vm._v(_vm._s(_vm.entity.visible_name))
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.permissionTypes, function(permissionType) {
        return _c("permission-item", {
          key: permissionType.id + "_" + _vm.entity.id,
          attrs: {
            showThisPermission: _vm.isVisiblePermission(
              permissionType,
              _vm.entity
            ),
            entityId: _vm.entity.id,
            userRoleInfo: _vm.userRoleInfo,
            permissionTypeId: permissionType.id,
            roleGranted: _vm.getRolePermission(
              _vm.entity.id,
              permissionType.id
            ),
            granted: _vm.getPermission(_vm.entity.id, permissionType.id),
            nbReadOnly: _vm.nbReadOnly
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }