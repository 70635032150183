<template>
    <div class="form-group m-form__group col-12">
        <input name="tokens" type="hidden" :value="tokensDeleted">
        <div class="mx-2 mt-4">
            <table class="table table-striped table-bordered m-table">
                <thead>
                <tr>
                    <th>Token</th>
                    <th>Expiración</th>
                    <th>Expiración de refresco</th>
                    <th>Creado</th>
                    <th>Actualizado</th>
                    <th width="1%"></th>
                </tr>
                </thead>
                <tbody v-if="allTokens.length">
                <tr v-for="(token, ix) in allTokens">
                    <td>
                        {{token.token }}
                    </td>
                    <td>
                        {{ formatDate(token.expiration_at) }}
                    </td>
                    <td>
                        {{ formatDate(token.refresh_expiration_at) }}
                    </td>
                    <td>
                        {{ formatDate(token.created_at) }}
                    </td>
                    <td>
                        {{ formatDate(token.updated_at) }}
                    </td>
                    <td class="align-middle text-center">
                        <button class="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only" :disabled="nbReadOnly"
                                @click.prevent="deleteToken(ix, token.id)">
                            <i class="fa fa-plus"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="6" class="text-center py-4">
                        Este cliente no tiene logins.
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

  export default ({
    name: 'tokens',
    props: {
      tokens: {
        type: Array,
        default: () => [],
      }
    },
    data: function () {
      return {
        allTokens: [],
        tokensDelete: [],
      }
    },
    computed: {
      tokensDeleted () {
        return JSON.stringify(this.tokensDelete)
      },
    },
    methods: {
      formatDate (date) {
        return moment(date).format('LLLL')
      },
      deleteToken (index, id) {
        this.allTokens.splice(index, 1)
        this.tokensDelete.push(id)
      },
    },
    created () {
      this.allTokens = this.tokens
    }
  })


</script>
