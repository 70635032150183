<template>
    <div>
        <div class="m-entity-list-search form-group m-form__group col-12">

            <div class="row mt-4">
                <div class="col-6">
                    <div class="m-input-icon m-input-icon--right position-relative">
                        <label for="search" class="sr-only"></label>
                        <input autocomplete="off" placeholder="Buscar" name="q" id="search"
                                v-model="search" type="text" aria-label="Search" class="pe-10 form-control m-input">
                        <div class="position-absolute translate-middle-y top-50 end-0 me-3">
                            <span>
                                <i class="fa fa-fw fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <multiselect :options="reportTypes"
                                :multiple="true"
                                v-model="reportTypesSelected"
                                track-by="name"
                                label="name"
                                :placeholder="placeholder">
                    </multiselect>
                </div>
            </div>
        </div>

        <div class="row m-form__section mt-0 body-padding pt-0">
            <div class="col-12">

                <div v-if="reports.length" class="table-responsive">
                    <table class="table table-hover m-table">
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Descripción</th>
                        </tr>
                        </thead>
                        <paginate
                                  name="reportsPagination" :list="filteredReports"
                                  :per="10" tag="tbody" :key="search">
                            <tr v-for="report in paginated('reportsPagination')" :key="'report-' + report.id">
                                <td><a :href="report.href">{{ report.name }}</a></td>
                                <td>{{ report.description }}</td>
                            </tr>
                        </paginate>
                    </table>
                </div>
                <div v-else class="fs-5 fw-bolder alert m-alert--default text-center my-20">No tienes informes asignados.</div>

                <div class="row" v-if="paginated('reportsPagination').length">
                    <div class="col-4">
                        <span>{{ }}</span>
                    </div>
                    <div class="col-8">
                        <div class="pull-right">
                            <nav aria-label="Paginación de informes">
                                <paginate-links
                                        :limit="10"
                                        :show-step-links="true"
                                        :step-links="{ prev: '‹', next: '›' }"
                                        :hide-single-page="true"
                                        :classes="{'ul': 'pagination', 'li': 'page-item', 'a': 'page-link'}"
                                        :async="true"
                                        for="reportsPagination">
                                </paginate-links>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DateHelpers from '@/js/mixins/DateHelpers'

export default {
  props: {
    reports: { type: Array, default: () => [] },
  },
  mixins: [DateHelpers],

  data () {
    return {
      reportsPagination: [],
      paginate: ['reportsPagination'],
      paginationVisible: true,
      search: null,
      key: 0,
      reportTypesSelected: [],
    }
  },

  computed: {
    filteredReports () {
      const search = this.search ? this.search.trim() : ''
      const regexp = new RegExp(search, 'i')
      const reportTypes = this.reportTypesSelected
      return this.reports.filter(report => {
        const inTypes = !reportTypes || !reportTypes.length || reportTypes.map(r => r.name).indexOf(report.type) !== -1
        return report.name.match(regexp) && inTypes
      })
    },
    reportTypes () {
      return _.uniq(this.reports.map(report => report.type)).map((type, id) => ({id, name: type}))
    },
    placeholder () {
      return this.reportTypesSelected.length ? '' : 'Tipo de informe'
    },
  },

  watch: {
    search () {
      this.key = (this.key++) % 10
    }
  },
}
</script>
