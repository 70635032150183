<template>
    <div id="ticket-editor" tabindex="0" class="ticket-editor__ticket" :style="ticketStyle">
        <vue-draggable-resizable v-for="element of elements" :key="element.id"
                                 v-if="element.visible !== false && layoutEnabled"
                                 :w="element.width" :h="element.height" :minw="1" :minh="1"
                                 :x="element.x" :y="element.y" :z="element.zIndex" :parent="!!loading"
                                 :active="activeElement && element.id === activeElement.id"
                                 @activated="changeActiveElement(element)"
                                 v-on:dragging="onDrag(element, ...arguments)"
                                 v-on:resizing="onResize(element, ...arguments)"
                                 :draggable="!isBlockElement(element)"
                                 :resizable="!isBlockElement(element)"
                                 :class="{ blocked: isBlockElement(element) }">
            <div class="ticket-editor__text-type d-flex justify-content-center align-items-center"
                 :style="getElementWrapperStyle(element)"
                 v-if="element.type === 'text'">
                <div :style="getElementStyle(element)">
                    <span>{{ renderValue(element.value) }}</span>
                </div>
            </div>

            <div class="ticket-editor__image-type d-flex"
                 :style="getElementWrapperStyle(element)"
                 v-if="element.type === 'image'">
                <div class="image" :style="getElementStyle(element)"></div>
            </div>

            <div class="ticket-editor__barcode-type d-flex justify-content-center align-items-center"
                 :style="getElementWrapperStyle(element)"
                 v-if="element.type === 'barcode'">
                <div :style="getElementStyle(element)">
                    <img src="#" class="ticket-editor__barcode-type-barcode" :id="`barcode${element.id}`"/>
                </div>
            </div>

            <div class="ticket-editor__qr-type"
                 :style="getElementWrapperStyle(element)"
                 v-if="element.type === 'qr'">
                <img src="#" class="ticket-editor__qr-type-qr" :id="`qr${element.id}`"
                     :style="getElementStyle(element)"/>
            </div>

        </vue-draggable-resizable>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters, mapMutations } from 'vuex'
  import JsBarcode from 'jsbarcode'
  import QRCode from 'qrcode'

  export default {
    name: 'ticket-preview',
    data: function () {
      return {
        layoutEnabled: true,
      }
    },
    props: ['powerUserLoged'],
    watch: {
      elements () {
        setTimeout(this.initBarcodes(), 0)
        setTimeout(this.initQRCodes(), 0)
      },
    },
    mounted () {
      this.$eventBus.$off('layoutChange')
      this.$eventBus.$on('layoutChange', this.updateLayout)
    },
    computed: {
      ...mapGetters('ticketEditor', {
        getTicket: 'ticket',
        getElements: 'elements',
        activeElement: 'activeElement',
        getScopeData: 'getScopeData',
        getLoadingPreview: 'getLoadingPreview',
        zoomPreview: 'zoomPreview',
        zoomBase: 'zoomBase',
      }),

      ticket: {
        get () { return this.getTicket},
        set (ticket) { this.setTicket(ticket) },
      },

      elements: {
        get () { return this.getElements},
        set (elements) { this.setElements(elements) },
      },

      loading: {
        set (loading) { this.setLoadingPreview(loading) },
        get () { return this.getLoadingPreview },
      },

      scopeData () {
        return this.getScopeData
      },

      zoom () {
        let calcZoom

        if (this.zoomPreview >= 0) {
          calcZoom = ((this.zoomPreview / this.zoomBase) + 1)
        } else {
          calcZoom = (this.zoomBase - Math.abs(this.zoomPreview)) / this.zoomBase
        }

        return `scale(${calcZoom})`
      },

      ticketStyle () {
        return {
          width: this.ticket.width + 'cm',
          height: this.ticket.height + 'cm',
          transform: this.zoom
        }
      },
    },
    methods: {
      ...mapMutations('ticketEditor', {
        setActiveElement: 'setActiveElement',
        setTicket: 'setTicket',
        setElements: 'setElements',
        setLoadingPreview: 'setLoadingPreview',
      }),

      /**
       * Actualizar coordenadas al desplazar elemento con ratón.
       */
      onDrag (element, x, y) {
        element.x = x
        element.y = y
      },

      /**
       * Actualizar coordenadas y dimensiones al alterar tamaño
       * con ratón.
       */
      onResize (element, x, y, width, height) {
        element.x = x
        element.y = y
        element.width = width
        element.height = height
      },

      /**
       * El componente actualmente no tiene un watch activo sobre
       * su posicionamiento por lo que se requiere un refresco del
       * componente completo. Se puede buscar algo más optimizado
       * si se presentan problemas de rendimiento.
       */
      updateLayout () {
        this.layoutEnabled = false
        this.$nextTick(() => {
          this.layoutEnabled = true
          setTimeout(this.initBarcodes(), 0)
          setTimeout(this.initQRCodes(), 0)
        })
      },

      getElementStyle (element) {
        if (element.type === 'text') {
          let textStyles = {
            ...element.style,
            fontSize: element.style.fontSize * 10 + 'pt',
            transformOrigin: 'center',
            transform: `rotate(${element.style.rotation}deg)`,
            width: `${element.width}px`,
            height: `${element.height}px`,
            backgroundColor: element.style.setBackgroundColor ? element.style.backgroundColor : null,
          }

          // Se invierte el ancho y alto para los 90º y 270º
          if ([90, 270].includes(parseInt(element.style.rotation))) {
            textStyles = { ...textStyles, width: `${element.height}px`, height: `${element.width}px` }
          }

          return textStyles
        }

        if (element.type === 'barcode') {
          let barcodeStyles = {
            ...element.style,
            transformOrigin: 'center',
            transform: `rotate(${element.style.rotation}deg)`,
            width: `${element.width}px`,
            height: `${element.height}px`,
          }

          // Se invierte el ancho y alto para los 90º y 270º
          if ([90, 270].includes(parseInt(element.style.rotation))) {
            barcodeStyles = { ...barcodeStyles, width: `${element.height}px`, height: `${element.width}px` }
          }

          return barcodeStyles
        }

        if (['image', 'barcode'].includes(element.type)) {
          let backgroundSize = `${element.style.imageWidth}px ${element.style.imageHeight}px`
          if (!element.style.imageWidth || !element.style.imageHeight) { backgroundSize = 'contain' }

          let imageSrc = element.value
          if (!element.value) {
            imageSrc = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
          } else if (element.value.indexOf('${') === 0 && this.scopeData) {
            // Replace variable.
            let tmpValue = element.value.replace('${', '')
            tmpValue = tmpValue.replace('}', '')
            imageSrc = this.scopeData[tmpValue]
          }

          return {
            ...element.style,
            backgroundImage: `url(${imageSrc})`,
            backgroundSize,
            transform: `rotate(${element.style.rotation}deg)`,
          }
        }

        if (element.type === 'qr') {
          return {}
        }

        return { ...element.style }
      },

      getElementWrapperStyle (element) {
        if (element.hasOwnProperty('allowOverflow') && !element.allowOverflow) {
          return {
            overflow: 'hidden',
          }
        }

        return {}
      },

      initBarcodes () {
        this.$nextTick(() => {
          this.elements.filter((element) => element.type === 'barcode').forEach((element) => {
            if ($.trim(element.value) !== '') {
              JsBarcode(`#barcode${element.id}`, this.renderValue(element.value), {
                ...element.barcode,
              })
            }
          })
        })
      },

      initQRCodes () {
        this.$nextTick(() => {
          this.elements.filter(element => element.type === 'qr').forEach(async element => {
            const domTarget = document.getElementById(`qr${element.id}`)
            domTarget && domTarget.setAttribute('src', await QRCode.toDataURL(this.renderValue(element.value), {
              ...element.qr,
              color: {
                dark: element.qr.colorDark,
                light: element.qr.colorLight,
              },
            }))
          })
        })
      },

      renderValue (rawValue) {
        if (!this.scopeData) return rawValue

        let newString = rawValue
        for (let variable in this.scopeData) {
          if (this.scopeData.hasOwnProperty(variable) && this.scopeData[variable])
            newString = newString.replace(new RegExp('\\${' + variable + '}', 'g'), this.scopeData[variable])
        }
        return newString
      },

      changeActiveElement (element) {
        this.setActiveElement(element)
        this.$nextTick(() => {
          let active = $('.ticket-select-elements .list-element.active')
          let parent = active.parent()
          if (active.length) {
            parent.scrollTop(0).scrollTop(active.position().top - parent.position().top)
          }
        })
      },

      isBlockElement (element) {
        return element.blocked && this.powerUserLoged < element.blockPower
      },
    },
  }
</script>
