<template>
    <tbody>
    <tr>
        <td>
            <div :style="{paddingLeft: (depth * 15) + 'px'}" :class="{'disabled': nbReadOnly}">
                <strong class="fa fa-fw">&#8226;</strong>
                <i v-if="userRoleSpecialPermission" :class="`fa role-status ${roleIcon}`"
                   :title="$options.filters.trans('permission_component.inheritedRoleAuth')"></i>
                <i @click.stop="checkSpecialPermission"
                   :class="`fa state-icon ${icon}`"></i>
                {{ specialPermission.name }}
            </div>
        </td>
    </tr>
    </tbody>

</template>

<script>
  import { EventBus } from '@/js/vue/services/event-bus'

  export default {
    name: 'special-permission-item',
    computed: {
      icon () {
        if (this.specialPermission.granted) {
          return 'fa-check-circle text-success js-checked'
        } else {
          if (this.specialPermission.granted === null) {
            return 'fa-minus-circle text-muted js-no-value'
          }

          return 'fa-times-circle text-danger js-unchecked'
        }
      },
      roleIcon () {
        if (this.userRoleSpecialPermission.granted) {
          return 'fa-check-circle text-success js-checked'
        } else {
          if (this.userRoleSpecialPermission.granted === null) {
            return 'fa-minus-circle js-muted js-no-value'
          }

          return 'fa-times-circle text-danger js-unchecked'
        }
      },
    },
    methods: {
      specialPermissionUpdated (sectionId) {
        EventBus.$emit('specialPermissionUpdated', sectionId)
      },
      checkSpecialPermission () {
        if (this.nbReadOnly) {
          return
        }
        this.$emit('specialPermissionUpdated')
      },
    },
    props: {
      specialPermission: {
        default: null,
        validator: value => value instanceof Object,
      },
      index: String,
      depth: Number,
      userRoleSpecialPermission: {
        default: null,
        validator: value => value instanceof Object,
      }
    },
  }
</script>

<style scoped>
    .disabled {
        opacity: 0.5;
    }

    .animated.pulse {
        animation-duration: 500ms;
    }

    .animated.fadeOutUp {
        animation-duration: 200ms;
    }

    .list-group.list-group-root .list-group {
        margin-bottom: 0;
    }

    .list-group.list-group-root .list-group-item {
        border-radius: 0;
        border-width: 1px 0 0 0;
    }

    .list-group-item i {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .list-group.list-group-root > .list-group-item:first-child {
        border-top-width: 0;
    }

    .role-status {
        margin-left: -7px;
        margin-top: -7px;
    }
</style>
