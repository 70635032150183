export default {
  'main': [
    { 'meta': 'title', 'length': { 'min': '60', 'max': '70' }, 'type': 'tag', 'choices': null, 'value': null },
    { 'meta': 'description', 'length': { 'min': '100', 'max': '300' }, 'type': 'meta', 'choices': null, 'value': null },
  ],
  'social': {
    'facebook': [
      { 'meta': 'og:url', 'length': null, 'type': 'og', 'choices': null, 'value': null },
      {
        'meta': 'og:type',
        'length': null,
        'type': 'og',
        'choices': ['product', 'website', 'article', 'place'],
        'value': 'product',
      },
      { 'meta': 'og:title', 'length': { 'min': '60', 'max': '70' }, 'type': 'og', 'choices': null, 'value': null },
      {
        'meta': 'og:description',
        'length': { 'min': '100', 'max': '300' },
        'type': 'og',
        'choices': null,
        'value': null,
      },
      { 'meta': 'og:image', 'length': null, 'type': 'og', 'choices': null, 'value': null },
      { 'meta': 'fb:app_id', 'length': null, 'type': 'og', 'choices': null, 'value': null },
    ],
    'twitter': [
      {
        'meta': 'twitter:card',
        'length': null,
        'type': 'meta',
        'choices': ['summary', 'summary_large_image', 'app', 'player'],
        'value': 'summary',
      },
      { 'meta': 'twitter:site', 'length': null, 'type': 'meta', 'choices': null, 'prefix': '@', 'value': null },
      { 'meta': 'twitter:creator', 'length': null, 'type': 'meta', 'choices': null, 'prefix': '@', 'value': null },
      {
        'meta': 'twitter:title',
        'length': { 'min': '60', 'max': '70' },
        'type': 'meta',
        'choices': null,
        'value': null,
      },
      {
        'meta': 'twitter:description',
        'length': { 'min': '130', 'max': '200' },
        'type': 'meta',
        'choices': null,
        'value': null,
      },
      { 'meta': 'twitter:image', 'length': null, 'type': 'meta', 'choices': null, 'value': null },
    ],
  },
  'schemas': {
    'TheaterEvent': [
      { 'meta': '@context', 'value': 'http://schema.org' },
      { 'meta': '@type', 'value': 'TheaterEvent' },
      { 'meta': 'name', 'value': null, 'type': 'string' },
      { 'meta': 'description', 'value': null, 'type': 'string' },
      { 'meta': 'startDate', 'value': null, 'type': 'date' },
      { 'meta': 'endDate', 'value': null, 'type': 'date' },
      // { 'meta': 'url', 'value': null, 'type': 'string' },
      { 'meta': 'isAccessibleForFree', 'value': null, 'type': 'boolean' },
      { 'meta': 'typicalAgeRange', 'value': null, 'type': 'string' },
      {
        'meta': 'eventStatus', 'choices': [
          'http://schema.org/EventCancelled',
          'http://schema.org/EventPostponed',
          'http://schema.org/EventRescheduled',
          'http://schema.org/EventScheduled',
        ],
        'type': 'string',
        'value': 'http://schema.org/EventScheduled',
      },
      { 'meta': 'image', 'value': null, 'type': 'string' },
      {
        'meta': 'performer', 'props': [
          { 'meta': '@type', 'value': 'Organization' },
          { 'meta': 'name', 'value': null, 'type': 'string' },
          { 'meta': 'url', 'value': null, 'type': 'string' },
        ],
      },
      {
        'meta': 'location', 'props': [
          { 'meta': '@type', 'value': 'PerformingArtsTheater' },
          { 'meta': 'name', 'value': null, 'type': 'string' },
          { 'meta': 'sameAs', 'value': null, 'type': 'string' },
          {
            'meta': 'address', 'props': [
              { 'meta': '@type', 'value': 'PostalAddress' },
              { 'meta': 'streetAddress', 'value': null, 'type': 'string' },
              { 'meta': 'addressLocality', 'value': null, 'type': 'string' },
              { 'meta': 'postalCode', 'value': null, 'type': 'string' },
              { 'meta': 'addressCountry', 'value': null, 'type': 'string' },
            ],
          },
        ],
      },
      {
        'meta': 'offers', 'props': [
          { 'meta': '@type', 'value': 'AggregateOffer' },
          { 'meta': 'name', 'value': null, 'type': 'string' },
          { 'meta': 'itemOffered', 'value': null, 'type': 'string' },
          // { 'meta': 'url', 'value': null, 'type': 'string' },
          { 'meta': 'priceCurrency', 'value': 'EUR', 'type': 'string' },
          { 'meta': 'price', 'value': null, 'type': 'currency' },
          { 'meta': 'lowPrice', 'value': null, 'type': 'currency' },
          { 'meta': 'highPrice', 'value': null, 'type': 'currency' },
          {
            'meta': 'availability', 'value': null,
            'choices': [
              'http://schema.org/Discontinued',
              'http://schema.org/InStock',
              'http://schema.org/InStoreOnly',
              'http://schema.org/LimitedAvailability',
              'http://schema.org/OnlineOnly',
              'http://schema.org/OutOfStock',
              'http://schema.org/PreOrder',
              'http://schema.org/PreSale',
              'http://schema.org/SoldOut',
            ],
            'type': 'string',
          },
          { 'meta': 'availabilityStarts', 'value': null, 'type': 'datetime' },
          { 'meta': 'validFrom', 'value': null, 'type': 'datetime' },
        ],
      },
      {
        'meta': 'workPerformed', 'props': [
          { 'meta': '@type', 'value': 'CreativeWork' },
          { 'meta': 'name', 'value': null, 'type': 'string' },
          { 'meta': 'sameAs', 'value': null, 'type': 'string' },
        ],
      },
    ],
    'EntertainmentBusiness': [
      { 'meta': '@context', 'value': 'http://schema.org' },
      { 'meta': '@type', 'value': 'EntertainmentBusiness' },
      { 'meta': 'name', 'value': null, 'type': 'string' },
      { 'meta': 'image', 'value': null, 'type': 'string' },
      { 'meta': 'telephone', 'value': null, 'type': 'string' },
      { 'meta': 'priceRange', 'value': '$$$', 'type': 'string' },
      {
        'meta': 'address', 'props': [
          { 'meta': '@type', 'value': 'PostalAddress' },
          { 'meta': 'streetAddress', 'value': null, 'type': 'string' },
          { 'meta': 'addressLocality', 'value': null, 'type': 'string' },
          { 'meta': 'postalCode', 'value': null, 'type': 'string' },
          { 'meta': 'addressCountry', 'value': null, 'type': 'string' },
        ],
      },
    ],
  },
}
