<template>
    <div>
        <div class="form-group">
            <label for="fontFamily">Fuente del texto</label>
            <select class="form-select form-select-solid form-select-sm" id="fontFamily" :value="activeElement.style.fontFamily"
                    @input="updateStyle($event, 'fontFamily')">
                <option value="Arial, sans-serif">Arial</option>
                <option value="Helvetica, sans-serif">Helvetica</option>
                <option value="Gill Sans, sans-serif">Gill Sans</option>
                <option value="Lucida, sans-serif">Lucida</option>

                <option value="Times, serif">Times</option>
                <option value='"Times New Roman", serif'>Times New Roman</option>
                <option value="Palatino, serif">Palatino</option>
                <option value="Georgia, serif">Georgia</option>
                <option value="New Century Schoolbook, serif">New Century Schoolbook</option>

                <option value="monospace">Monospace</option>

                <option value="Impact, fantasy">Impact</option>
            </select>
        </div>
        <div class="form-group">
            <label for="fontSize">Tamaño de texto</label>
            <input type="number" id="fontSize" :value="activeElement.style.fontSize"
                   @input="updateStyle($event, 'fontSize')" step="0.1" class="form-control form-control-sm"
                   placeholder="Tamaño de la fuente">
        </div>
        <div class="form-group">
            <label for="fontWeight">Grosor del texto
                <span class="fa fa-fw fa-info-circle" v-tooltip
                      title="No todas las fuentes soportan todos los niveles de grosor"></span>
            </label>
            <select class="form-select form-select-solid form-select-sm" id="fontWeight" :value="activeElement.style.fontWeight"
                    @input="updateStyle($event, 'fontWeight')">
                <option value="100">Delgado</option>
                <option value="200">Muy ligero</option>
                <option value="300">Ligero</option>
                <option value="400">Regular</option>
                <option value="500">Medio</option>
                <option value="600">Semi-Negrita</option>
                <option value="700">Negrita</option>
                <option value="800">Extra-Negrita</option>
                <option value="900">Máximo grosor</option>
            </select>
        </div>

      <vue-color-picker class-field="form-group"
                        name="Color de texto"
                        field="color"
                        v-model="activeElement.style.color"
                        :needs-target="true"
                        @input="updateStyle($event, 'color')"></vue-color-picker>

        <div class="form-group">
            <label class="m-checkbox mb-3">
                <input type="checkbox" v-model="activeElement.style.setBackgroundColor"/>Color del fondo de texto
                <span></span>
            </label>
          <vue-color-picker class-field=""
                            field="backgroundColor"
                            v-model="activeElement.style.backgroundColor"
                            :disabled="!activeElement.style.setBackgroundColor"
                            :needs-target="true"
                            @input="updateStyle($event, 'backgroundColor')"></vue-color-picker>
        </div>

        <div class="form-group mb-1">
            <label for="rotation">Rotación de texto</label>
            <select class="form-select form-select-solid form-select-sm" id="rotation" :value="activeElement.style.rotation"
                    @input="updateStyle($event, 'rotation')">
                <option :value="0">0</option>
                <option :value="90">90</option>
                <option :value="180">180</option>
                <option :value="270">270</option>
            </select>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import StylesMixin from '@/js/components/ticket_editor/tabs/element-tabs/StylesMixin'
  import VueColorPicker from "@/js/components/widgets/VueColorPicker";

  export default {
    name: 'TextFieldStyles',
    components: {VueColorPicker},
    mixins: [StylesMixin],
  }
</script>

<style lang="scss" scoped>
    .background-color--wrapper {
        background-color: #F4F5F8;
        padding: 0;
        border-radius: 5px;
        height: 37px;
    }

    label {
        font-size: 0.9rem !important;
        margin-bottom: 0.4rem !important;
        background: red;
    }
</style>
