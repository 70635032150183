var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.customer
      ? _c("em", {
          directives: [{ name: "tooltip", rawName: "v-tooltip" }],
          staticClass: "fa fa-user-circle mr-1",
          class: _vm.customerClass,
          attrs: { title: _vm.customerTooltip }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.customer
      ? _c("a", { attrs: { href: _vm.customerPath } }, [
          _vm._v("\n        " + _vm._s(_vm.customer.fullName) + "\n    ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }