<template>
    <div class="border rounded w-100 p-2">
        <div id="js-table-wrapper" class="conditions-list"
             :class="{'conditions-list-height': activeElement.visibility.conditions.length}">
            <div class="conditions-list-box" v-for="(condition, i) of activeElement.visibility.conditions">
                <div class="form-group mb-1">
                    <select class="form-select form-select-solid form-select-sm js-condition-variable conditions-select"
                            title="variable" :value="condition.variable" :id="'condition-' + i"
                            @input="inputCondition($event, condition, 'variable')" required>
                        <option value="">Variable</option>
                        <option v-for="variable of availableVariables" :value="variable">{{ variable }}</option>
                    </select>
                    <nb-error-block
                      :path="`elements.${index}.visibility.conditions.${i}.variable`"></nb-error-block>
                </div>
                <div class="form-group mb-1">
                    <select class="form-select form-select-solid form-select-sm conditions-select" title="Comparador"
                            :value="condition.comparer" :id="'condition-comparer-' + i"
                            @input="inputCondition($event, condition, 'comparer')" required>
                        <option value="">Comparación</option>
                        <option value="<">Menor que</option>
                        <option value="<=">Menor o igual que</option>
                        <option value=">">Mayor que</option>
                        <option value=">=">Mayor o igual que</option>
                        <option value="=">Igual a</option>
                        <option value="!=">Distinto de</option>
                        <option value="LIKE">Contiene</option>
                        <option value="EMPTY">Vacío</option>
                        <option value="NOT_EMPTY">No vacío</option>
                    </select>
                    <nb-error-block
                      :path="`elements.${index}.visibility.conditions.${i}.comparer`"></nb-error-block>
                </div>
                <div class="form-group mb-2">
                    <div class="input-group">
                        <input type="text" class="form-control form-control-sm" title="Valor" :value="condition.value"
                               @input="inputCondition($event, condition, 'value')" placeholder="Valor"
                               :id="'condition-value-' + i" :required="!disabledValueField(condition)"
                               :disabled="disabledValueField(condition)">
                        <div class="input-group-append">
                            <button class="btn btn-danger m-btn--icon btn-sm" type="button"
                                    :id="'delete-condition-' + i"
                                    @click.prevent.stop="deleteCondition(condition)">
                                <span><i class="fa fa-trash"></i></span>
                            </button>
                        </div>
                    </div>
                    <nb-error-block
                      :path="`elements.${index}.visibility.conditions.${i}.value`"></nb-error-block>
                </div>
            </div>
        </div>

        <button class="btn btn-block btn-primary btn-sm m-btn--icon" id="add-condition"
                @click="insertCondition" type="button">
            <i class="fa fa-fw fa-plus"></i> Añadir condición
        </button>
    </div>
</template>
<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters, mapMutations } from 'vuex'
  import uuid from 'uuid/v4'

  export default {
    name: 'VisibilityConditions',
    computed: {
      ...mapGetters('ticketEditor', {
        getElements: 'elements',
        activeElement: 'activeElement',
        availableVariables: 'availableVariables',
      }),

      index () {
        if (!this.activeElement) return -1
        return this.getElements.findIndex((el) => el.id === this.activeElement.id)
      },
    },

    methods: {
      ...mapMutations('ticketEditor', {
        addCondition: 'addCondition',
        removeCondition: 'removeCondition',
        updateCondition: 'updateCondition',
        updateVisibility: 'updateVisibility',
      }),

      insertCondition () {
        this.addCondition({
          id: uuid(),
          variable: null,
          comparer: null,
          value: null,
        })

        this.$nextTick(() => {
          const conditionFields = document.getElementsByClassName('js-condition-variable')
          conditionFields[conditionFields.length - 1].focus()
          const tableWrapper = document.getElementById('js-table-wrapper')
          tableWrapper.scrollTop = tableWrapper.scrollHeight
        })
      },

      inputCondition (e, condition, property) {
        const newCondition = { ...condition, value: condition.value || '' }
        newCondition[property] = e.target.value
        this.updateCondition({ condition, newCondition })
      },

      deleteCondition (condition) {
        this.removeCondition(condition)
      },

      disabledValueField (condition) {
        return ['EMPTY', 'NOT_EMPTY'].indexOf(condition.comparer) !== -1
      },
    },
  }
</script>

<style lang="scss">
    .conditions-list-height {
        height: 180px;
    }

    .conditions-list {
        overflow-y: auto;
    }

    .conditions-list-box {
        padding: 0.5rem;
        border-bottom: 1px dashed #F1F1F1;
        margin-bottom: 0.5rem;
    }

    .conditions-list-box:last-child {
        border: none;
    }

    .conditions-select option[value=""] {
        color: #F1F1F1;
    }
</style>
