var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c(
      "div",
      {
        staticClass:
          "alert alert-warning alert-dismissible d-flex flex-column flex-sm-row p-5 mb-10 show",
        attrs: { role: "alert" }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pe-0 pe-sm-10" },
          [
            _c("h4", { staticClass: "fw-bold" }, [
              _vm._v(
                "Los siguientes campos son necesarios para tramitar facturas nominales: "
              )
            ]),
            _vm._v(" "),
            _vm._l(_vm.remainingFields, function(field) {
              return _c("span", [
                _vm.remainingFields.indexOf(field) <
                _vm.remainingFields.length - 2
                  ? _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("trans")("forms_crm_customers." + field)
                          ) +
                          ",\n                "
                      )
                    ])
                  : _vm.remainingFields.indexOf(field) <
                    _vm.remainingFields.length - 1
                  ? _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("trans")("forms_crm_customers." + field)
                          ) +
                          " y\n                "
                      )
                    ])
                  : _vm.remainingFields.indexOf(field) ===
                    _vm.remainingFields.length - 1
                  ? _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("trans")("forms_crm_customers." + field)
                          ) +
                          ".\n                "
                      )
                    ])
                  : _vm._e()
              ])
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm._m(1)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0"
      },
      [
        _c("i", {
          staticClass: "alert-warning bi bi-exclamation-circle",
          staticStyle: { "font-size": "2rem" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto",
        attrs: {
          type: "button",
          "data-bs-dismiss": "alert",
          "aria-label": "Close",
          id: "close"
        }
      },
      [
        _c("span", { staticClass: "svg-icon svg-icon-1 svg-icon-primary" }, [
          _c("i", { staticClass: "alert-warning bi bi-x-lg" })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }