var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-6" }, [
    !_vm.live
      ? _c("div", { staticClass: "d-flex flex-row" }, [
          _c("input", {
            attrs: { type: "hidden", name: _vm.name + "_checkbox" },
            domProps: { value: _vm.customizeCheckbox ? 1 : 0 }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "p-2" }, [
            _c("label", { staticClass: "m-checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customizeCheckbox,
                    expression: "customizeCheckbox"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  title: "Modificable",
                  disabled: _vm.nbReadOnly,
                  id: "customize-check-" + _vm.prefix + _vm.name
                },
                domProps: {
                  checked: Array.isArray(_vm.customizeCheckbox)
                    ? _vm._i(_vm.customizeCheckbox, null) > -1
                    : _vm.customizeCheckbox
                },
                on: {
                  click: function($event) {
                    return _vm.changeCheckBox(true)
                  },
                  change: function($event) {
                    var $$a = _vm.customizeCheckbox,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.customizeCheckbox = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.customizeCheckbox = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.customizeCheckbox = $$c
                    }
                  }
                }
              }),
              _vm._v(" " + _vm._s(_vm.label) + "\n                "),
              _c("span")
            ])
          ])
        ])
      : _c("label", [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.usedFilterJSON,
          expression: "usedFilterJSON"
        }
      ],
      ref: "saveField",
      attrs: { type: "hidden", name: _vm.prefix + "[" + _vm.name + "]" },
      domProps: { value: _vm.usedFilterJSON },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.usedFilterJSON = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _vm.isMultiple
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-5 col-select-one" },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.optionFilters,
                      expression: "optionFilters"
                    }
                  ],
                  staticClass: "form-select form-select-solid",
                  attrs: {
                    size: "5",
                    disabled: _vm.nbReadOnly,
                    multiple: _vm.isMultiple,
                    id: "filters-" + _vm.prefix + _vm.name
                  },
                  on: {
                    dblclick: function($event) {
                      return _vm.moveData()
                    },
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.optionFilters = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.optionFiltersList, function(text, value) {
                  return _c("option", { domProps: { value: value } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(text) +
                        "\n                "
                    )
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _vm.isMultiple
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("span", { staticClass: "m-form__help" }, [
                        _vm._v(
                          _vm._s(_vm._f("trans")("report_groupings.no_include"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6 text-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "m-btn btn btn-sm btn-link",
                          attrs: {
                            type: "button",
                            disabled: _vm.nbReadOnly,
                            id: "move-all-filters-" + _vm.prefix + _vm.name
                          },
                          on: {
                            click: function($event) {
                              return _vm.moveAllData()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")("report_groupings.include_all")
                              ) +
                              "\n                        "
                          ),
                          _c("i", { staticClass: "fa fa-angle-double-right" })
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("nb-error-block", { attrs: { path: "filters." + _vm.name } })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-2 col-select-center" }, [
            _c("div", { staticClass: "btn-group-vertical" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-metal btn-sm",
                  attrs: {
                    type: "button",
                    disabled: _vm.nbReadOnly,
                    id: "move-filters-" + _vm.prefix + _vm.name
                  },
                  on: {
                    click: function($event) {
                      return _vm.moveData()
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-angle-right" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-metal btn-sm",
                  attrs: {
                    type: "button",
                    disabled: _vm.nbReadOnly,
                    id: "return-filters-" + _vm.prefix + _vm.name
                  },
                  on: {
                    click: function($event) {
                      return _vm.returnData()
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-angle-left" })]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-5 col-select-one" },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.usedFilters,
                      expression: "usedFilters"
                    }
                  ],
                  staticClass: "form-select form-select-solid",
                  attrs: {
                    size: "5",
                    disabled: _vm.nbReadOnly,
                    multiple: _vm.multiple === _vm.E_SELECT_MULTIPLE,
                    id: "used-filters-" + _vm.prefix + _vm.name
                  },
                  on: {
                    dblclick: function($event) {
                      return _vm.returnData()
                    },
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.usedFilters = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.usedFiltersList, function(id) {
                  return _c("option", { domProps: { value: id } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.optionsAux[id]) +
                        "\n                "
                    )
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _vm.isMultiple
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "m-btn btn btn-sm btn-link",
                          attrs: {
                            type: "button",
                            disabled: _vm.nbReadOnly,
                            id: "return-all-filters-" + _vm.prefix + _vm.name
                          },
                          on: {
                            click: function($event) {
                              return _vm.returnAllData()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-angle-double-left" }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")("report_groupings.remove_all")
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6 text-right" }, [
                      _c("span", { staticClass: "m-form__help" }, [
                        _vm._v(
                          _vm._s(_vm._f("trans")("report_groupings.include"))
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("nb-error-block", { attrs: { path: "filters." + _vm.name } })
            ],
            1
          )
        ])
      : _c(
          "div",
          [
            _c("multiselect", {
              attrs: {
                options: _vm.options,
                searchable: false,
                "close-on-select": true,
                "allow-empty": false,
                "track-by": "value",
                label: "text"
              },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }