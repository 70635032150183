var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-delivery__container" }, [
    _c("h2", { staticClass: "c-delivery__main-title" }, [
      _vm._v("Selecciona el método de envío:")
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "c-delivery__menu" }, [
      _c(
        "li",
        {
          staticClass: "c-delivery__menu-li",
          class: { "is-active": _vm.activeTab === "recurrent" }
        },
        [
          _c(
            "label",
            {
              staticClass: "c-delivery__menu-li-label m-radio",
              attrs: { disabled: _vm.nbReadOnly }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activeTab,
                    expression: "activeTab"
                  }
                ],
                staticClass: "c-delivery__menu-li-button",
                attrs: {
                  type: "radio",
                  name: "option-recurrent",
                  id: "recurrent-option",
                  value: "recurrent",
                  disabled: _vm.nbReadOnly
                },
                domProps: { checked: _vm._q(_vm.activeTab, "recurrent") },
                on: {
                  change: function($event) {
                    _vm.activeTab = "recurrent"
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "c-delivery__menu-li-radio" }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("p", { staticClass: "c-delivery__menu-li-txt" }, [
                _vm._v(
                  "\n          Configura las fechas, días, horas ... para enviar una campaña de forma perdiódica.\n        "
                )
              ]),
              _vm._v(" "),
              _vm.recurrent
                ? _c("div", { staticClass: "c-delivery__status" }, [
                    _vm._v("Activado")
                  ])
                : _c("div", { staticClass: "c-delivery__status--off" }, [
                    _vm._v("Desactivado")
                  ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "c-delivery__menu-li",
          class: { "is-active": _vm.activeTab === "trigger" }
        },
        [
          _c(
            "label",
            {
              staticClass: "c-delivery__menu-li-label m-radio",
              attrs: { disabled: _vm.nbReadOnly }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activeTab,
                    expression: "activeTab"
                  }
                ],
                staticClass: "c-delivery__menu-li-button",
                attrs: {
                  type: "radio",
                  name: "option-trigger",
                  id: "trigger",
                  value: "trigger",
                  disabled: _vm.nbReadOnly
                },
                domProps: { checked: _vm._q(_vm.activeTab, "trigger") },
                on: {
                  change: function($event) {
                    _vm.activeTab = "trigger"
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "c-delivery__menu-li-radio" }),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("p", { staticClass: "c-delivery__menu-li-txt" }, [
                _vm._v("Crea un disparador para que se envíe automáticamente.")
              ]),
              _vm._v(" "),
              _vm.triggerEnabled
                ? _c("div", { staticClass: "c-delivery__status" }, [
                    _vm._v("Activado")
                  ])
                : _c("div", { staticClass: "c-delivery__status--off" }, [
                    _vm._v("Desactivado")
                  ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "c-delivery__menu-li",
          class: { "is-active": _vm.activeTab === "send" }
        },
        [
          _c(
            "label",
            {
              staticClass: "c-delivery__menu-li-label m-radio",
              attrs: { disabled: _vm.nbReadOnly }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activeTab,
                    expression: "activeTab"
                  }
                ],
                staticClass: "c-delivery__menu-li-button",
                attrs: {
                  type: "radio",
                  name: "option-send",
                  id: "send",
                  value: "send",
                  disabled: _vm.nbReadOnly
                },
                domProps: { checked: _vm._q(_vm.activeTab, "send") },
                on: {
                  change: function($event) {
                    _vm.activeTab = "send"
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "c-delivery__menu-li-radio" }),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("p", { staticClass: "c-delivery__menu-li-txt" }, [
                _vm._v("Envía una campaña de forma manual y al instante.")
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "c-delivery__menu-li",
          class: { "is-active": _vm.activeTab === "send-test" }
        },
        [
          _c(
            "label",
            {
              staticClass: "c-delivery__menu-li-label m-radio",
              attrs: { disabled: _vm.nbReadOnly }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activeTab,
                    expression: "activeTab"
                  }
                ],
                staticClass: "c-delivery__menu-li-button",
                attrs: {
                  type: "radio",
                  name: "option-send-test",
                  id: "send-test",
                  value: "send-test",
                  disabled: _vm.nbReadOnly
                },
                domProps: { checked: _vm._q(_vm.activeTab, "send-test") },
                on: {
                  change: function($event) {
                    _vm.activeTab = "send-test"
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "c-delivery__menu-li-radio" }),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("p", { staticClass: "c-delivery__menu-li-txt" }, [
                _vm._v(
                  "Realiza un envío de prueba para ver que todo está bien."
                )
              ])
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-delivery__config-options m-form" }, [
      _c(
        "div",
        {
          staticClass: "c-delivery__config-option-container",
          class: { "is-active": _vm.activeTab === "recurrent" }
        },
        [
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "row m-0 pb-3" }, [
            _c("input", {
              attrs: { type: "hidden", name: "recurrent", id: "recurrent" },
              domProps: { value: _vm.recurrent ? "1" : "0" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12" }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group m-form__group col-md-4" }, [
              _c("div", { staticClass: "input-group m-input-group" }, [
                _vm._m(5),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.scheduledRepeatDate,
                        expression: "scheduledRepeatDate"
                      }
                    ],
                    staticClass: "form-select form-select-solid",
                    attrs: {
                      id: "scheduled_repeat_date--input",
                      disabled: _vm.nbReadOnly
                    },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.scheduledRepeatDate = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: _vm.repeatDate.hour } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("trans")("campaign_component.schedule_hour")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: _vm.repeatDate.day } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("trans")("campaign_component.schedule_day")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: _vm.repeatDate.week } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("trans")("campaign_component.schedule_week")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "option",
                      { domProps: { value: _vm.repeatDate.month } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("trans")("campaign_component.schedule_month")
                          )
                        )
                      ]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group m-form__group col-md-4" }, [
              _c(
                "div",
                { staticClass: "input-group m-input-group" },
                [
                  _vm._m(6),
                  _vm._v(" "),
                  _c("label"),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.scheduledStart,
                        expression: "scheduledStart"
                      }
                    ],
                    staticClass: "form-control m-input",
                    attrs: {
                      id: "schedule_start",
                      type: "date",
                      min: "1000-01-01",
                      max: "5000-01-01",
                      disabled: _vm.nbReadOnly || _vm.isNotRecurrent
                    },
                    domProps: { value: _vm.scheduledStart },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.scheduledStart = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.scheduledStartFormatted,
                        expression: "scheduledStartFormatted"
                      }
                    ],
                    attrs: { type: "hidden", name: "scheduled_start" },
                    domProps: { value: _vm.scheduledStartFormatted },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.scheduledStartFormatted = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("nb-error-block", { attrs: { path: "scheduled_start" } })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group m-form__group col-md-4" }, [
              _c(
                "div",
                { staticClass: "input-group m-input-group" },
                [
                  _vm._m(7),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.scheduledTime,
                        expression: "scheduledTime"
                      }
                    ],
                    staticClass: "form-control m-input",
                    attrs: {
                      id: "scheduled_time",
                      type: "time",
                      disabled: _vm.nbReadOnly || _vm.isNotRecurrent
                    },
                    domProps: { value: _vm.scheduledTime },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.scheduledTime = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "scheduled_conditions" },
                    domProps: { value: _vm.scheduledConditions }
                  }),
                  _vm._v(" "),
                  _c("nb-error-block", {
                    attrs: { path: "scheduled_conditions.time" }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group m-form__group col-md-4" }, [
              _c("div", { staticClass: "input-group m-input-group" }, [
                _vm._m(8),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scheduledRepeat,
                      expression: "scheduledRepeat"
                    }
                  ],
                  staticClass: "form-control m-input",
                  attrs: {
                    id: "schedule_repeat",
                    type: "number",
                    disabled: _vm.nbReadOnly || _vm.isNotRecurrent
                  },
                  domProps: { value: _vm.scheduledRepeat },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.scheduledRepeat = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "scheduled_repeat" },
                  domProps: { value: _vm.scheduledRepeat }
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "scheduled_repeat_date" },
                  domProps: { value: _vm.scheduledRepeatDate }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "input-group-append" }, [
                  _vm.scheduledRepeatDate === _vm.repeatDate.hour
                    ? _c("span", { staticClass: "input-group-text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("trans")(
                                "campaign_component.schedule_hour"
                              )
                            ) +
                            "\n              "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.scheduledRepeatDate === _vm.repeatDate.day
                    ? _c("span", { staticClass: "input-group-text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("trans")("campaign_component.schedule_day")
                            ) +
                            "\n              "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.scheduledRepeatDate === _vm.repeatDate.week
                    ? _c("span", { staticClass: "input-group-text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("trans")(
                                "campaign_component.schedule_week"
                              )
                            ) +
                            "\n              "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.scheduledRepeatDate === _vm.repeatDate.month
                    ? _c("span", { staticClass: "input-group-text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("trans")(
                                "campaign_component.schedule_month"
                              )
                            ) +
                            "\n              "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group m-form__group col-md-8" }, [
              _c(
                "div",
                {
                  staticClass: "btn-group m-btn-group btn-block",
                  attrs: { role: "group" }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "mr-2",
                      staticStyle: { "line-height": "37px" }
                    },
                    [_vm._v("Los días:")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.weekDays, function(day) {
                    return _c(
                      "button",
                      {
                        staticClass: "btn",
                        class: {
                          "btn-brand": _vm.viewDay(day.id),
                          "btn m-btn btn-secondary": !_vm.viewDay(day.id)
                        },
                        attrs: {
                          type: "button",
                          id: "day-" + day.id,
                          disabled: _vm.nbReadOnly || _vm.isNotRecurrent
                        },
                        on: {
                          click: function($event) {
                            return _vm.markDay(day)
                          }
                        }
                      },
                      [
                        _c("em", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.viewDay(day.id),
                              expression: "viewDay(day.id)"
                            }
                          ],
                          staticClass: "la la-check mr-1"
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(day.value) +
                            "\n            "
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: {
                        "btn-brand": _vm.allDaysMarked,
                        "btn m-btn btn-secondary": !_vm.allDaysMarked
                      },
                      attrs: {
                        type: "button",
                        id: "all-days",
                        disabled: _vm.nbReadOnly || _vm.isNotRecurrent
                      },
                      on: { click: _vm.markAllDays }
                    },
                    [
                      _c("em", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allDaysMarked,
                            expression: "allDaysMarked"
                          }
                        ],
                        staticClass: "la la-check mr-1"
                      }),
                      _vm._v("\n              Todos\n            ")
                    ]
                  )
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group m-form__group col-md-4" }, [
              _c("div", { staticClass: "input-group m-input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scheduledShipments,
                      expression: "scheduledShipments"
                    }
                  ],
                  staticClass: "form-control m-input",
                  attrs: {
                    id: "scheduled_shipments",
                    type: "number",
                    disabled: _vm.nbReadOnly || _vm.isNotRecurrent,
                    placeholder: _vm.getTrans(
                      "campaign_component.schedule_placeholder"
                    )
                  },
                  domProps: { value: _vm.scheduledShipments },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.scheduledShipments = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm._m(9),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "scheduled_shipments" },
                  domProps: { value: _vm.scheduledShipments }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "small form-text text-muted mb-2",
                  attrs: { id: "schedule_email_help" }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("trans")(
                          "campaign_component.schedule_shipments_message"
                        )
                      ) +
                      "\n          "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group m-form__group col-md-4" }, [
              _c("div", { staticClass: "input-group m-input-group" }, [
                _vm._m(10),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scheduledEnd,
                      expression: "scheduledEnd"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "schedule_end",
                    type: "datetime-local",
                    min: "1000-01-01T00:00",
                    max: "5000-01-01T00:00",
                    disabled: _vm.nbReadOnly || _vm.isNotRecurrent
                  },
                  domProps: { value: _vm.scheduledEnd },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.scheduledEnd = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.scheduledEndFormatted,
                      expression: "scheduledEndFormatted"
                    }
                  ],
                  attrs: { type: "hidden", name: "scheduled_end" },
                  domProps: { value: _vm.scheduledEndFormatted },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.scheduledEndFormatted = $event.target.value
                    }
                  }
                })
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-delivery__config-option-container",
          class: { "is-active": _vm.activeTab === "trigger" }
        },
        [
          _vm._m(11),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row m-0 pb-4" },
            [
              _c(
                "select-with-create",
                {
                  attrs: {
                    "nb-read-only": _vm.nbReadOnly,
                    fieldName: "crm_trigger_id",
                    model: "triggers",
                    route: "API.triggers.options",
                    initial: _vm.selectedTriggerId,
                    initialData: _vm.initialSelectedTrigger
                  },
                  on: { dirty: _vm.updateDirty },
                  scopedSlots: _vm._u([
                    {
                      key: "modalTitle",
                      fn: function() {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm._f("trans")("campaign_component.trigger")
                            )
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("h5", [
                    _vm._v(
                      "Cuando las condiciones definidas en el disparador se cumplan, la campaña se enviará."
                    )
                  ]),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Disparador")])
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-delivery__config-option-container",
          class: { "is-active": _vm.activeTab === "send" }
        },
        [
          _vm._m(12),
          _vm._v(" "),
          _c("div", { staticClass: "row m-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "form-group m-form__group col-12 d-flex mb-4 align-items-center"
              },
              [
                _c("h5", { staticClass: "mb-0 mr-4" }, [
                  _vm._v(
                    "Envía ahora mismo la campaña a los usuarios seleccionados"
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary c-delivery__btn-send",
                    attrs: {
                      id: "send-campaign",
                      type: "button",
                      disabled: _vm.nbReadOnly
                    },
                    on: { click: _vm.sendCampaign }
                  },
                  [
                    _vm._v("\n            Enviar\n            "),
                    _c("em", { staticClass: "la la-angle-right" })
                  ]
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-delivery__config-option-container",
          class: { "is-active": _vm.activeTab === "send-test" }
        },
        [
          _vm._m(13),
          _vm._v(" "),
          _c("div", { staticClass: "row m-0" }, [
            _c(
              "div",
              { staticClass: "form-group m-form__group col-md-9 mb-2 pb-3" },
              [
                _c("h5", [
                  _vm._v(
                    "Selecciona destinatarios para la prueba separados por punto y coma"
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.testEmails,
                        expression: "testEmails"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "test_emails",
                      type: "text",
                      placeholder: "Correos electrónicos separados por ;",
                      disabled: _vm.nbReadOnly
                    },
                    domProps: { value: _vm.testEmails },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.testEmails = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary c-delivery__btn-send",
                      attrs: {
                        type: "button",
                        id: "send-test-campaign",
                        disabled: _vm.nbReadOnly
                      },
                      on: { click: _vm.sendTestCampaign }
                    },
                    [
                      _vm._v("\n              Enviar\n              "),
                      _c("em", { staticClass: "la la-angle-right" })
                    ]
                  )
                ]),
                _vm._v(" "),
                this.errorEmails
                  ? _c("div", { staticClass: "m--font-danger" }, [
                      _vm._v("Debe escribir los destinatarios")
                    ])
                  : _vm._e()
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "c-delivery__config-repeat-container",
        class: { "is-active": _vm.activeTab !== "" }
      },
      [
        _c("div", { staticClass: "c-delivery__title-box" }, [
          _c("em", { staticClass: "c-delivery__icon la la-users" }),
          _vm._v(" "),
          _c("h4", { staticClass: "c-delivery__title" }, [
            _vm._v(
              _vm._s(
                _vm._f("trans")("campaign_component.dont_repeat_recipients")
              )
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "m-checkbox-list m-4" }, [
          _c("input", {
            attrs: {
              type: "hidden",
              name: "repeat_recipients",
              id: "repeat_recipients"
            },
            domProps: { value: _vm.dontRepeatRecipients ? "0" : "1" }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "m-checkbox", attrs: { disabled: _vm.nbReadOnly } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.dontRepeatRecipients,
                    expression: "dontRepeatRecipients"
                  }
                ],
                attrs: {
                  id: "toggle_recipients_repeat",
                  type: "checkbox",
                  disabled: _vm.nbReadOnly
                },
                domProps: {
                  checked: Array.isArray(_vm.dontRepeatRecipients)
                    ? _vm._i(_vm.dontRepeatRecipients, null) > -1
                    : _vm.dontRepeatRecipients
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.dontRepeatRecipients,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.dontRepeatRecipients = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.dontRepeatRecipients = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.dontRepeatRecipients = $$c
                    }
                  }
                }
              }),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("trans")(
                      "campaign_component.dont_repeat_recipients_message"
                    )
                  ) +
                  "\n        "
              ),
              _c("span")
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-delivery__menu-li-title-box" }, [
      _c("em", { staticClass: "c-delivery__menu-li-icon la la-clock-o" }),
      _vm._v(" "),
      _c("h4", { staticClass: "c-delivery__menu-li-title" }, [
        _vm._v("Programación")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-delivery__menu-li-title-box" }, [
      _c("em", { staticClass: "c-delivery__menu-li-icon la la-hand-o-right" }),
      _vm._v(" "),
      _c("h4", { staticClass: "c-delivery__menu-li-title" }, [
        _vm._v("Disparador")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-delivery__menu-li-title-box" }, [
      _c("em", { staticClass: "c-delivery__menu-li-icon fa fa-send-o" }),
      _vm._v(" "),
      _c("h4", { staticClass: "c-delivery__menu-li-title" }, [
        _vm._v("¡Envíalo ya!")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-delivery__menu-li-title-box" }, [
      _c("em", { staticClass: "c-delivery__menu-li-icon fa fa-envelope-o" }),
      _vm._v(" "),
      _c("h4", { staticClass: "c-delivery__menu-li-title" }, [
        _vm._v("Envío de prueba")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-delivery__title-box" }, [
      _c("em", { staticClass: "c-delivery__icon la la-clock-o" }),
      _vm._v(" "),
      _c("h4", { staticClass: "c-delivery__title" }, [
        _vm._v("Opciones de programación")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("Enviar por")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("Empezando el")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("a las")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("Cada")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("veces")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("Y terminar el")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-delivery__title-box" }, [
      _c("em", { staticClass: "c-delivery__icon la la-hand-o-right" }),
      _vm._v(" "),
      _c("h4", { staticClass: "c-delivery__title" }, [
        _vm._v("Configuración del disparador")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-delivery__title-box" }, [
      _c("em", { staticClass: "c-delivery__icon fa fa-send-o" }),
      _vm._v(" "),
      _c("h4", { staticClass: "c-delivery__title" }, [
        _vm._v("3, 2, 1 ... ¡Ya!")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-delivery__title-box" }, [
      _c("em", { staticClass: "c-delivery__icon fa fa-envelope-o" }),
      _vm._v(" "),
      _c("h4", { staticClass: "c-delivery__title" }, [
        _vm._v("Escribe el destinatario")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }