var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "buttons-list" }, [
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        attrs: { type: "button", id: "add-text", title: "Añadir texto" },
        on: { click: _vm.addTextField }
      },
      [_c("i", { staticClass: "fa fa-fw fa-file-text" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        attrs: { type: "button", id: "add-image", title: "Añadir imagen" },
        on: { click: _vm.addImageField }
      },
      [_c("i", { staticClass: "fa fa-fw fa-image" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        attrs: {
          type: "button",
          id: "add-barcode",
          title: "Añadir código de barras"
        },
        on: { click: _vm.addBarcodeField }
      },
      [_c("i", { staticClass: "fa fa-fw fa-barcode" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        attrs: { type: "button", id: "add-qr", title: "Añadir código QR" },
        on: { click: _vm.addQrField }
      },
      [_c("i", { staticClass: "fa fa-fw fa-qrcode" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        attrs: {
          type: "button",
          id: "duplicate-element",
          disabled:
            !_vm.activeElement ||
            (_vm.activeElement.blocked &&
              _vm.powerUserLoged < _vm.activeElement.blockPower),
          title: "Copiar elemento"
        },
        on: { click: _vm.duplicateActiveElement }
      },
      [_c("i", { staticClass: "fa fa-fw fa-copy" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        attrs: {
          type: "button",
          id: "remove-element",
          disabled:
            _vm.disableDeleteButton ||
            !_vm.activeElement ||
            (_vm.activeElement.blocked &&
              _vm.powerUserLoged < _vm.activeElement.blockPower),
          title: "Eliminar elemento"
        },
        on: { click: _vm.removeActiveElement }
      },
      [_c("i", { staticClass: "fa fa-fw fa-trash" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        class: {
          "btn-brand": _vm.activeBorders,
          "btn-light": !_vm.activeBorders
        },
        attrs: { type: "button", id: "show-borders", title: "Mostrar bordes" },
        on: { click: _vm.showBorders }
      },
      [_c("i", { staticClass: "fa fa-fw fa-square-o" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        attrs: { type: "button", id: "zoom-plus", title: "Aumentar" },
        on: { click: _vm.zoomPlus }
      },
      [_c("i", { staticClass: "fa fa-fw fa-search-plus" })]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "buttons-list__btn-view-zoom buttons-view-zoom",
        attrs: { id: "zoom" },
        on: { click: _vm.zoomNormal }
      },
      [
        _vm._v(
          "\n        " +
            _vm._s((_vm.zoom + _vm.zoomBase) * _vm.zoomBase) +
            " %\n    "
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        attrs: { type: "button", id: "zoom-less", title: "Disminuir" },
        on: { click: _vm.zoomLess }
      },
      [_c("i", { staticClass: "fa fa-fw fa-search-minus" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        attrs: {
          type: "button",
          id: "print",
          disabled: !_vm.getPrintUrl(),
          title: "Imprimir"
        },
        on: { click: _vm.print }
      },
      [_c("i", { staticClass: "fa fa-fw fa-print" })]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
        staticClass:
          "buttons-list__btn btn m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only",
        class: { "btn-brand": _vm.fullScreen, "btn-light": !_vm.fullScreen },
        attrs: {
          type: "button",
          id: "full-screen",
          title: "Pantalla completa"
        },
        on: { click: _vm.changeFullScreen }
      },
      [
        _c("i", {
          staticClass: "fa fa-fw",
          class: { "fa-expand": !_vm.fullScreen, "fa-compress": _vm.fullScreen }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }