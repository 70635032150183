var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tbody", [
    _c("tr", [
      _c("td", [
        _c(
          "div",
          {
            class: { disabled: _vm.nbReadOnly },
            style: { paddingLeft: _vm.depth * 15 + "px" }
          },
          [
            _c("strong", { staticClass: "fa fa-fw" }, [_vm._v("•")]),
            _vm._v(" "),
            _vm.userRoleSpecialPermission
              ? _c("i", {
                  class: "fa role-status " + _vm.roleIcon,
                  attrs: {
                    title: _vm.$options.filters.trans(
                      "permission_component.inheritedRoleAuth"
                    )
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("i", {
              class: "fa state-icon " + _vm.icon,
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.checkSpecialPermission($event)
                }
              }
            }),
            _vm._v(
              "\n            " +
                _vm._s(_vm.specialPermission.name) +
                "\n        "
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }