const hexServerToString = colorHex =>
  colorHex != null ? `#${colorHex.substring(0, colorHex.length - 2)}` : ''

const stringToHexServer = colorString => colorString.replace('#', '') + 'FF'

const hexStringToRGBA = hexString => {
  const hexColors = hexString.replace('#', '').match(/.{1,2}/g)
  return {
    r: parseInt(hexColors[0], 16),
    g: parseInt(hexColors[1], 16),
    b: parseInt(hexColors[2], 16),
    a: hexColors.length === 4 ? parseInt(hexColors[3], 16) : 255,
  }
}

const colors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
]

const magmaColors = [
  '#000000',
  '#02020b',
  '#050416',
  '#090720',
  '#0e0b2b',
  '#140e36',
  '#1a1042',
  '#21114e',
  '#29115a',
  '#311165',
  '#390f6e',
  '#420f75',
  '#4a1079',
  '#52137c',
  '#5a167e',
  '#621980',
  '#6a1c81',
  '#721f81',
  '#792282',
  '#812581',
  '#892881',
  '#912b81',
  '#992d80',
  '#a1307e',
  '#aa337d',
  '#b2357b',
  '#ba3878',
  '#c23b75',
  '#ca3e72',
  '#d2426f',
  '#d9466b',
  '#e04c67',
  '#e75263',
  '#ec5860',
  '#f1605d',
  '#f4695c',
  '#f7725c',
  '#f97b5d',
  '#fb8560',
  '#fc8e64',
  '#fd9869',
  '#fea16e',
  '#feaa74',
  '#feb47b',
  '#febd82',
  '#fec68a',
  '#fecf92',
  '#fed89a',
  '#fde2a3',
  '#fdebac',
  '#fcf4b6',
  '#fcfdbf',
]

const lasColors = [
  '#999999',
  '#808080',
  '#5F5F5F',
  '#BD6137',
  '#A1522E',
  '#824326',
  '#2EFF2E',
  '#00FF00',
  '#02E002',
  '#01DC01',
  '#00CC00',
  '#00BA00',
  '#00A700',
  '#009900',
  '#018301',
  '#FFB527',
  '#FFA800',
  '#D68D00',
  '#FE43FE',
  '#FF00FF',
  '#C600C6',
  '#FF3F3F',
  '#FF0000',
  '#D10000',
  '#FFFF43',
  '#FFFF00',
  '#CECE02',
  '#72B6B6',
  '#4D9999',
  '#338181',
  '#3B3BFF',
  '#0000FF',
  '#0000B8',
]

export {
  colors,
  lasColors,
  magmaColors,
  hexServerToString,
  hexStringToRGBA,
  stringToHexServer,
}
