<template>
    <SuggesterText id="elementContent"
                   :placeholder="'Valor del elemento'"
                   :suggestions="availableVariables"
                   v-model="activeElement.value"
                   @input="$emit('layoutChange')"
    >
        <template slot-scope="{ suggestion }">
            {{ suggestion }}
            <span v-if="renderVariable(suggestion) !== suggestion" class="ml-2 m-badge m-badge--brand m-badge--wide">
                {{ renderVariable(suggestion) }}
            </span>
        </template>
    </SuggesterText>
</template>
<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters } from 'vuex'
  import SuggesterText from '@/js/components/ticket_editor/tabs/element-tabs/SuggesterText'
  import SuggesterScopeMixin from '@/js/components/ticket_editor/tabs/element-tabs/SuggesterScopeMixin'

  export default {
    name: 'ValueSuggester',
    components: { SuggesterText },
    mixins: [SuggesterScopeMixin],
    computed: {
      ...mapGetters('ticketEditor', {
        activeElement: 'activeElement',
        availableVariables: 'availableVariables',
      }),
    },
  }
</script>
