<template>
    <div
        class="card card-xl-stretch"
        :class="{
            'is-full-screen position-fixed modal overflow-auto top-0 start-0 bottom-0 end-0 was-full-screen rounded-0 d-block': isFullDashboard,
            'was-full-screen': wasFullDashboard,
            loading: loading
        }"
        ref="dashboardPlatform"
    >
        <!-- Header -->
        <div class="card-header border-0 py-5">
            <h3 class="card-title fw-bolder">
                <i class="fa fa-fw fa-sliders-h text-muted pe-2"></i>
                Uso de la plataforma
            </h3>
            <div class="card-toolbar">
                <button
                    class="btn btn-sm btn-icon btn-color-dark btn-active-dark btn-active-color- border-0"
                    @click="exportToExcel"
                >
                    <i class="fa fa-download"></i>
                </button>
                <button
                    class="btn btn-sm btn-icon btn-color-dark btn-active-dark btn-active-color- border-0 me-n3"
                    @click="toggleFullDashboard"
                >
                    <i class="fa fa-expand-alt"></i>
                </button>
            </div>
        </div>
        <!-- Header FIN -->

        <!-- Cuerpo -->
        <div class="card-body d-flex flex-column">
            <div class="mixed-widget-5-chart card-rounded-top">
                <canvas id="registeredUsersChart"></canvas>
            </div>

            <!-- content without img -->
            <div class="mt-5">
                <!--begin::Item-->
                <div class="d-flex flex-stack mb-5">
                    <!--begin::Section-->
                    <div class="d-flex align-items-center me-2">
                        <!--begin::Title-->
                        <div>
                            <a
                                href="#"
                                class="fs-6 text-gray-800 text-hover-primary fw-bolder"
                                >Usuarios registrados</a
                            >
                            <div class="fs-7 text-muted fw-bold mt-1">
                                en el periodo
                            </div>
                        </div>
                        <!--end::Title-->
                    </div>
                    <!--end::Section-->
                    <!--begin::Label-->
                    <div class="badge badge-light fw-bold py-4 px-3">
                        {{ customersRecount }}
                    </div>
                    <!--end::Label-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex flex-stack mb-5">
                    <!--begin::Section-->
                    <div class="d-flex align-items-center me-2">
                        <!--begin::Title-->
                        <div>
                            <a
                                href="#"
                                class="fs-6 text-gray-800 text-hover-primary fw-bolder"
                                >Bajas</a
                            >
                        </div>
                        <!--end::Title-->
                    </div>
                    <!--end::Section-->
                    <!--begin::Label-->
                    <div class="badge badge-light fw-bold py-4 px-3">
                        {{ unsubscribedCustomersRecount }}
                    </div>
                    <!--end::Label-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex flex-stack mb-5">
                    <!--begin::Section-->
                    <div class="d-flex align-items-center me-2">
                        <!--begin::Title-->
                        <div>
                            <a
                                href="#"
                                class="fs-6 text-gray-800 text-hover-primary fw-bolder"
                                >Ausencias</a
                            >
                        </div>
                        <!--end::Title-->
                    </div>
                    <!--end::Section-->
                    <!--begin::Label-->
                    <div class="badge badge-light fw-bold py-4 px-3">0</div>
                    <!--end::Label-->
                </div>
                <!--end::Item-->
                <!-- items FIN -->

                <!-- datos 2nd nivel -->
                <!-- Ver si interesa algo de esto para el base o se deja a cada proyecto -->
                <!--<div class="c-dashboard-portlet__full-screen-only row pt-2">
                    <div class="c-dashboard_body-content-block">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>
                                        <em class="flaticon-user"></em>
                                        Mejores clientes
                                    </th>
                                    <th>Entradas</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="customer in bestCustomers">
                                    <td>{{ customer.name }}</td>
                                    <td>{{ customer.recount }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>-->
                <!-- datos 2nd nivel FIN -->
            </div>
            <!-- content without img FIN -->
        </div>
        <!-- Cuerpo FIN -->
    </div>
</template>

<script>
import axios from "axios";
import RelativeDatePicker from "@/js/components/dashboard/RelativeDatePicker";
import DateHelpers from "@/js/mixins/DateHelpers";
import { mapActions, mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
    name: "dashboard-component",
    mixins: [DateHelpers],
    components: { RelativeDatePicker },
    data() {
        return {
            isFullDashboard: false,
            wasFullDashboard: false,
            loading: true,
            customersRecount: 0,
            unsubscribedCustomersRecount: 0,
            bestCustomers: [],
            ctx: null,
            registeredUsersChart: null,
            chart:null,
            chart_data: [],
        };
    },
    watch: {
        dateFilters(newValue, oldValue) {
            this.updateData();
            this.updateChart()
        },
    },
    computed: {
        ...mapGetters("Dashboard", {
            dateFilters: "getDateFilters",
        })
    },
    async mounted() {
        this.ctx = document
            .getElementById("registeredUsersChart")
            .getContext("2d");
        await this.getChart(this.ctx);
    },
    methods: {
        ...mapActions("Dashboard", {
            setRegisteredCustomers: "setRegisteredCustomers",
            setUnsubscribedCustomers: "setUnsubscribedCustomers",
            setDashboardAbsences: "setDashboardAbsences"
        }),
        updateData: debounce(async function() {
            this.loading = true;
            mApp.block(this.$refs.dashboardPlatform, {});

            const data = (
                await axios.post(this.$route("API.general.dashboard"), {
                    params: {
                        dateFiltersData: this.dateFilters,
                    }
                })
            ).data;
            this.customersRecount = data.customers;
            this.unsubscribedCustomersRecount = data.unsubscribedCustomers;
            this.bestCustomers = data.bestCustomers;

            this.setRegisteredCustomers(this.customersRecount);
            this.setUnsubscribedCustomers(this.unsubscribedCustomersRecount);
            this.setDashboardAbsences(0);

            mApp.unblock(this.$refs.dashboardPlatform);
            this.loading = false;
        }, 500),

        async exportToExcel() {
            window.open(
                this.$route("API.general.dashboard.export", {
                    paramsData: $.param({
                        dateFiltersData: { ...this.dateFilters },
                    })
                }),
                "_blank"
            );
        },

        toggleFullDashboard() {
            this.isFullDashboard = !this.isFullDashboard;
            if (this.isFullDashboard) {
                document.body.classList.add("has-full-modal");
                this.wasFullDashboard = false;
            } else {
                document.body.classList.remove("has-full-modal");
                this.wasFullDashboard = true;
            }
        },
        updateChart: debounce(async function () {
            const response = await axios.post(this.$route('API.general.dashboard.chart'), {
                dateFiltersData: this.dateFilters,
            })
            const data = response.data

            let dataset = data.customers
            let labels = data.months
            this.fillChartData(dataset, labels)
        }, 500),

        fillChartData(dataset, labels) {
            this.registeredUsersChart.data.labels = labels
            this.registeredUsersChart.data.datasets[0].data = dataset
            Chart.defaults.scales['linear'] =
                {
                    ticks: {
                        max: Math.max(...dataset) + 1
                    }
                }
            this.registeredUsersChart.update()
        },
        async getChart(ctx) {
            await axios
                .post(this.$route("API.general.dashboard.chart"), {
                    dateFiltersData: this.dateFilters,
                })
                .then(response => {
                    this.chart_data["months"] = response.data.months;
                    this.chart_data["customers"] = response.data.customers;

                    this.registeredUsersChart = new Chart(ctx, {
                        type: "line",
                        data: {
                            labels: this.chart_data.months,
                            datasets: [
                                {
                                    data: this.chart_data.customers,
                                    backgroundColor: "rgba(0,125,184, 0.6)",
                                    borderColor: "rgba(0,125,184, 1)",
                                    borderWidth: 3,
                                    label: "nº de usuarios registrados",
                                    fill: "start"
                                }
                            ]
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            elements: {
                                line: {
                                    tension: 0
                                }
                            },
                            legend: {
                                display: false
                            },
                            title: {
                                text: "Usuarios registrados",
                                display: false
                            },
                            scales: {
                                xAxes: [
                                    {
                                        display: false,
                                        gridLines: {
                                            display: false
                                        }
                                    }
                                ],
                                yAxes: [
                                    {
                                        display: false,
                                        gridLines: {
                                            display: false
                                        }
                                    }
                                ]
                            }
                        }
                    });
                })
                .catch(error => console.log(error));
        }
    }
};
</script>

<style>
.m-portlet .m-portlet__head .m-portlet__head-label {
}
</style>
