<template>
  <div class="form-group m-form__group col-12 my-4 accordion" id="kt_accordion_1">
    <input
      type="hidden"
      name="generalFeaturesData"
      :value="jsonGeneralFeaturesData"
    />

      <!-- ITEM -->
    <div
      v-for="(generalFeature, i) of dataGeneralFeatures"
      :key="i"
      class="accordion-item"
    >

        <!-- cabecera item -->
        <h2 class="accordion-header" :id="'kt_accordion_1_header_' + i">
            <button :class="['accordion-button', 'text-dark', 'fs-4', 'fw-semibold', {'collapsed': (i !== dataGeneralFeatures.length - 1)}]"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#kt_accordion_1_body_' + i"
              aria-expanded="true"
              aria-controls="kt_accordion_1_body_1"
            >
                <button
                    v-if="!lang"
                    class="btn btn-icon btn-danger btn-sm m-2 me-5"
                    @click.prevent="remove(i)"
                ><i class="fa fa-trash"></i></button>
                Destacado {{i + 1}}
            </button>
        </h2>
        <!-- FIN cabecera item -->

        <!-- contenido item -->
        <div :id="'kt_accordion_1_body_' + i"
          :class="['accordion-collapse collapse', {'show': (i === dataGeneralFeatures.length - 1)}]"
          :aria-labelledby="'kt_accordion_1_header_' + i"
          data-bs-parent="#kt_accordion_1"
        >
            <div class="accordion-body">
                <div class="m-4">

                <div class="form-group mb-4" v-if="!lang">
                  <label class="form-label">Color de fondo</label>
                  <select class="form-control" v-model="generalFeature.backgroundColor">
                    <option
                      :key="backgroundColor.id"
                      v-for="backgroundColor in backgroundColorOptions"
                      :value="backgroundColor.id"
                    >
                      {{ backgroundColor.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group mb-4 trumbowyg-small">
                  <label class="form-label">Texto</label>
                  <trumbowyg
                    :style="'height: 120px;'"
                    @input="$emit('input', generalFeature.text)"
                    v-model="generalFeature.text"
                    :config="configs"
                  ></trumbowyg>
                  <span v-if="lang" v-html="generalFeaturesDefault[i].text"></span>
                </div>

                <div class="form-group mb-4" v-if="!lang">
                  <label class="form-label">Imagen</label>
                  <repository-button
                    :allowed-mime-types="allowedMimeTypes"
                    :short-version="true"
                    v-model="generalFeature.image"
                    class-field="col-md-12 px-0"
                    v-on:clear-value="clearValue(i)"
                    group-id="1"
                    group-type="App\Models\Rep\FileGroup"
                  >
                  </repository-button>
                </div>

                <div class="form-group mb-4" v-if="!lang">
                  <label class="form-label">Alineación de imagen</label>
                  <select class="form-control" v-model="generalFeature.alignment">
                    <option
                      :key="alignment.id"
                      v-for="alignment in alignmentsOptions"
                      :value="alignment.id"
                    >
                      {{ alignment.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group mb-4 row">
          <div class="col">
            <label class="form-label">Texto de botón</label>
            <input
              type="text"
              class="form-control"
              v-model="generalFeature.buttonText"
            />
            <span v-if="lang">{{ generalFeaturesDefault[i].buttonText }}</span>
          </div>
          <div class="col">
            <label class="form-label">Url de botón</label>
            <input
              :disabled="lang !== null ? true : false"
              type="url"
              class="form-control"
              v-model="generalFeature.buttonUrl"
            />
          </div>
        </div>

                </div>
            </div>
      </div>
        <!-- FIN contenido item -->
    </div>
      <!-- FIN ITEM -->

      <!-- boton añadir item -->
    <button
      v-if="!lang"
      type="button"
      class="btn btn-secondary mt-9 mb-4"
      @click.stop="addFeatureBlock()"
    >
      Añadir destacado
      <i class="fa fa-plus-circle ms-3"></i>
    </button>
  </div>
</template>

<script>
require("trumbowyg/dist/plugins/table/trumbowyg.table");
require("trumbowyg/dist/plugins/table/ui/trumbowyg.table.css");

require("trumbowyg/dist/plugins/colors/trumbowyg.colors");
require("trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css");

require("trumbowyg/dist/plugins/emoji/trumbowyg.emoji");
require("trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.css");

require("trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily");
require("trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize");

import RepositoryButton from "@/js/components/repository_explorer/RepositoryButton";
import trumbowyg from "vue-trumbowyg";
import "trumbowyg/dist/langs/es.min.js";
import "trumbowyg/dist/ui/sass/trumbowyg.scss";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "GeneralFeaturesTab",
  components: { trumbowyg, RepositoryButton },
  props: {
    lang: String,
    generalFeatures: Array,
    generalFeaturesDefault: Array,
  },
  data() {
    return {
      configs: {
        // Any option from
        // https://alex-d.github.io/Trumbowyg/documentation/#basic-options
        lang: "es",
        autogrow: false,
        imageWidthModalEdit: true,
        removeformatPasted: true,
        btnsDef: {
          repositoryFile: {
            fn: () => {
              this.showRepository = true;
            },
            title: "Repositorio de imágenes",
            ico: "upload",
          },
        },
        btns: [
          ["viewHTML"],
          ["undo", "redo"], // Only supported in Blink browsers
          ["formatting"],
          ["fontfamily"],
          ["fontsize"],
          ["strong", "em", "del"],
          ["superscript", "subscript"],
          ["link"],
          ["emoji"],
          ["insertImage"],
          ["table"],
          ["foreColor", "backColor"],
          ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
          ["unorderedList", "orderedList"],
          ["horizontalRule"],
          ["removeformat"],
          ["repositoryFile"],
          ["fullscreen"],
        ],
      },
      dataGeneralFeatures: [],
      selectedImage: null,
      alignmentsOptions: [
        { id: "top", name: "Arriba" },
        { id: "right", name: "Derecha" },
        { id: "left", name: "Izquierda" },
      ],
      backgroundColorOptions: [
        { id: "white", name: "Blanco" },
        { id: "primary", name: "Primario" },
        { id: "secondary", name: "Secundario" },
      ],
    };
  },
  created() {
    this.dataGeneralFeatures = typeof this.generalFeatures === 'string' ? JSON.parse(this.generalFeatures) : this.generalFeatures
  },
  methods: {
    ...mapActions("ticketEditor", {
      onEditorLoading: "onEditorLoading",
      offEditorLoading: "offEditorLoading",
    }),
    async getFiles(ids) {
      this.onEditorLoading();
      try {
        let response = await axios.get(
          route("API.repository.getFiles", { files: ids })
        );
      } catch (error) {
        console.log(error);
      }
      this.offEditorLoading();
    },
    clearValue(i) {
      this.dataGeneralFeatures[i].image = null;
    },
    addFeatureBlock() {
      this.dataGeneralFeatures.push({
        backgroundColor: null,
        text: null,
        image: null,
        alignment: "right",
        buttonText: null,
        buttonUrl: null,
      });
    },
    remove(i) {
      this.dataGeneralFeatures.splice(i, 1);
    },
  },
  computed: {
    ...mapGetters("Repository", {
      allowedMimeTypes: "getMimeTypeImages",
    }),
    jsonGeneralFeaturesData() {
      return JSON.stringify(this.dataGeneralFeatures);
    },
  },
};
</script>

<style>
</style>
