var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "scrolltop",
        attrs: { id: "kt_scrolltop", "data-kt-scrolltop": "true" }
      },
      [_c("i", { staticClass: "fa fa-arrow-up" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }