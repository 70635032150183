import Vue from 'vue'
import { EventBus } from '@/js/vue/services/event-bus'
import Swal from 'sweetalert2'

export default function loadPrototypes () {
  Vue.prototype.$clone = (o) => Object.assign({}, o)
  Vue.prototype.$route = (name, params = {}, absolute = false) => route(name, params, absolute)
  Vue.prototype.$eventBus = EventBus
  Vue.prototype.$debug = window.node_env === 'development'
  Vue.prototype.$env = process.env
  Vue.prototype.$swal = Swal
  Vue.prototype.$global = global
  Vue.prototype.$constants = global.MODEL_CONSTANTS
  Vue.prototype.$supportUser = global.is_support_account_enabled
}
