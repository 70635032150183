<template>
  <div class="form-group m-form__group col-12 my-4 accordion" id="kt_accordion_1">
    <input type="hidden" name="testInfoData" :value="jsonTestInfoData" />

    <input type="hidden" name="testInfoHeader" v-model="dataTestInfoHeader" />

    <div class="form-group m-4 trumbowyg-small">
        <label class="form-label">Texto de cabecera</label>
        <trumbowyg
          @input="$emit('input', dataTestInfoHeader)"
          v-model="dataTestInfoHeader"
          :config="configs"
        ></trumbowyg>
        <span v-if="lang" v-html="testInfoHeaderDefault"></span>
    </div>
      <!-- ITEM -->
    <div
      v-for="(testData, i) of dataTestInfo"
      :key="i"
      class="accordion-item"
    >

        <!-- cabecera item -->
        <h2 class="accordion-header" :id="'kt_accordion_1_header_' + i">
            <button :class="['accordion-button', 'text-dark', 'fs-4', 'fw-semibold', {'collapsed': (i !== dataTestInfo.length - 1)}]"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#kt_accordion_1_body_' + i"
              aria-expanded="true"
              aria-controls="kt_accordion_1_body_1"
            >
                <button
                    v-if="!lang"
                    class="btn btn-icon btn-danger btn-sm m-2 me-5"
                    @click.prevent="remove(i)"
                ><i class="fa fa-trash"></i></button>
                Presentación test {{i + 1}}
            </button>
        </h2>
        <!-- FIN cabecera item -->

        <!-- contenido item -->
        <div :id="'kt_accordion_1_body_' + i"
          :class="['accordion-collapse collapse', {'show': (i === dataTestInfo.length - 1)}]"
          :aria-labelledby="'kt_accordion_1_header_' + i"
          data-bs-parent="#kt_accordion_1"
        >
            <div class="accordion-body">
                <div class="m-4">
                    <div class="form-group mb-4">
                          <label class="form-label">Titulo</label>
                          <input type="text" class="form-control" v-model="testData.mainText" />
                          <span v-if="lang">{{ testInfoDefault[i].mainText }}</span>
                    </div>
                    <div class="form-group mb-15 trumbowyg-small">
                      <label class="form-label">Descripción</label>
                      <trumbowyg
                        @input="$emit('input', testData.secondaryText)"
                        v-model="testData.secondaryText"
                        :config="configs"
                      ></trumbowyg>
                      <span v-if="lang" v-html="testInfoDefault[i].secondaryText"></span>
                    </div>

                    <div class="form-group mb-15 row">
                      <div class="col">
                        <label class="form-label">Texto de botón</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="testData.buttonText"
                        />
                        <span v-if="lang">{{ testInfoDefault[i].buttonText }}</span>
                      </div>
                      <div class="col">
                        <label class="form-label">Url de botón</label>
                        <multiselect
                          v-model="testData.buttonUrl"
                          :options="tests"
                          label="name"
                          track-by="id"
                          :disabled="
                            lang !== null ? true : testData.buttonText ? false : true
                          "
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="form-group mb-4">
                      <div>
                        <label
                          class="form-label"
                          v-if="
                            (testData.features && testData.features.length > 0 && lang) ||
                            !lang
                          "
                          >Caracteristicas</label
                        >
                      </div>
                      <div
                        v-for="(feature, index) in testData.features"
                        :key="index"
                        class="mb-2"
                      >
                        <div class="input-group">
                          <input
                            type="text"
                            v-model="testData.features[index]"
                            class="form-control"
                            placeholder="Escribe una respuesta"
                          />
                          <button
                            v-if="!lang"
                            type="button"
                            class="btn btn-danger"
                            @click.stop="removeFeature(testData, index)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                        <span v-if="lang">
                          {{ testInfoDefault[i].features[index] }}
                        </span>
                      </div>
                      <button
                        v-if="!lang"
                        type="button"
                        class="btn btn-primary"
                        @click.stop="addFeature(testData)"
                      >
                        Agregar Caracteristica
                        <i class="fa fa-plus"></i>
                      </button>
    </div>
    </div>
            </div>
        </div>
        <!-- FIN contenido item -->
    </div>
      <!-- FIN ITEM -->

    <!-- boton añadir item -->
    <button
      v-if="!lang"
      type="button"
      class="btn btn-secondary mt-9 mb-4"
      @click.stop="addTestInfo()"
    >
      Añadir presentación de test
      <i class="fa fa-plus-circle ms-3"></i>
    </button>
  </div>
</template>

<script>
require("trumbowyg/dist/plugins/table/trumbowyg.table");
require("trumbowyg/dist/plugins/table/ui/trumbowyg.table.css");

require("trumbowyg/dist/plugins/colors/trumbowyg.colors");
require("trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css");

require("trumbowyg/dist/plugins/emoji/trumbowyg.emoji");
require("trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.css");

require("trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily");
require("trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize");

import trumbowyg from "vue-trumbowyg";
import "trumbowyg/dist/langs/es.min.js";
import "trumbowyg/dist/ui/sass/trumbowyg.scss";
import { mapActions } from "vuex";

export default {
  name: "TestInfoTab",
  components: { trumbowyg },
  props: {
    lang: String,
    testInfoHeader: String,
    testInfoHeaderDefault: String,
    testInfo: Array,
    testInfoDefault: Array,
    tests: Array,
  },
  data() {
    return {
      configs: {
        // Any option from
        // https://alex-d.github.io/Trumbowyg/documentation/#basic-options
        lang: "es",
        autogrow: false,
        imageWidthModalEdit: true,
        removeformatPasted: true,
        btnsDef: {
          repositoryFile: {
            fn: () => {
              this.showRepository = true;
            },
            title: "Repositorio de imágenes",
            ico: "upload",
          },
        },
        btns: [
          ["viewHTML"],
          ["undo", "redo"], // Only supported in Blink browsers
          ["formatting"],
          ["fontfamily"],
          ["fontsize"],
          ["strong", "em", "del"],
          ["superscript", "subscript"],
          ["link"],
          ["emoji"],
          ["insertImage"],
          ["table"],
          ["foreColor", "backColor"],
          ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
          ["unorderedList", "orderedList"],
          ["horizontalRule"],
          ["removeformat"],
          ["repositoryFile"],
          ["fullscreen"],
        ],
      },
      dataTestInfo: [],
      dataTestInfoHeader: "",
      selectedImage: null,
    };
  },
  created() {
    this.dataTestInfo = typeof this.testInfo === 'string' ? JSON.parse(this.testInfo) : this.testInfo
    this.selectedImage = this.testInfo.image;
    this.dataTestInfoHeader = this.testInfoHeader;
  },
  methods: {
    ...mapActions("ticketEditor", {
      onEditorLoading: "onEditorLoading",
      offEditorLoading: "offEditorLoading",
    }),
    addTestInfo() {
      this.dataTestInfo.push({
        mainText: null,
        secondaryText: null,
        buttonText: null,
        buttonUrl: null,
        features: [],
      });
    },
    addFeature(testData) {
      testData.features.push("");
    },
    removeFeature(testData, i) {
      testData.features.splice(i, 1);
    },
    remove(i) {
      this.dataTestInfo.splice(i, 1);
    }
  },
  computed: {
    jsonTestInfoData() {
      return JSON.stringify(this.dataTestInfo);
    },
  },
};
</script>

<style>
</style>
