import Vue from 'vue'
import DetailView from '@/js/components/templating/detail-view/DetailView.vue'
import PermissionsComponent from '@/js/components/permissions/PermissionsComponent.vue'
import SpecialPermissionsComponent from '@/js/components/permissions/SpecialPermissionsComponent.vue'
import TaggerComponent from '@/js/components/tagger/Tagger'
import QuestionComponent from '@/js/components/question/QuestionComponent'
import SurveyComponent from '@/js/components/survey/SurveyComponent'
import PollComponent from '@/js/components/poll/PollComponent'
import HtmlEditor from '@/js/components/html_editor/HtmlEditor'
import DynamicTable from '@/js/components/dynamic_table/DynamicTable'
import CssEditor from '@/js/components/css_editor/CssEditor'
// TODO: EDITOR VISUAL Generalizar
import TicketEditor from '@/js/components/ticket_editor/TicketEditor'
import MetasGenerator from '@/js/components/metas/MetasGenerator'
import MessageComponent from '@/js/components/campaign/MessageComponent'
import CampaignStatistics from '@/js/components/campaign/CampaignStatistics'
import ListBoxEntity from '@/js/components/reports/ListBox-entity'
import ListBoxGroups from '@/js/components/reports/ListBox-groups'
import ListBoxSelect from '@/js/components/reports/ListBox-select'
import ListBoxFields from '@/js/components/reports/ListBox-fields'
import ReportGroupings from '@/js/components/reports/ReportGroupings'
import ReportDatePicker from '@/js/components/reports/ReportDatePicker'
import ReportNumber from '@/js/components/reports/ReportNumber'
import ReportCheckbox from '@/js/components/reports/ReportCheckbox'
import SortableComponent from '@/js/components/sortable/SortableComponent'
import Tokens from '@/js/components/crm/Tokens'
import EvaluatorInstances from '@/js/components/campaign/EvaluatorInstances'
import TriggerEvaluatorInstances from '@/js/components/campaign/TriggerEvaluatorInstances'
import UploadFiles from '@/js/components/repository_explorer/UploadFiles'
import EvaluatorInclude from '@/js/components/campaign/evaluators/EvaluatorInclude'
import EvaluatorOperator from '@/js/components/campaign/evaluators/EvaluatorOperator'
import EvaluatorGroups from '@/js/components/campaign/evaluators/EvaluatorGroups'
import EvaluatorDate from '@/js/components/campaign/evaluators/EvaluatorDate'
import SchedulingComponent from '@/js/components/reports/SchedulingComponent'
import CmsSelectorType from '@/js/components/cms/CmsSelectorType'
import CmsSelectorPage from '@/js/components/cms/CmsSelectorPage'
import Repository from '@/js/components/repository_explorer/Repository'
import RepositoryButton from '@/js/components/repository_explorer/RepositoryButton'
import ManagerList from '@/js/components/user/ManagerList'
import DashboardFilters from '@/js/components/dashboard/DashboardFilters'
import TranslationsForm from '@/js/components/translations/TranslationsForm'
import ModalComponent from '@/js/components/widgets/ModalComponent'
import QrCode from '@/js/components/widgets/QrCode'
import VueColorPicker from '@/js/components/widgets/VueColorPicker'
import DualMultiselect from '@/js/components/widgets/DualMultiselect'
import AutonomousMultiselect from '@/js/components/widgets/AutonomousMultiselect'
import CampaingDataFields from '@/js/components/campaign/CampaingDataFields'
import EditList from '@/js/components/widgets/EditList'
import ListSimple from '@/js/components/widgets/ListSimple'
import SelectWithCreate from '@/js/components/widgets/SelectWithCreate'
import ButtonUpload from '@/js/components/widgets/ButtonUpload'
import InternalIdField from '@/js/components/organization/InternalIdField'
import PreviewComponent from "@/js/components/reports/PreviewComponent"
import FooterWarning from "@/js/components/widgets/FooterWarning"
import QuickSearch from '@/js/components/quick_search/quick-search'
import ActionsSubmenu from '@/js/components/panel/actions-submenu'
import MenuSections from '@/js/components/panel/menu-sections'
import SupportCenter from '@/js/components/panel/SupportCenter'
import ScrolltopArrow from '@/js/components/panel/scrolltop-arrow'
import FavoriteCards from '@/js/components/home/FavoriteCards'
import NbErrorBlock from '@/js/components/error_block/nb-error-block'
import CustomerActivationsComponent from '@/js/components/crm/CustomerActivationsComponent'
import BillingAlert from '@/js/components/crm/BillingAlert'
import CustomerAddressesComponent from '@/js/components/crm/CustomerAddressesComponent'
import ImportComponent from '@/js/components/import/ImportComponent'
import MyReports from '@/js/components/reports/MyReports'
import CustomerName from '@/js/components/crm/CustomerName'
import CustomerCombine from '@/js/components/crm/CustomerCombine'
import CustomerNotifications from '@/js/components/crm/CustomerNotifications'
import DashboardComponent from '@/js/components/dashboard/DashboardComponent'
import BlogPostPostedFeaturedComponent from '@/js/components/BlogPostPostedFeaturedComponent'
import Statistics from '@/js/components/statistics/Statistics'
import ExternalRegisterConfig from '@/js/components/crm/ExternalRegisterConfig'
import CountrySelectComponent from '@/js/components/crm/CountrySelectComponent'
import TableComponent from '@/js/components/question_table/TableComponent'
import EditQuestionModal from '@/js/components/question_table/EditQuestionModal'
import ProfessionalSpecialtiesSelector from '@/js/components/professional_specialties_selector/ProfessionalSpecialtiesSelector'
import CustomerProfessionalSpecialties from '@/js/components/crm/CustomerProfessionalSpecialties'
import ConfigurationHome from '@/js/components/configuration_home/ConfigurationHome'
import EntityType from '@/js/components/activity_log/EntityType'
import AnswersComponent from '@/js/components/question_table/AnswersComponent'
import SelectTestType from '@/js/components/test/SelectTestType'

Vue.component('modal-component', ModalComponent)

export default {
  InternalIdField,
  ButtonUpload,
  CampaignStatistics,
  SelectWithCreate,
  ListSimple,
  EditList,
  CampaingDataFields,
  AutonomousMultiselect,
  DualMultiselect,
  QrCode,
  VueColorPicker,
  ModalComponent,
  QuestionComponent,
  SurveyComponent,
  PollComponent,
  HtmlEditor,
  CssEditor,
  TicketEditor,
  DetailView,
  PermissionsComponent,
  SpecialPermissionsComponent,
  TaggerComponent,
  MetasGenerator,
  MessageComponent,
  ListBoxEntity,
  ListBoxGroups,
  ListBoxSelect,
  ListBoxFields,
  ReportGroupings,
  ReportDatePicker,
  ReportNumber,
  ReportCheckbox,
  SortableComponent,
  Tokens,
  EvaluatorInstances,
  TriggerEvaluatorInstances,
  UploadFiles,
  EvaluatorInclude,
  EvaluatorOperator,
  EvaluatorGroups,
  EvaluatorDate,
  SchedulingComponent,
  CmsSelectorType,
  CmsSelectorPage,
  Repository,
  RepositoryButton,
  ManagerList,
  DashboardFilters,
  TranslationsForm,
  PreviewComponent,
  FooterWarning,
  QuickSearch,
  ActionsSubmenu,
  MenuSections,
  SupportCenter,
  ScrolltopArrow,
  FavoriteCards,
  NbErrorBlock,
  CustomerActivationsComponent,
  BillingAlert,
  CustomerAddressesComponent,
  ImportComponent,
  MyReports,
  CustomerName,
  CustomerCombine,
  CustomerNotifications,
  DashboardComponent,
  BlogPostPostedFeaturedComponent,
  Statistics,
  ExternalRegisterConfig,
  DynamicTable,
  CountrySelectComponent,
  TableComponent,
  EditQuestionModal,
  ProfessionalSpecialtiesSelector,
  CustomerProfessionalSpecialties,
  ConfigurationHome,
  EntityType,
  AnswersComponent,
  SelectTestType
}
