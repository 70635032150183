const STANDARD_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export default {
  filters: {
    moment: function (dateStr, format = 'DD/MM/YYYY HH:mm', sourceFormat = STANDARD_FORMAT) {
      if (!dateStr) return ''
      return moment(dateStr, sourceFormat).format(format)
    },
    fromNow: function (dateStr, sourceFormat = STANDARD_FORMAT) {
      if (!dateStr) return ''
      return moment(dateStr, sourceFormat).fromNow()
    },
    toNow: function (dateStr, sourceFormat = STANDARD_FORMAT) {
      if (!dateStr) return ''
      return moment(dateStr, sourceFormat).toNow()
    },
  },
}
