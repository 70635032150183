<template>
  <div class="form-group m-form__group col-12 my-4">
    <input type="hidden" name="mainBlockData" :value="jsonMainBlockData" />

    <div class="m-4">
      <div class="form-group mb-4">
        <label class="form-label">Subtitular </label>
        <input
          type="text"
          class="form-control"
          v-model="mainBlockData.secondaryText"
        />
        <span v-if="lang">{{ mainBlockDefault.secondaryText }}</span>
      </div>
      <div class="form-group mb-4 trumbowyg-small">
        <label class="form-label">Titular</label>
        <trumbowyg
          :style="'height: 120px;'"
          @input="$emit('input', mainBlockData.mainText)"
          v-model="mainBlockData.mainText"
          :config="configs"
        ></trumbowyg>
        <span v-if="lang" v-html="mainBlockDefault.mainText"></span>
      </div>
      <div class="form-group mb-4 row">
        <div class="col">
          <label class="form-label">Texto de botón</label>
          <input
            type="text"
            class="form-control"
            v-model="mainBlockData.buttonText"
          />
          <span v-if="lang">{{ mainBlockDefault.buttonText }}</span>
        </div>
        <div v-if="!lang" class="col">
          <label>Imagen</label>
          <repository-button
            :allowed-mime-types="allowedMimeTypes"
            :short-version="true"
            v-model="mainBlockData.mainImageId"
            class-field="col-md-12 px-0"
            field="mainImageId"
            group-id="1"
            group-type="App\Models\Rep\FileGroup"
          >
          </repository-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
require("trumbowyg/dist/plugins/table/trumbowyg.table");
require("trumbowyg/dist/plugins/table/ui/trumbowyg.table.css");

require("trumbowyg/dist/plugins/colors/trumbowyg.colors");
require("trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css");

require("trumbowyg/dist/plugins/emoji/trumbowyg.emoji");
require("trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.css");

require("trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily");
require("trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize");

import RepositoryButton from "@/js/components/repository_explorer/RepositoryButton";
import trumbowyg from "vue-trumbowyg";
import "trumbowyg/dist/langs/es.min.js";
import "trumbowyg/dist/ui/sass/trumbowyg.scss";
import { mapGetters } from "vuex";

export default {
  name: "MainBlockTab",
  components: { trumbowyg, RepositoryButton },
  props: {
    lang: String,
    mainBlock: Object,
    mainBlockDefault: Object,
    tests: Array,
  },
  data() {
    return {
      configs: {
        // Any option from
        // https://alex-d.github.io/Trumbowyg/documentation/#basic-options
        lang: "es",
        autogrow: false,
        imageWidthModalEdit: true,
        removeformatPasted: true,
        btnsDef: {
          repositoryFile: {
            fn: () => {
              this.showRepository = true;
            },
            title: "Repositorio de imágenes",
            ico: "upload",
          },
        },
        btns: [
          ["viewHTML"],
          ["undo", "redo"], // Only supported in Blink browsers
          ["formatting"],
          ["fontfamily"],
          ["fontsize"],
          ["strong", "em", "del"],
          ["superscript", "subscript"],
          ["link"],
          ["emoji"],
          ["insertImage"],
          ["table"],
          ["foreColor", "backColor"],
          ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
          ["unorderedList", "orderedList"],
          ["horizontalRule"],
          ["removeformat"],
          ["repositoryFile"],
          ["fullscreen"],
        ],
      },
      mainBlockData: null,
      selectedImage: null,
    };
  },
  created() {
    this.mainBlockData = typeof this.mainBlock === 'string' ? JSON.parse(this.mainBlock) : this.mainBlock
    this.selectedImage = this.mainBlock.image;
  },
  computed: {
    ...mapGetters("Repository", {
      allowedMimeTypes: "getMimeTypeImages",
    }),
    jsonMainBlockData() {
      return JSON.stringify(this.mainBlockData);
    },
  },
};
</script>

<style>
</style>
