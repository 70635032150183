<template>
    <!-- Desactivado provisionalmente -->
    <li class="m-nav__item m-topbar__actions m-topbar__actions--img" v-if="1 === 0">
        <a href="javascript:" class="m-nav__link" id="update" @click="updateActions">
            <span class="m-nav__link-icon">
                <em class="flaticon-squares"></em>
            </span>
        </a>
        <div class="m-quick-panel" :class="{'m-quick-panel--on' : active}">
            <button href="#" class="m-quick-panel__close btn btn-sm btn-outline-dark btn-icon"
                    id="m_quick_panel_close_btn" @click="active = false">
                <i class="la la-close"></i>
            </button>
            <div class="m-quick-panel__content">
                <h4 class="m-quick-panel__content--title">Histórico de sucesos</h4>

                <div class="kt-notification kt-notification--no-link">
                    <a href="#" class="kt-notification__item" id="actions" v-for="action in actions">
                        <div class="kt-notification__item-icon">
                            <i class="kt-font-success" :class="action['icon']"></i>
                        </div>
                        <div class="kt-notification__item-details">
                            <div class="kt-notification__item-title">
                                <span>{{ action['type'] }}</span>
                                <br>
                                <span>{{ action['entity'] }}</span>
                            </div>
                            <div class="kt-notification__item-time">
                                {{ action['timestamp'] }}
                            </div>
                        </div>
                    </a>
                </div>

            </div>
        </div>
    </li>
</template>

<script>
  import debounce from 'lodash/debounce'
  import dummyActions from './dummy.json'

  export default {
    name: 'actions-submenu',
    data () {
      return {
        active: false,
        actions: [],
      }
    },
    methods: {
      updateActions: debounce(async function () {
        this.active = !this.active
        mApp.block(this.$refs.actionsPanel, {})

        if (!this.$debug) {
          const data = (await axios.get('/api/get-elastic-log')).data
          this.actions = data.actions
        } else {
          this.actions = dummyActions
        }

        Object.keys(this.actions).forEach(action => {
          if (this.actions[action]['type'] === 'LOG_BASE_MODEL_CREATED') {
            this.actions[action]['icon'] = 'flaticon-add'
          }
          if (this.actions[action]['type'] === 'LOG_BASE_MODEL_UPDATED') {
            this.actions[action]['icon'] = 'la la-refresh'
          }
          if (this.actions[action]['type'] === 'LOG_BASE_MODEL_DELETED') {
            this.actions[action]['icon'] = 'la la-trash'
          }
          if (this.actions[action]['type'] === 'EMAIL') {
            this.actions[action]['icon'] = 'la la-envelope'
          }
        })

        mApp.unblock(this.$refs.actionsPanel)
      }, 500),
    }
  }
</script>

<style>
    .m-quick-panel {
        display: none;
        background: #fff;
        padding: 0;
        box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);
        z-index: 1001;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 425px !important;
        overflow-y: auto;
    }

    .m-quick-panel--on {
        display: block;
        right: 0;
    }

    .m-quick-panel .m-quick-panel__close {
        position: absolute;
        z-index: 1;
        right: 1.5rem;
        top: 1.5rem;
        display: flex;
        justify-content: center;
        width: 28px;
        height: 28px;
    }

    .m-quick-panel__content {
        padding: 1.5rem;
    }
</style>
