<template>
    <div :class="classField">
        <input type="hidden" :name="field" :id="field" :value="getValue">
        <label v-if="name">{{ name }}</label>
        <multiselect v-model="managers" :options="options" :searchable="true" :internal-search="false"
                     :placeholder="getPlaceHolder" :loading="loading" :show-no-results="false" :limit="50"
                     :disabled="nbReadOnly" :multiple="true" :show-labels="false" label="name" track-by="name"
                     @open="open" @search-change="find" :id="'managers-' + field">
        </multiselect>
    </div>
</template>

<script>
  export default {
    name: 'manager-list',
    props: {
      name: { String, default: '' },
      field: { String, default: '' },
      classField: { String, default: 'form-group m-form__group col-md-6' },
      value: { Array, default: () => [] },
      placeHolder: { String, default: 'Selecciona un responsable' },
    },
    mounted () {
      this.$nextTick(() => {
        this.setValues()
      })
    },
    data () {
      return {
        options: [],
        managers: [],
        loading: false
      }
    },
    computed: {
      getValue: function () {
        let list = []

        if (!this.managers) {
          return ''
        }

        for (let manager of this.managers) {
          list.push(manager.id)
        }

        return JSON.stringify(list)
      },
      getPlaceHolder: function () {
        if (this.managers) {
          if (this.managers.length) {
            return ''
          }
        }

        return this.placeHolder
      }
    },
    methods: {
      setValues () {
        this.find(null, this.value)
      },
      open () {
        this.find()
      },
      async find (query, ids) {
        this.loading = true

        let sendData = {
          search: query
        }

        if (ids) {
          sendData['ids'] = ids
        }

        try {
          let response = await axios.post(route('users.getListUser'), sendData)
          this.response(response, ids)
        } catch (error) {
          this.response(error, ids)
        }
      },
      response (response, ids) {
        if (response.data) {
          this.options = response.data.options
          if (ids) {
            this.managers = response.data.options
          }
        }
        this.loading = false
      },
    }
  }
</script>

<style scoped>

</style>
