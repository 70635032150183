<template>
    <div class="properties-box">
        <div class="properties-box__text">
            Ancho (CM)
        </div>
        <div class="properties-box__field">
            <div class="m-input-icon m-input-icon--right">
                <input type="number" step="0.01" id="ticketWidth" :readonly="widthLocked"
                       class="form-control m-input form-control-sm" v-model="ticket.width">
                <span @click="widthLocked = !widthLocked" class="m-input-icon__icon m-input-icon__icon--right">
                    <span><i class="fa" :class="widthLocked ? 'fa-lock' : 'fa-unlock'"></i></span>
                </span>
            </div>
        </div>
        <div class="properties-box__text">
            Altura (CM)
        </div>
        <div class="properties-box__field">
            <div class="m-input-icon m-input-icon--right">
                <input type="number" step="0.01" id="ticketHeight" :readonly="heightLocked"
                       class="form-control m-input form-control-sm" v-model="ticket.height">
                <span @click="heightLocked = !heightLocked" class="m-input-icon__icon m-input-icon__icon--right">
                    <span><i class="fa" :class="heightLocked ? 'fa-lock' : 'fa-unlock'"></i></span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'TicketDimensions',
    data () {
      return {
        widthLocked: true,
        heightLocked: true,
      }
    },
    computed: {
      ...mapGetters('ticketEditor', {
        ticket: 'ticket',
      }),
      ticketWidth: {
        set (width) { this.setTicket({ ...this.ticket, width }) },
        get () { return this.ticket.width },
      },
      ticketHeight: {
        set (height) { this.setTicket({ ...this.ticket, height }) },
        get () { return this.ticket.height },
      },
    },
    methods: {
      ...mapMutations('ticketEditor', {
        setTicket: 'setTicket',
      }),
    },
  }
</script>
