// TODO: EDITOR VISUAL Generalizar
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('ticketEditor', {
      activeElement: 'activeElement',
    }),
  },

  methods: {
    ...mapMutations('ticketEditor', {
      setActiveElement: 'setActiveElement',
      updateActiveElementStyle: 'updateActiveElementStyle',
    }),

    updateStyle (event, prop) {
      this.updateActiveElementStyle({ prop, value: event.target.value })
      this.layoutChange()
    },

    layoutChange () { this.$eventBus.$emit('layoutChange') },
  },
}
