<template>
    <div>
        <form @submit.prevent="importQuestions()" enctype="multipart/form-data">
            <modal-component
                :visible.sync="showModalImport"
                :buttonClose="false"
                :classesModalDialog="['modal-dialog-centered', 'modal-xl']"
            >
                <div class="modal-body">
                    <div class="form-group">
                        <label>Selecciona archivo zip con Excel y carpeta images</label>
                        <input
                            type="file"
                            name="import_questions_file"
                            id="import_questions_file"
                            class="form-control"
                            @change="setFile"
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="$emit('closeImportModal')">Cerrar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </modal-component>
        </form>
    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import RepositoryButton from "@/js/components/repository_explorer/RepositoryButton";
import SuggesterScopeMixin from "@/js/components/ticket_editor/tabs/element-tabs/SuggesterScopeMixin";

  export default {
    name: "ImportQuestionModal",
    components: { RepositoryButton },
    mixins: [SuggesterScopeMixin],
    props: {
        showModalImport: { type: Boolean, required: true },
        testId: { type: Number, required: true },
    },
    data () {
      return {

      }
    },
    methods: {
        setFile(event) {
            this.file = event.target.files[0]
            this.filename = this.file.name
        },
        async importQuestions() {
            if (!this.file || this.nbReadOnly) return

            const form = new FormData()
            form.append('file', this.file)
            form.append('type', 'question')

            const response = await axios.post(route('import.importQuestions', this.testId),form,{ headers: {'Content-Type': 'multipart/form-data'} })

            console.log(response);
            if (response.data === 1) {
                this.$emit('closeImportModal');
                this.$swal.fire('Preguntas importadas correctamente', '', 'success')
                    .then(() => { window.location.reload() })
                    //TODO: Ver si lo podemos hacer sin recargar página entera mejor
            } else {
                this.$emit('closeImportModal');
                let errorsData = 'Se produjo un error al importar <br>'

                if(typeof(response.data) === 'object') {
                    response.data.map((error) => {
                        errorsData = errorsData + 'Error en la fila ' + error.row + ' en la columna ' + error.attribute + ': ' + error.errors[0] + '<br>'
                    })
                }else{
                    errorsData = errorsData + response.data
                }

                this.$swal.fire(errorsData, '' ,'error').then(() => { window.location.reload() })

            }

        }

    },
  }
</script>
