<template>
    <tr>
        <template v-if="!('props' in meta)">
            <td class="align-middle" style="width: 1%;">
                <span>{{ meta.meta }}</span>
            </td>
            <td class="align-middle">
                <span v-if='!("type" in meta)'>{{ meta.value }}</span>
                <template v-else>
                    <template v-if="metaHasChoices(meta)">
                        <multiselect v-if="metaHasChoices(meta)" :options="meta.choices" v-model="meta.value"
                                     tag-placeholder="Añadir nueva opción" :disabled="nbReadOnly" :taggable="true"
                                     @tag="addTag(tag, $event)" id="meta"></multiselect>

                        <span class="m-switch mt-1" v-if="metaIsBoolean(meta)">
                            <label class="m--marginless">
                                <span></span>
                                <input type="checkbox" v-model="meta.value" :disabled="nbReadOnly" id="meta-value">
                                <span></span>
                            </label>
                        </span>
                    </template>

                    <template v-else>
                        <input :type="getFieldType(meta)" v-model="meta.value" :disabled="nbReadOnly" title="Valor"
                               class="form-control" :id="'field-type-' + meta.meta">
                    </template>
                </template>
            </td>
        </template>

        <template v-else>
            <td colspan="2">
                <table class="table table-striped m-table table-bordered">
                    <thead>
                    <tr>
                        <th colspan="2" class="sub-row-title">
                            {{ meta.meta }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <schema-row v-for="(submeta, i) of meta.props" :nbReadOnly="nbReadOnly" :meta="submeta"
                                :key="`submeta${i}`">
                    </schema-row>
                    </tbody>
                </table>
            </td>
        </template>
    </tr>
</template>

<script>
  export default {
    name: 'schema-row',
    props: {
      meta: { type: Object, default: () => [] },
    },
    methods: {
      addTag (tag, event) {
        tag.choices.push(event)
        tag.value = event
      },
      metaHasChoices (meta) {
        return meta.hasOwnProperty('choices')
      },
      metaIsBoolean (meta) {
        return meta.hasOwnProperty('type') && meta.type === 'boolean'
      },
      getFieldType (meta) {
        if (this.metaHasChoices(meta)) {
          return ''
        }

        switch (meta.type) {
          case 'string':
            return 'text'
          case 'date':
            return 'date'
          case 'datetime':
            return 'datetime-local'
          case 'currency':
            return 'number'
          default:
            return ''
        }
      },
    },
  }
</script>

<style scoped>
    .sub-row-title {
        text-transform: capitalize;
        font-weight: bold;
    }
</style>
