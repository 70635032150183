<template>
    <td class="text-center" :class="{'disabled': nbReadOnly}">
        <div class="permission-group" v-if="showThisPermission">
            <i v-if="userRoleInfo"
               :title="`${$options.filters.trans('permission_component.inheritedRoleAuth')}
               ${userRoleInfo.role.visible_name}`"
               :class="`role-status__relative fa fa-fw fa-2x ${getRolePermissionStatus()}`"></i>
            <i @click="toggle()" :class="`fa fa-2x state-icon ${getPermissionStatus()}`"></i>
        </div>
    </td>
</template>

<script>
  import { EventBus } from '@/js/vue/services/event-bus'

  export default {
    name: 'permission-item',
    data: function () {
      return {
        checked: this.granted,
      }
    },
    props: {
      entityId: {
        default: null,
        validator: value => !isNaN(value),
      },
      permissionTypeId: {
        default: null,
        validator: value => !isNaN(value),
      },
      userRoleInfo: {
        default: null,
        validator: value => value instanceof Object,
      },
      granted: {
        default: null,
        validator: value => {
          return (typeof value === 'boolean') || value === null
        },
      },
      roleGranted: {
        default: null,
        validator: value => {
          return (typeof value === 'boolean') || value === null
        },
      },
      showThisPermission: {
        default: true,
        validator: value => {
          return (typeof value === 'boolean') || value === null
        },
      },
    },
    methods: {
      toggle () {
        if (this.nbReadOnly) {
          return
        }

        EventBus.$emit('permissionUpdated', this.entityId, this.permissionTypeId)
      },
      getPermissionStatus () {
        if (this.granted) {
          return 'fa-check-circle text-success js-checked'
        } else {
          if (this.granted === null) {
            return 'fa-minus-circle text-muted js-no-value'
          }

          return 'fa-times-circle text-danger js-unchecked'
        }
      },
      getRolePermissionStatus () {
        if (this.roleGranted) {
          return 'fa-check-circle text-success js-checked'
        } else {
          if (this.roleGranted === null) {
            return 'fa-minus-circle text-muted js-no-value'
          }

          return 'fa-times-circle text-danger js-unchecked'
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
    .disabled {
        opacity: 0.5;
    }

    .state-icon {
        padding: 5px;
        position: relative;
    }

    .permission-group {
        margin: 0;
        padding: 0;
        position: relative;
        width: 60px;
    }

    .role-status__relative {
        position: absolute;
        top: 0;
        z-index: 40;
        left: 10px;
        opacity: 0.7;
        cursor: help;
        margin: 0;
        padding: 0;
        font-size: 0.85em;

        &.js-no-value {
            opacity: 0.3;
        }
    }
</style>
