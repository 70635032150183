import { render, staticRenderFns } from "./TicketPreview.vue?vue&type=template&id=5469049e&"
import script from "./TicketPreview.vue?vue&type=script&lang=js&"
export * from "./TicketPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5469049e')) {
      api.createRecord('5469049e', component.options)
    } else {
      api.reload('5469049e', component.options)
    }
    module.hot.accept("./TicketPreview.vue?vue&type=template&id=5469049e&", function () {
      api.rerender('5469049e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/ticket_editor/tabs/TicketPreview.vue"
export default component.exports