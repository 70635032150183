var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table m-table table-bordered" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "tbody",
      [
        _c("tr", [
          _c("td", [_vm._v("Tipo")]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("multiselect", {
                attrs: { options: _vm.schemas, id: "schema-type" },
                model: {
                  value: _vm.schemaSelected,
                  callback: function($$v) {
                    _vm.schemaSelected = $$v
                  },
                  expression: "schemaSelected"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm._l(_vm.schemaValue, function(meta, i) {
          return _c("schema-row", {
            key: "meta" + i,
            attrs: { nbReadOnly: _vm.nbReadOnly, meta: meta }
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "fitCell" }, [_vm._v("Propiedad")]),
        _vm._v(" "),
        _c("th", [_vm._v("Valor")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }