<template>
    <div class="overlay" :class="getCurrentClassesLoading" >
        <div class="overlay-wrapper">
              <slot></slot>
        </div>

        <div class="overlay-layer bg-dark bg-opacity-5" :class="getCurrentClassesAnimation">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Espere...</span>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Loader',
    props: {
      loading: { type: Boolean, default: false },
      classes: { type: Array, default: () => [] },
      classesAnimation: { type: Array, default: () => [] },
    },
    computed: {
      getCurrentClassesLoading () {
        return [
          ...this.classes,
          this.loading ? 'is-loading overlay-block' : 'is-loaded',
        ]
      },
      getCurrentClassesAnimation () {
        return [
          ...this.classesAnimation,
          this.loading ? '' : 'd-none',
        ]
      }
    },
  }
</script>
