var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group m-form__group col-md-6",
      class: _vm.containerClass
    },
    [
      !_vm.live
        ? _c("div", { staticClass: "d-flex flex-row" }, [
            _c("input", {
              attrs: { type: "hidden", name: _vm.name + "_checkbox" },
              domProps: { value: _vm.customizeCheckbox ? 1 : 0 }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "p-2" }, [
              _c("label", { staticClass: "m-checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customizeCheckbox,
                      expression: "customizeCheckbox"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    title: "Modificable",
                    disabled: _vm.nbReadOnly,
                    id: "change-check"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.customizeCheckbox)
                      ? _vm._i(_vm.customizeCheckbox, null) > -1
                      : _vm.customizeCheckbox
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeCheckBox(true)
                    },
                    change: function($event) {
                      var $$a = _vm.customizeCheckbox,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.customizeCheckbox = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.customizeCheckbox = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.customizeCheckbox = $$c
                      }
                    }
                  }
                }),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.label) +
                    "\n                "
                ),
                _c("span")
              ])
            ])
          ])
        : _c("label", [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", {
            staticClass: "form-group",
            domProps: { innerHTML: _vm._s(_vm.setInput()) }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }