<!-- Pestaña de condiciones en la gestión de listas (crm) -->
<template>
    <div class="form-group m-form__group col-12">
        <input ref="evaluator_instances" v-model="evaluatorInstancesJson" type="hidden" name="evaluator_instances">
        <input type="hidden" :value="staticList" name="is_static" id="is_static">
        <input ref="group" v-model="group" type="hidden" name="group">
        <input ref="targetId" v-model="targetId" type="hidden" name="targetId">

        <modal-component :theRef="'evaluatorSearch'"
                         :visible.sync="showModal"
                         :backdrop="true"
                         :buttonMaximize="false"
                         :classesModalDialog="['modal-dialog-centered modal-xl']"
                         :classesFooter="['text-right']">
            <template #title>Añadir condición</template>
            <template>
                <div class="row">
                    <!-- Lista de categorías inicial -->
                    <div v-for="category in inModalCategoriesList" class="col-6 list-conditions">
                        <div :id="'category-' + category.id"
                             class="m-alert m-alert--icon m-alert--outline alert alert-metal"
                             @click="selectOneCategory(category.class, category.father)">

                            <div class="m-alert__icon">
                                <i :class="category.icon"></i>
                            </div>
                            <div class="m-alert__text">
                                <div>
                                    <strong>{{ category.name }}</strong>
                                </div>
                                <span class="text-muted">{{ category.description }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-for="evaluator in categoryFilteredEvaluatorsList" class="col-6 list-conditions">
                        <div :id="'evaluator-' + evaluator.id"
                             class="m-alert m-alert--icon m-alert--outline alert alert-brand"
                             @click="setEvaluator(evaluator)">
                            <div class="m-alert__icon">
                                <i :class="evaluator.icon"></i>
                            </div>
                            <div class="m-alert__text">
                                <div>
                                    <strong>{{ evaluator.name }}</strong>
                                </div>
                                <span class="text-muted">{{ evaluator.description }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-show="evaluatorsList.length <= 0" class="col-12">
                        <div class="fs-5 fw-bolder alert text-center my-20"
                             role="alert">
                            No se han encontrado condiciones
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <button id="back-history"
                        v-if="categoryNavigationHistory.length > 0"
                        type="button"
                        class="btn btn-brand btn-sm"
                        @click="goBackInHistory()">
                    <i class="la la-reply"></i>
                    Volver
                </button>
            </template>
        </modal-component>

        <div class="row mb-4">

            <!-- Título con contador -->

            <div class="col-6">
                <h4 class="mb-0">
                    Condiciones, aplican
                    <span v-show="!loading" class="m--font-brand">{{ count }}</span>
                    <div v-show="loading" class="m-loader m-loader--sm m-loader--brand"></div>
                </h4>
            </div>

            <!-- Checkbox lista estática y botón + Añadir condición -->

            <div class="col-4 text-right">
                <span class="m-switch m-switch--icon">
                    <label>
                        <input ref="is_static_ref"
                               v-model="staticList"
                               type="checkbox"
                               true-value="1"
                               false-value="0">
<!--                               @change="count = count">-->
                        <span>Lista estática</span>
                    </label>
                </span>
            </div>

            <div class="col-2 text-right">
                <button id="show-modal"
                        type="button"
                        class="btn btn-brand m-btn m-btn--icon btn-sm"
                        :disabled="nbReadOnly"
                        @click="openModal(null)">
                    <span>
                        <i class="fa fa-plus"></i>
                        <span>Añadir condición</span>
                    </span>
                </button>
            </div>

            <div class="col-12">
                <div class="m-separator m-separator--dashed m-separator--lg mt-3 mb-0"></div>
            </div>

        </div>

        <!-- Listado de condiciones -->

        <div class="row">
            <div v-for="(evaluator, key) in evaluatorsSelect" class="col-12" :key="key">
                <div class="m-alert m-alert--outline list-select alert alert-metal mb-4" role="alert">
                    <component :is="evaluator.component"
                               v-if="evaluator.component"
                               :key="'cO' + key"
                               v-model="evaluatorsSelect[key].bindings"
                               :evaluator="evaluator"
                               :group="group"
                               :nbReadOnly="nbReadOnly"
                               :route-prefix="'API.targets.'"
                               @filter-group="filterGroup"
                               @openModal="openModal">
                    </component>
                    <button :id="'remove-evaluator-' + key"
                            type="button"
                            class="btn btn-danger btn-sm m-btn delete-evaluator"
                            :disabled="nbReadOnly"
                            @click="removeEvaluator(key)">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <div v-show="evaluatorsSelect.length <= 0" class="col-12">
                <div class="alert text-center my-20" role="alert">
                    Añade una o varias condiciones
                </div>
            </div>
        </div>

        <div class="row mb-4">

            <!-- Buscador de clientes -->

            <div class="col-10" :class="{'has-selection': customerSearcherSelected.length}">
                <multiselect class="multiselect--search"
                             :options="customerOptions"
                             :multiple="true"
                             v-model="customerSearcherSelected"
                             track-by="id"
                             label="name"
                             placeholder="Buscar cliente">
                </multiselect>
            </div>
            <div class="col-2">
                <button id="send"
                        :disabled="customerSearcherSelected.length === 0"
                        type="button"
                        class="btn btn-success m-btn--icon d-block w-100"
                        @click="addClients">
                    <i class="fa fa-plus"></i>
                    Añadir cliente
                </button>
            </div>
        </div>
        <!-- Listado de entidades que aplican -->

        <list-simple :list.sync="list"
                     :headers.sync="headers"
                     :name="'evaluator-instances-list'"
                     :interface-texts="{title:'Clientes', 'empty-list':'No hay resultados'}"
                     :ajax="true"
                     :actions="{linkIdUrl: linkEditCustomers}"
                     :ajaxPagination="paginate"
                     @page-change="setPage(...arguments)">
        </list-simple>


    </div>
</template>
<script>

  export default {
    name: 'evaluator-instances',
    props: {
      group: { type: String, default: '' }, // Se pasa al componente
      name: { type: String, default: '' },
      categories: { type: Object, default: () => { }, },
      evaluators: { type: Array, default: () => [], },
      evaluatorInstances: { type: Array, default: () => [], },
      triggerCategory: { type: String, default: '' },
      targetId: { type: Number, default: null },
      isStaticList: { type: Number, default: 0 },
      linkEditCustomers: { type: String, default: '' },
    },
    data () {
      return {
        staticList: 0,
        count: 0,
        list: [], // Lista de elementos filtrados por los evaluadores
        fields: {}, // Campos de listado
        headers: [], // Campos de listado para listSimple
        page: 1,
        paginate: {},
        loading: false, // Muestra u oculta un par de loaders
        lastCatetorySelected: null,
        categoriesSelect: {},
        evaluatorsSelect: {}, // Contiene la lista de evaluadores que se renderizan
        categoryNavigationHistory: [],
        child: null, // Determina que hijo tendrá el nuevo evaluador, dicho de otro modo: el padre.
        groupFilter: false,
        groupEvaluator: null,
        showModal: null,
        customerSearcherSelected: [],
        customerOptions: [],
      }
    },
    created () {
      this.$eventBus.$on('select-evaluator', (child, evaluator) => this.selectEvaluator(child, evaluator))
      this.staticList = this.isStaticList
      this.retrieveCustomerOptions()
      let evaluator
      let object = {}

      for (evaluator in this.evaluatorInstances) {
        object = { ...this.evaluatorInstances[evaluator].parameters }
        this.setEvaluator(Object.assign({}, object, { id: this.evaluatorInstances[evaluator].id }))
      }
    },
    computed: {
      /**
       * El contenido del input
       */
      evaluatorInstancesJson () {
        return this.createdEvaluators(true)
      },

      /**
       * Lista de evaluadores de la categoría en el modal.
       */
      categoryFilteredEvaluatorsList () {
        // Si hay categoria elegida y no hay una lista de categorías mostrandose
        if (this.lastCatetorySelected && this.inModalCategoriesList.length <= 0) {
          return this.evaluators.filter(evaluator => this.lastCatetorySelected === evaluator.category)
        }
        return []
      },

      /**
       * Se usa para mostrar o no el mensaje de no hay filtros? No lo entiendo
       */
      evaluatorsList () {
        let list = this.evaluators

        if (Object.keys(this.categoriesSelect).length > 0) {
          list = list.filter(evaluator => this.categoriesSelect[evaluator.category])
        }

        if (this.groupEvaluator !== null) {
          list = list.filter(evaluator => evaluator.class !== this.groupEvaluator)
        }

        return list
      },

      /**
       * Lista de categorías en el modal.
       */
      inModalCategoriesList () {
        return Object.values(this.categories).filter(cat =>
          (this.lastCatetorySelected && cat.father === this.lastCatetorySelected)
          || (!this.lastCatetorySelected && cat.father === null))
      },

      categoriesList () {
        let categories = []
        let category
        let content

        for (category in this.categories) {
          content = this.categories[category]
          content.name = this.nameCategory(content)

          categories.push(this.categories[category])
        }

        return categories
      },
    },

    methods: {
      /**
       * Callback del botón + Añadir condición y del evento openModal de los componentes
       */
      openModal (element) {
        if (element === null) {
          this.groupFilter = false
          this.groupEvaluator = null
          this.clearCategory()
        }

        this.child = element

        this.showModal = true
      },

      /**
       * Callback del botón volver. Muestra el listado de categorías previo, es como el atrás el navegador.
       */
      goBackInHistory () {
        if (this.categoryNavigationHistory.length) {
          this.lastCatetorySelected = this.categoryNavigationHistory[this.categoryNavigationHistory.length - 1]
          this.categoryNavigationHistory.splice(-1, 1)
        }
      },

      closeModal () {
        this.showModal = false
        this.clearOneCategory()
      },

      /**
       * Metodo intermedio de dudoso propósito
       */
      createdEvaluators (returnValue) {
        let instancesToJson = []
        let evaluator
        let value

        for (evaluator in this.evaluatorsSelect) {
          instancesToJson.push(this.evaluatorsSelect[evaluator])
        }

        value = JSON.stringify(instancesToJson)

        this.countEvaluators(value)

        if (returnValue === true) {
          return value
        }
        return null
      },

      /**
       * Callback de la elección el evaluador en el modal.
       */
      setEvaluator (evaluator) {
        if (this.child !== null) {
          let index = Object.keys(this.child.evaluatorsSelect).map(Number).sort((a, b) => b - a)[0]
          if (index === undefined) index = -1
          index = index + 1
          this.$set(this.child.evaluatorsSelect, index, evaluator)
          this.child.updateModel()
        } else {
          let index = Object.keys(this.evaluatorsSelect).map(Number).sort((a, b) => b - a)[0]
          if (index === undefined) index = -1
          index = index + 1
          this.$set(this.evaluatorsSelect, index, evaluator)
        }
        this.closeModal()
      },

      selectEvaluator (child, evaluator) {
        this.child = child
        this.setEvaluator(evaluator)
      },

      clearCategory () {
        this.categoriesSelect = {}
        this.sendCategoryValues()
      },
      clearOneCategory () {
        this.lastCatetorySelected = null
        this.categoryNavigationHistory = []
      },

      /**
       * Callback elección de categoría de evaluador del modal.
       */
      selectOneCategory (category, father) {
        this.lastCatetorySelected = category
        this.categoryNavigationHistory.push(father)
      },
      selectCategory (category) {
        if (this.categoriesSelect[category]) {
          delete this.categoriesSelect[category]
        } else {
          this.categoriesSelect[category] = true
        }

        this.sendCategoryValues()
      },
      sendCategoryValues () {
        this.categoriesSelect = Object.assign({}, this.categoriesSelect)
      },
      nameCategory (category) {
        let name = category.name
        let father = category.father

        if (father !== null && father !== undefined) {
          father = this.categories[father]
          name = father.name + ' > ' + name
        }

        return name
      },
      setPage (page) {
        if (this.loading === false) {
          this.page = page
          this.createdEvaluators(false)
        }
      },

      /**
       * Elimina un evaluador de la lista. Callback de la x roja de cada evaluador.
       */
      async removeEvaluator (reference) {
        if (this.evaluatorsSelect[reference]['class'].includes('ReceiveOffers')) {
          const response = await this.$swal.fire({
            title: 'Si eliminas esta comprobación se podría realizar el envío de emails a clientes' +
              ' que han expresado su deseo de no recibir comunicaciones por email.',
            text: '¿Continuar?',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
          })
          if (response.value) {
            this.$delete(this.evaluatorsSelect, reference)
          }
        } else {
          this.$delete(this.evaluatorsSelect, reference)
        }
      },

      /**
       * Retrieves the evaluators via ajax
       */
      async countEvaluators (evaluators) {
        this.loading = true

        let context = this
        let response = await axios.post(route('API.targets.count'), {
          group: this.group,
          evaluator_instances: evaluators,
          page: this.page,
          static: this.staticList
        }).then(function (response) {
          if (response.data) {
            context.count = parseInt(response.data.count)

            let fields = response.data.fields
            context.headers = []
            Object.keys(fields).forEach((key, index) => {
              if (key === 'name') {
                context.headers.push([fields[key], 'link-id', key])
              } else {
                context.headers.push([fields[key], 'string', key])
              }
            })
            context.fields = fields
            context.paginate = response.data.paginate
            context.list = response.data.list
          }
        }).finally(() => this.loading = false)
      },

      /**
       * Trae la lista de clientes para el selector de clientes.
       */
      async retrieveCustomerOptions (evaluators) {
        this.loading = true

        let context = this
        let response = await axios.post(route('API.customers.customer-options'))
          .then(function (response) {
            if (response.data) {
              context.customerOptions = Object.values(response.data)
            }
          })
        this.loading = false
      },

      /**
       * Añade un evaluador de clientes concretos a la lista.
       */
      addClients (event) {
        let evaluator = {
          'class': 'App\\Evaluators\\Evaluator\\Customer\\Id',
          'name': 'Cliente/s',
          'description': 'Clientes concretos.',
          'category': 'App\\Evaluators\\Category\\Customer',
          'component': 'evaluator-id',
          'icon': 'la la-user-plus',
          'list': null,
          'bindings': { 'status': this.customerSearcherSelected }
        }
        this.setEvaluator(evaluator)
      },

      /**
       * Callback of filter-group event of dinamic components
       */
      filterGroup (event) {
        this.groupFilter = true
        this.groupEvaluator = event.evaluator
        this.clearCategory()
        this.selectCategory(this.triggerCategory)
        this.selectCategory(event.category)
      }
    },
  }
</script>

<style lang="scss" scoped>
    .list-groups {
        .btn {
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }

    .list-conditions {
        animation: effect-conditions 1s;

        .m-alert {
            &:hover {
                cursor: pointer;
                transition: all .3s ease;
                box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.3);
                border-color: white;
            }

            .m-alert__icon {
                padding: 1rem 0.5rem 1rem 1rem;
            }

            .m-alert__text {
                color: black;
                padding: 1rem 0.5rem;
            }

            .m-alert__actions {
                padding: 1rem;
            }
        }
    }

    @keyframes effect-conditions {
        0% {
            opacity: 0.1;
        }
        100% {
            opacity: 1;
        }
    }

    .list-select {
        .m-alert {
            width: 100%;
        }
    }

    .delete-evaluator {
        position: absolute;
        top: -5px;
        right: -5px;
    }

    .m-loader--brand {
        width: 30px;
        height: 15px;
        display: inline-block;
    }
</style>
