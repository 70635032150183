var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "col-12  c-survey-component__items-container",
      attrs: { useDragHandle: true, lockToContainerEdges: true }
    },
    [
      _c(
        "ul",
        { staticClass: "c-survey-component__items ps-0" },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }