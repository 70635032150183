/* ===========================================================
 * trumbowyg.unsubscribe.js
 * Unsubscribe plugin for Trumbowyg
 * ===========================================================
 */

(function ($) {
  'use strict'

  let defaultOptions = {
    unsubscribeText: 'Unsubscribe',
  }

  function buildButtonDef (trumbowyg) {
    return {
      fn: function () {
        let text = trumbowyg.range.startContainer.parentElement
        trumbowyg.saveRange()
        let selectedText = trumbowyg.getRangeText()
        if (!selectedText) {
          trumbowyg.execCmd('insertHTML', '<a href="[unsubscribe]">' + trumbowyg.lang.unsubscribe + '</a>')
        } else if ($(text).is('a') && $(text).html() === selectedText) {
          $(text).attr('href', '[unsubscribe]')
        } else {
          trumbowyg.execCmd('insertHTML', '<a href="[unsubscribe]">' + selectedText + '</a>')
        }
        return true
      },
      tag: 'unsubscribe',
      title: 'Insertar enlace "Cancelar subscripción"',
      ico: 'unsubscribe',
      hasIcon: true,
      text: 'Insertar enlace "Cancelar subscripción"',
    }
  }

  $.extend(true, $.trumbowyg, {
    langs: {
      en: {
        unsubscribe: 'Unsubscribe',
      },
      es: {
        unsubscribe: 'Desuscribirse',
      },
    },
    plugins: {
      unsubscribe: {
        init: function (trumbowyg) {
          // Fill current Trumbowyg instance with the plugin default options
          trumbowyg.o.plugins.unsubscribe = $.extend(true, {},
            defaultOptions,
            trumbowyg.o.plugins.unsubscribe || {},
          )

          // If the plugin is a button
          trumbowyg.addBtnDef('unsubscribe', buildButtonDef(trumbowyg))
        },
        // Return a list of button names which are active on current element
        tagHandler: function (element, trumbowyg) {
          return []
        },
        destroy: function (trumbowyg) {
        },
      },
    },
  })
})(jQuery)
