var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-relative" }, [
    _c("textarea", {
      ref: "suggester",
      staticClass: "inputField form-control-sm",
      class: _vm.filteredSuggestions.length
        ? "open " + _vm.inputClass
        : _vm.inputClass,
      attrs: {
        id: _vm.id,
        title: _vm.placeholder,
        placeholder: _vm.placeholder,
        autocomplete: "off"
      },
      domProps: { value: _vm.value },
      on: {
        input: _vm.input,
        blur: _vm.handleLostFocus,
        focus: _vm.handleOnFocus
      }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.filteredSuggestions.length,
            expression: "filteredSuggestions.length"
          }
        ],
        ref: "suggestionsList",
        staticClass: "position-absolute suggestions rounded mw-100",
        class: _vm.filteredSuggestions.length ? "open" : "",
        staticStyle: { "z-index": "100", "max-height": "200px" }
      },
      [
        _c(
          "ul",
          { staticClass: "list-group list-group-flush rounded-bottom" },
          _vm._l(_vm.filteredSuggestions, function(suggestion, i) {
            return _c(
              "li",
              {
                ref: "option" + i,
                refInFor: true,
                staticClass: "list-group-item suggestion px-2 py-1",
                attrs: { tabindex: i },
                on: {
                  keydown: [
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.insertWordAtCaret(suggestion)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.insertWordAtCaret(suggestion, true)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 38
                      ) {
                        return null
                      }
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.selectPreviousOption(i)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 40
                      ) {
                        return null
                      }
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.selectNextOption(i)
                    }
                  ],
                  blur: _vm.handleLostFocus,
                  mousemove: function($event) {
                    return _vm.selectNextOption(i - 1)
                  },
                  click: function($event) {
                    return _vm.insertWordAtCaret(suggestion)
                  }
                }
              },
              [
                _c(
                  "a",
                  {
                    attrs: { id: "suggestion-" + i },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.insertWordAtCaret(suggestion)
                      }
                    }
                  },
                  [
                    _vm._t(
                      "default",
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(suggestion) +
                            "\n                    "
                        )
                      ],
                      { suggestion: suggestion }
                    )
                  ],
                  2
                )
              ]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }