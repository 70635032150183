import { TestType } from '../../enums/TestType'

export const state = {
    testType: TestType.Generalista
}

export const getters = {
    getTestType: state => state.testType
}

export const mutations = {
    setTestType: (state, type) => {
        state.testType = type
    },
}

export const actions = {
    async setTestType({ state, commit }, payload) {
        commit('setTestType', payload)
    }
}

export const Test = { state, mutations, actions, getters }

export default Test
