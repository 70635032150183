<template>
    <div class="col-12">
        <div class="row mt-4">
            <div class="form-group m-form__group col-12">
                <label class="m-checkbox m-checkbox--brand mb-0" :disabled="nbReadOnly">
                    <input type="hidden" name="enable_scheduling" :value.boolean="!!enableScheduling">
                    <input type="checkbox" checked="checked" v-model="enableScheduling" :disabled="nbReadOnly"
                           id="schedule-enabled">
                    ¿Activar programación de envío?
                    <span></span>
                </label>
                <div class="m-separator m-separator--dashed m-separator--lg mt-3 mb-4"></div>
            </div>
        </div>
        <div class="row" :class="{'is-disabled': !enableScheduling}">
            <div class="form-group m-form__group col-12">
                <label>Emails</label>
                <input type="text" class="form-control" name="schedule_emails" v-model="scheduleEmails"
                       :disabled="nbReadOnly" aria-describedby="schedule_email_help" placeholder="...@"
                       id="schedule-emails">
                <nb-error-block path="schedule_emails"></nb-error-block>
                <small id="schedule_email_help" class="form-text text-muted mb-2">
                    Introduce los destinatarios del informe separados por punto y coma (;).
                </small>
            </div>
            <div class="form-group m-form__group col-4">
                <label>Organización</label>
                <input v-for="organizationId in organizationIds" :key="organizationId"
                       type="hidden" name="schedule_organization_ids[]" :value="organizationId">
                <multiselect v-model="scheduleOrganizations" :options="organizations" track-by="id"
                             :disabled="nbReadOnly" label="name" :multiple="true" id="organizations"></multiselect>
                <nb-error-block path="schedule_organization_id"></nb-error-block>
            </div>
            <div class="form-group m-form__group col-4">
                <label>Inicio de actividad</label>
                <input type="datetime-local" class="form-control mr-4" v-model="scheduleStart" :disabled="nbReadOnly"
                       placeholder="Inicio" name="schedule_start" title="Inicio" id="schedule-start">
                <nb-error-block path="schedule_start"></nb-error-block>
            </div>
            <div class="form-group m-form__group col-4">
                <label class="m-checkbox m-checkbox--brand mb-2" :disabled="nbReadOnly">
                    <input type="checkbox" class="form-check-input" name="enable_schedule_end"
                           v-model="enableScheduleEnd" :disabled="nbReadOnly" id="enable-schedule-end">
                    Finalización de actividad
                    <span></span>
                </label>
                <input type="hidden" v-model="scheduleEnd" name="schedule_end">
                <input type="datetime-local" class="form-control mr-4" v-model="scheduleEnd"
                       :disabled="!enableScheduleEnd || nbReadOnly" id="schedule-end">
                <nb-error-block path="schedule_end"></nb-error-block>
            </div>
            <div class="form-group m-form__group col-4">
                <label class="m-checkbox m-checkbox--brand mb-2" :disabled="nbReadOnly">
                    <input type="checkbox" class="form-check-input" name="enable_execution_times"
                           v-model="enableExecutionTimes" :disabled="nbReadOnly" id="enable-executions">
                    Número de repeticiones
                    <span></span>
                </label>

                <div class="input-group">

                    <input type="number" class="form-control" v-model="scheduleMaxExecutionTimes" id="max-executions"
                           placeholder="" step="1" min="1" :disabled="!enableExecutionTimes || nbReadOnly">
                    <input type="hidden" v-model.number="scheduleMaxExecutionTimes"
                           name="schedule_max_execution_times">
                    <div class="input-group-append" v-if="scheduleMaxExecutionTimes">
                        <button class="btn btn-brand" type="button" @click="resetExecutionsCounter"
                                title="Reiniciar contador" id="reset-executions"
                                :disabled="scheduleExecutionsCounter === 0 || nbReadOnly">
                            <i class="la la-refresh"></i>
                        </button>
                    </div>
                </div>

                <div v-if="scheduleMaxExecutionTimes">
                    <span class="small">
                        Se han realizado <strong>{{ scheduleExecutionsCounter }}</strong> envíos.
                        Quedan <strong>{{ scheduleMaxExecutionTimes - scheduleExecutionsCounter }}</strong>
                        para finalizar.
                    </span>
                    <input type="hidden" name="schedule_executions_counter" v-model.number="scheduleExecutionsCounter">
                </div>
                <nb-error-block path="schedule_max_execution_times"></nb-error-block>
            </div>

            <div class="form-group m-form__group col-8">
                <input type="hidden" name="schedule_frequency" :value="selectedScheduleFrequencyValue">
                <label>Intervalos de repetición:</label>
                <div class="input-group m-input-group">
                    <div class="input-group-prepend"><span class="input-group-text">Repetir cada</span></div>
                    <input type="number" class="form-control m-input" min="1" :disabled="nbReadOnly"
                           v-model.number="selectedScheduleFrequency.amount" placeholder="1" id="schedule-frequency">
                    <div class="input-group-append">
                        <select class="form-select form-select-solid select--append" title="Repetición" :disabled="nbReadOnly"
                                v-model.number="selectedScheduleFrequency.type" id="schedule-frequency-type">
                            <option value="0">día/s</option>
                            <option value="1">semana/s</option>
                            <option value="2">mes/es</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group m-form__group col-8">
                <label>Días de la semana</label>
                <div class="btn-group m-btn-group btn-block" role="group">
                    <button type="button" class="btn" @click="markDay(day)" :id="'days-' + day.id"
                            :class="{'btn-outline-primary': viewDay(day.id), 'btn-outline-metal': !viewDay(day.id)}"
                            :disabled="nbReadOnly" v-for="day in weekDays">
                        <i class="la la-check mr-1" v-show="viewDay(day.id)"></i>
                        {{ day.value }}
                    </button>
                </div>
            </div>

            <div class="form-group m-form__group col-4">
                <input type="hidden" name="schedule_conditions" :value="selectedConditionsValue">
                <label>
                    Hora <i class="fa fa-fw fa-info-circle" title="La hora de recepción puede variar." v-tooltip></i>
                </label>
                <input class="form-control m-input" type="time" :disabled="nbReadOnly" v-model="selectedTime" id="time">
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'scheduling-component',
    computed: {
      selectedScheduleFrequencyValue () {
        return JSON.stringify(this.selectedScheduleFrequency)
      },
      selectedConditionsValue () {
        return JSON.stringify({
          weekDays: this.selectedWeekDays,
          time: this.selectedTime ? this.selectedTime : null,
        })
      },
      organizationIds () {
        return this.scheduleOrganizations.map(organization => parseInt(organization.id))
      },
    },
    props: {
      dbEnableScheduling: { type: Boolean, default: false },
      dbScheduleStart: { type: String, default: null },
      dbScheduleEnd: { type: String, default: null },
      dbScheduleEmails: { type: String, default: null },
      dbScheduleMaxExecutionTimes: { type: Number, default: null },
      dbScheduleExecutionsCounter: { type: Number, default: null },
      dbScheduleFrequency: { default: () => ({ amount: 1, type: 0 }) },
      dbScheduleConditions: { default: null },
      dbScheduleOrganizations: { default: () => {} },
      dbScheduleOrganizationIds: { default: [] },
      dbEnableScheduleEnd: { default: false },
      dbEnableMaxExecutionTimes: { default: false },
    },
    created () {
      this.enableScheduling = !!this.dbEnableScheduling
      this.scheduleStart = this.dbScheduleStart
      this.scheduleEnd = this.dbScheduleEnd
      this.enableScheduleEnd = this.dbEnableScheduleEnd
      this.scheduleEmails = this.dbScheduleEmails
      this.enableExecutionTimes = this.dbEnableMaxExecutionTimes
      this.scheduleMaxExecutionTimes = this.dbScheduleMaxExecutionTimes
      this.scheduleExecutionsCounter = this.dbScheduleExecutionsCounter
      this.selectedScheduleFrequency = this.dbScheduleFrequency ? { ...this.dbScheduleFrequency } : {
        amount: 1,
        type: 0
      }
      this.selectedWeekDays = this.dbScheduleConditions
        ? this.weekDays.filter(wd => this.dbScheduleConditions.weekDays.includes(wd.id)).map(wd => wd.id) : []
      this.selectedTime = this.dbScheduleConditions
        ? this.dbScheduleConditions.time : null

      if (Object.keys(this.dbScheduleOrganizations).length) {
        this.organizations = Object.keys(this.dbScheduleOrganizations).map(key => {
          return {
            id: key,
            name: this.dbScheduleOrganizations[key],
          }
        })
        this.scheduleOrganizations = this.organizations
          .filter(organization => this.dbScheduleOrganizationIds.indexOf(parseInt(organization.id)) !== -1)
      }

    },
    watch: {
      enableScheduleEnd () { if (!this.enableScheduleEnd) { this.scheduleEnd = null } },
      enableExecutionTimes () { if (!this.enableExecutionTimes) { this.scheduleMaxExecutionTimes = null } },
    },
    data () {
      return {
        enableScheduling: false,
        enableScheduleEnd: false,
        scheduleStart: null,
        scheduleEnd: null,
        scheduleEmails: null,
        enableExecutionTimes: false,
        scheduleMaxExecutionTimes: null,
        scheduleExecutionsCounter: null,
        scheduleOrganizationId: null,
        scheduleOrganizations: [],
        weekDays: [
          { id: 0, value: 'Domingo' },
          { id: 1, value: 'Lunes' },
          { id: 2, value: 'Martes' },
          { id: 3, value: 'Miércoles' },
          { id: 4, value: 'Jueves' },
          { id: 5, value: 'Viernes' },
          { id: 6, value: 'Sábado' },
        ],
        selectedWeekDays: [],
        selectedTime: null,
        selectedScheduleFrequency: null,
        organizations: [],
      }
    },
    methods: {
      resetExecutionsCounter () {
        this.scheduleExecutionsCounter = 0
      },
      markDay (val) {
        let position = this.selectedWeekDays.indexOf(val.id)

        if (position < 0) {
          this.selectedWeekDays.push(val.id)
        } else {
          this.selectedWeekDays.splice(position, 1)
        }
      },
      viewDay (id) {
        return this.selectedWeekDays.indexOf(id) < 0 ? false : true
      },
    }
  }
</script>

<style lang="scss" scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .7s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .is-disabled {
        filter: blur(0.7px) grayscale(20%);
        transition: all ease-in 0.5s;
        user-select: none;
        pointer-events: none;
    }

    .scheduling-wrapper {
        border: 4px solid #f7f7fa;
        position: relative;
    }

    .enable-scheduling {
        z-index: 10;
        background: #F7F8FC;
        border-radius: 8px;
        position: absolute;
        top: -25px;
        right: 15px;
    }

    .select--append {
        -webkit-appearance: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

</style>
