<template>
    <div class="ticket-edit-bar" :class="{'is-focus': getActiveBar}">
        <div class="ticket-edit-bar__box">
            <div class="ticket-edit-bar__box-left">
                <ticket-buttons :powerUserLoged="powerUserLoged"></ticket-buttons>
            </div>
            <div class="ticket-edit-bar__box-left">
                <ticket-relations></ticket-relations>
            </div>
            <div class="ticket-edit-bar__box-right">
                <ticket-dimensions></ticket-dimensions>
            </div>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters, mapMutations } from 'vuex'
  import TicketDimensions from '@/js/components/ticket_editor/tabs/TicketDimensions'
  import TicketButtons from '@/js/components/ticket_editor/tabs/TicketButtons'
  import TicketRelations from '@/js/components/ticket_editor/tabs/TicketRelations'

  export default {
    name: 'ticket-bar',
    components: { TicketDimensions, TicketButtons, TicketRelations },
    props: ['powerUserLoged'],
    data () {
      return {
        widthLocked: true,
        heightLocked: true,
      }
    },
    computed: {
      ...mapGetters('ticketEditor', {
        ticket: 'ticket',
        getActiveBar: 'getActiveBar',
      }),
      ticketWidth: {
        set (width) { this.setTicket({ ...this.ticket, width }) },
        get () { return this.ticket.width },
      },
      ticketHeight: {
        set (height) { this.setTicket({ ...this.ticket, height }) },
        get () { return this.ticket.height },
      },
    },
    methods: {
      ...mapMutations('ticketEditor', {
        setTicket: 'setTicket',
      }),
    },
  }
</script>
