var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layer-properties__color-graph" },
    [
      _c("span", { staticClass: "field__span" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm._f("trans")("statistics.colors.selectColor")) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("Swatches", {
        staticClass: "is-marginless",
        attrs: {
          swatches: _vm.colors,
          "popover-x": "right",
          "popover-y": "top",
          shapes: "circles",
          "show-border": "",
          "show-fallback": "",
          "fallback-input-type": "color",
          "trigger-style": _vm.swatchesTriggerStyle,
          "fallback-ok-text": _vm.$options.filters.trans(
            "statistics.swatches.buttonText"
          ),
          disabled: _vm.readonly
        },
        model: {
          value: _vm.color,
          callback: function($$v) {
            _vm.color = $$v
          },
          expression: "color"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "layer-properties__color-actions" }, [
        _c("button", {
          attrs: {
            "icon-left": "trash",
            type: "button",
            disabled: _vm.readonly
          },
          on: { click: _vm.emitDelete }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }