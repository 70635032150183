export default {
  methods: {
    setOptions () {
      if (this.button && this.options.length > 0) {
        let key

        for (key in this.options) {
          this.setOptionsFile(this.options[key])
        }

        this.clearSelect = true
        this.showRepository = false
      }
    },
    getOptionsFile (fileSelect) {
      let remove = false
      let fileId = parseInt(fileSelect.id)
      let position = this.listFiles.findIndex(file => file.id === fileId)

      if (fileSelect.hasOwnProperty('delete')) {
        if (fileSelect.delete) {
          remove = true
        }
      }

      return [remove, position]
    },
  },
}
