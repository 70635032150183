var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    { staticClass: "text-center", class: { disabled: _vm.nbReadOnly } },
    [
      _vm.showThisPermission
        ? _c("div", { staticClass: "permission-group" }, [
            _vm.userRoleInfo
              ? _c("i", {
                  class:
                    "role-status__relative fa fa-fw fa-2x " +
                    _vm.getRolePermissionStatus(),
                  attrs: {
                    title:
                      _vm.$options.filters.trans(
                        "permission_component.inheritedRoleAuth"
                      ) +
                      "\n           " +
                      _vm.userRoleInfo.role.visible_name
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("i", {
              class: "fa fa-2x state-icon " + _vm.getPermissionStatus(),
              on: {
                click: function($event) {
                  return _vm.toggle()
                }
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }