import { render, staticRenderFns } from "./SpecialPermissionsComponent.vue?vue&type=template&id=945b4e80&"
import script from "./SpecialPermissionsComponent.vue?vue&type=script&lang=js&"
export * from "./SpecialPermissionsComponent.vue?vue&type=script&lang=js&"
import style0 from "./SpecialPermissionsComponent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('945b4e80')) {
      api.createRecord('945b4e80', component.options)
    } else {
      api.reload('945b4e80', component.options)
    }
    module.hot.accept("./SpecialPermissionsComponent.vue?vue&type=template&id=945b4e80&", function () {
      api.rerender('945b4e80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/permissions/SpecialPermissionsComponent.vue"
export default component.exports