var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-12 mt-4" }, [
    _c("input", {
      attrs: { type: "hidden", name: "competenceDomainsData" },
      domProps: { value: _vm.jsonCompetenceDomains }
    }),
    _vm._v(" "),
    _c("table", { staticClass: "table table-row-bordered" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _c("th", { staticClass: "col-3" }, [
              _vm._v("Dominio Competencial *")
            ]),
            _vm._v(" "),
            _vm._l(_vm.questionTypes, function(questionType) {
              return _c("th", { key: questionType.id, staticClass: "col-1" }, [
                _vm._v(
                  "\n          " + _vm._s(questionType.name) + "\n        "
                )
              ])
            }),
            _vm._v(" "),
            _c("th", { staticClass: "col-3" }, [_vm._v("Cursos")]),
            _vm._v(" "),
            _c("th")
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.data.length > 0
        ? _c(
            "tbody",
            _vm._l(_vm.data, function(competenceDomain, i) {
              return _c(
                "tr",
                { key: i, staticClass: "tag-row" },
                [
                  _c(
                    "td",
                    { staticClass: "align-middle" },
                    [
                      _c(
                        "div",
                        { staticClass: "input-group input-group-solid" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: competenceDomain.name,
                                expression: "competenceDomain.name"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id:
                                "competenceDomain-" +
                                i +
                                "-" +
                                competenceDomain.name
                            },
                            domProps: { value: competenceDomain.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  competenceDomain,
                                  "name",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("nb-error-block", {
                            attrs: {
                              path: "competenceDomainsData." + i + ".name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("nb-error-block", {
                        attrs: {
                          path: "competenceDomainsData." + i + ".questionTypes"
                        }
                      }),
                      _vm._v(" "),
                      _vm.lang
                        ? _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(competenceDomain.default_name) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.questionTypes, function(questionType) {
                    return _c(
                      "td",
                      {
                        key: questionType.id,
                        staticClass: "text-center text-nowrap"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "form-check form-check-custom form-check-solid"
                          },
                          [
                            _c("input", {
                              staticClass: "form-check-input",
                              attrs: {
                                type: "checkbox",
                                id:
                                  "competenceDomain-" +
                                  i +
                                  "-" +
                                  questionType.id,
                                disabled: _vm.lang !== null ? true : false
                              },
                              domProps: {
                                checked: _vm.findCompetenceDomain(
                                  competenceDomain,
                                  questionType.id
                                )
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updateCompetenceDomains(
                                    questionType,
                                    i
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.getTestType.id == _vm.TestType.SuperEspecialista
                              ? _c("input", {
                                  staticClass: "form-control w-50 ms-1 p-1",
                                  attrs: {
                                    id:
                                      "competenceDomain-" +
                                      i +
                                      "-" +
                                      questionType.id +
                                      "-weight",
                                    type: "number",
                                    disabled: !_vm.findCompetenceDomain(
                                      competenceDomain,
                                      questionType.id
                                    )
                                  },
                                  domProps: {
                                    value: _vm.getWeight(
                                      competenceDomain,
                                      questionType.id
                                    )
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateCompetenceDomainsWeight(
                                        questionType.id,
                                        i,
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle" }, [
                    _c(
                      "div",
                      { staticClass: "input-group input-group-solid" },
                      [
                        _c(
                          "div",
                          { staticClass: "form-control p-0" },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.courses,
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": false,
                                label: "name",
                                "track-by": "name",
                                disabled: _vm.lang !== null ? true : false
                              },
                              model: {
                                value: competenceDomain.courses,
                                callback: function($$v) {
                                  _vm.$set(competenceDomain, "courses", $$v)
                                },
                                expression: "competenceDomain.courses"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    !_vm.lang
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            attrs: {
                              type: "button",
                              id:
                                "remove-competence-domain-" +
                                i +
                                "-" +
                                competenceDomain.id
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.removeCompetenceDomain(_vm.data, i)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-trash" })]
                        )
                      : _vm._e()
                  ])
                ],
                2
              )
            }),
            0
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    !_vm.lang
      ? _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button" },
            on: { click: _vm.addCompetenceDomain }
          },
          [_c("i", { staticClass: "fa fa-plus-circle" })]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }