var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 pl-0 pr-0" },
    [
      _vm.interfaceTexts.title !== null
        ? [
            _c("div", {}, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "h4",
                  { staticClass: "ml-3 mr-3" },
                  [
                    _c("em", { staticClass: "flaticon-graphic mr-1" }),
                    _vm._v(" "),
                    "title" in _vm.interfaceTexts
                      ? [_vm._v(_vm._s(_vm.interfaceTexts["title"]))]
                      : [_vm._v("Listado")]
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-6 text-right" },
                [_vm._t("actions")],
                2
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.filters.length
        ? _c("div", { staticClass: "m-entity-list-search" }, [
            _c(
              "form",
              { staticClass: "d-flex align-content-start flex-wrap" },
              [
                _vm._l(_vm.filters, function(element, key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "col-sm-6 col-md-2 p-2" },
                    [
                      element[0] === "select"
                        ? [
                            _c(
                              "select",
                              {
                                staticClass: "form-control m-input",
                                attrs: {
                                  autocomplete: "off",
                                  placeholder: element[2],
                                  name: element[1],
                                  id: key,
                                  value: ""
                                },
                                on: {
                                  change: function($event) {
                                    var i = arguments.length,
                                      argsArray = Array(i)
                                    while (i--) argsArray[i] = arguments[i]
                                    return _vm.addFilter.apply(
                                      void 0,
                                      [key, "equalFilter", element[1]].concat(
                                        argsArray
                                      )
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      hidden: "",
                                      disabled: "",
                                      selected: "",
                                      value: ""
                                    }
                                  },
                                  [_vm._v(_vm._s(element[2]))]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.options(element[1]), function(
                                  element
                                ) {
                                  return [
                                    _c(
                                      "option",
                                      { domProps: { value: element } },
                                      [_vm._v(_vm._s(element))]
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        : element[0] === "date_since"
                        ? [
                            _c("input", {
                              staticClass: "form-control m-input",
                              attrs: {
                                autocomplete: "off",
                                placeholder: element[2],
                                name: element[1],
                                id: key,
                                value: "",
                                type: "text",
                                onfocus: "(this.type='date')"
                              },
                              on: {
                                change: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.addFilter.apply(
                                    void 0,
                                    [key, "gtFilter", element[1]].concat(
                                      argsArray
                                    )
                                  )
                                }
                              }
                            })
                          ]
                        : element[0] === "date_to"
                        ? [
                            _c("input", {
                              staticClass: "form-control m-input",
                              attrs: {
                                autocomplete: "off",
                                placeholder: element[2],
                                name: element[1],
                                id: key,
                                value: "",
                                type: "text",
                                onfocus: "(this.type='date')"
                              },
                              on: {
                                change: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.addFilter.apply(
                                    void 0,
                                    [key, "ltFilter", element[1]].concat(
                                      argsArray
                                    )
                                  )
                                }
                              }
                            })
                          ]
                        : element[0] === "search"
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "m-input-icon m-input-icon--right"
                              },
                              [
                                _c("label", {
                                  staticClass: "sr-only",
                                  attrs: { for: "qSearch-" + key }
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "form-control m-input",
                                  attrs: {
                                    autocomplete: "off",
                                    placeholder: element[2],
                                    name: element[1],
                                    id: "qSearch-" + key,
                                    value: "",
                                    type: "text"
                                  },
                                  on: {
                                    input: function($event) {
                                      var i = arguments.length,
                                        argsArray = Array(i)
                                      while (i--) argsArray[i] = arguments[i]
                                      return _vm.addFilter.apply(
                                        void 0,
                                        [key, "likeFilter", element[1]].concat(
                                          argsArray
                                        )
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm._m(0, true)
                              ]
                            )
                          ]
                        : element[0] === "multiselect"
                        ? [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.options(element[1]),
                                multiple: false,
                                placeholder: element[2]
                              },
                              on: {
                                input: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.addFilterMultiselect.apply(
                                    void 0,
                                    [key, "likeFilter", element[1]].concat(
                                      argsArray
                                    )
                                  )
                                },
                                "search-change": function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.addFilterMultiselect.apply(
                                    void 0,
                                    [key, "likeFilter", element[1]].concat(
                                      argsArray
                                    )
                                  )
                                },
                                select: function($event) {
                                  var i = arguments.length,
                                    argsArray = Array(i)
                                  while (i--) argsArray[i] = arguments[i]
                                  return _vm.addFilterMultiselect.apply(
                                    void 0,
                                    [key, "likeFilter", element[1]].concat(
                                      argsArray
                                    )
                                  )
                                }
                              },
                              model: {
                                value: _vm.multiselectValues[key],
                                callback: function($$v) {
                                  _vm.$set(_vm.multiselectValues, key, $$v)
                                },
                                expression: "multiselectValues[key]"
                              }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "p-2" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "m-input btn-reset btn btn-secondary m-btn--icon",
                      attrs: { type: "reset", "data-reset-form": "true" },
                      on: {
                        click: function($event) {
                          return _vm.resetFilters()
                        }
                      }
                    },
                    [
                      _c("em", { staticClass: "fa fa-fw fa-trash" }),
                      _vm._v("\n                    Limpiar\n                ")
                    ]
                  )
                ])
              ],
              2
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "body-padding pt-0" },
        [
          _c("div", {
            staticClass: "m-separator m-separator--dashed m-separator--lg my-3"
          }),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table m-table m-table--head-bg-light" },
            [
              _c("thead", [
                _c(
                  "tr",
                  [
                    _vm._l(_vm.renderableHeaders(), function(header, key) {
                      return _c("th", { key: key }, [
                        _c("span", { staticClass: "column-no-sort" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(header[0]) +
                              "\n                "
                          )
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    !_vm.isEmptyActions()
                      ? _c("th", { staticClass: "text-center" })
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _vm.currentList.length
                ? _c(
                    "paginate",
                    {
                      key: _vm.paginateKey,
                      attrs: {
                        name: "currentList",
                        tag: "tbody",
                        list: _vm.currentList,
                        per: _vm.elementsPerPage
                      }
                    },
                    _vm._l(_vm.currentList, function(row, rowKey) {
                      return _vm.currentList[_vm.calculateIndex(rowKey)]
                        ? _c(
                            "tr",
                            {
                              key: JSON.stringify(row) + "_" + rowKey,
                              attrs: { "data-model-id": rowKey }
                            },
                            [
                              _vm._l(_vm.renderableHeaders(), function(
                                col,
                                colKey
                              ) {
                                return _c("td", { key: colKey }, [
                                  col[1] === "checkbox"
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "m-switch m-switch--icon"
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked:
                                                    _vm.currentList[
                                                      _vm.calculateIndex(rowKey)
                                                    ][col[2]]
                                                },
                                                on: {
                                                  change: function($event) {
                                                    _vm.$emit(
                                                      "update:" + col[2],
                                                      _vm.currentList[
                                                        _vm.calculateIndex(
                                                          rowKey
                                                        )
                                                      ]
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.addCell(
                                              _vm.calculateIndex(rowKey),
                                              col
                                            )
                                          )
                                        }
                                      })
                                ])
                              }),
                              _vm._v(" "),
                              !_vm.isEmptyActions()
                                ? _c(
                                    "td",
                                    { staticClass: "align-middle text-center" },
                                    [
                                      "edit" in _vm.actions
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn-info m-btn m-btn--icon btn-sm m-btn--icon-only mr-1",
                                              attrs: {
                                                id: "edit",
                                                title: "Editar",
                                                id: "edit-" + rowKey,
                                                href: _vm.populateURL(
                                                  _vm.actions["edit"],
                                                  row
                                                ),
                                                "data-original-title": "Editar"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-fw fa-pencil",
                                                attrs: { "data-v-a89a1b92": "" }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      "delete" in _vm.actions
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only",
                                              attrs: {
                                                type: "button",
                                                title: "Eliminar",
                                                id: "delete-" + rowKey,
                                                disabled: _vm.nbReadOnly
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.erase(rowKey)
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-plus"
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    }),
                    0
                  )
                : _c("tbody", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "text-center py-4",
                          attrs: { colspan: _vm.colspan }
                        },
                        [
                          "empty-list" in _vm.interfaceTexts
                            ? [_vm._v(_vm._s(_vm.interfaceTexts["empty-list"]))]
                            : [_vm._v("Lista vacía.")]
                        ],
                        2
                      )
                    ])
                  ]),
              _vm._v(" "),
              _vm.footers
                ? _c("tfoot", { staticClass: "footer" }, [
                    _c(
                      "tr",
                      _vm._l(_vm.footers, function(footer, key) {
                        return _c(
                          "td",
                          { key: key, attrs: { colspan: _vm.colspan } },
                          [
                            footer[1] === "eursum"
                              ? [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(footer[0]) +
                                      ": " +
                                      _vm._s(_vm.addFooter(key)) +
                                      " €\n                    "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.ajax === false
            ? _c("paginate-links", {
                staticClass: "pull-right",
                attrs: {
                  for: "currentList",
                  limit: 4,
                  "show-step-links": true,
                  "step-links": { prev: "‹", next: "›" },
                  "hide-single-page": true,
                  classes: {
                    ul: "pagination",
                    li: "page-item",
                    a: "page-link"
                  },
                  async: true
                },
                on: { change: _vm.onPageChange }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.ajax === true && _vm.currentList.length && _vm.lastPage > 1
        ? _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("paginator", {
                key: _vm.paginateKey,
                attrs: {
                  "current-page": _vm.currentPage,
                  links: _vm.paginationLinks,
                  meta: _vm.paginationMeta,
                  "loading-page-number": _vm.currentPage
                },
                on: { "set-page": _vm.onPageChange }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "m-input-icon__icon m-input-icon__icon--right" },
      [_c("span", [_c("i", { staticClass: "fa fa-fw fa-search" })])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }