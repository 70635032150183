var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group m-form__group col-12 mt-4" },
    [
      _c("input", {
        attrs: { type: "hidden", name: "questionsData" },
        domProps: { value: _vm.jsonQuestions }
      }),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            !_vm.lang
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    attrs: { disabled: _vm.actionDisabled },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.remove($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-trash" }),
                    _vm._v(" Eliminar\n        ")
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.lang
              ? _c("div", { staticClass: "dropdown ms-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary dropdown-toggle",
                      attrs: {
                        type: "button",
                        "data-bs-toggle": "dropdown",
                        "aria-expanded": "false",
                        disabled: _vm.actionDisabled
                      }
                    },
                    [_vm._v("\n            Publicar\n          ")]
                  ),
                  _vm._v(" "),
                  _c("ul", { staticClass: "dropdown-menu" }, [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: { click: _vm.publishQuestions }
                        },
                        [_vm._v("Publicar")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: { click: _vm.unpublishQuestions }
                        },
                        [_vm._v("Despublicar")]
                      )
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "float-end" }, [
            _vm.testId
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.importQuestion($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v("\n          Importar preguntas\n        ")
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.lang
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.addQuestion($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v("\n          Nueva pregunta\n        ")
                  ]
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("bootstrap-table", {
        ref: "table",
        staticClass:
          "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 table-disable-hover",
        attrs: {
          columns: _vm.columns,
          data: _vm.questions,
          options: _vm.options
        },
        on: {
          "on-check": _vm.onSelect,
          "on-uncheck": _vm.onSelect,
          "on-check-all": _vm.onSelect,
          "on-uncheck-all": _vm.onSelect
        }
      }),
      _vm._v(" "),
      _vm.showModal
        ? _c("EditQuestionModal", {
            attrs: {
              lang: _vm.lang,
              showModal: _vm.showModal,
              question: _vm.currentQuestion,
              questionPosition: _vm.questionPosition,
              questionTypes: _vm.questionTypes,
              competenceDomains: _vm.competenceDomains,
              levels: _vm.levels,
              questions: _vm.questions
            },
            on: {
              closeShowModal: _vm.closeShowModal,
              editQuestion: _vm.editQuestion,
              newQuestion: _vm.newQuestion
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalImport
        ? _c("ImportQuestionModal", {
            attrs: {
              showModalImport: _vm.showModalImport,
              "test-id": _vm.testId
            },
            on: { closeImportModal: _vm.closeImportModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }