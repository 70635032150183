<template>
  <div>
    <div class="col-12 mb-4 text-right c-survey-component__btn-add-option">
      <input type="hidden" name="configuration" :value="configurationJSON">
      <button type="button" class="btn btn-primary m-btn m-btn--icon" @click="addFile"
              :disabled="nbReadOnly" id="add-option">
        <span>
            <i class="fa fa-plus"></i>
            <span>
              {{ 'forms_crm_external_register.configuration_component.add_config_button' | trans }}
            </span>
        </span>
      </button>
    </div>
    <div class="fs-5 fw-bolder alert text-center my-20" v-if="!configurationArray.length">
      {{ 'forms_crm_external_register.configuration_component.no_data' | trans }}
    </div>
    <table v-else class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
      <thead>
        <tr>
          <th>
            {{ 'forms_crm_external_register.configuration_component.name' | trans }}
          </th>
          <th>
            {{ 'forms_crm_external_register.configuration_component.value' | trans }}
          </th>
          <th width="1%" v-if="!lang"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(configuration, key) in configurationArray" :key="key" class="align-top">
          <td>
            <div class="m-form__control">
                <input v-model="configuration.name" class="form-control form-control-sm m-input"
                       placeholder="Opcion" :disabled="nbReadOnly" :id="'option-' + key"
                       @input="emit"
                >
                <nb-error-block :path="`configuration.${key}.name`"></nb-error-block>
            </div>
          </td>
          <td>
            <div class="m-form__control">
                <input v-model="configuration.value" class="form-control form-control-sm m-input"
                       placeholder="Opcion" :disabled="nbReadOnly" :id="'option-' + key"
                       @input="emit"
                >
                <NbErrorBlock :path="`configuration.${key}.value`" />
            </div>
          </td>
          <td class="align-middle text-nowrap">
            <div class="actions text-center">
              <button type="button" class="btn btn-icon btn-danger btn-sm"
                      @click="deleteOption(key)" :disabled="nbReadOnly"
                      :id="'delete-option-' + key">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </td>
          </tr>
          </tbody>
      </table>
  </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";

  export default {
    name: 'ExternalRegisterConfig',
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      lang: {
        type: String,
        default: null,
      },
    },
    components: {
      NbErrorBlock
    },
    data () {
      return {
        configurationArray: [],
      }
    },
    created () {
      this.configurationArray = this.value.length ? this.value : []
    },
    computed: {
      configurationJSON () {
        return JSON.stringify(this.configurationArray)
      }
    },
    methods: {
      deleteOption (index) {
        this.configurationArray.splice(index, 1)
      },
      getEmptyNewOption () {
        return {
          name: '',
          value: '',
        }
      },
      addFile () {
        this.configurationArray.push(this.getEmptyNewOption())
      },
      emit () {
        this.$emit('input', this.configurationArray)
      }
    },
  }
</script>

<style scoped>

</style>
