<template>
    <div ref="modal"
         class="modal fade"
         :class="getCurrentClassesModal"
         :tabindex="keyboard ? '-1' : false"
         role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">

        <div class="modal-dialog" :class="getCurrentClassesModalDialog"  role="document">
            <div v-if="visible" class="modal-content">
                <loader :loading="loading">
                    <div v-if="!!this.$slots.header || !!this.$slots.title || buttonClose || buttonMaximize"
                         class="modal-header"
                         :class="classesHeader">
                        <h4 v-if="!!this.$slots.title" class="modal-title">
                            <slot name="title"></slot>
                        </h4>
                        <slot name="header"></slot>
                        <button v-if="buttonMaximize"
                                type="button"
                                class="maximize"
                                aria-label="Maximize"
                                @click="maximized = !maximized">
                        </button>
                        <div v-if="buttonClose"
                             type="button"
                             class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                             aria-label="Close"
                             @click="toggleModal(false)">
                            <span class="svg-icon svg-icon-2x">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>

                    <loader :loading="refreshing">
                        <div class="modal-body" :class="classesBody">
                            <slot></slot>
                        </div>
                        <div v-if="!!this.$slots.footer" class="modal-footer" :class="classesFooter">
                            <slot name="footer"></slot>
                        </div>
                    </loader>

                </loader>
            </div>
        </div>
    </div>
</template>

<script>
  import Loader from '@/js/components/back-office/packages/Loader'

  export default {
    name: 'modal-component',
    components: { Loader, },
    props: {
      visible: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
      refreshing: { type: Boolean, default: false },
      buttonClose: { type: Boolean, default: true },
      buttonMaximize: { type: Boolean, default: false },
      openMaximized: { type: Boolean, default: false },
      classesModal: { type: Array, default: () => [] },
      classesModalDialog: { type: Array, default: () => [] },
      classesHeader: { type: Array, default: () => [] },
      classesBody: { type: Array, default: () => [] },
      classesFooter: { type: Array, default: () => [] },
      backdrop: { type: [String, Boolean], default: '' },
      keyboard: { type: Boolean, default: true },
      focus: { type: Boolean, default: true },
    },
    data () {
      return {
        maximized: false,
      }
    },
    watch: {
      visible (newVisible, oldVisible) {
        this.toggleModal(newVisible)
      }
    },
    mounted () {
      this.maximized = this.openMaximized

      this.$refs['modal'].addEventListener('hidden.bs.modal', (event) => {
        event.stopPropagation()
        this.toggleModal(false)
      })

      this.$refs['modal'].addEventListener('shown.bs.modal', (event) => {
        event.stopPropagation()
        this.$emit('shown')
      })

      this.toggleModal(this.visible)

      // keyboard events
      if (this.keyboard) {
        document.body.addEventListener('keyup', e => {
          if (e.keyCode === 27) { // Esc key
            this.toggleModal(false)
          }
        })
      }
    },
    unmounted () {
      this.toggleModal(false)
    },
    beforeDestroy () {
      this.toggleModal(false)
    },
    computed: {
      /**
       * Gets the correct modal classes, beware, it doesn't retrieve .show class.
       */
      getCurrentClassesModal () {
        return [
          ...this.classesModal,
          this.maximized ? 'modal--maximized' : '',
          // https://stackoverflow.com/a/30876481/2165413
          (this.backdrop === true || this.backdrop === 'static') ? 'in' : '',
        ]
      },
      getCurrentClassesModalDialog () {
        return [
          ...this.classesModalDialog,
          this.loading ? '' : 'is-loaded',
        ]
      },
    },
    methods: {
      /**
       * Launches and closes the modal.
       */
      toggleModal (visible) {
        const $modal = bootstrap.Modal.getOrCreateInstance(this.$refs['modal'], {
            keyboard: this.keyboard,
            backdrop: this.backdrop,
            focus: this.focus,
        })

        if (visible === true) {
          $modal.show()
        } else {
          $modal.hide()
          this.$emit('update:visible', false)
        }
      }
    }
  }
</script>
