<template>
  <div class="form-group m-form__group col-md-8 mb-10">
    <div class="row">
      <div class="col-md-6">

          <label class="form-check form-switch form-check-custom form-check-solid">
              <input type="hidden" :value="enabled" name="enabled" id="enabled">
              <input type="checkbox" :checked="enabled"
                     name="enabled--check" :disabled="nbReadOnly" id="enabled--check"
                     v-model.boolean="enabled"
                    class="form-check-input">
                  <span class="form-check-label">{{ 'forms_crm_customers.enabled' | trans }}</span>
          </label>

      </div>
      <div class="col-md-6">

          <label class="form-check form-switch form-check-custom form-check-solid">
              <input type="hidden" :value="emailVerified" name="email_verified" id="email_verified">
              <input type="checkbox" :checked="emailVerified" class="form-check-input"
                     name="email_verified--check" :disabled="nbReadOnly" id="email_verified--check"
                     v-model.boolean="emailVerified" @change="updateEmailVerified" />
              <span class="form-check-label">{{ 'forms_crm_customers.email_verified' | trans }}</span>
          </label>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'customer-activations-component',
  props: {
    oldEnabled: {type: Boolean, default: false},
    oldEmailVerified: {type: Boolean, default: false},
  },
  data() {
    return {
      enabled: false,
      emailVerified: false,
    }
  },
  created() {
    this.enabled = this.oldEnabled
    this.emailVerified = this.oldEmailVerified
  },
  methods: {
    updateEmailVerified (e) {
      if (e.target.checked) {
        this.$swal.fire({
          title: '¿Está seguro de que desea validar el email de este cliente?',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Sí, validar',
          cancelButtonText: 'Cancelar',
            //ToDo: añadir como default
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-white'
            },
            buttonsStyling: false,
        }).then((result) => {
          if (result.dismiss) {
            this.emailVerified = false
          }
        })
      }
    }
  }
}
</script>
