// TODO: EDITOR VISUAL Generalizar
export const state = {
  ticket: {
    width: null,
    height: null,
  },
  elements: [],
  activeElement: null,
  availableVariables: [
    '{categoria_audiencia}',
    '{cupo.nombre}',
    '{cupo.color}',
    '{tipo_audiencia}',
    '{sesion.titulo}',
    '{organizacion.nombre}',
    '{organizacion.cif}',
    '{tarifa.base_y_gastos}',
    '{tarifa.base}',
    '{tarifa.gastos}',
    '{tarifa.descuento}',
    '{codigo_barras}',
    '{promotor.nombre}',
    '{promotor.cif}',
    '{sesion.fecha}',
    '{sesion.fecha_amigable}',
    '{butaca}',
    '{espacio}',
    '{direccion_espacio}',
    '{sala}',
    '{zona_fisica}',
    '{puerta}',
    '{ticket.asistente}',
    '{ticket.numero}',
    '{ticket.fecha_impresion}',
    '{localizador_expediente}',
    '{butaca_fila}',
    '{butaca_numero}',
    '{recibo_servicio}',
    '{servicios_incluidos}',
  ],
  availableImageVariables: [
    '{imagen}',
    '{logo_organismo}',
    '{logo_organismo_ticket}',
    '{logo_organismo_ticket_bn}',
    '{logo_patrocinador}',
    '{publi.1}',
    '{publi.2}',
    '{publi.3}',
  ],
  selectedScope: null,
  scopeData: null,
  activeTabProperties: 'properties',
  zoomPreview: 0,
  zoomBase: 10,
  scopeEvents: null,
  scopeEvent: null,
  support: null,
  loadingPreview: false,
  fullScreen: false,
  activeBar: false,
}

export const getters = {
  ticket: state => state.ticket,
  elements: state => state.elements,
  activeElement: state => state.activeElement,
  activeTabProperties: state => state.activeTabProperties,
  availableVariables: state => state.availableVariables,
  availableImageVariables: state => state.availableImageVariables,
  getSelectedScope: state => state.selectedScope,
  getScopeData: state => state.scopeData,
  getScopeEvents: state => state.scopeEvents,
  getScopeEvent: state => state.scopeEvent,
  getLoadingPreview: state => state.loadingPreview,
  getFullScreen: state => state.fullScreen,
  getSupport: state => state.support,
  zoomPreview: state => state.zoomPreview,
  zoomBase: state => state.zoomBase,
  getActiveBar: state => state.activeBar,
}

export const mutations = {
  setTicket: (state, ticket) => { state.ticket = ticket },
  setElements: (state, elements) => { state.elements = [...elements] },
  addElement: (state, element) => {
    if (element.blocked === undefined)
      element.blocked = false
    if (element.visible === undefined || element.visible === null)
      element.visible = 1
    if (element.blockPower === undefined)
      element.blockPower = 0
    state.elements.unshift(element)
  },
  setActiveElement: (state, element) => {state.activeElement = element },
  setActiveTabProperties: (state, tab) => {state.activeTabProperties = tab },
  setSelectedScope: (state, scope) => {state.selectedScope = scope },
  setScopeData: (state, scopeData) => {state.scopeData = scopeData},
  setScopeEvents: (state, scopeEvents) => {state.scopeEvents = scopeEvents},
  setScopeEvent: (state, scopeEvent) => {state.scopeEvent = scopeEvent},
  setLoadingPreview: (state, loading) => {state.loadingPreview = loading},
  setZoomPreview: (state, zoom) => {state.zoomPreview = zoom},
  setFullScreen: (state, fullScreen) => {state.fullScreen = fullScreen},
  setSupport: (state, support) => {state.support = support},
  setActiveBar: (state, activeBar) => {state.activeBar = activeBar},
  updateActiveElementStyle: (state, properties) => {
    const newStyle = {}
    newStyle[properties.prop] = properties.value
    state.activeElement.style = { ...state.activeElement.style, ...newStyle }
  },
  updateBarcodeProperties: (state, properties) => {
    const newProperty = {}
    newProperty[properties.prop] = properties.value
    state.activeElement.barcode = { ...state.activeElement.barcode, ...newProperty }
  },
  updateQRProperties: (state, properties) => {
    const newProperty = {}
    newProperty[properties.prop] = properties.value
    state.activeElement.qr = { ...state.activeElement.qr, ...newProperty }
  },
  updateVisibility: (state, properties) => {
    const newProperty = {}
    newProperty[properties.prop] = properties.value
    state.activeElement.visibility = { ...state.activeElement.visibility, ...newProperty }
  },
  updateCondition: ({ activeElement }, { condition, newCondition }) => {
    const index = activeElement.visibility.conditions.findIndex(c => c.id === condition.id)
    Vue.set(activeElement.visibility.conditions, index, { ...newCondition })
  },
  addCondition: (state, condition) => {
    Vue.set(state.activeElement, 'visibility', {
      ...state.activeElement.visibility,
      conditions: [...state.activeElement.visibility.conditions, { ...condition }],
    })
  },
  removeCondition: (state, condition) => {
    Vue.set(state.activeElement, 'visibility', {
      ...state.activeElement.visibility,
      conditions: [...state.activeElement.visibility.conditions.filter(c => c.id !== condition.id)],
    })
  },
}

export const actions = {
  async resetValues ({ commit }) {
    commit('setTicket', {
      width: null,
      height: null,
    })
    commit('setElements', [])
    commit('setActiveElement', null)
    commit('setSelectedScope', null)
    commit('setScopeData', null)
    commit('setScopeEvents', null)
    commit('setScopeEvent', null)
  },
  async onEditorLoading () {
    mApp.block('#ticket-editor-html')
  },
  async offEditorLoading () {
    mApp.unblock('#ticket-editor-html')
  },
}

export const TicketEditor = { state, mutations, actions, getters }

export default TicketEditor
