var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "form-group m-form__group " + _vm.containerClass },
    [
      _c("label", { staticClass: "form-label", attrs: { for: "tagger" } }, [
        _vm._v("Tags")
      ]),
      _vm._v(" "),
      _c("multiselect", {
        attrs: {
          name: "tagger",
          "tag-placeholder": "Crear nuevo tag",
          id: "tags",
          placeholder: "Elige o crea un tag",
          label: "name",
          "track-by": "name",
          disabled: _vm.nbReadOnly,
          options: _vm.choices,
          multiple: true,
          taggable: true
        },
        on: { tag: _vm.addTag },
        model: {
          value: _vm.selectedTags,
          callback: function($$v) {
            _vm.selectedTags = $$v
          },
          expression: "selectedTags"
        }
      }),
      _vm._v(" "),
      _c("input", {
        ref: "saveField",
        attrs: { id: "tagger", name: "tagger", type: "hidden" },
        domProps: { value: _vm.jsonResult }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }