const StringOperators = {
  Contains: 'contains',
  NotContains: 'notContains',
  Equals: 'equals',
  NotEquals: 'notEquals',
  Set: 'set',
  NotSet: 'notSet',
}

const DateOperators = {
  InDateRange: 'inDateRange',
  Equals: 'equals',
  NotEquals: 'notEquals',
  BeforeDate: 'beforeDate',
  AfterDate: 'afterDate',
  Set: 'set',
  NotSet: 'notSet',
}

const NumberOperators = {
  Equals: 'equals',
  NotEquals: 'notEquals',
  LessThan: 'lt',
  LessEqualThan: 'lte',
  GreaterThan: 'gt',
  GreaterEqualThan: 'gte',
  Set: 'set',
  NotSet: 'notSet',
}

export { StringOperators, DateOperators, NumberOperators }
