var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: "click-prevent-layer " + _vm.savingLayerClass }),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.role
          ? _c("p", { staticClass: "permissions-manager__intro" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("trans")("permission_component.grantsForRole")
                  ) +
                  " "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.role.visible_name))])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.user
          ? _c("div", [
              _c("small", { staticClass: "text-muted pull-right" }, [
                _c("i", { staticClass: "fa fa-fw fa-info-circle" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm._f("trans")(
                        "permission_component.showInheritedGroupGrants"
                      )
                    ) +
                    "\n                    "
                ),
                _c("strong", [
                  _vm._v(_vm._s(_vm.userRoleInfo.role.visible_name))
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm._f("trans")("permission_component.grantsForUser")
                  ) + " "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.user.name))])
              ])
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-4 permissions-manager__access" },
        [
          _c("strong", { staticClass: "permissions-manager__title" }, [
            _vm._v(
              _vm._s(_vm._f("trans")("permission_component.sectionGrants"))
            )
          ]),
          _vm._v(" "),
          _c("span", [
            _c(
              "div",
              {
                staticClass:
                  "m-input-icon m-input-icon--right position-relative"
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sectionSearch,
                      expression: "sectionSearch"
                    }
                  ],
                  staticClass: "pe-10 form-control form-control-solid",
                  attrs: {
                    autocomplete: "off",
                    type: "text",
                    name: "section_search",
                    placeholder: "Buscar",
                    id: "section-search"
                  },
                  domProps: { value: _vm.sectionSearch },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.sectionSearch = $event.target.value
                      },
                      function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.performSectionSearch($event)
                      }
                    ]
                  }
                }),
                _vm._v(" "),
                _vm._m(0)
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "permission-siblings" }, [
            _c(
              "table",
              { staticClass: "table table-hover" },
              _vm._l(_vm.sectionSearchResults, function(section, index) {
                return _c("section-item", {
                  key: section.id,
                  attrs: {
                    userRoleSection: _vm.userRoleInfo
                      ? _vm.userRoleInfo.sections[index]
                      : null,
                    section: section,
                    index: "0_" + index,
                    depth: 0,
                    nbReadOnly: _vm.nbReadOnly
                  },
                  on: {
                    sectionUpdated: function($event) {
                      return _vm.sectionUpdated(section.id)
                    }
                  }
                })
              }),
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-8 permissions-manager__auth" }, [
        _c("strong", { staticClass: "permissions-manager__title" }, [
          _vm._v(_vm._s(_vm._f("trans")("permission_component.entityGrants")))
        ]),
        _vm._v(" "),
        _c("span", [
          _c(
            "div",
            {
              staticClass: "m-input-icon m-input-icon--right position-relative"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.entitySearch,
                    expression: "entitySearch"
                  }
                ],
                staticClass: "pe-10 form-control form-control-solid",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  name: "entity_search",
                  placeholder: "Buscar",
                  id: "entity-search"
                },
                domProps: { value: _vm.entitySearch },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.entitySearch = $event.target.value
                    },
                    function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.performEntitySearch($event)
                    }
                  ]
                }
              }),
              _vm._v(" "),
              _vm._m(1)
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table table-hover" }, [
            _c("thead", [
              _c(
                "tr",
                [
                  _c("th"),
                  _vm._v(" "),
                  _vm._l(_vm.permissionTypes, function(permissionType) {
                    return _c(
                      "th",
                      {
                        staticClass: "text-center permission-type text-nowrap"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "form-check form-check-custom form-check-solid"
                          },
                          [
                            _c("input", {
                              staticClass: "form-check-input",
                              attrs: {
                                type: "checkbox",
                                id: "permission-type-" + permissionType.id,
                                disabled: _vm.nbReadOnly
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updatePermissions(
                                    $event,
                                    permissionType
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "form-check-label" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(permissionType.name) +
                                  "\n                    "
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              [
                _vm._l(_vm.entitySearchResults, function(entity) {
                  return [
                    _c("permission-row", {
                      key: entity.id,
                      attrs: {
                        entity: entity,
                        userRoleInfo: _vm.userRoleInfo,
                        permissions: _vm.permissions,
                        permissionTypes: _vm.permissionTypes,
                        nbReadOnly: _vm.nbReadOnly
                      }
                    }),
                    _vm._v(" "),
                    _vm._l(
                      _vm.entitySearchResults.filter(function(e) {
                        return e.parent_id === entity.id
                      }),
                      function(childrenEntity) {
                        return _c("permission-row", {
                          key: "child-" + childrenEntity.id,
                          attrs: {
                            entity: childrenEntity,
                            userRoleInfo: _vm.userRoleInfo,
                            permissions: _vm.permissions,
                            permissionTypes: _vm.permissionTypes,
                            nbReadOnly: _vm.nbReadOnly
                          }
                        })
                      }
                    )
                  ]
                })
              ],
              2
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "position-absolute translate-middle-y top-50 end-0 me-3" },
      [
        _c("span", { staticClass: "svg-icon" }, [
          _c("i", { staticClass: "fa fa-fw fa-search fa-lg" })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "position-absolute translate-middle-y top-50 end-0 me-3" },
      [
        _c("span", { staticClass: "svg-icon" }, [
          _c("i", { staticClass: "fa fa-fw fa-search fa-lg" })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }