var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "rotation" } }, [
        _vm._v("Rotación de imagen")
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control-sm",
        attrs: {
          type: "number",
          id: "rotation",
          placeholder: "Rotación de la imagen"
        },
        domProps: { value: _vm.activeElement.style.rotation },
        on: {
          input: function($event) {
            return _vm.updateStyle($event, "rotation")
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "width" } }, [_vm._v("Ancho de imagen")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control-sm",
        attrs: { type: "number", id: "width", placeholder: "Ancho de imagen" },
        domProps: { value: _vm.activeElement.style.imageWidth },
        on: {
          input: function($event) {
            return _vm.updateStyle($event, "imageWidth")
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "height" } }, [_vm._v("Altura de imagen")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control-sm",
        attrs: {
          type: "number",
          id: "height",
          placeholder: "Altura de imagen"
        },
        domProps: { value: _vm.activeElement.style.imageHeight },
        on: {
          input: function($event) {
            return _vm.updateStyle($event, "imageHeight")
          }
        }
      })
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-styles-message" }, [
      _c("i", { staticClass: "fa fa-fw fa-info-circle" }),
      _vm._v(
        "\n        Sin valor para que la imagen se adapte al espacio definido en la pestaña propiedades.\n    "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }