<template>
    <table class="table m-table table-bordered">
        <thead>
        <tr>
            <th class="fitCell">Propiedad</th>
            <th>Valor</th>
        </tr>
        </thead>
        <tbody>
            <tr>
                <td>Tipo</td>
                <td>
                    <multiselect :options="schemas" v-model="schemaSelected" id="schema-type"></multiselect>
                </td>
            </tr>
            <schema-row v-for="(meta, i) of schemaValue" :nbReadOnly="nbReadOnly" :meta="meta" :key="`meta${i}`"></schema-row>
        </tbody>
    </table>
</template>

<script>
  import SchemaRow from './SchemaRow'
  import template from '@/js/components/metas/templates/standard'

  export default {
    name: 'schema-table',
    components: {
      SchemaRow,
    },
    props: {
      schema: { type: Array, default: () => [] },
      typeSchema: { type: String, default: null },
    },
    data () {
        const type = this.schema.find(row => row.meta === '@type')
        return {
            schemas: Object.keys(template.schemas),
            schemaSelected: this.typeSchema || (type ? type.value : null),
            schemaValue: {...this.schema}
        }
    },
    watch: {
        schemaSelected () {
            this.schemaValue = template.schemas[this.schemaSelected]
            this.$emit('update:schema', this.schemaValue)
            this.$emit('update:type-schema', this.schemaSelected)
        }
    },
  }
</script>

<style scoped>
    .fitCell {
        min-width: 150px;
        width: 20%;
        padding-right: 20px;
    }
</style>
