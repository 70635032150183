<template>
    <li @click.stop.prevent="$emit('itemActivated', item)" class="ticket-select-elements__list-item"
        :class="activeItem && activeItem.id === item.id ? 'active' : ''" :id="'item-' + item.id">
        <span class="fa fa-fw fa-eye open-eye" v-if="handleIndicator(item)"></span>
        <span class="fa fa-fw fa-eye-slash closed-eye" v-else></span>
        <span v-tooltip :title="(handleIndicator(item) ? 'Visible' : 'Oculto') + ' condicionalmente'"
              class="fa fa-asterisk conditional-tip" v-if="item.visible === null"></span>
        {{ item.name }}
    </li>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { ElementMixin } from 'vue-slicksort'

  export default {
    name: 'SortableItem',
    mixins: [ElementMixin],
    props: ['item', 'activeItem'],
    methods: {
      handleIndicator (item) {
        if (item.visible === null) {
          return (item.visibility.showIfConditionsMet === true)
        }
        return item.visible
      },
    },
  }
</script>
