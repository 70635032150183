var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        attrs: { type: "hidden", name: "edition_css" },
        domProps: { value: _vm.jsonCustomCss }
      }),
      _vm._v(" "),
      _vm.label.length
        ? _c(
            "label",
            { staticClass: "form-label", attrs: { for: _vm.field } },
            [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("AceEditor", {
        attrs: {
          name: _vm.field,
          lang: "css",
          theme: "monokai",
          width: "100%",
          height: "200px",
          options: {
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            fontSize: 14,
            highlightActiveLine: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
            showPrintMargin: false,
            showGutter: true
          }
        },
        on: { init: _vm.editorInit },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }