<template>
    <div class="col-12">
        <input name="reportsUsers" type="hidden" :value="selectedsJSON">



        <div class="m-entity-list-search form-group m-form__group col-12">
            <div class="row">
                <div class="col-6">
                    <multiselect :placeholder="typesSalesChannelsSelected.length ? '' : 'Filtrar por tipo de canal de venta'"
                                 select-label="<" deselect-label="×"
                                 selected-label="×" v-model="typesSalesChannelsSelected" track-by="id" label="name"
                                 :options="typesSalesChannels" id="salesChannel" name="salesChannel"
                                 :multiple="true"/>
                </div>
                <div class="col-6">
                    <multiselect :placeholder="salesChannelsSelected.length ? '' : 'Filtrar por canal de venta'"
                                 select-label="<" deselect-label="×"
                                 selected-label="×" v-model="salesChannelsSelected" track-by="id" label="name"
                                 :options="salesChannelsFiltered" id="salesChannel" name="salesChannel"
                                 :multiple="true"/>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <span class="m-switch m-switch--icon">
                        <label>
                            <input type="checkbox" v-model="applyToAll" :disabled="nbReadOnly">
                            <span></span>
                        </label>
                        <label class="label">Activar/Desactivar usuarios listados</label>
                    </span>
                </div>
                <div class="col-6">
                    <button @click="apply" type="button" title="Aplicar" class="m-btn btn m-btn--icon btn-brand">
                        <span>Aplicar</span>
                    </button>
                </div>
            </div>
        </div>




        <div class="table-responsive m--padding-left-30 m--padding-right-30 mt-4">
            <table class="table table-striped m-table">
                <thead>
                    <tr>
                        <th>Asignado</th>
                        <th>Nombre</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <paginate
                        ref="usersPagination"
                        name="usersPagination" :list="usersFiltered"
                        :key="key"
                        :per="5" tag="tbody">
                    <tr v-for="user in paginated('usersPagination')" :key="'customer-' + user.id">
                        <td>
                            <span class="m-switch m-switch--icon">
                                <label>
                                    <input type="checkbox" 
                                           :disabled="nbReadOnly || user.blocked" v-model="user.selected">
                                    <span></span>
                                </label>
                                <label class="label-hidden">Asignar informe</label>
                            </span>
                        </td>
                        <td>{{ user.name }}</td>
                        <td>{{ user.email }}</td>
                    </tr>
                </paginate>
            </table>

            <div class="row" v-if="paginated('usersPagination').length">
                <div class="col-4">
                </div>
                <div class="col-8">
                    <div class="pull-right">
                        <nav aria-label="Paginación de usuarios">
                            <paginate-links
                                    :key="key"
                                    :limit="4"
                                    :show-step-links="true"
                                    :step-links="{ prev: '‹', next: '›' }"
                                    :hide-single-page="true"
                                    :classes="{'ul': 'pagination', 'li': 'page-item', 'a': 'page-link'}"
                                    :async="true"
                                    for="usersPagination">
                            </paginate-links>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    users: { type: Array, required: true },
    selecteds: { type: Array, default: () => [] },
    blocked: { type: Array, default: () => [] },
    salesChannels: { type: Array, default: () => [] },
    typesSalesChannels: { type: Array, default: () => [] },
  },

  data () {
    return {
      usersPagination: [],
      paginate: ['usersPagination'],
      paginationVisible: true,
      usersData: [],
      salesChannelsSelected: [],
      typesSalesChannelsSelected: [],
      key: 0,
      applyToAll: false,
    }
  },

  computed: {
    selectedsJSON () {
      return JSON.stringify(this.usersData.filter(user => user.selected).map(user => user.id))
    },
    usersFiltered () {
      this.key++
      if (!this.salesChannelsFiltered || !this.salesChannelsFiltered.length)
        return this.usersData
      const salesChannels = this.salesChannelsSelected.length ? this.salesChannelsSelected : this.salesChannelsFiltered
      return this.usersData.filter(user => {
        return _.intersection(
          user.salesChannels.map(salesChannel => salesChannel.id),
          salesChannels.map(salesChannel => salesChannel.id)
        ).length
      })
    },
    salesChannelsFiltered () {
      if (!this.typesSalesChannelsSelected || !this.typesSalesChannelsSelected.length)
        return this.salesChannels
      const typesId = this.typesSalesChannelsSelected.map(type => type.id)
      return this.salesChannels.filter(salesChannel => {
        return typesId.indexOf(salesChannel.sales_channel_type_id) !== -1
      })
    },
  },

  mounted () {
    this.usersData = this.users.map(user => {
      const u = Object.assign({}, user)
      Vue.set(u, 'selected', false)
      Vue.set(u, 'blocked', false)
      return u
    })
    
    this.selecteds.map(userId => {
      const user = this.usersData.find(user => user.id == userId)
      if (user) {
        user.selected = true
      }
    })

    this.blocked.map(userId => {
      const user = this.usersData.find(user => user.id == userId)
      if (user) {
        user.blocked = true
      }
    })
  },

  methods: {
    apply () {
      this.usersFiltered.map(user => {
        if (!user.blocked) {
          user.selected = this.applyToAll
        }
      })
    },
  },

  watch: {
    usersFiltered (value) {
      if (value.length)
        this.$nextTick(() => this.$refs.usersPagination.goToPage(1))
    },
    salesChannelsFiltered () {
      const typesId = this.typesSalesChannelsSelected.map(type => type.id)
      this.salesChannelsSelected = this.salesChannelsSelected.filter(salesChannel => {
        return typesId.indexOf(salesChannel.sales_channel_type_id) !== -1
      })
    },
  },
}
</script>