<template>
    <div class="buttons-list">
        <button type="button" id="add-text"
                class="buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only"
                @click="addTextField" v-tooltip title="Añadir texto">
            <i class="fa fa-fw fa-file-text"></i>
        </button>

        <button type="button" id="add-image"
                class="buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only"
                @click="addImageField" v-tooltip title="Añadir imagen">
            <i class="fa fa-fw fa-image"></i>
        </button>

        <button type="button" id="add-barcode"
                class="buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only"
                @click="addBarcodeField" v-tooltip title="Añadir código de barras">
            <i class="fa fa-fw fa-barcode"></i>
        </button>

        <button type="button" id="add-qr"
                class="buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only"
                @click="addQrField" v-tooltip title="Añadir código QR">
            <i class="fa fa-fw fa-qrcode"></i>
        </button>

        <button type="button" id="duplicate-element"
                :disabled="!activeElement || activeElement.blocked && powerUserLoged < activeElement.blockPower"
                class="buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only"
                @click="duplicateActiveElement" v-tooltip title="Copiar elemento">
            <i class="fa fa-fw fa-copy"></i>
        </button>

        <button type="button" id="remove-element"
                :disabled="disableDeleteButton ||
                  !activeElement || activeElement.blocked && powerUserLoged < activeElement.blockPower"
                class="buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only"
                @click="removeActiveElement" v-tooltip title="Eliminar elemento">
            <i class="fa fa-fw fa-trash"></i>
        </button>

        <button type="button" id="show-borders"
                class="buttons-list__btn btn m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only"
                @click="showBorders" :class="{'btn-brand': activeBorders, 'btn-light': !activeBorders}" v-tooltip
                title="Mostrar bordes">
            <i class="fa fa-fw fa-square-o"></i>
        </button>

        <button type="button" id="zoom-plus"
                class="buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only"
                @click="zoomPlus" v-tooltip title="Aumentar">
            <i class="fa fa-fw fa-search-plus"></i>
        </button>

        <div class="buttons-list__btn-view-zoom buttons-view-zoom" @click="zoomNormal" id="zoom">
            {{ (zoom + zoomBase) * zoomBase }} %
        </div>

        <button type="button" id="zoom-less"
                class="buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only"
                @click="zoomLess" v-tooltip title="Disminuir">
            <i class="fa fa-fw fa-search-minus"></i>
        </button>

        <button type="button" id="print"
                class="buttons-list__btn btn btn-light m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only"
                @click="print" :disabled="!getPrintUrl()" v-tooltip title="Imprimir">
            <i class="fa fa-fw fa-print"></i>
        </button>

        <button type="button" id="full-screen"
                class="buttons-list__btn btn m-btn m-btn m-btn m-btn--icon btn-sm m-btn--icon-only" v-tooltip
                title="Pantalla completa" :class="{'btn-brand': fullScreen, 'btn-light': !fullScreen}"
                @click="changeFullScreen">
            <i class="fa fa-fw" :class="{'fa-expand': !fullScreen, 'fa-compress': fullScreen}"></i>
        </button>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters, mapMutations } from 'vuex'
  import uuidv4 from 'uuid/v4'

  export default {
    name: 'ticket-buttons',
    props: ['powerUserLoged'],
    data () {
      return {
        activeBorders: false,
        disableDeleteButton: false,
        baseElementProperties: {
          width: 100,
          height: 100,
          x: 0,
          y: 5,
          allowOverflow: false,
          style: {
            display: 'block',
            rotation: 0,
          },
          visible: true,
          visibility: {
            showIfConditionsMet: true,
            requireAllConditions: true,
            conditions: [],
          },
        },
      }
    },
    computed: {
      ...mapGetters('ticketEditor', {
        getElements: 'elements',
        getFullScreen: 'getFullScreen',
        getSelectedScope: 'getSelectedScope',
        getSupport: 'getSupport',
        getTicket: 'ticket',
        activeElement: 'activeElement',
        zoomPreview: 'zoomPreview',
        zoomBase: 'zoomBase',
      }),
      elements: {
        set (elements) { this.setElements(elements) },
        get () { return this.getElements },
      },
      zoom: {
        set (zoom) { this.setZoomPreview(zoom) },
        get () { return this.zoomPreview },
      },
      fullScreen: {
        set (fullScreen) { this.setFullScreen(fullScreen) },
        get () { return this.getFullScreen },
      },
    },
    mounted () {
      document.getElementById('ticket-editor').addEventListener('keydown', (event) => {
        if (event.keyCode === 46) {
          this.removeActiveElement()
        }
      })
    },
    methods: {
      ...mapMutations('ticketEditor', {
        addElement: 'addElement',
        setElements: 'setElements',
        setActiveElement: 'setActiveElement',
        setZoomPreview: 'setZoomPreview',
        setFullScreen: 'setFullScreen',
      }),
      generateRandomId () {
        return '_' + uuidv4().substr(2, 9)
      },
      addTextField () {
        const element = {
          ...this.baseElementProperties,
          name: `Nuevo texto #${this.elements.filter(e => e.type === 'text').length + 1}`,
          type: 'text',
          zIndex: this.elements.length + 1,
          value: 'Nuevo campo',
          id: this.generateRandomId(),
          style: {
            fontSize: 1,
            fontWeight: 400,
            color: '#000000',
            setBackgroundColor: false,
            backgroundColor: '#000000',
            fontFamily: 'Arial, sans-serif',
          },
        }

        this.addElement(element)
      },
      addImageField () {
        const element = {
          ...this.baseElementProperties,
          name: `Nueva imagen #${this.elements.filter(e => e.type === 'image').length + 1}`,
          type: 'image',
          zIndex: this.elements.length + 1,
          value: '',
          backgroundId: null,
          id: this.generateRandomId(),
          style: {
            width: '100%',
            height: '100%',
            imageWidth: null,
            imageHeight: null,
            backgroundImage: '',
          },
        }

        this.addElement(element)
      },
      addBarcodeField () {
        const element = {
          ...this.baseElementProperties,
          name: `Nuevo código de barras #${this.elements.filter(e => e.type === 'barcode').length + 1}`,
          type: 'barcode',
          zIndex: this.elements.length + 1,
          id: this.generateRandomId(),
          allowOverflow: true,
          value: '',
          barcode: {
            'format': 'code128',
            'width': 2,
            'height': 40,
            'displayValue': false,
            'margin': 0
          },
        }

        this.addElement(element)
      },
      addQrField () {
        const element = {
          ...this.baseElementProperties,
          name: `Nuevo código QR #${this.elements.filter(e => e.type === 'qr').length + 1}`,
          type: 'qr',
          zIndex: this.elements.length + 1,
          id: this.generateRandomId(),
          value: uuidv4(),
          allowOverflow: true,
          qr: {
            type: 'image/jpeg',
            errorCorrectionLevel: 'M',
            margin: 1,
            width: 64,
            colorDark: '#000000',
            colorLight: '#ffffff',
            rendererOpts: {
              quality: 1,
            },
          },
        }

        this.addElement(element)
      },
      duplicateActiveElement () {
        if (!this.activeElement
          || this.activeElement.blocked && this.powerUserLoged < this.activeElement.blockPower) {
          return
        }

        const newElement = {
          ...this.activeElement,
          name: `Copia de ${this.activeElement.name} #${this.elements.filter(e => e.type === 'text').length + 1}`,
          id: this.generateRandomId(),
          zIndex: this.elements.length + 1,
        }

        this.addElement(newElement)
      },
      async removeActiveElement () {
        if (!this.activeElement
          || this.activeElement.blocked && this.powerUserLoged < this.activeElement.blockPower) {
          return
        }

        this.disableDeleteButton = true
        await this.removeElement()

        // Marcar primer elemento disponible
        this.setActiveElement(null)
        this.elements.length && this.setActiveElement(this.elements[0])
        this.disableDeleteButton = false
      },
      removeElement () {
        return new Promise((resolve) => {
          this.setElements(this.elements.filter(element => element.id !== this.activeElement.id))
          resolve(true)
        })
      },
      showBorders () {
        document.body.classList.toggle('vdr-borders')
        this.activeBorders = document.body.classList.contains('vdr-borders')
      },
      zoomNormal () {
        this.zoom = 0
      },
      zoomPlus () {
        if (this.zoom < this.zoomBase) {
          this.zoom++
        }
      },
      zoomLess () {
        if (this.zoom > this.zoomBase - ((this.zoomBase * 2) - 1)) {
          this.zoom--
        }
      },
      print () {
        window.open(this.getPrintUrl(), '_blank')
      },
      getPrintUrl () {
        if (!this.getSelectedScope) {
          return null
        }

        if (this.getSupport) {
          return `/api/template-pdf/${this.getSelectedScope.id}/${this.getSupport}`
        }

        if (this.getTicket.hasOwnProperty('id')) {
          return `/api/template-ticket-pdf/${this.getSelectedScope.id}/${this.getTicket.id}`
        }

        return null
      },
      changeFullScreen () {
        this.fullScreen = !this.fullScreen
      },
    }
  }
</script>
