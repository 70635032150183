var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideResults,
          expression: "hideResults"
        }
      ],
      staticClass: "persistent"
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center",
          attrs: {
            "data-kt-search-element": "toggle",
            id: "kt_header_search_toggle"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn btn-icon btn-active-light-primary",
              on: { click: _vm.showResults }
            },
            [
              _c("span", { staticClass: "svg-icon svg-icon-1" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none"
                    }
                  },
                  [
                    _c("rect", {
                      attrs: {
                        opacity: "0.5",
                        x: "17.0365",
                        y: "15.1223",
                        width: "8.15546",
                        height: "2",
                        rx: "1",
                        transform: "rotate(45 17.0365 15.1223)",
                        fill: "black"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z",
                        fill: "black"
                      }
                    })
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.visibleDropdown
        ? _c(
            "div",
            {
              staticClass:
                "dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg w-md-375px",
              staticStyle: {
                "z-index": "101",
                display: "block",
                outline: "none"
              },
              attrs: { tabindex: "0" },
              on: {
                focus: function($event) {
                  $event.stopPropagation()
                  return _vm.showResults($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "p-5 quick-search quick-search-dropdown",
                  attrs: { id: "kt_quick_search_dropdown" }
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "w-100 position-relative mb-3",
                      on: {
                        submit: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.performSearch($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0"
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                width: "24px",
                                height: "24px",
                                viewBox: "0 0 24 24",
                                version: "1.1"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd"
                                  }
                                },
                                [
                                  _c("rect", {
                                    attrs: {
                                      x: "0",
                                      y: "0",
                                      width: "24",
                                      height: "24"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z",
                                      fill: "#000000",
                                      "fill-rule": "nonzero",
                                      opacity: "0.3"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z",
                                      fill: "#000000",
                                      "fill-rule": "nonzero"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchValue,
                            expression: "searchValue"
                          }
                        ],
                        staticClass: "form-control form-control-flush ps-10",
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          name: "q",
                          placeholder: "Buscar",
                          id: "search-query"
                        },
                        domProps: { value: _vm.searchValue },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.searchValue = $event.target.value
                            },
                            function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.performSearch($event)
                            }
                          ],
                          focus: function($event) {
                            $event.stopPropagation()
                            return _vm.showResults($event)
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "separator border-gray-200 mb-6" }),
                  _vm._v(" "),
                  !_vm.results.length && _vm.searchValue
                    ? _c("div", { staticClass: "text-center" }, [
                        _c("div", { staticClass: "pt-10 pb-10" }, [
                          _c(
                            "span",
                            { staticClass: "svg-icon svg-icon-4x opacity-50" },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                    fill: "none"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      opacity: "0.25",
                                      d:
                                        "M3 4C3 2.34315 4.34315 1 6 1H15.7574C16.553 1 17.3161 1.31607 17.8787 1.87868L20.1213 4.12132C20.6839 4.68393 21 5.44699 21 6.24264V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V4Z",
                                      fill: "#12131A"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M15 1.89181C15 1.39927 15.3993 1 15.8918 1V1C16.6014 1 17.2819 1.28187 17.7836 1.78361L20.2164 4.21639C20.7181 4.71813 21 5.39863 21 6.10819V6.10819C21 6.60073 20.6007 7 20.1082 7H16C15.4477 7 15 6.55228 15 6V1.89181Z",
                                      fill: "#12131A"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M13.032 15.4462C12.4365 15.7981 11.7418 16 11 16C8.79086 16 7 14.2091 7 12C7 9.79086 8.79086 8 11 8C13.2091 8 15 9.79086 15 12C15 12.7418 14.7981 13.4365 14.4462 14.032L16.7072 16.293C17.0977 16.6835 17.0977 17.3167 16.7072 17.7072C16.3167 18.0977 15.6835 18.0977 15.293 17.7072L13.032 15.4462ZM13 12C13 13.1046 12.1046 14 11 14C9.89543 14 9 13.1046 9 12C9 10.8954 9.89543 10 11 10C12.1046 10 13 10.8954 13 12Z",
                                      fill: "#12131A"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(0)
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.searchValue
                    ? _c(
                        "div",
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _vm._l(_vm.lastPurchases, function(purchase) {
                            return _c(
                              "div",
                              {
                                staticClass:
                                  "m-list-search__result-item-text is-owner"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "m-list-search__result-item",
                                    attrs: {
                                      href: purchase.link,
                                      id: "purchase-" + purchase.id
                                    }
                                  },
                                  [
                                    _vm._m(2, true),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "m-list-search__result-item-text"
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(purchase.display) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : [
                        _vm.purchases.length
                          ? [
                              _c(
                                "span",
                                {
                                  staticClass: "m-list-search__result-category"
                                },
                                [
                                  _vm._v(
                                    "\n                        Expedientes\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.purchases, function(result) {
                                return _c(
                                  "div",
                                  {
                                    staticClass:
                                      "m-list-search__result-item-text",
                                    class: { "is-owner": result.ownership }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "m-list-search__result-item",
                                        attrs: {
                                          href: result.link,
                                          id: "purchases-" + result.id
                                        }
                                      },
                                      [
                                        _vm._m(3, true),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "m-list-search__result-item-text"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(result.display) +
                                                "\n                            "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              })
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.orders.length
                          ? [
                              _c(
                                "span",
                                {
                                  staticClass: "m-list-search__result-category"
                                },
                                [
                                  _vm._v(
                                    "\n                        Pedidos\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.orders, function(result) {
                                return _c(
                                  "div",
                                  {
                                    staticClass:
                                      "m-list-search__result-item-text",
                                    class: { "is-owner": result.ownership }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "m-list-search__result-item",
                                        attrs: {
                                          href: result.link,
                                          id: "orders-" + result.id
                                        }
                                      },
                                      [
                                        _vm._m(4, true),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "m-list-search__result-item-text"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(result.display) +
                                                "\n                            "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              })
                            ]
                          : _vm._e()
                      ]
                ],
                2
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pb-15 fw-bold" }, [
      _c("h3", { staticClass: "text-gray-600 fs-5 mb-2" }, [
        _vm._v("Sin resultados")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-muted fs-7" }, [
        _vm._v("Please try again with a different query")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-stack fw-bold mb-4" }, [
      _c("span", { staticClass: "text-muted fs-6 me-2" }, [
        _vm._v("Realizar búsqueda")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "m-list-search__result-item-icon" }, [
      _c("i", { staticClass: "flaticon-profile-1" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "m-list-search__result-item-icon" }, [
      _c("i", { staticClass: "flaticon-profile-1" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "m-list-search__result-item-icon" }, [
      _c("i", { staticClass: "flaticon-business" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }