<template>
  <div class="form-group m-form__group col-md-12">
    <div class="row">
      <div class="col-12">
        <h5 class="mt-1">
          <i :class="evaluator.icon"></i>
          {{ evaluator.name }}
        </h5>
      </div>
      <div class="col-4">
        <multiselect v-model="selectedParent" :options="parents" :multiple="false" :disabled="nbReadOnly"
                     track-by="name" label="name" :placeholder="`Seleccionar grupo de ${label}`"></multiselect>
      </div>
      <div class="col-8 col-select-one">
        <multiselect v-model="data" :options="children" :multiple="true" :disabled="nbReadOnly"
                     :limit="1" :limitText="() => ''" track-by="name"
                     :close-on-select="false"
                     :searchable="false"
                     label="name">
          <span slot="noResult">No existen elementos</span>
          <template slot="tag">
            <span class="badge m-badge py-0 px-2" style="margin-top: 4px;">
              {{
                allIncludes
                  ? 'Todos incluidos'
                  : `${data.length} elementos seleccionados de ${differentSelectedGroupsCount} eventos.`
              }}
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <span v-if="!selectedParent">{{ props.option.name }}</span>
            <span v-else-if="selectedParent.id === props.option.parent_id">{{ props.option.name }}</span>
          </template>
          <template slot="beforeList">
            <div class="d-flex flex-grow-1 align-content-stretch text-nowrap">
              <button @click="selectAll" class="btn btn-link select-all w-50" type="button" :disabled="nbReadOnly">
                Seleccionar todo
              </button>
              <button @click="removeAll" class="btn btn-link clear-all w-50" type="button" :disabled="nbReadOnly">
                Deseleccionar todo
              </button>
            </div>
          </template>
        </multiselect>
        <nb-error-block :path="`filters.${name}`"></nb-error-block>
      </div>
      <div class="col-12">
        <div class="m-alert alert-primary m-alert--outline m-alert--air m-alert--square alert fade show mt-1"
             role="alert">
          <div class="m-alert__text">
            <div class="d-flex">
              <div class="w-50 pr-1">
                <button type="button" @click="selectAllComplete"
                        class="btn btn-block btn-sm btn-primary"
                        :disabled="nbReadOnly || allIncludes"
                >
                  Añadir todas las sesiones
                </button>
              </div>
              <div class="w-50 pl-1">
                <button type="button" @click="removeAllComplete"
                        :disabled="nbReadOnly || !this.data.length"
                        class="btn btn-block btn-sm btn-primary">
                  Quitar todas las sesiones
                </button>
              </div>
            </div>
            <div class="mb-1" v-if="this.data.length">Selección:</div>
            <ul class="group-box">
              <li class="group-box__item d-flex flex-wrap" v-for="group of groupedSelection">
                <span class="group-box__name">{{ groupName(group[0].parent_id) }}</span>
                <button v-if="!isGroupVisible(group[0].parent_id)" type="button"
                        class="ml-2 m-badge m-badge--wide group-box__recount-button"
                        @click="toggleGroupVisibility(group[0].parent_id)">
                  {{ isGroupComplete(group) ? 'Todos incluidos' : `${group.length} elementos` }}
                  <span class="fa fa-angle-double-right"></span>
                </button>
                <template v-else>
                  <span class="group-box__opening">(</span>
                  <span v-for="item of group" class="group-box__open-item">
                    {{ stylizeGroupItem(item.parent_id, item.name) }}
                    <span class="fa fa-times p-1 group-box__remove-item"
                          title="Eliminar elemento" v-tooltip
                          @click="removeItem(item.id)"></span>
                  </span>
                </template>

                <span v-if="!isGroupVisible(group[0].parent_id)" class="group-closing"></span>
                <span class="group-box__closing-end" v-else>)</span>
                <span v-if="isGroupVisible(group[0].parent_id)"
                      @click="toggleGroupVisibility(group[0].parent_id)"
                      class="group-box__close-button fa fa-angle-double-left"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { EventBus } from '@/js/vue/services/event-bus'

export default {
  name: 'evaluator-parent-select',
  props: {
    live: { type: Boolean, default: false },
    prefix: { type: String, default: '' },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    customizable: { type: Boolean, default: false },
    default_values: { type: Array, default: () => ([]) },
    id: { type: Number, default: 0 },
    selectAllChildrenAutomatically: { type: Boolean, default: false },
    allowOnlySingleParent: { type: Boolean, default: false },
    routePrefix: { type: String, default: '' },
    group: { type: String, default: '' },
    evaluator: {
      type: Object, default: () => {
      }
    },
    value: { type: Array, default: () => [] },
    parent: { type: Object, default: () => ({}) },
  },
  data () {
    const values = []
    return {
      selectedParent: null,
      data: [],
      customizeCheckbox: this.customizable,
      visibleGroups: [],
      options: [],
    }
  },
  created () {
    this.filterDataOnlyParent()
    const parents = _.uniqBy(this.data, 'parent_id').map(e => e.parent_id)
    const last = _.last(parents)
    if (last && this.filterDataOnlyParent) {
      this.selectedParent = this.parents.find(p => p.id === last)
    }
    this.fetchData()
  },
  computed: {
    valueJSON () {
      return JSON.stringify(this.data.map(entity => entity.id))
    },
    parents () {
      return this.options.filter(o => o.group.length).map(o => ({ id: o.id, name: o.parent }))
    },
    children () {
      return _.flatten(
          this.options
              .filter(o => !this.selectedParent ? true : o.id === this.selectedParent.id)
              .map(o => o.group)
      )
    },
    differentSelectedGroupsCount () {
      return _.uniq(this.data.map(o => o.parent_id)).length
    },
    groupedSelection () {
      return _.groupBy(this.data, g => g.parent_id)
    },
    allIncludes () {
      return this.data.length === this.options.reduce((acc, o) => acc + o.group.length, 0)
    },
  },
  methods: {
    changeCheckBox () {
      this.customizeCheckbox = !this.customizeCheckbox
      EventBus.$emit('filterStatusChange', this.id, this.label, !this.customizeCheckbox)
    },
    selectAll () {
      this.data = _.uniqBy([...this.data, ...this.children], 'id')
    },
    selectAllComplete () {
      this.data = _.flatten(this.options.map(o => o.group))
    },
    removeAll () {
      this.data = this.data.filter(o => !this.children.includes(o))
    },
    removeAllComplete () {
      this.data = []
    },
    groupName (id) {
      return this.options.find(o => o.id === id).parent
    },

    stylizeGroupItem (group, label) {
      return label.replace(this.groupName(group), '...')
    },

    isGroupVisible (id) {
      return this.visibleGroups.includes(id)
    },
    toggleGroupVisibility (id) {
      if (this.isGroupVisible(id)) {
        this.visibleGroups = this.visibleGroups.filter(g => g !== id)
      } else {
        this.visibleGroups.push(id)
      }
    },
    removeItem (id) {
      if (this.nbReadOnly) return
      this.data = this.data.filter(d => d.id !== id)
    },
    filterDataOnlyParent () {
      const parents = _.uniqBy(this.data, 'parent_id').map(e => e.parent_id)
      const last = _.last(parents)
      if (parents.length > 1 && this.allowOnlySingleParent) {
        this.data = this.data.filter(v => v.parent_id === last)
      }
    },
    isGroupComplete (group) {
      const parent = this.options.find(o => o.id === group[0].parent_id)
      return parent.group.length === group.length
    },
    async fetchData () {
      let response = await axios.post(route(this.routePrefix + 'parent-list'), {
        group: this.group,
        query: '',
        evaluator: this.evaluator.class,
      })
      if (response.data) {
        this.options = response.data.options
        this.data = this.value
      }
      console.log(this.options, this.data, this.value)
    }
  },
  watch: {
    data () {
      this.filterDataOnlyParent()
      this.$emit('input', this.data)
    },
    selectedParent () {
      if (this.selectedParent && this.selectAllChildrenAutomatically) {
        const children = this.data.concat(this.options.find(v => v.id === this.selectedParent.id).group)
        this.data = _.uniqBy(children, 'id')
      }
    },
  },
}
</script>

<style lang="scss">
.multiselect__strong {
  display: none !important;
}
</style>
