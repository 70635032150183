<template>
    <div class="ticket-editor__edition-board">
        <div class="ticket-editor__preview">
            <ticket-preview :powerUserLoged="powerUserLoged"></ticket-preview>
        </div>
        <div class="ticket-editor__edition-tools">
            <ticket-select-elements></ticket-select-elements>
            <ticket-properties :powerUserLoged="powerUserLoged"></ticket-properties>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import TicketPreview from '@/js/components/ticket_editor/tabs/TicketPreview'
  import TicketSelectElements from '@/js/components/ticket_editor/tabs/TicketSelectElements'
  import TicketProperties from '@/js/components/ticket_editor/tabs/TicketProperties'

  export default {
    name: 'ticket-edition',
    components: { TicketSelectElements, TicketProperties, TicketPreview },
    props: ['powerUserLoged'],
  }
</script>
