var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c(
        "div",
        { staticClass: "btn btn-secondary ", on: { click: _vm.changeVisible } },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          attrs: {
            refreshing: _vm.refreshing,
            backdrop: true,
            classesModalDialog: ["modal-xl modal-dialog-scrollable"],
            visible: _vm.visible
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v("\n            Combinar clientes\n        ")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          [
            _c(
              "div",
              { staticClass: "m-form" },
              [
                _vm.message
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "m-alert m-alert--square alert alert-dismissible fade show",
                        class: [
                          _vm.successMessage ? "alert-success" : "alert-danger"
                        ],
                        attrs: { role: "alert" }
                      },
                      [
                        _c("div", { staticClass: "m-alert__text" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.message) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "m-alert__close" }, [
                          _c("button", {
                            staticClass: "close",
                            attrs: {
                              type: "button",
                              "data-bs-dismiss": "alert",
                              "aria-label": "Close"
                            }
                          })
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("list-simple", {
                  attrs: {
                    headers: _vm.headers,
                    list: _vm.customers,
                    filters: _vm.filters,
                    "elements-per-page": _vm.perPage,
                    ajax: true,
                    "ajax-pagination": _vm.ajaxPagination,
                    "interface-texts": {
                      title: null,
                      "empty-list": "No existen clientes"
                    }
                  },
                  on: {
                    "update:combinate": function(model) {
                      return _vm.negateCombine(model)
                    },
                    "page-change": _vm.setPage,
                    "set-filters": _vm.setFilters
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn m-btn m-btn--icon  m-input btn-brand",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.combine()
                      }
                    }
                  },
                  [
                    _c("span", [
                      _c("i", { staticClass: "fa fa-upload" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Combinar")])
                    ])
                  ]
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("em", { staticClass: "fa fa-user fa-fw me-1" }),
      _vm._v(" "),
      _c("span", [_vm._v("Combinar")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }