var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-survey-component form-group m-form__group w-100 mt-4" },
    [
      _c("input", {
        attrs: { type: "hidden", name: "questions", id: "questions" },
        domProps: { value: _vm.questionsJSON }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 row" },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-between mb-3"
              },
              [
                _c(
                  "h4",
                  { staticClass: "col-6 c-survey-component__title m-0" },
                  [_vm._v("\n                    Preguntas\n                ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary m-btn m-btn--icon",
                    attrs: {
                      type: "button",
                      disabled: _vm.nbReadOnly,
                      id: "add-option"
                    },
                    on: { click: _vm.addQuestion }
                  },
                  [_vm._m(0)]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "list-questions-component",
              {
                attrs: {
                  lockToContainerEdges: true,
                  useDragHandle: true,
                  lockAxis: "y",
                  helperClass: "sorting-select"
                },
                model: {
                  value: _vm.order_questions,
                  callback: function($$v) {
                    _vm.order_questions = $$v
                  },
                  expression: "order_questions"
                }
              },
              _vm._l(_vm.order_questions, function(question, index) {
                return _c("item-question-component", {
                  key: index,
                  staticClass: "accordion",
                  attrs: {
                    index: index,
                    item: question,
                    question: question,
                    indexQuestion: index,
                    question_types: _vm.question_types
                  },
                  on: {
                    deleteQuestion: _vm.deleteQuestion,
                    click: function($event) {
                      _vm.select = index
                    }
                  }
                })
              }),
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-plus" }),
      _vm._v(" "),
      _c("span", [_vm._v("Añadir pregunta")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }