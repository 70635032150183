<template>
  <div>
    <input type="hidden" name="configuration" id="configuration" :value="statisticsJSON">
    <QueryBuilder v-if="!loadingData" :cubejs-api="cubejsApi" :query="query" :disable-heuristics="true">
      <template v-slot:builder="{setMeasures, availableMeasures,
                                 setDimensions, availableDimensions,
                                 setFilters}"
      >
        <!-- <template v-slot:builder="{setMeasures, availableMeasures}"> -->
        <div class="columns columns--fields">
          <!--measures-->
          <div class="column is-half height-auto u-py-0">
            <TagInputWithTranslations v-model="selectedMeasures"
                                      :tags-array="addTranslations(availableMeasures)"
                                      :title="$options.filters.trans('statistics.measures.title')"
                                      :placeholder="$options.filters.trans('statistics.measures.placeholder')"
                                      @input="setMeasures($event.map(tag => tag.name)), setFilters(filtersToSend)"
            />
          </div>
          <!--dimensions-->
          <div class="column is-half height-auto u-py-0">
            <TagInputWithTranslations v-model="selectedDimensions"
                                      :tags-array="addTranslations(availableDimensions)"
                                      :title="$options.filters.trans('statistics.dimensions.title')"
                                      :placeholder="$options.filters.trans('statistics.dimensions.placeholder')"
                                      @input="setDimensions($event.map(tag => tag.name)), setFilters(filtersToSend)"
            />
          </div>
          <!--variable x-->
          <!-- TODO: Pivots eliminados de la interfaz por confusiones -->
          <div class="column is-half u-py-0">
            <span class="field__span field__span--required">{{ 'statistics.chartType' | trans }}</span>
            <select v-model="chartType" class="form-control">
              <option v-for="(type, name) in chartTypes" :key="name" :value="name">
                {{ `statistics.chartTypes.${name}` | trans }}
              </option>
            </select>
          </div>
        </div>
        <!--statistics filters -->
        <div class="statistics-filters">
          <div class="card-header">
            <p class="card-header-title">
              {{ 'statistics.filters.title' | trans }}
            </p>
            <button id="new-filter" type="button" @click="getNewFilter" />
          </div>
          <div class="section section-mini u-px-0 u-pb-0">
            <div v-for="(filter, index) in filters"
                 :key="filter.id"
            >
              <StatisticsFilter v-model="filters[index]"
                                :filters="getFilters(availableMeasures, availableDimensions)"
                                @input="testFilters(setFilters)"
                                @delete-filter="deleteFilter(index, setFilters)"
              />
            </div>
          </div>
          <div v-if="!(filters.length > 0)" class="has-text-centered">
            {{ 'statistics.filters.noFilters' | trans }}
          </div>
          <div class="section section-mini u-px-0">
            <div class="card-header">
              <p class="card-header-title">
                {{ 'statistics.colors.title' | trans }}
              </p>
              <button icon-left="plus" type="button" @click="getNewColor" />
            </div>
            <div class="columns columns--fields">
              <div v-for="(color, index) in colors"
                   :key="index"
                   class="column is-1"
              >
                <StatisticsColor :value="color"
                                 @input="setColor($event, index)"
                                 @delete-color="deleteColor(index)"
                />
              </div>
            </div>
          </div>
          <div v-if="!(colors.length > 0)" class="has-text-centered">
            {{ 'statistics.colors.noColors' | trans }}
          </div>
        </div>
      </template>
      <template v-slot="{resultSet}">
        <ApexChart v-if="showChart(resultSet)"
                   :result-set="resultSet"
                   :chart-type="chartType"
                   :pivot-x-axis="selectedPivotXAxis"
                   :pivot-y-axis="selectedPivotYAxis"
                   :prop-options="options"
                   height="300"
        />
      </template>
    </QueryBuilder>
  </div>
</template>

<script>
import cubejs from '@cubejs-client/core'
import { QueryBuilder } from '@cubejs-client/vue'
import StatisticsFilter from '@/js/components/statistics/StatisticsFilter'
import ApexChart from '@/js/components/statistics/ApexChart'
import StatisticsColor from '@/js/components/statistics/StatisticsColor'
import _ from 'lodash'
import { StringOperators } from '@/js/enums/Operators'
import TagInputWithTranslations from '@/js/components/statistics/TagInputWithTranslations'

export default {
  name: 'Statistics',
  components: {
    QueryBuilder,
    StatisticsFilter,
    ApexChart,
    StatisticsColor,
    // StatisticsPlantsFilters,
    TagInputWithTranslations,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    idStatistic: {
      type: Number,
      default: null,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    cubeJsToken: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      loading: false,
      cubejsApi: null,
      plantsReviewsFilters: [],
      pivotObject: {
        x: [],
        y: [],
      },
      chartTypes: {
        bar: 'axis',
        line: 'axis',
        radar: 'axis',
        area: 'axis',
        heatmap: 'axis',
        treemap: 'axis',
        pie: 'nonaxis',
        donut: 'nonaxis',
      },
      query: {
        dimensions: [],
        timeDimensions: [],
        order: {},
        measures: [],
        filters: [],
      },
      // query: null,
      initialOptions: {
        theme: {
          palette: 'palette1',
        },
        // TODO: ver si se puede generalizar la forma de coger los colores predeterminados de la palette
        colors: [
          '#008FFB',
          '#00E396',
          '#FEB019',
          '#FF4560',
          '#775DD0',
        ],
      },
      options: {},
      chartOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        title: {
          text: 'Temperaturas',
        },
        yaxis: {
          decimalsInFloat: 2,
        },
        xaxis: {
          type: 'category',
          categories: [],
        },
        fill: {
          type: 'solid',
        },
      },
      series: [],
      colors: [...this.value.colors] ?? [],
      chartType: this.value.chartType ?? null,
      filters: [...this.value.filters] ?? [],
      selectedMeasures: [...this.value.measures] ?? [],
      selectedDimensions: [...this.value.dimensions] ?? [],
    }
  },
  computed: {
    statisticsJSON () {
      const data = {
        'measures': this.selectedMeasures,
        'dimensions': this.selectedDimensions,
        'filters': this.filters,
        'chartType': this.chartType,
        'colors': this.colors
      }
      return JSON.stringify(data)
    },
    loadingData: {
      get () {
        return this.loading || !this.cubejsApi
      },
      set (value) {
        this.$emit('loading', value)
      },
    },
    filterMeasures () {
      return this.selectedMeasures.map(measure => measure.name)
    },
    filterDimensions () {
      return this.selectedDimensions.map(dimension => dimension.name)
    },
    selectedPivotXAxis: {
      get () {
        return this.value.pivotXAxis ?? []
      },
      set (value) {
        this.emit({ pivotXAxis: value })
      },
    },
    selectedPivotYAxis: {
      get () {
        // let pivot = this.value.pivotYAxis ?? []
        // pivot = this.selectedMeasures.length > 0 ? pivot.concat('measures') : pivot
        // pivot = pivot.filter(pivot =>
        //   !this.selectedPivotXAxis.includes(pivot) &&
        //   !this.selectedPivotYAxis.includes(pivot)
        // )
        // return pivot
        return this.value.pivotYAxis ?? []
      },
      set (value) {
        this.emit({ pivotYAxis: value })
      },
    },
    availablePivotResults () {
      const pivot = this.selectedDimensions.concat(
        this.selectedMeasures.length > 0 ? { name: 'measures' } : []
      )
      const translatedPivot = this.addTranslations(pivot)
      return translatedPivot.filter(pivot => {
        const alreadyAddedX = this.selectedPivotXAxis.find(selectedPivot => pivot.name === selectedPivot.name)
        const alreadyAddedY = this.selectedPivotYAxis.find(selectedPivot => pivot.name === selectedPivot.name)
        return !alreadyAddedX && !alreadyAddedY
        // !this.selectedPivotXAxis.includes(pivot) && !this.selectedPivotYAxis.includes(pivot)
        // !_.includes(this.selectedPivotXAxis, pivot.name) && !_.includes(this.selectedPivotYAxis, pivot.name)
      })
    },
    filtersToSend () {
      return this.filters.filter(filter =>
        filter.member && filter.operator &&
        (
          filter.values.length > 0 ||
          filter.operator === StringOperators.Set ||
          filter.operator === StringOperators.NotSet
        )
      ).map(filter =>
        ({
          member: filter.member.name,
          operator: filter.operator,
          values: filter.values,
        })
      )
        .concat(this.plantsReviewsFilters)
    },
    hasMeasuresDimensions () {
      return this.selectedMeasures.length > 0 || this.selectedDimensions.length > 0
    },
  },
  watch: {
    colors (val) {
      const newColors = val.map(color => color.color).filter(color => color)
      if (newColors.length) {
        this.$set(this.options, 'colors', newColors)
      } else {
        this.options = _.cloneDeep(this.initialOptions)
        // this.options = {}
      }
    },
    selectedMeasures (val, old) {
      // if ((val.length > 0 &&
      //   !this.selectedPivotYAxis.includes('measures') &&
      //   !this.selectedPivotXAxis.includes('measures')) &&
      //   (old.length !== val.length)
      // ) {
      //   this.selectedPivotYAxis = this.selectedPivotYAxis.concat(['measures'])
      // }
      if (val.length === 0) {
        if (this.selectedPivotYAxis.includes('measures')) {
          const indexPivotYAxis = this.selectedPivotYAxis.findIndex(pivot => pivot.name === 'measures')
          this.selectedPivotYAxis.splice(indexPivotYAxis, 1)
        }
        if (this.selectedPivotXAxis.includes('measures')) {
          const indexPivotXAxis = this.selectedPivotXAxis.findIndex(pivot => pivot.name === 'measures')
          this.selectedPivotXAxis.splice(indexPivotXAxis, 1)
        }
      }
    },
    selectedDimensions (val, old) {
      // const addedDimensions = linq.from(val).except(
      //   old, (newDimension, oldDimension) => newDimension === oldDimension)
      const deletedDimensions = _.differenceBy(old, val)
      // const deletedDimensions = linq.from(old).except(
      //   val, (oldDimension, newDimension) => oldDimension === newDimension)
      // addedDimensions.foreach(dimension => {
      //   if ((!this.selectedPivotYAxis.includes(dimension) &&
      //     !this.selectedPivotXAxis.includes(dimension))) {
      //     this.selectedPivotXAxis = this.selectedPivotXAxis.concat([dimension])
      //   }
      // })
      deletedDimensions.forEach(dimension => {
        if (this.selectedPivotYAxis.includes(dimension)) {
          const indexPivotYAxis = this.selectedPivotYAxis.findIndex(pivot => pivot.name === dimension.name)
          this.selectedPivotYAxis.splice(indexPivotYAxis, 1)
        }
        if (this.selectedPivotXAxis.includes(dimension)) {
          const indexPivotXAxis = this.selectedPivotXAxis.findIndex(pivot => pivot.name === dimension.name)
          this.selectedPivotXAxis.splice(indexPivotXAxis, 1)
        }
      })
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.loading = true

      const response = await axios.get(route('API.cubejs-token'))
      const cubeJsToken = response.data

      const options = {
        apiUrl: this.apiUrl
      }

      this.cubejsApi = cubejs(cubeJsToken, options)

      this.options = _.cloneDeep(this.initialOptions)
      if (this.idStatistic) {
        await this.loadQuery()
      }
      if (this.colors.length) {
        const newColors = this.colors.map(color => color.color).filter(color => color)
        this.$set(this.options, 'colors', newColors)
      }
      this.loading = false
    },
    async loadQuery () {
      const query = {
        measures: this.filterMeasures,
        dimensions: this.filterDimensions,
        filters: this.filters.map(filter =>
          ({
            member: filter.member.name,
            operator: filter.operator,
            values: filter.values,
          })
        ),
      }
      this.query = query
      await this.cubejsApi.load(query)
    },
    getFilters (measures, dimensions) {
      const filters = measures.concat(dimensions).map(filter =>
        ({
          name: filter.name,
          type: filter.type,
          tagName: this.getTranslation(filter.name),
        })
      )
      return _.sortBy(filters, ['tagName'])
    },
    testFilters (setFilters) {
      if (this.hasMeasuresDimensions) {
        setFilters(this.filtersToSend)
      }
    },
    getPivotPlaceholder (pivot) {
      return pivot.length > 0 ? this.$options.filters.trans('statistics.pivot.placeholder') : this.$options.filters.trans('statistics.pivot.placeholderDefault')
    },
    showChart (resultSet) {
      return resultSet && this.hasMeasuresDimensions
    },
    getTranslation (string) {
      // const numbers = string.match(/(\d+)/)
      // if (numbers) {
      //   const number = numbers[0]
      //   return this.$t('dynamicData.layer' + number + '.statistics.' + string)
      // }
      return this.$options.filters.trans(`statistics.${string}`)
    },
    addTranslations (variable) {
      return variable.map(variable => {
        const tagName = this.getTranslation(variable.name)
        return {
          name: variable.name,
          tagName,
        }
      })
    },
    getNewFilter () {
      this.filters.push({
        member: null,
        operator: null,
        values: [],
      })
    },
    deleteFilter (index, setFilters) {
      this.filters.splice(index, 1)
      this.testFilters(setFilters)
    },
    getNewColor () {
      this.colors.push({ color: null })
    },
    deleteColor (index) {
      this.colors.splice(index, 1)
    },
    setColor (color, index) {
      this.$set(this.colors, index, color)
    },
    emit (configuration) {
      this.$emit('input', { ...this.value, ...configuration })
    },
  },
}
</script>
