var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-md-12" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h5", { staticClass: "mt-1" }, [
          _c("i", { class: _vm.evaluator.icon }),
          _vm._v("\n        " + _vm._s(_vm.evaluator.name) + "\n      ")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c("multiselect", {
            attrs: {
              options: _vm.parents,
              multiple: false,
              disabled: _vm.nbReadOnly,
              "track-by": "name",
              label: "name",
              placeholder: "Seleccionar grupo de " + _vm.label
            },
            model: {
              value: _vm.selectedParent,
              callback: function($$v) {
                _vm.selectedParent = $$v
              },
              expression: "selectedParent"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-8 col-select-one" },
        [
          _c(
            "multiselect",
            {
              attrs: {
                options: _vm.children,
                multiple: true,
                disabled: _vm.nbReadOnly,
                limit: 1,
                limitText: function() {
                  return ""
                },
                "track-by": "name",
                "close-on-select": false,
                searchable: false,
                label: "name"
              },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function(props) {
                    return [
                      !_vm.selectedParent
                        ? _c("span", [_vm._v(_vm._s(props.option.name))])
                        : _vm.selectedParent.id === props.option.parent_id
                        ? _c("span", [_vm._v(_vm._s(props.option.name))])
                        : _vm._e()
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.data,
                callback: function($$v) {
                  _vm.data = $$v
                },
                expression: "data"
              }
            },
            [
              _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v("No existen elementos")
              ]),
              _vm._v(" "),
              _c("template", { slot: "tag" }, [
                _c(
                  "span",
                  {
                    staticClass: "badge m-badge py-0 px-2",
                    staticStyle: { "margin-top": "4px" }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.allIncludes
                            ? "Todos incluidos"
                            : _vm.data.length +
                                " elementos seleccionados de " +
                                _vm.differentSelectedGroupsCount +
                                " eventos."
                        ) +
                        "\n          "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._v(" "),
              _c("template", { slot: "beforeList" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-grow-1 align-content-stretch text-nowrap"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link select-all w-50",
                        attrs: { type: "button", disabled: _vm.nbReadOnly },
                        on: { click: _vm.selectAll }
                      },
                      [_vm._v("\n              Seleccionar todo\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link clear-all w-50",
                        attrs: { type: "button", disabled: _vm.nbReadOnly },
                        on: { click: _vm.removeAll }
                      },
                      [
                        _vm._v(
                          "\n              Deseleccionar todo\n            "
                        )
                      ]
                    )
                  ]
                )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c("nb-error-block", { attrs: { path: "filters." + _vm.name } })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "m-alert alert-primary m-alert--outline m-alert--air m-alert--square alert fade show mt-1",
            attrs: { role: "alert" }
          },
          [
            _c("div", { staticClass: "m-alert__text" }, [
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "w-50 pr-1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-sm btn-primary",
                      attrs: {
                        type: "button",
                        disabled: _vm.nbReadOnly || _vm.allIncludes
                      },
                      on: { click: _vm.selectAllComplete }
                    },
                    [
                      _vm._v(
                        "\n                Añadir todas las sesiones\n              "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-50 pl-1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-sm btn-primary",
                      attrs: {
                        type: "button",
                        disabled: _vm.nbReadOnly || !this.data.length
                      },
                      on: { click: _vm.removeAllComplete }
                    },
                    [
                      _vm._v(
                        "\n                Quitar todas las sesiones\n              "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              this.data.length
                ? _c("div", { staticClass: "mb-1" }, [_vm._v("Selección:")])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "group-box" },
                _vm._l(_vm.groupedSelection, function(group) {
                  return _c(
                    "li",
                    { staticClass: "group-box__item d-flex flex-wrap" },
                    [
                      _c("span", { staticClass: "group-box__name" }, [
                        _vm._v(_vm._s(_vm.groupName(group[0].parent_id)))
                      ]),
                      _vm._v(" "),
                      !_vm.isGroupVisible(group[0].parent_id)
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "ml-2 m-badge m-badge--wide group-box__recount-button",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.toggleGroupVisibility(
                                    group[0].parent_id
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.isGroupComplete(group)
                                      ? "Todos incluidos"
                                      : group.length + " elementos"
                                  ) +
                                  "\n                "
                              ),
                              _c("span", {
                                staticClass: "fa fa-angle-double-right"
                              })
                            ]
                          )
                        : [
                            _c("span", { staticClass: "group-box__opening" }, [
                              _vm._v("(")
                            ]),
                            _vm._v(" "),
                            _vm._l(group, function(item) {
                              return _c(
                                "span",
                                { staticClass: "group-box__open-item" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.stylizeGroupItem(
                                          item.parent_id,
                                          item.name
                                        )
                                      ) +
                                      "\n                  "
                                  ),
                                  _c("span", {
                                    directives: [
                                      { name: "tooltip", rawName: "v-tooltip" }
                                    ],
                                    staticClass:
                                      "fa fa-times p-1 group-box__remove-item",
                                    attrs: { title: "Eliminar elemento" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeItem(item.id)
                                      }
                                    }
                                  })
                                ]
                              )
                            })
                          ],
                      _vm._v(" "),
                      !_vm.isGroupVisible(group[0].parent_id)
                        ? _c("span", { staticClass: "group-closing" })
                        : _c(
                            "span",
                            { staticClass: "group-box__closing-end" },
                            [_vm._v(")")]
                          ),
                      _vm._v(" "),
                      _vm.isGroupVisible(group[0].parent_id)
                        ? _c("span", {
                            staticClass:
                              "group-box__close-button fa fa-angle-double-left",
                            on: {
                              click: function($event) {
                                return _vm.toggleGroupVisibility(
                                  group[0].parent_id
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }