var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group m-form__group col-12 my-4 accordion",
      attrs: { id: "kt_accordion_1" }
    },
    [
      _vm._l(_vm.answers, function(answer, i) {
        return _c("div", { key: i, staticClass: "accordion-item" }, [
          _c(
            "h2",
            {
              staticClass: "accordion-header ",
              attrs: { id: "kt_accordion_1_header_" + i }
            },
            [
              _c(
                "button",
                {
                  class: [
                    "accordion-button",
                    "text-dark",
                    "fs-5",
                    "fw-semibold",
                    "p-2",
                    { collapsed: i !== _vm.answers.length - 1 }
                  ],
                  attrs: {
                    type: "button",
                    "data-bs-toggle": "collapse",
                    "data-bs-target": "#kt_accordion_1_body_" + i,
                    "aria-expanded": "true",
                    "aria-controls": "kt_accordion_1_body_1"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between w-100" },
                    [
                      _c("div", { staticClass: "mt-4" }, [
                        _vm._v("Respuesta " + _vm._s(i + 1))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "float-end d-flex justify-content-between"
                        },
                        [
                          _vm.questionType === 3 && !_vm.lang
                            ? _c("div", { staticClass: "me-4" }, [
                                _c("i", {
                                  class:
                                    "fa fa-2x answer-icon " +
                                    _vm.getCorrectAnswer(answer),
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.$emit(
                                        "checkCorrectResponse",
                                        answer
                                      )
                                    }
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.lang
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-icon btn-danger btn-sm m-2 me-5",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.$emit("removeAnswer", i)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-trash" })]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "accordion-collapse collapse",
                { show: i === _vm.answers.length - 1 }
              ],
              attrs: {
                id: "kt_accordion_1_body_" + i,
                "aria-labelledby": "kt_accordion_1_header_" + i,
                "data-bs-parent": "#kt_accordion_1"
              }
            },
            [
              _c("div", { staticClass: "accordion-body" }, [
                _c("div", { staticClass: "m-4" }, [
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Identificador")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: answer.numeration,
                          expression: "answer.numeration"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: answer.numeration },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(answer, "numeration", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group mb-4 trumbowyg-small" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Contenido")
                      ]),
                      _vm._v(" "),
                      _c("html-editor", {
                        attrs: {
                          field: "editor-answer-" + "-" + i,
                          height: 100
                        },
                        model: {
                          value: answer.text,
                          callback: function($$v) {
                            _vm.$set(answer, "text", $$v)
                          },
                          expression: "answer.text"
                        }
                      }),
                      _vm._v(" "),
                      _vm.lang
                        ? _c("span", {
                            domProps: { innerHTML: _vm._s(answer.default_text) }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.questionType !== 3 && !_vm.lang
                    ? _c("div", { staticClass: "form-group mb-4" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Valor")
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: answer.value,
                                expression: "answer.value"
                              }
                            ],
                            staticClass: "form-select",
                            attrs: { required: "" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  answer,
                                  "value",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              { attrs: { selected: "", value: "" } },
                              [_vm._v("Seleccione una opción")]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.questionValues, function(questionValue) {
                              return _c(
                                "option",
                                {
                                  key: questionValue,
                                  attrs: {
                                    disabled: _vm.answers
                                      .map(function(answer) {
                                        return answer.value
                                      })
                                      .includes(questionValue)
                                  },
                                  domProps: { value: questionValue }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(questionValue) +
                                      "\n                            "
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]
          )
        ])
      }),
      _vm._v(" "),
      ((_vm.questionType !== 3 &&
        _vm.answers.length < _vm.numberAnswersAttitudeAndSkills) ||
        (_vm.questionType === 3 && _vm.answers.length < 5)) &&
      !_vm.lang
        ? _c(
            "button",
            {
              staticClass: "btn btn-secondary mt-9 mb-4",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("addAnswer")
                }
              }
            },
            [
              _vm._v("\n            Añadir respuesta\n        "),
              _c("i", { staticClass: "fa fa-plus-circle ms-3" })
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }