<template>
    <div class="col-md-8 col-xl-6">
        <input type="hidden" :name="`${prefix}[${name}][type]`" :value="type">

<!--        <div class="c-dashboard-filters__filter-group c-dashboard-filters__filter-group-1 pull-left"-->
<!--             :class="{'is-inactive' : type !== 'operator'}" id="dashboard-filter-operator"-->
<!--             role="group" @click="changeType('operator')">-->
<!--            <div class="c-dashboard-filters__filter-title mb-1">Por operador:</div>-->
<!--            <div class="c-dashboard-filters__filter-group-settings">-->
<!--                <input type="hidden" :name="`${prefix}[${name}][operator]`" :value="operator">-->
<!--                <select id="operator-filter" class="c-dashboard-filters__filter-operator form-control"-->
<!--                        v-model="operator">-->
<!--                    <option :value="key" v-for="(operator, key) in operators">{{ operator }}</option>-->
<!--                </select>-->
<!--                <input type="hidden" :name="`${prefix}[${name}][date]`" :value="date">-->
<!--                <input type="datetime-local" id="operator-datetime" v-model="date"-->
<!--                       class="c-dashboard-filters__filter-input form-control"/>-->
<!--            </div>-->
<!--        </div>-->

        <div :class="{'is-inactive' : type !== 'range'}"
             id="dashboard-filter-range" role="group" @click="changeType('range')">
            <label class="form-label">Por rango de fechas:</label>
            <div class="input-group">
                <input type="hidden" :name="`${prefix}[${name}][startDate]`" :value="startDate">
                <input placeholder="Desde" id="startDate" type="datetime-local" v-model="startDate" class="form-control"/>
                <span class="input-group-text">-</span>
                <input type="hidden" :name="`${prefix}[${name}][endDate]`" :value="endDate">
                <input placeholder="Hasta" id="endDate" type="datetime-local" v-model="endDate" class="form-control"/>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'relative-date-picker',
    props: {
      prefix: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      default_value: {
        type: Object,
        default: () => ({
          type: 'range',
          operator: 'smallerThan',
          date: 'now',
          startDate: moment().subtract(30, 'days').format('YYYY-MM-DD[T]HH:MM'),
          endDate: 'now'
        })
      },
      operators: {
        type: Object,
        default: () => ({
          same: '=',
          greaterThan: '>',
          smallerThan: '<',
          greaterThanOrEqual: '>=',
          smallerThanOrEqual: '<=',
        })
      },
    },
    watch: {
      globalWatcher: 'emitValues'
    },
    created: function () {
      this.type = this.default_value.type
      this.operator = this.default_value.operator
      this.date = this.dateFormat(this.default_value.date)
      this.startDate = this.dateFormat(this.default_value.startDate)
      this.endDate = this.dateFormat(this.default_value.endDate)

    },
    data () {
      return {
        type: 'operator',
        operator: 'same',
        date: 'now',
        startDate: 'now',
        endDate: 'now',
      }
    },
    computed: {
      globalWatcher () {
        return [
          this.type, this.operator, this.date, this.startDate, this.endDate, this.dayOperator,
        ].join()
      }
    },
    mounted () {
      this.emitValues()
    },
    methods: {
      dateFormat (date) {
        if (date === 'now') {
          date = moment().format('YYYY-MM-DD[T]HH:MM')
        }

        return date
      },
      changeType (type) {
        this.type = type
      },
      emitValues () {
        this.$emit('valuesUpdated', {
          type: this.type,
          operator: this.operator,
          date: this.date,
          startDate: this.startDate,
          endDate: this.endDate,
        })
      },
    }
  }
</script>

<style scoped>

</style>
