var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group m-form__group col-md-2 px-1 mt-auto" },
    [
      _c(
        "a",
        {
          staticClass: "btn btn-secondary dropdown-toggle col-12",
          attrs: {
            href: "#",
            role: "button",
            id: "exportDropdown",
            "data-bs-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [_vm._v("\n    Exportar\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: { "aria-labelledby": "exportDropdown" }
        },
        [
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.exportReport("pdf", $event)
                }
              }
            },
            [_vm._v("PDF")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.exportReport("csv", $event)
                }
              }
            },
            [_vm._v("CSV")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.exportReport("xlsx", $event)
                }
              }
            },
            [_vm._v("XLSX")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }