var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card mb-5", class: { "m-portlet": _vm.isNotActiveSelect } },
    [
      _vm.isNotActiveSelect
        ? _c("div", { staticClass: "card-header border-0 pt-5 pb-0" }, [
            _c(
              "h3",
              { staticClass: "card-title align-items-start flex-column" },
              [_vm._v("\n            Archivos\n        ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "card-toolbar d-flex align-items-center gap-2 gap-lg-3"
              },
              [
                _vm.canCreateFile
                  ? _c(
                      "a",
                      {
                        staticClass: "m-btn btn btn-primary m-btn--icon",
                        attrs: {
                          href: _vm.getRouteCreate(),
                          id: "route-create"
                        }
                      },
                      [_vm._m(0)]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isNotActiveSelect && _vm.canCreateFile
                  ? _c("upload-files", {
                      attrs: {
                        name: "Subir archivos",
                        types: _vm.filesTypes,
                        type: _vm.type,
                        "type-default": _vm.typeDefault,
                        "group-id": _vm.groupId,
                        "group-type": _vm.groupType,
                        isPrivate: _vm.isPrivateUpload,
                        "allowed-mime-types": _vm.allowedMimeTypes
                      },
                      on: {
                        reload: _vm.reloadFilesType,
                        open: function($event) {
                          _vm.shadow = true
                        },
                        close: function($event) {
                          _vm.shadow = false
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "m-btn btn btn-secondary m-btn--icon",
                    attrs: { href: _vm.getRouteExport(), id: "route-export" }
                  },
                  [_vm._m(1)]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card-body pt-0",
          class: { "mb-0 py-0": _vm.isActiveSelect },
          attrs: { id: "list-files-types" }
        },
        [
          _c("div", { staticClass: "d-sm-flex justify-content-between" }, [
            _c(
              "div",
              {
                class: {
                  "col-md-6": _vm.isActiveSelect,
                  "col-md-12": _vm.isNotActiveSelect,
                  "box-blur-shadow": _vm.isActiveShadow
                }
              },
              [
                _c("h4", { staticClass: "d-flex align-items-center mb-5" }, [
                  _vm.type
                    ? _c(
                        "span",
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:", id: "types" },
                              on: {
                                click: function($event) {
                                  return _vm.goToHome()
                                }
                              }
                            },
                            [_vm._v("Tipos")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.getNavigation, function(navigation) {
                            return _c("span", [
                              _vm._v(
                                "\n                            >\n                            "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "javascript:",
                                    id: "navigation-" + navigation.name
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectType(navigation)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(navigation.name) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ])
                          })
                        ],
                        2
                      )
                    : _c("span", [_vm._v("Tipos")])
                ])
              ]
            ),
            _vm._v(" "),
            _vm.isActiveSelect
              ? _c(
                  "div",
                  { staticClass: "mb-5" },
                  [
                    _vm.isActiveSelect
                      ? _c("upload-files", {
                          attrs: {
                            name: "Subir archivos",
                            types: _vm.filesTypes,
                            type: _vm.type,
                            "active-select": _vm.isActiveShadow,
                            "group-id": _vm.groupId,
                            "group-type": _vm.groupType,
                            "type-default": _vm.typeDefault,
                            isPrivate: _vm.isPrivateUpload,
                            allowedMimeTypes: _vm.allowedMimeTypes
                          },
                          on: {
                            reload: _vm.reloadFilesType,
                            open: function($event) {
                              _vm.shadow = true
                            },
                            close: function($event) {
                              _vm.shadow = false
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "row list",
              class: { "box-blur-shadow": _vm.isActiveShadow }
            },
            [
              _vm._l(_vm.listTypes, function(type) {
                return _c(
                  "div",
                  { staticClass: "col-xl-2 col-lg-3 col-md-4 col-sm-12" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "list-type",
                        on: {
                          click: function($event) {
                            return _vm.selectType(type)
                          }
                        }
                      },
                      [
                        _vm._m(2, true),
                        _vm._v(" "),
                        _c("div", { staticClass: "list-type-text" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(type.name) +
                              "\n                    "
                          )
                        ])
                      ]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _vm.typeNew
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xl-2 col-lg-3 col-md-4 col-sm-12 position-relative"
                    },
                    [
                      _c("div", { staticClass: "list-type disabled" }, [
                        _c("div", { staticClass: "list-type-delete" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger btn-sm",
                              attrs: {
                                type: "button",
                                id: "delete-type-" + _vm.typeNew.id
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deleteNewType()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-trash" })]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(3),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "list-type-text",
                            class: { "has-danger": _vm.typeNew.error }
                          },
                          [
                            _c("div", { staticClass: "input-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.typeNew.name,
                                    expression: "typeNew.name"
                                  }
                                ],
                                staticClass:
                                  "form-control form-control-sm m-input",
                                attrs: {
                                  type: "text",
                                  maxlength: "255",
                                  placeholder: "Escriba el nombre",
                                  name: "name",
                                  id: "type-name-" + _vm.typeNew.id
                                },
                                domProps: { value: _vm.typeNew.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.typeNew,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group-append" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "rounded-0 rounded-end btn btn-primary btn-sm",
                                    attrs: {
                                      type: "button",
                                      id: "save-type-" + _vm.typeNew.id
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.saveNewType()
                                      }
                                    }
                                  },
                                  [_c("em", { staticClass: "fa fa-save" })]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.typeNew.error
                              ? _c(
                                  "div",
                                  { staticClass: "form-control-feedback" },
                                  [_vm._v("Debe escribir el nombre")]
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canCreateType
                ? _c(
                    "div",
                    { staticClass: "col-xl-2 col-lg-3 col-md-4 col-sm-12" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "list-type list-type-new",
                          class: { disabled: !_vm.canAddNew },
                          on: {
                            click: function($event) {
                              return _vm.addNewType()
                            }
                          }
                        },
                        [
                          _vm._m(4),
                          _vm._v(" "),
                          _c("div", { staticClass: "list-type-text" }, [
                            _vm._v(
                              "\n                        Agregar tipo\n                    "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.type
            ? _c(
                "div",
                {
                  staticClass: "row mb-5",
                  class: { "box-blur-shadow": _vm.isActiveShadow }
                },
                [
                  _vm._m(5),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4 mb-0" }, [
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search,
                            expression: "search"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "Buscar un archivo...",
                          id: "search-files"
                        },
                        domProps: { value: _vm.search },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.search = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary rounded-0 rounded-end",
                            attrs: {
                              type: "button",
                              disabled: !_vm.activeSearch(),
                              id: "search"
                            },
                            on: {
                              click: function($event) {
                                return _vm.searchFiles()
                              }
                            }
                          },
                          [_vm._v("Buscar\n                        ")]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-8 text-right" }, [
                    _vm.isActiveSelect && _vm.isActiveMultiple()
                      ? _c(
                          "div",
                          {
                            staticClass: "btn-group mr-4",
                            attrs: { role: "group" }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "m-btn btn",
                                class: {
                                  "btn-brand": _vm.selectAllActive,
                                  "btn-secondary": !_vm.selectAllActive
                                },
                                attrs: { type: "button", id: "select-all" },
                                on: {
                                  click: function($event) {
                                    return _vm.selectAllFiles()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-check mr-1" }),
                                _vm._v(
                                  "\n                        Seleccionar todos\n                    "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn-group me-3",
                        attrs: { role: "group" }
                      },
                      _vm._l(_vm.orders, function(order) {
                        return _c(
                          "button",
                          {
                            staticClass: "border m-btn btn",
                            class: {
                              "btn-brand": _vm.getOrderActive(order),
                              "btn-secondary": !_vm.getOrderActive(order)
                            },
                            attrs: { type: "button", id: "orders-" + order.id },
                            on: {
                              click: function($event) {
                                return _vm.setOrder(order)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa mr-1",
                              class: {
                                "fa-sort-amount-asc": _vm.getClassOrderAsc(
                                  order
                                ),
                                "fa-sort-amount-desc": _vm.getClassOrderDesc(
                                  order
                                )
                              }
                            }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(order.text) +
                                "\n                    "
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btn-group", attrs: { role: "group" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "m-btn btn border",
                            class: {
                              "btn-brand": _vm.viewBox,
                              "btn-secondary": !_vm.viewBox
                            },
                            attrs: { type: "button", id: "view" },
                            on: {
                              click: function($event) {
                                _vm.viewBox = true
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-th-large" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "m-btn btn border",
                            class: {
                              "btn-brand": !_vm.viewBox,
                              "btn-secondary": _vm.viewBox
                            },
                            attrs: { type: "button", id: "no-view" },
                            on: {
                              click: function($event) {
                                _vm.viewBox = false
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-list" })]
                        )
                      ]
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type && _vm.viewBox
            ? _c(
                "div",
                {
                  staticClass: "row files",
                  class: { "box-blur-shadow": _vm.isActiveShadow }
                },
                [
                  _vm._l(_vm.getListFiles, function(file) {
                    return _c(
                      "div",
                      { staticClass: "col-xl-2 col-lg-3 col-md-4 col-sm-12" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "file",
                            class: {
                              "file-check": _vm.activeFileSelect(file.id)
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "file-background",
                                class: {
                                  "file-background__hover": _vm.isShowable(file)
                                },
                                style: _vm.getBackgroundImage(file),
                                on: {
                                  click: function($event) {
                                    return _vm.showModal(file)
                                  }
                                }
                              },
                              [
                                !_vm.isShowable(file)
                                  ? _c("div", { staticClass: "file-icon" }, [
                                      _c("i", {
                                        staticClass: "la la-file-text"
                                      })
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "file-text" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(file.name) +
                                  "\n                    "
                              )
                            ]),
                            _vm._v(" "),
                            (file.edit || file.delete) && _vm.isNotActiveSelect
                              ? _c("div", { staticClass: "file-actions" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn-group m-btn-group btn-block d-flex",
                                      attrs: { role: "group" }
                                    },
                                    [
                                      file.edit && _vm.canUpdateFile
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "rounded-top-0 rounded-bottom-0 btn btn-light m-btn m-btn--icon btn-sm m-btn--square",
                                              attrs: {
                                                href: file.edit,
                                                id: "file-edit-" + file.id
                                              }
                                            },
                                            [
                                              _c("em", {
                                                staticClass:
                                                  "fa fa-fw fa-pencil-alt m--font-brand"
                                              }),
                                              _vm._v(
                                                "\n                                Editar\n                            "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      file.delete && _vm.canDeleteFile
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "rounded-top-0 rounded-bottom-0 btn btn-light m-btn m-btn--icon btn-sm m-btn--square js-remove-model",
                                              attrs: {
                                                href: "javascript:",
                                                "data-url": file.delete,
                                                "data-event-name":
                                                  "reloadImages",
                                                id: "file-delete-" + file.id
                                              }
                                            },
                                            [
                                              _c("em", {
                                                staticClass: "fa fa-fw fa-trash"
                                              }),
                                              _vm._v(
                                                "\n                                Eliminar\n                            "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isActiveSelect
                              ? _c("div", { staticClass: "file-checkbox" }, [
                                  _c("label", { staticClass: "m-checkbox" }, [
                                    _c("input", {
                                      attrs: {
                                        type: "checkbox",
                                        id: "file-select-" + file.id
                                      },
                                      domProps: {
                                        checked: _vm.activeFileSelect(file.id)
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.setFileSelect(file)
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span")
                                  ])
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  !_vm.files.length
                    ? _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "fs-5 fw-bolder alert text-center my-20",
                            attrs: { role: "alert" }
                          },
                          [
                            _vm.loading
                              ? _c("div", {
                                  staticClass: "m-loader m-loader--info"
                                })
                              : _c("span", [
                                  _vm._v("No se han encontrado elementos")
                                ])
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type && !_vm.viewBox
            ? _c(
                "div",
                {
                  staticClass: "row",
                  class: { "box-blur-shadow": _vm.isActiveShadow }
                },
                [
                  _c("div", { staticClass: "col-12" }, [
                    _vm.files.length
                      ? _c("div", { staticClass: "table-responsive" }, [
                          _c(
                            "table",
                            { staticClass: "table table-striped m-table" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.isActiveSelect
                                    ? _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { width: "5%" }
                                        },
                                        [
                                          _vm.isActiveMultiple()
                                            ? _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "m-checkbox m-checkbox--state-brand",
                                                  class: {
                                                    "m-checkbox--solid m-checkbox--state-brand":
                                                      _vm.selectAllActive
                                                  }
                                                },
                                                [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "select-all-files"
                                                    },
                                                    domProps: {
                                                      checked:
                                                        _vm.selectAllActive
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.selectAllFiles()
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span")
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "text-center",
                                      attrs: { width: "7%" }
                                    },
                                    [_vm._v("Miniatura")]
                                  ),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Nombre")]),
                                  _vm._v(" "),
                                  _vm.isNotActiveSelect
                                    ? _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { width: "5%" }
                                        },
                                        [_vm._v("Acción")]
                                      )
                                    : _vm._e()
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.files, function(file) {
                                  return _c("tr", [
                                    _vm.isActiveSelect
                                      ? _c(
                                          "td",
                                          {
                                            staticClass:
                                              "align-middle text-nowrap text-center"
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "m-checkbox",
                                                class: {
                                                  "m-checkbox--solid m-checkbox--state-brand": _vm.activeFileSelect(
                                                    file.id
                                                  )
                                                }
                                              },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    type: "checkbox",
                                                    id:
                                                      "file-selected-" + file.id
                                                  },
                                                  domProps: {
                                                    checked: _vm.activeFileSelect(
                                                      file.id
                                                    )
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setFileSelect(
                                                        file
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span")
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm.isShowable(file)
                                        ? _c(
                                            "div",
                                            { staticClass: "img-thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getShowableUrl(file),
                                                  alt: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showModal(file)
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.isShowable(file)
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "img-thumbnail text-center"
                                            },
                                            [_vm._m(6, true)]
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "align-middle text-nowrap"
                                      },
                                      [
                                        file.edit &&
                                        _vm.isNotActiveSelect &&
                                        _vm.canUpdateFile
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: file.edit,
                                                  id: "file-" + file.id
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(file.name) +
                                                    "\n                                "
                                                )
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(file.name))
                                            ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.isNotActiveSelect
                                      ? _c(
                                          "td",
                                          {
                                            staticClass:
                                              "align-middle text-nowrap text-center"
                                          },
                                          [
                                            file.delete && _vm.canDeleteFile
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only js-remove-model",
                                                    attrs: {
                                                      href: "javascript:",
                                                      "data-url": file.delete,
                                                      "data-event-name":
                                                        "reloadImages",
                                                      id:
                                                        "delete-file-" + file.id
                                                    }
                                                  },
                                                  [
                                                    _c("em", {
                                                      staticClass:
                                                        "fa fa-fw fa-trash"
                                                    })
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        ])
                      : _c(
                          "div",
                          {
                            staticClass:
                              "fs-5 fw-bolder alert text-center my-20",
                            attrs: { role: "alert" }
                          },
                          [
                            _vm.loading
                              ? _c("div", {
                                  staticClass: "m-loader m-loader--info"
                                })
                              : _c("span", [
                                  _vm._v("No se han encontrado elementos")
                                ])
                          ]
                        )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.files.length > 0 && _vm.paginate.last_page > 1,
                  expression: "files.length > 0 && paginate.last_page > 1"
                }
              ],
              staticClass: "row",
              class: { "box-blur-shadow": _vm.isActiveShadow }
            },
            [
              _vm.paginate.last_page
                ? _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "pull-right" }, [
                      _c(
                        "ul",
                        {
                          staticClass: "pagination",
                          attrs: { role: "navigation" }
                        },
                        [
                          _c(
                            "li",
                            {
                              staticClass: "page-item",
                              class: { disabled: _vm.page === 1 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "page-link",
                                  on: {
                                    click: function($event) {
                                      return _vm.setPage(--_vm.page)
                                    }
                                  }
                                },
                                [_vm._v("‹")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.countPaginate, function(page) {
                            return _c(
                              "li",
                              {
                                staticClass: "page-item",
                                class: {
                                  active: page === _vm.paginate.current_page,
                                  disabled: !_vm.isNumber(page)
                                }
                              },
                              [
                                _vm.isNumber(page)
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "page-link",
                                        on: {
                                          click: function($event) {
                                            return _vm.setPage(page)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(page) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _c("span", { staticClass: "page-link" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(page) +
                                          "\n                            "
                                      )
                                    ])
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              staticClass: "page-item",
                              class: {
                                disabled: _vm.page === _vm.paginate.last_page
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "page-link",
                                  on: {
                                    click: function($event) {
                                      return _vm.setPage(++_vm.page)
                                    }
                                  }
                                },
                                [_vm._v("›")]
                              )
                            ]
                          )
                        ],
                        2
                      )
                    ])
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "modal-component",
            {
              attrs: {
                id: "repositoryModal",
                visible: _vm.openModal,
                backdrop: true,
                keyboard: true
              },
              on: {
                "update:visible": function($event) {
                  _vm.openModal = $event
                }
              },
              scopedSlots: _vm._u(
                [
                  _vm.image
                    ? {
                        key: "title",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.image.name) +
                                "\n            "
                            )
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              )
            },
            [
              _vm._v(" "),
              [
                _vm.image
                  ? _c("div", { staticClass: "modal-body-image" }, [
                      _c("img", {
                        attrs: { src: _vm.image.url, alt: _vm.image.name },
                        on: { error: _vm.imgDefault }
                      })
                    ])
                  : _vm._e()
              ]
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("em", { staticClass: "fa fa-plus fa-fw me-1" }),
      _vm._v(" "),
      _c("span", [_vm._v("Crear")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("em", { staticClass: "fa fa-cloud-download-alt" }),
      _vm._v(" "),
      _c("span", [_vm._v("Exportar")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-type-icon" }, [
      _c("i", { staticClass: "fa fa-folder-open display-4" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-type-icon" }, [
      _c("i", { staticClass: "fa fa-folder display-4" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-type-icon" }, [
      _c("i", { staticClass: "fa fa-plus display-4" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 mb-3" }, [
      _c("div", { staticClass: "m-separator m-separator--dashed mt-1" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [_c("i", { staticClass: "la la-file-text" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }