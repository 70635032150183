<template>
  <div class="layer-properties__color-graph">
    <span class="field__span">
      {{ 'statistics.colors.selectColor' | trans }}
    </span>
    <Swatches v-model="color"
              :swatches="colors"
              popover-x="right"
              popover-y="top"
              shapes="circles"
              show-border
              show-fallback
              fallback-input-type="color"
              :trigger-style="swatchesTriggerStyle"
              :fallback-ok-text="$options.filters.trans('statistics.swatches.buttonText')"
              :disabled="readonly"
              class="is-marginless"
    />
    <div class="layer-properties__color-actions">
      <button icon-left="trash" type="button" :disabled="readonly" @click="emitDelete" />
    </div>
  </div>
</template>
<script>
import Swatches from 'vue-swatches'

import SwatchesMixin from '@/js/mixins/Swatches'
import { colors, hexServerToString, stringToHexServer } from '@/js/helpers/ColorHelpers'

export default {
  name: 'StatisticsColors',
  components: { Swatches },
  mixins: [SwatchesMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    // readonly: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  data () {
    return {
      colors,
      readonly: false,
    }
  },
  computed: {
    color: {
      get () {
        return this.value.color ?? null
      },
      set (value) {
        this.emit({ color: value })
      },
    },
  },
  methods: {
    hexServerToString (color) {
      return hexServerToString(color)
    },
    stringToHexServer (color) {
      return stringToHexServer(color)
    },
    messageColor (color) {
      return color ? null : this.$options.filters.trans('statistics.colors.selectColor')
    },
    deleteRow (index) {
      this.selectedColors.splice(index, 1)
      this.emit()
    },
    emitDelete () {
      this.$emit('delete-color')
    },
    emit (value) {
      this.$emit('input', value)
    },
  },
}
</script>
