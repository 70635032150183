<template>
    <div
        class="m-input-icon m-input-icon--left m-input-icon--right c-sections-search position-relative overflow-hidden">
        <input type="text" id="filter-search"
               class="pe-15 form-control form-control-flush text-white m-input--solid"
               placeholder="Buscar sección"
               v-model="filter">
        <div class="position-absolute translate-middle-y top-50 end-0 me-3"><span class="svg-icon svg-icon-2"><svg
            width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Stockholm-icons-/-General-/-Search" stroke="none"
                                                          stroke-width="1" fill="none" fill-rule="evenodd"><rect
            id="bound" x="0" y="0" width="24" height="24"></rect> <path
            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
            id="Path-2" fill="#000000" fill-rule="nonzero" opacity="0.3"></path> <path
            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
            id="Path" fill="#000000" fill-rule="nonzero"></path></g></svg></span></div>

        <div
            class="c-sections-search__icon-clear position-absolute translate-middle-y top-50 end-0 me-11 cursor-pointer "
            @click="clearSearch" v-if="filter" id="clear-search">
            <span><i class="fa fa-times"></i></span>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'section-search',
    data() {
        return {}
    },
    computed: {
        ...mapGetters('Menu', {
            getFilter: 'getFilter',
        }),
        filter: {
            get() {
                return this.getFilter
            },
            set(value) {
                this.setFilter(value)
            }
        }
    },
    methods: {
        ...mapActions('Menu', {
            setFilter: 'setFilter',
        }),
        clearSearch() {
            this.filter = null
        },
    },
}
</script>

<style>
.clear-search {
    cursor: pointer;
}

.mt-05 {
    margin-top: .15rem !important;
}
</style>
