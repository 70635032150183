var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        attrs: { type: "hidden", name: "configuration", id: "configuration" },
        domProps: { value: _vm.statisticsJSON }
      }),
      _vm._v(" "),
      !_vm.loadingData
        ? _c("QueryBuilder", {
            attrs: {
              "cubejs-api": _vm.cubejsApi,
              query: _vm.query,
              "disable-heuristics": true
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "builder",
                  fn: function(ref) {
                    var setMeasures = ref.setMeasures
                    var availableMeasures = ref.availableMeasures
                    var setDimensions = ref.setDimensions
                    var availableDimensions = ref.availableDimensions
                    var setFilters = ref.setFilters
                    return [
                      _c("div", { staticClass: "columns columns--fields" }, [
                        _c(
                          "div",
                          { staticClass: "column is-half height-auto u-py-0" },
                          [
                            _c("TagInputWithTranslations", {
                              attrs: {
                                "tags-array": _vm.addTranslations(
                                  availableMeasures
                                ),
                                title: _vm.$options.filters.trans(
                                  "statistics.measures.title"
                                ),
                                placeholder: _vm.$options.filters.trans(
                                  "statistics.measures.placeholder"
                                )
                              },
                              on: {
                                input: function($event) {
                                  setMeasures(
                                    $event.map(function(tag) {
                                      return tag.name
                                    })
                                  ),
                                    setFilters(_vm.filtersToSend)
                                }
                              },
                              model: {
                                value: _vm.selectedMeasures,
                                callback: function($$v) {
                                  _vm.selectedMeasures = $$v
                                },
                                expression: "selectedMeasures"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column is-half height-auto u-py-0" },
                          [
                            _c("TagInputWithTranslations", {
                              attrs: {
                                "tags-array": _vm.addTranslations(
                                  availableDimensions
                                ),
                                title: _vm.$options.filters.trans(
                                  "statistics.dimensions.title"
                                ),
                                placeholder: _vm.$options.filters.trans(
                                  "statistics.dimensions.placeholder"
                                )
                              },
                              on: {
                                input: function($event) {
                                  setDimensions(
                                    $event.map(function(tag) {
                                      return tag.name
                                    })
                                  ),
                                    setFilters(_vm.filtersToSend)
                                }
                              },
                              model: {
                                value: _vm.selectedDimensions,
                                callback: function($$v) {
                                  _vm.selectedDimensions = $$v
                                },
                                expression: "selectedDimensions"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "column is-half u-py-0" }, [
                          _c(
                            "span",
                            {
                              staticClass: "field__span field__span--required"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("trans")("statistics.chartType"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.chartType,
                                  expression: "chartType"
                                }
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.chartType = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            _vm._l(_vm.chartTypes, function(type, name) {
                              return _c(
                                "option",
                                { key: name, domProps: { value: name } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("trans")(
                                          "statistics.chartTypes." + name
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "statistics-filters" }, [
                        _c("div", { staticClass: "card-header" }, [
                          _c("p", { staticClass: "card-header-title" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("trans")("statistics.filters.title")
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("button", {
                            attrs: { id: "new-filter", type: "button" },
                            on: { click: _vm.getNewFilter }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "section section-mini u-px-0 u-pb-0" },
                          _vm._l(_vm.filters, function(filter, index) {
                            return _c(
                              "div",
                              { key: filter.id },
                              [
                                _c("StatisticsFilter", {
                                  attrs: {
                                    filters: _vm.getFilters(
                                      availableMeasures,
                                      availableDimensions
                                    )
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.testFilters(setFilters)
                                    },
                                    "delete-filter": function($event) {
                                      return _vm.deleteFilter(index, setFilters)
                                    }
                                  },
                                  model: {
                                    value: _vm.filters[index],
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters, index, $$v)
                                    },
                                    expression: "filters[index]"
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        !(_vm.filters.length > 0)
                          ? _c("div", { staticClass: "has-text-centered" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("trans")(
                                      "statistics.filters.noFilters"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "section section-mini u-px-0" },
                          [
                            _c("div", { staticClass: "card-header" }, [
                              _c("p", { staticClass: "card-header-title" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("trans")("statistics.colors.title")
                                    ) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("button", {
                                attrs: { "icon-left": "plus", type: "button" },
                                on: { click: _vm.getNewColor }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "columns columns--fields" },
                              _vm._l(_vm.colors, function(color, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "column is-1" },
                                  [
                                    _c("StatisticsColor", {
                                      attrs: { value: color },
                                      on: {
                                        input: function($event) {
                                          return _vm.setColor($event, index)
                                        },
                                        "delete-color": function($event) {
                                          return _vm.deleteColor(index)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          ]
                        ),
                        _vm._v(" "),
                        !(_vm.colors.length > 0)
                          ? _c("div", { staticClass: "has-text-centered" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("trans")(
                                      "statistics.colors.noColors"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  }
                },
                {
                  key: "default",
                  fn: function(ref) {
                    var resultSet = ref.resultSet
                    return [
                      _vm.showChart(resultSet)
                        ? _c("ApexChart", {
                            attrs: {
                              "result-set": resultSet,
                              "chart-type": _vm.chartType,
                              "pivot-x-axis": _vm.selectedPivotXAxis,
                              "pivot-y-axis": _vm.selectedPivotYAxis,
                              "prop-options": _vm.options,
                              height: "300"
                            }
                          })
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              3425314441
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }