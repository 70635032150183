var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-12" }, [
    _c(
      "ul",
      {
        staticClass: "nav nav-tabs",
        attrs: { id: "homeTabs", role: "tablist" }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        !_vm.lang
          ? _c("li", { staticClass: "nav-item" }, [_vm._m(3)])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content", attrs: { id: "tabsContent" } }, [
      _c(
        "div",
        {
          staticClass: "tab-pane fade show active",
          attrs: { id: "main", role: "tabpanel", "aria-labelledby": "main-tab" }
        },
        [
          _c("main-block-tab", {
            attrs: {
              lang: _vm.lang,
              mainBlock: _vm.mainBlock,
              mainBlockDefault: _vm.mainBlockDefault,
              tests: _vm.tests
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tab-pane fade",
          attrs: {
            id: "infoTest",
            role: "tabpanel",
            "aria-labelledby": "infoTest-tab"
          }
        },
        [
          _c("test-info-tab", {
            attrs: {
              lang: _vm.lang,
              testInfoHeader: _vm.testInfoHeader,
              testInfoHeaderDefault: _vm.testInfoHeaderDefault,
              testInfo: _vm.testInfo,
              testInfoDefault: _vm.testInfoDefault,
              tests: _vm.tests
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tab-pane fade",
          attrs: {
            id: "generalFeatures",
            role: "tabpanel",
            "aria-labelledby": "generalFeatures-tab"
          }
        },
        [
          _c("general-features-tab", {
            attrs: {
              lang: _vm.lang,
              generalFeatures: _vm.generalFeatures,
              generalFeaturesDefault: _vm.generalFeaturesDefault
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.lang
        ? _c(
            "div",
            {
              staticClass: "tab-pane fade",
              attrs: {
                id: "sponsors",
                role: "tabpanel",
                "aria-labelledby": "sponsors-tab"
              }
            },
            [
              _c("sponsors-tab", {
                attrs: { sponsorsImages: _vm.sponsorsImages }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link active",
          attrs: {
            id: "main-tab",
            "data-bs-toggle": "tab",
            href: "#main",
            role: "tab",
            "aria-controls": "main",
            "aria-selected": "true"
          }
        },
        [
          _c("em", { staticClass: "fa fa-globe mr-1" }),
          _vm._v("\n        Bloque Principal\n      ")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: {
            id: "infoTest-tab",
            "data-bs-toggle": "tab",
            href: "#infoTest",
            role: "tab",
            "aria-controls": "infoTest",
            "aria-selected": "false"
          }
        },
        [
          _c("em", { staticClass: "fa fa-hashtag mr-1" }),
          _vm._v("\n        Presentación de Tests\n      ")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: {
            id: "generalFeatures-tab",
            "data-bs-toggle": "tab",
            href: "#generalFeatures",
            role: "tab",
            "aria-controls": "generalFeatures",
            "aria-selected": "false"
          }
        },
        [
          _c("em", { staticClass: "fa fa-th-list mr-1" }),
          _vm._v("\n        Destacados\n      ")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link",
        attrs: {
          id: "sponsors-tab",
          "data-bs-toggle": "tab",
          href: "#sponsors",
          role: "tab",
          "aria-controls": "sponsors",
          "aria-selected": "false"
        }
      },
      [
        _c("em", { staticClass: "fa fa-th-list mr-1" }),
        _vm._v("\n        Patrocinadores\n      ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }