var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ticket-edit-bar", class: { "is-focus": _vm.getActiveBar } },
    [
      _c("div", { staticClass: "ticket-edit-bar__box" }, [
        _c(
          "div",
          { staticClass: "ticket-edit-bar__box-left" },
          [
            _c("ticket-buttons", {
              attrs: { powerUserLoged: _vm.powerUserLoged }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ticket-edit-bar__box-left" },
          [_c("ticket-relations")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ticket-edit-bar__box-right" },
          [_c("ticket-dimensions")],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }