<template>
    <subevaluators ref="subevaluators"
                   v-model="subevaluators"
                   :route-prefix="routePrefix"
                   :evaluator="evaluator"
                   :group="group"
                   :value="value.subevaluators"
                   :nbReadOnly="nbReadOnly"
                   @filter-group="filterGroup"
                   @input="updateModel()"
                   @openModal="openModal">
    </subevaluators>
</template>

<script>
  export default {
    name: 'evaluator-groups',
    props: {
      routePrefix: { type: String, default: '' },
      group: { type: String, default: '' },
      evaluator: { type: Object, default: () => {} },
      value: { type: Object, default: () => ({ subevaluators: [] }) },
    },
    data () {
      return {
        subevaluators: [],
      }
    },
    created () {
      let evaluator
      let object = {}

      for (evaluator in this.value.subevaluators) {
        object = { ...this.value.subevaluators[evaluator] }
        this.subevaluators.push(object)
      }
    },
    methods: {
      updateModel () {
        this.$emit('input', {
          subevaluators: this.subevaluators
        })
      },
      openModal (element) {
        this.$emit('openModal', element)
      },
      /**
       * Method only for triggers
       */
      filterGroup (event) {
        this.$emit('filter-group', { evaluator: event.evaluator, category: event.category })
      },
    },
  }
</script>
