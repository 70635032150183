<template>
    <div>
        <div class="m-entity-list-search form-group m-form__group col-12">

            <div class="row col-12 py-2 mt-4">
                <div class="col-4">
                    <input type="file" ref="file" name="data" id="data" @change="setFile" :disabled="nbReadOnly">
                    <label for="file" class="btn btn-block btn-primary m-btn--icon"
                            @click="$refs.file.click()" :disabled="nbReadOnly || loading">
                        <em class="fa fa-plus-circle fa-fw me-1"></em>
                        Seleccionar archivo
                    </label>
                </div>

                <div class="col-8 text-right">
                    <button type="button" class="btn btn-brand btn m-btn--icon"
                            id="preview" @click="preview" :disabled="nbReadOnly || !file || loading">
                        <span>
                            <i class="la la-refresh"></i>
                            <span>Vista previa</span>
                        </span>
                    </button>
                    <button type="button" class="btn btn-brand btn m-btn--icon"
                            id="import" @click="importData" :disabled="nbReadOnly || !file || loading">
                        <span>
                            <i class="la la-refresh"></i>
                            <span>Importar {{ filename }}</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>

        <div class="m-form__section body-padding pt-0">
            <div class="row ml-2 mr-2" v-if="previewData">
                <div class="col-12">
                    <table class="table table-striped table-bordered m-table">
                        <thead>
                            <tr>
                                <th v-for="header in previewData.headers">{{header}}</th>
                            </tr>
                        </thead>
                        <paginate
                                ref="rowsPaginator" name="rows" :list="previewData.rows"
                                :per="10" tag="tbody" v-if="previewData.rows.length">
                            <tr v-for="row in paginated('rows')">
                                <td v-for="header in previewData.headers">{{row[header]}}</td>
                            </tr>
                        </paginate>
                    </table>
                </div>
                <div class="row" v-if="paginated('rows').length">
                    <div class="col-4">
                        <span v-if="$refs.rowsPaginator">{{  }}</span>
                    </div>
                    <div class="col-8">
                        <div class="pull-right">
                            <nav aria-label="Paginación de filas">
                                <paginate-links
                                    :limit="4"
                                    :show-step-links="true"
                                    :step-links="{ prev: '‹', next: '›' }"
                                    :hide-single-page="true"
                                    :classes="{'ul': 'pagination', 'li': 'page-item', 'a': 'page-link'}"
                                    :async="true"
                                    for="rows">
                                </paginate-links>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <!--progress class="col-12" v-if="importing" :max="numberLines" :value="doneLines"></progress-->
        </div>
    </div>
</template>

<script>
  export default {
    props: {},
    data () {
      return {
        file: null,
        filename: '',
        previewData: null,
        loading: false,
        importing: false,
        rows: [],
        paginate: ['rows'],
        numberLines: 0,
        doneLines: 0,
      }
    },
    methods: {
      setFile (event) {
        this.file = event.target.files[0]
        this.filename = this.file.name
      },
      async preview () {
        if (!this.file) return

        this.previewData = null
        this.loading = true
        const form = new FormData()
        form.append('file', this.file)
        form.append('type', 'customer')

        const response = await axios.post(
          route('import.previewCustomers'),
          form,
          { headers: {'Content-Type': 'multipart/form-data'} }
        ).finally(() => this.loading = false)

        console.log(response)
        this.previewData = response.data
      },
      async importData () {
        if (!this.file || this.nbReadOnly) return

        this.previewData = null
        this.loading = true
        this.importing = true

        const reader = new FileReader()
        reader.readAsText(this.file, 'UTF-8')
        reader.onerror = error => reject(error)
        reader.onload = async event => {
          const content = event.target.result.split(/\r\n|\n|\r/)
          this.numberLines = content.length - 1
          let chunkFn = []
          const max = 1
          const chuckSize = 2000
          let actives = 0
          let hasErrors = false
          for(let i = 0; i < content.length / chuckSize; i++) {
            chunkFn.push(() => {
              actives++
              const start = 1 + chuckSize * i
              const end = Math.min(1 + chuckSize * (i+1), content.length)
              const lines = [content[0]].concat(content.slice(start, end)).map(l => l + '\n')
              const file = new Blob(lines, {type: 'text/plain'})
              const form = new FormData()
              form.append('file', file)
              form.append('type', 'customer')
              const promise = axios.post(
                route('import.importCustomers'),
                form,
                { headers: {'Content-Type': 'multipart/form-data'} }
              )
              promise.finally(() => {
                actives--
                if (chunkFn.length) {
                  chunkFn.shift()()
                } else if (!actives) {
                  this.loading = false
                  this.doneLines = 0
                  this.importing = false
                  this.file = null
                  this.filename = ''
                  if (hasErrors) {
                    this.$swal.fire('Se produjo un error al importar', '', 'error')
                  } else {
                    this.$swal.fire('Importado correctamente', '', 'success')
                  }
                }
                this.doneLines += lines.length - 1
              }).catch(error => {
                chunkFn = []
                hasErrors = true
              })
            })
          }
          for(let i = 0; i < max && chunkFn.length; i++) {
            chunkFn.shift()()
          }
        }
      },
    },
  }
</script>


<style scoped lang="scss">
    #data {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
</style>
