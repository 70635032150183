import 'select2'
import 'select2/dist/js/i18n/es'

const defaultOptions = {
  width: '100%',
  placeholder: 'Selecciona una opción',
  allowClear: false,
  minimumResultsForSearch: 10,
  tags: false,
  language: 'es',
}

const buildSelect2 = {
  element: null,
  placeHolder: null,
  newOption: null,
  options: null,
  select: null,
  getElement () {
    return this.element
  },
  getPlaceHolder () {
    return this.placeholder
  },
  getNewOptions () {
    return this.newOptions
  },
  getOptions () {
    return this.options
  },
  getReference () {
    return $(this.getElement())
  },
  getSelect () {
    return this.select
  },
  setElement (element) {
    this.element = element
  },
  setPlaceHolder (placeHolder) {
    this.placeholder = placeHolder
  },
  setNewOptions (newOptions) {
    this.newOptions = newOptions
  },
  setOptions (options) {
    this.options = options
  },
  setSelect (select) {
    this.select = select
  },
  init (element) {
    this.setElement(element)
    this.definePlaceholder()
    this.defineOptions()
    this.defineSelect()
    if (this.getReference().attr('multiple')) {
      this.defineMultiple()
    }
  },
  defineOptions () {
    this.defineNewOptions()
    this.mergeOptions()
    this.defineTags()
  },
  definePlaceholder () {
    let reference = this.getReference()
    let placeHolder = reference.prop('placeholder')

    if (!placeHolder) {
      placeHolder = reference.data('placeholder')
    }

    this.setPlaceHolder(placeHolder)
  },
  defineNewOptions () {
    let reference = this.getReference()

    this.setNewOptions({
      'placeholder': this.getPlaceHolder(),
      'allowClear': reference.data('allow-clear'),
      'minimumResultsForSearch': reference.data('minimum-results-for-search'),
      'tags': reference.data('tags'),
      'tokenSeparators': reference.data('token-separators'),
    })
  },
  mergeOptions () {
    let newOptions = this.getNewOptions()

    Object.keys(newOptions).forEach(key => newOptions[key] === undefined && delete newOptions[key])

    this.setOptions(Object.assign({}, defaultOptions, newOptions))
  },
  defineTags () {
    let options = this.getOptions()

    if (options['tags']) {
      this.getReference().find('option').each((index, element) => {
        element.value = element.text
      })
    }
  },
  defineSelect () {
    this.setSelect(this.defineSelectElement())

    this.getSelect().on('select2:select', () => this.getReference().trigger('change'))
  },
  defineMultiple () {
    this.getSelect().on('select2:open', function () {
      buildSelect2.defineWrapper(this)
    })
  },
  defineWrapper (element) {
    this.setElement(element)

    let wrapper = $('<div class="bs-actionsbox"></div>')

    wrapper.append(this.getActionWrapper())

    if (!$('.select2-dropdown').find('div.bs-actionsbox').length) {
      wrapper.insertBefore('.select2-results')
    }
  },
  redefineElement () {
    this.defineOptions()
    this.defineSelectElement().toggle()
  },
  defineSelectElement () {
    return this.getReference().select2(this.getOptions())
  },
  getActionWrapper () {
    let actionsWrapper = $('<div class="btn-group btn-group-sm btn-block"></div>')

    if (!actionsWrapper.find('button.js-add-all').length) {
      actionsWrapper.append(this.getAddButton())
    }

    if (!actionsWrapper.find('button.js-remove-all').length) {
      actionsWrapper.append(this.getRemoveButton())
    }

    return actionsWrapper
  },
  getAddButton () {
    const addButton = $(
      '<button type="button" class="actions-btn btn btn-light btn-sm js-add-all">' +
      'Añadir todos' +
      '</button>',
    )

    addButton.on('click', () => {
      let element = this.getReference()
      element.val(_.map(element.find('option'), 'value')).trigger('change')
      this.redefineElement()
    })

    return addButton
  },
  getRemoveButton () {
    const removeButton = $(
      '<button type="button" class="actions-btn btn btn-light btn-sm js-remove-all">' +
      'Quitar todos' +
      '</button>',
    )

    removeButton.on('click', () => {
      let element = this.getReference()
      if (element.get()[0].value.length) {
        element.val([]).trigger('change')
        this.redefineElement()
      }
    })

    return removeButton
  },
}

export default buildSelect2

$(() => {
  $('.js-select2').each((i, inputElement) => {
    if (inputElement.tagName === 'SELECT') {
      buildSelect2.init(inputElement)
    }
  })
})
