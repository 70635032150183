export default function configureAxios (axios) {
  axios.interceptors.response.use(onResponseOK, onResponseError)

  function onResponseOK (responseOK) {
    return responseOK
  }

  /**
   * Si ocurre un error en las peticiones a la API
   * lo controlamos para si se ha perdido la sesión llevar al login
   * @param {*} responseError
   */
  function onResponseError (responseError) {
    let response = responseError.response
    if (response.status === 401) {
      window.location.reload()
      return false
    }

    if (response.status === 429) {
      Swal.fire({
        title: 'Solicitudes excedidas',
        text: 'Se han sobrepasado las solicitudes admitidas al servidor. Espera unos segundos y refresca la página',
        type: 'error',
      })
    }

    return Promise.reject(response)
  }
}
