<template>
    <div class="form-control-feedback has-danger" ref="errorSpan" v-if="renderedNbError">
        <template v-if="multiple" v-for="(error, i) of renderedNbError">
            <span class="text-danger">{{ error }}</span><br v-if="renderedNbError[i + 1]"/>
        </template>
        <template v-else>
            <span class="text-danger">{{ renderedNbError[0] }}</span>
        </template>
    </div>
</template>

<script>
  export default {
    name: 'nb-error-block',
    props: {
      path: { type: String, default: '' },
      multiple: { type: Boolean, default: true },
    },
    computed: {
      renderedNbError () {
        var output = this.renderNbError(this.path)
        if (output !== false) {
          // Add has-danger to parent form-group
          $(this.$refs.errorSpan).parent('div').addClass('has-danger')
        }
        return output
      },
    },
  }
</script>

<style scoped>

</style>
