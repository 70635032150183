<template>
    <div class="card mb-5">

        <div class="card-header border-0 pt-5 pb-0">
                <div class="m-portlet__head-title w-auto">
                    <span class="m-portlet__head-icon">
                        <i class="flaticon-dashboard"></i>
                    </span>
                    <h3 class="card-title align-items-start flex-column m-portlet__head-text">
                        Cuadros de mando
                    </h3>
                </div>
                <div v-if="false" class="card-toolbar">
                    <button id="reports-button" class="btn btn-primary m-btn m-btn--custom m-btn--icon"
                            @click="goToReports">
                        Ir a informes
                        <i class="fa fa-chart-bar fa-fw ms-1"></i>
                    </button>
                </div>
        </div>

        <!-- Filtros-->
        <div class="card-body pt-0">
            <div class="row">
                <!-- TODO: Ver si interesa meter en el base algún filtro por tenant o se deja ya a niveel de cada proyecto -->
                <!--<div class="col-md-4 col-xl-3 mb-5 mb-md-0">
                  <label class="form-label">Por organismo:</label>
                  <div class="c-dashboard-filters__filter-group-settings mr-4">
                    <multiselect id="organization-filter" label="name" track-by="id" placeholder="Selecciona un organismo"
                                 :multiple="false" :options="organizations" v-model="selectedOrganization"
                                 @input="emitOrganizationFilters" style="width: 215px;">
                    </multiselect>
                  </div>
                </div>-->

                <relative-date-picker v-on:valuesUpdated="emitDateFilters"></relative-date-picker>
            </div>
        </div>
        <!-- Filtros FIN-->

    </div>
</template>

<script>
  import RelativeDatePicker from '@/js/components/dashboard/RelativeDatePicker'
  import DateHelpers from '@/js/mixins/DateHelpers'
  import { mapActions } from 'vuex'

  export default {
    name: 'dashboard-filters',
    mixins: [DateHelpers],
    components: { RelativeDatePicker },
    props: {
      organizations: {type: Array, default: () => []}
    },
    data () {
      return {
        selectedOrganization: null
      }
    },
    computed: {},
    methods: {
      ...mapActions('Dashboard', {
        setDateFilters: 'setDateFilters',
        setOrganizationFilters: 'setOrganizationFilters',
      }),
      emitDateFilters (dateFilters) {
        this.setDateFilters({ ...dateFilters })
      },
      emitOrganizationFilters (organization) {
        this.setOrganizationFilters(organization ? organization.id : null)
      },
      goToReports () {
        window.location.href = 'others/reports'
      }
    }
  }
</script>

<style scoped>

</style>
