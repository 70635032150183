<template>
    <div>
        <div class="form-group mb-1">
            <label for="elementsVisibility">Visibilidad del campo</label>
            <div class="m-radio-list" id="elementsVisibility">
                <label class="m-radio">
                    <input type="radio" name="element-visibility" :value="1" v-model="activeElement.visible"
                           @click="changeVisibility(true)" id="element-visibility">
                    Visible
                    <span></span>
                </label>
                <label class="m-radio">
                    <input type="radio" name="element-visibility" :value="0" v-model="activeElement.visible"
                           @click="changeVisibility(false)" id="no-element-visibility">
                    Oculto
                    <span></span>
                </label>
                <label class="m-radio">
                    <input type="radio" name="element-visibility" :value="null" v-model="activeElement.visible"
                           @click="changeVisibility(null)" id="null-element-visibility">
                    Condicional
                    <span></span>
                </label>
            </div>
        </div>
        <div class="form-group mt-3 mb-2" v-if="activeElement.visible === null">
            <label for="showIfConditionsMet">Si la condición se cumple:</label>
            <div class="m-radio-inline" id="showIfConditionsMet">
                <label class="m-radio">
                    <input type="radio" name="showIfConditionsMet" id="conditions-met"
                           :checked="activeElement.visibility.showIfConditionsMet === true"
                           @input="updateVisibility(true, 'showIfConditionsMet')"
                           :value="true"> Mostrar
                    <span></span>
                </label>
                <label class="m-radio">
                    <input type="radio" name="showIfConditionsMet" id="no-conditions-met"
                           :checked="activeElement.visibility.showIfConditionsMet === false"
                           @input="updateVisibility(false, 'showIfConditionsMet')"
                           :value="false"> Ocultar
                    <span></span>
                </label>
            </div>
        </div>
        <div class="form-group" v-if="activeElement.visible === null">
            <label for="requireAllConditions">¿Cuantas condiciones deben cumplirse?</label>
            <div class="m-radio-inline" id="requireAllConditions">
                <label class="m-radio">
                    <input type="radio" name="requireAllConditions" id="all-conditions"
                           :checked="activeElement.visibility.requireAllConditions === true"
                           @input="updateVisibility(true, 'requireAllConditions')"
                           :value="true"> Todas
                    <span></span>
                </label>
                <label class="m-radio">
                    <input type="radio" name="requireAllConditions" id="no-all-conditions"
                           :checked="activeElement.visibility.requireAllConditions === false"
                           @input="updateVisibility(false, 'requireAllConditions')"
                           :value="false"> Solo una
                    <span></span>
                </label>
            </div>
        </div>

        <VisibilityConditions v-if="activeElement.visible === null"/>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters, mapMutations } from 'vuex'
  import VisibilityConditions from '@/js/components/ticket_editor/tabs/element-tabs/VisibilityConditions'

  export default {
    name: 'VisibilityTab',
    components: { VisibilityConditions },
    computed: {
      ...mapGetters('ticketEditor', {
        activeElement: 'activeElement',
      }),
    },

    methods: {
      ...mapMutations('ticketEditor', {
        updateVisibilityProperty: 'updateVisibility',
      }),

      changeVisibility (value) {
        this.activeElement.visible = value
        this.layoutChange()
      },

      updateVisibility (value, prop) {
        this.updateVisibilityProperty({ prop, value })
        this.layoutChange()
      },

      layoutChange () { this.$eventBus.$emit('layoutChange') },
    },
  }
</script>

