import Vue from 'vue'
import Fuse from 'fuse.js'
import axios from 'axios'

export const state = {
  flatMenu: null,
  menu: null,
  favorites: null,
  filter: null,
}

export const getters = {
  getFlatMenu: state => state.flatMenu,
  getMenu: state => state.menu,
  getFavorites: state => state.favorites,
  getFilter: state => state.filter,
  getFilteredItems: state => {
    if (!state.filter) return state.flatMenu

    let results = (new Fuse(state.flatMenu, {
      shouldSort: false,
      keys: ['title'],
      threshold: 0.3,
    })).search(state.filter)

    function populateParents (item) {
      if (!item._parent) return
      let itemParent = state.flatMenu.find(i => i.attributes.id === item._parent)
      results.push(itemParent)
      populateParents(itemParent)
    }

    results.map(item => populateParents(item))

    return results
  },
}

export const mutations = {
  setFlatMenu: (state, flatMenu) => state.flatMenu = flatMenu,
  setMenu: (state, menu) => state.menu = menu,
  setFavorites: (state, favorites) => state.favorites = favorites,
  setFilter: (state, filter) => state.filter = filter,
}

export const actions = {
  async setFlatMenu ({ state, commit }, payload) {
    commit('setFlatMenu', payload)
  },
  async setMenu ({ state, commit }, payload) {
    commit('setMenu', payload)
  },
  async setFavorites ({ state, commit }, payload) {
    commit('setFavorites', payload)
  },
  async addFavoriteItem ({ state, commit }, payload) {
    let oldFavorites = { ...state.favorites }
    let favorites = { ...state.favorites }

    Vue.set(favorites, payload.attributes.id, payload)
    commit('setFavorites', favorites)

    try {
      return await axios.post('/api/menu/favorite', {
        id: payload.attributes.id,
        slug: payload.attributes.slug,
      })
    } catch (e) {
      commit('setFavorites', oldFavorites)
    }
  },
  async deleteFavoriteItem ({ state, commit }, payload) {
    let oldFavorites = { ...state.favorites }
    let favorites = { ...state.favorites }

    Vue.delete(favorites, payload.attributes.id)
    commit('setFavorites', { ...favorites })

    try {
      return await axios.delete('/api/menu/favorite', {
        params: {
          id: payload.attributes.id,
          slug: payload.attributes.slug
        },
      })
    } catch (e) {
      commit('setFavorites', oldFavorites)
    }
  },
  async setFilter ({ state, commit }, payload) {
    if (!payload || !payload.length) {
      payload = null
    }
    commit('setFilter', payload)
  },
}

export const Menu = { state, mutations, actions, getters }

export default Menu
