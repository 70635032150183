<template>
    <div class="form-group m-form__group col-12">
        <input :name="name" type="hidden" :value="listJSON">
        <div class="d-sm-flex justify-content-between">
            <h4 class="d-flex align-items-center mb-5">
                <em class="flaticon-settings mr-1"></em>
                <template v-if="'title' in interfaceTexts">{{ interfaceTexts['title'] }}</template>
                <template v-else>Listado</template>
            </h4>
            <button id="add"
                    type="button"
                    class="mb-5 btn btn-primary m-btn btn-sm m-btn m-btn--icon"
                    :disabled="nbReadOnly"
                    @click="add()">
                <span>
                    <i class="fa fa-plus"></i>
                    <span>
                        <template v-if="'add-button' in interfaceTexts">
                            {{ interfaceTexts['add-button'] }}
                        </template>
                        <template v-else>Añadir nueva entrada</template>
                    </span>
                </span>
            </button>
        </div>
        <table class="table table-striped table-bordered m-table">
            <thead>
            <tr>
                <th v-for="(header, key) in headers" v-if="getInputType(key) != 'hidden'">
                        <span class="column-no-sort">
                            {{ getHeaderName(header) }}
                        </span>
                </th>
                <th v-if="!isEmptyActions()" class="text-center">
                </th>
            </tr>
            </thead>

            <paginate v-if="list.length" name="list" tag="tbody" :list="list" :per="10">
                <tr v-for="(value,id) in paginated('list')" :data-model-id="id">
                    <td v-for="(key, metaKey) in Object.keys(value)"
                        v-if="getInputType(metaKey) != 'hidden'"
                        class="align-middle">
                        <div class="m-form__control">
                            <input :id="name + '-' + id"
                                   v-model="value[key]"
                                   class="form-control form-control-sm m-input"
                                   :type="getInputType(metaKey)"
                                   :disabled="nbReadOnly"
                                   :required="isRequired(metaKey)">
                            <nb-error-block :path="`${errorPath}.${id}.${key}`"></nb-error-block>
                        </div>
                    </td>
                    <td class="align-middle text-center">
                        <a id="edit"
                           v-if="'edit' in actions && nNewItems <= id"
                           title=""
                           :href="populateURL(actions['edit'], value)"
                           class="btn btn-info m-btn m-btn--icon btn-sm m-btn--icon-only mr-1"
                           data-original-title="Editar">
                            <i data-v-a89a1b92="" class="fa fa-fw fa-pencil"></i>
                        </a>

                        <button v-if="'delete' in actions || nNewItems > id"
                                type="button"
                                class="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only"
                                :disabled="nbReadOnly"
                                @click="erase(id)">
                            <i class="fa fa-times"></i>
                        </button>
                    </td>
                </tr>
            </paginate>
            <tbody v-else>
            <tr>
                <td colspan="4" class="text-center py-4">
                    <template v-if="'empty-list' in interfaceTexts">{{ interfaceTexts['empty-list'] }}</template>
                    <template v-else>Lista vacía, añade una nueva entrada.</template>
                </td>
            </tr>
            </tbody>
        </table>
        <paginate-links for="list"
                        :limit="4"
                        :show-step-links="true"
                        :step-links="{ prev: '‹', next: '›' }"
                        :hide-single-page="true"
                        :classes="{'ul': 'pagination', 'li': 'page-item', 'a': 'page-link'}"
                        :async="true">
        </paginate-links>
    </div>
</template>

<script>
  export default ({
    name: 'edit-list',
    props: {
      name: { type: String, default: '' },
      list: { type: Array, default: () => [] },
      emptyItem: { type: Object, default: () => {} },
      nNewItems: { type: Number, default: 0 },
      headers: { type: Array, default: () => [] },
      actions: { type: Object, default: () => {} },
      errorPath: { type: String, default: '' },
      interfaceTexts: { type: Object, default: () => [] },
    },
    data: function () {
      return {
        currentList: [],
        paginate: ['list']
      }
    },
    computed: {
      listJSON () {
        return JSON.stringify(this.currentList)
      },
    },
    created () {
      this.currentList = this.list
    },
    methods: {
      /**
       * Returns the type of field (second key in headers arrays)
       */
      getInputType (key) {
        if (Array.isArray(this.headers[key])) {
          return this.headers[key][1]
        }
      },
      /**
       * Populate the url with appropiate row id
       */
      populateURL (url, value) {
        return url.replace('*', value.id)
      },
      /**
       * Lodash method _.isEmpty() does not work in template
       */
      isEmptyActions () {
        return _.isEmpty(this.actions)
      },
      /**
       * Comprueba si el campo es requerido
       */
      isRequired (key) {
        if (Array.isArray(this.headers[key])) {
          return this.headers[key].includes('required')
        }
        return false
      },
      /**
       * Retrive proper header name from header object
       */
      getHeaderName (header) {
        if (Array.isArray(header)) {
          return header[0]
        }
        return header
      },
      /**
       * Añade una nueva zona al array
       */
      add () {
        this.currentList.unshift({ ...this.emptyItem })
        this.nNewItems++
      },

      /**
       * Elimina la zona con el index pasado
       * por parametro
       * @param index
       */
      erase (index) {
        this.currentList.splice(index, 1)
        this.nNewItems--
      },
    },
  })
</script>
