var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group m-form__group col-12 my-4 accordion",
      attrs: { id: "kt_accordion_1" }
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "testInfoData" },
        domProps: { value: _vm.jsonTestInfoData }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.dataTestInfoHeader,
            expression: "dataTestInfoHeader"
          }
        ],
        attrs: { type: "hidden", name: "testInfoHeader" },
        domProps: { value: _vm.dataTestInfoHeader },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.dataTestInfoHeader = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group m-4 trumbowyg-small" },
        [
          _c("label", { staticClass: "form-label" }, [
            _vm._v("Texto de cabecera")
          ]),
          _vm._v(" "),
          _c("trumbowyg", {
            attrs: { config: _vm.configs },
            on: {
              input: function($event) {
                return _vm.$emit("input", _vm.dataTestInfoHeader)
              }
            },
            model: {
              value: _vm.dataTestInfoHeader,
              callback: function($$v) {
                _vm.dataTestInfoHeader = $$v
              },
              expression: "dataTestInfoHeader"
            }
          }),
          _vm._v(" "),
          _vm.lang
            ? _c("span", {
                domProps: { innerHTML: _vm._s(_vm.testInfoHeaderDefault) }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.dataTestInfo, function(testData, i) {
        return _c("div", { key: i, staticClass: "accordion-item" }, [
          _c(
            "h2",
            {
              staticClass: "accordion-header",
              attrs: { id: "kt_accordion_1_header_" + i }
            },
            [
              _c(
                "button",
                {
                  class: [
                    "accordion-button",
                    "text-dark",
                    "fs-4",
                    "fw-semibold",
                    { collapsed: i !== _vm.dataTestInfo.length - 1 }
                  ],
                  attrs: {
                    type: "button",
                    "data-bs-toggle": "collapse",
                    "data-bs-target": "#kt_accordion_1_body_" + i,
                    "aria-expanded": "true",
                    "aria-controls": "kt_accordion_1_body_1"
                  }
                },
                [
                  !_vm.lang
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-icon btn-danger btn-sm m-2 me-5",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.remove(i)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-trash" })]
                      )
                    : _vm._e(),
                  _vm._v(
                    "\n              Presentación test " +
                      _vm._s(i + 1) +
                      "\n          "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "accordion-collapse collapse",
                { show: i === _vm.dataTestInfo.length - 1 }
              ],
              attrs: {
                id: "kt_accordion_1_body_" + i,
                "aria-labelledby": "kt_accordion_1_header_" + i,
                "data-bs-parent": "#kt_accordion_1"
              }
            },
            [
              _c("div", { staticClass: "accordion-body" }, [
                _c("div", { staticClass: "m-4" }, [
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Titulo")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: testData.mainText,
                          expression: "testData.mainText"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: testData.mainText },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(testData, "mainText", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.lang
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.testInfoDefault[i].mainText))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group mb-15 trumbowyg-small" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Descripción")
                      ]),
                      _vm._v(" "),
                      _c("trumbowyg", {
                        attrs: { config: _vm.configs },
                        on: {
                          input: function($event) {
                            return _vm.$emit("input", testData.secondaryText)
                          }
                        },
                        model: {
                          value: testData.secondaryText,
                          callback: function($$v) {
                            _vm.$set(testData, "secondaryText", $$v)
                          },
                          expression: "testData.secondaryText"
                        }
                      }),
                      _vm._v(" "),
                      _vm.lang
                        ? _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.testInfoDefault[i].secondaryText
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-15 row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Texto de botón")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: testData.buttonText,
                            expression: "testData.buttonText"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: testData.buttonText },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              testData,
                              "buttonText",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.lang
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.testInfoDefault[i].buttonText))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Url de botón")
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.tests,
                            label: "name",
                            "track-by": "id",
                            disabled:
                              _vm.lang !== null
                                ? true
                                : testData.buttonText
                                ? false
                                : true
                          },
                          model: {
                            value: testData.buttonUrl,
                            callback: function($$v) {
                              _vm.$set(testData, "buttonUrl", $$v)
                            },
                            expression: "testData.buttonUrl"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group mb-4" },
                    [
                      _c("div", [
                        (testData.features &&
                          testData.features.length > 0 &&
                          _vm.lang) ||
                        !_vm.lang
                          ? _c("label", { staticClass: "form-label" }, [
                              _vm._v("Caracteristicas")
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm._l(testData.features, function(feature, index) {
                        return _c("div", { key: index, staticClass: "mb-2" }, [
                          _c("div", { staticClass: "input-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: testData.features[index],
                                  expression: "testData.features[index]"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "Escribe una respuesta"
                              },
                              domProps: { value: testData.features[index] },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    testData.features,
                                    index,
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            !_vm.lang
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.removeFeature(
                                          testData,
                                          index
                                        )
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-trash" })]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.lang
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.testInfoDefault[i].features[index]
                                    ) +
                                    "\n                      "
                                )
                              ])
                            : _vm._e()
                        ])
                      }),
                      _vm._v(" "),
                      !_vm.lang
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.addFeature(testData)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                      Agregar Caracteristica\n                      "
                              ),
                              _c("i", { staticClass: "fa fa-plus" })
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ])
            ]
          )
        ])
      }),
      _vm._v(" "),
      !_vm.lang
        ? _c(
            "button",
            {
              staticClass: "btn btn-secondary mt-9 mb-4",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.addTestInfo()
                }
              }
            },
            [
              _vm._v("\n    Añadir presentación de test\n    "),
              _c("i", { staticClass: "fa fa-plus-circle ms-3" })
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }