var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "m-entity-list-search form-group m-form__group col-12" },
      [
        _c("div", { staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              {
                staticClass:
                  "m-input-icon m-input-icon--right position-relative"
              },
              [
                _c("label", {
                  staticClass: "sr-only",
                  attrs: { for: "search" }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search"
                    }
                  ],
                  staticClass: "pe-10 form-control m-input",
                  attrs: {
                    autocomplete: "off",
                    placeholder: "Buscar",
                    name: "q",
                    id: "search",
                    type: "text",
                    "aria-label": "Search"
                  },
                  domProps: { value: _vm.search },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.search = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm._m(0)
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.reportTypes,
                  multiple: true,
                  "track-by": "name",
                  label: "name",
                  placeholder: _vm.placeholder
                },
                model: {
                  value: _vm.reportTypesSelected,
                  callback: function($$v) {
                    _vm.reportTypesSelected = $$v
                  },
                  expression: "reportTypesSelected"
                }
              })
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row m-form__section mt-0 body-padding pt-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm.reports.length
          ? _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                { staticClass: "table table-hover m-table" },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "paginate",
                    {
                      key: _vm.search,
                      attrs: {
                        name: "reportsPagination",
                        list: _vm.filteredReports,
                        per: 10,
                        tag: "tbody"
                      }
                    },
                    _vm._l(_vm.paginated("reportsPagination"), function(
                      report
                    ) {
                      return _c("tr", { key: "report-" + report.id }, [
                        _c("td", [
                          _c("a", { attrs: { href: report.href } }, [
                            _vm._v(_vm._s(report.name))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(report.description))])
                      ])
                    }),
                    0
                  )
                ],
                1
              )
            ])
          : _c(
              "div",
              {
                staticClass:
                  "fs-5 fw-bolder alert m-alert--default text-center my-20"
              },
              [_vm._v("No tienes informes asignados.")]
            ),
        _vm._v(" "),
        _vm.paginated("reportsPagination").length
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-4" }, [
                _c("span", [_vm._v(_vm._s())])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-8" }, [
                _c("div", { staticClass: "pull-right" }, [
                  _c(
                    "nav",
                    { attrs: { "aria-label": "Paginación de informes" } },
                    [
                      _c("paginate-links", {
                        attrs: {
                          limit: 10,
                          "show-step-links": true,
                          "step-links": { prev: "‹", next: "›" },
                          "hide-single-page": true,
                          classes: {
                            ul: "pagination",
                            li: "page-item",
                            a: "page-link"
                          },
                          async: true,
                          for: "reportsPagination"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "position-absolute translate-middle-y top-50 end-0 me-3" },
      [_c("span", [_c("i", { staticClass: "fa fa-fw fa-search" })])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Nombre")]),
        _vm._v(" "),
        _c("th", [_vm._v("Descripción")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }