<template>
  <div>
    <form @submit.prevent="saveQuestion">
      <modal-component
        :visible.sync="showModalHandle"
        :classesModalDialog="['modal-dialog-centered', 'modal-xl']"
        :buttonClose="false"
        :focus="false"
      >
        <template #title>
          <h5 class="modal-title">
            {{ question !== null ? "Editar pregunta" : "Nueva pregunta" }}
          </h5>
        </template>
        <template>
          <div class="form-group mb-4">
            <label>Enunciado</label>
            <html-editor
              v-model="questionData.name"
              field="name"
              :height="100"
            />
            <div v-if="lang" v-html="questionData.default_name"></div>
          </div>
          <div class="form-group mb-4">
            <label>Descripción</label>
            <textarea
              class="form-control"
              v-model="questionData.description"
              rows="2"
              maxlength="255"
              required
            />
            <span v-if="lang">
              {{ questionData.default_description }}
            </span>
          </div>
          <div class="form-group mb-4">
            <label>Explicación</label>
            <html-editor
              field="explanation"
              v-model="questionData.explanation"
              :height="100"
            />
            <div v-if="lang" v-html="questionData.default_explanation"></div>
          </div>
          <div class="form-group mb-4" v-if="multimedia !== 'image' && !lang">
            <label>Video</label>
            <div class="input-group">
              <input type="url" class="form-control" v-model="video_url" maxlength="255"/>
              <button
                type="button"
                class="btn btn-danger"
                @click="removeVideo()"
              >
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
          <div class="form-group mb-4" v-if="multimedia !== 'video' && !lang">
            <label>Imagen</label>
            <repository-button
              :allowed-mime-types="allowedMimeTypes"
              :short-version="true"
              v-model="selectedImage"
              v-on:clear-value="clearValue"
              class-field="col-md-12 px-0"
              group-id="1"
              group-type="App\Models\Rep\FileGroup"
            >
            </repository-button>
          </div>
          <div class="form-group mb-4" v-if="!lang">
            <label>Dominio</label>
            <select
              class="form-control"
              v-model="questionData.competence_domain_id"
              required
            >
              <option
                :key="competenceDomain.id"
                v-for="competenceDomain in competenceDomains"
                :value="competenceDomain.id"
              >
                {{ competenceDomain.name }}
              </option>
            </select>
          </div>
          <div class="form-group mb-4" v-if="!lang">
            <label>Tipo</label>
            <select
              class="form-control"
              v-model="questionData.question_type_id"
              required
            >
              <option
                :key="questionType.id"
                v-for="questionType in questionTypes"
                :value="questionType.id"
              >
                {{ questionType.name }}
              </option>
            </select>
          </div>
          <div class="form-group mb-4" v-if="!lang">
            <label>Nivel</label>
            <select
              class="form-control"
              v-model="questionData.level_id"
              required
            >
              <option :key="level.id" v-for="level in levels" :value="level.id">
                {{ level.name }}
              </option>
            </select>
          </div>
          <div class="form-group mb-4">
            <div>Respuestas</div>
            <AnswersComponent
              :answers="questionData.answers"
              :questionType="questionData.question_type_id"
              :lang="lang"
              :numberAnswersAttitudeAndSkills="numberAnswersAttitudeAndSkills"
              @addAnswer="addAnswer"
              @removeAnswer="removeAnswer"
              @checkCorrectResponse="checkCorrectResponse"
            />
          </div>
        </template>
        <template #footer>
          <button
            class="btn btn-danger outline"
            @click="$emit('closeShowModal')"
          >
            Cancelar
          </button>
          <button class="btn btn-primary" type="submit">Guardar</button>
        </template>
      </modal-component>
    </form>
  </div>
</template>

<script>
import RepositoryButton from "@/js/components/repository_explorer/RepositoryButton";
import HtmlEditor from "@/js/components/html_editor/HtmlEditor";
import SuggesterScopeMixin from "@/js/components/ticket_editor/tabs/element-tabs/SuggesterScopeMixin";
import { mapActions, mapGetters } from "vuex";
import AnswersComponent from "@/js/components/question_table/AnswersComponent";

export default {
  name: "EditQuestionModal",
  components: { RepositoryButton, HtmlEditor, AnswersComponent },
  mixins: [SuggesterScopeMixin],
  props: {
    questionTypes: { type: Array, required: true },
    competenceDomains: { type: Array, required: true },
    levels: { type: Array, required: true },
    question: { required: true },
    questionPosition: { required: true },
    lang: { type: String },
    showModal: { type: Boolean, required: true },
  },
  async created() {
    this.showModalHandle = this.showModal;

    if (this.question !== null) {
      this.questionData = this.question;
      this.selectedImage = this.question.image_id;
      this.video_url = this.question.video;
    } else {

      let answers = [];

      for (let i = 1; i <= this.numberAnswersAttitudeAndSkills; i++) {
        answers.push({
          numeration: null,
          id: i,
          text: null,
          correct: false,
          value: i,
        });
      }

      this.questionData = {
        name: "",
        explanation: "",
        description: "Solo puedes elegir una respuesta",
        competence_domain_id: null,
        question_type_id: 1,
        level_id: null,
        answers
      };
    }
  },
  data() {
    return {
      questionData: {},
      multimedia: null,
      video_url: null,
      selectedImage: null,
      showModalHandle: false,
      numberAnswersAttitudeAndSkills: 3,
    };
  },
  computed: {
    ...mapGetters("Repository", {
      allowedMimeTypes: "getMimeTypeImages",
    }),
    ...mapGetters("ticketEditor", {
      activeElement: "activeElement",
      availableImageVariables: "availableImageVariables",
    }),
  },
  watch: {
    video_url(value) {
      if (value !== null) {
        this.questionData.video = value;
        this.questionData.image_id = null;
        this.multimedia = "video";
      }
    },
    selectedImage: {
      handler: function (value) {
        if (value !== null) {
          this.setImage(value);
        }
      },
      immediate: true,
      deep: true,
    },
    activeElement: {
      handler: function (value) {
        this.selectedImage = value;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions("ticketEditor", {
      onEditorLoading: "onEditorLoading",
      offEditorLoading: "offEditorLoading",
    }),
    addAnswer() {
      this.questionData.answers.push({
        numeration: null,
        id: this.questionData.answers[this.questionData.answers.length - 1]
          ? this.questionData.answers[this.questionData.answers.length - 1].id + 1
          : 1,
        text: null,
        correct: false,
        value: ''
      });
    },
    removeAnswer(index) {
      this.questionData.answers.splice(index, 1);
    },
    saveQuestion() {
      if(this.questionData.question_type_id !== 3 && this.questionData.answers.length !== this.numberAnswersAttitudeAndSkills) {
        this.$swal.fire({
          title: "Error",
          text: "Las preguntas de tipo Actitudes y Habilidades deben tener 3 respuestas",
          icon: "error",
        });
        return;
      }

      if (this.question !== null) {
        this.$emit("editQuestion", this.questionData, this.questionPosition);
      } else {
        this.$emit("newQuestion", this.questionData);
      }
    },
    checkCorrectResponse(answer) {
      answer.correct = true;
      this.questionData.answers.map((a) => {
        if (a.id !== answer.id) {
          a.correct = false;
        }
      });
    },
    removeVideo() {
      this.questionData.video = null;
      this.video_url = null;
      this.multimedia = null;
    },
    async getFiles(ids) {
      this.onEditorLoading();
      try {
        let response = await axios.get(
          route("API.repository.getFiles", { files: ids })
        );
        this.filterFiles(response.data);
      } catch (error) {
        console.log(error);
      }
      this.offEditorLoading();
    },
    filterFiles(data) {
      this.listFiles = data.files;
    },
    async setImage(img) {
      await this.getFiles(img);
      if (this.listFiles.length === 0) return;
      this.multimedia = "image";
      this.questionData.image_id = this.listFiles[0].id;
      this.questionData.video = null;
    },
    clearValue() {
      this.multimedia = null;
    },
  },
};
</script>

<style>
.answer-id-input {
  max-width: 6%;
}
</style>
