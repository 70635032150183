var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-12 my-4" }, [
    _c("input", {
      attrs: { type: "hidden", name: "mainBlockData" },
      domProps: { value: _vm.jsonMainBlockData }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "m-4" }, [
      _c("div", { staticClass: "form-group mb-4" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Subtitular ")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.mainBlockData.secondaryText,
              expression: "mainBlockData.secondaryText"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.mainBlockData.secondaryText },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.mainBlockData, "secondaryText", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.lang
          ? _c("span", [_vm._v(_vm._s(_vm.mainBlockDefault.secondaryText))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group mb-4 trumbowyg-small" },
        [
          _c("label", { staticClass: "form-label" }, [_vm._v("Titular")]),
          _vm._v(" "),
          _c("trumbowyg", {
            style: "height: 120px;",
            attrs: { config: _vm.configs },
            on: {
              input: function($event) {
                return _vm.$emit("input", _vm.mainBlockData.mainText)
              }
            },
            model: {
              value: _vm.mainBlockData.mainText,
              callback: function($$v) {
                _vm.$set(_vm.mainBlockData, "mainText", $$v)
              },
              expression: "mainBlockData.mainText"
            }
          }),
          _vm._v(" "),
          _vm.lang
            ? _c("span", {
                domProps: { innerHTML: _vm._s(_vm.mainBlockDefault.mainText) }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-4 row" }, [
        _c("div", { staticClass: "col" }, [
          _c("label", { staticClass: "form-label" }, [
            _vm._v("Texto de botón")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mainBlockData.buttonText,
                expression: "mainBlockData.buttonText"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.mainBlockData.buttonText },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.mainBlockData, "buttonText", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.lang
            ? _c("span", [_vm._v(_vm._s(_vm.mainBlockDefault.buttonText))])
            : _vm._e()
        ]),
        _vm._v(" "),
        !_vm.lang
          ? _c(
              "div",
              { staticClass: "col" },
              [
                _c("label", [_vm._v("Imagen")]),
                _vm._v(" "),
                _c("repository-button", {
                  attrs: {
                    "allowed-mime-types": _vm.allowedMimeTypes,
                    "short-version": true,
                    "class-field": "col-md-12 px-0",
                    field: "mainImageId",
                    "group-id": "1",
                    "group-type": "App\\Models\\Rep\\FileGroup"
                  },
                  model: {
                    value: _vm.mainBlockData.mainImageId,
                    callback: function($$v) {
                      _vm.$set(_vm.mainBlockData, "mainImageId", $$v)
                    },
                    expression: "mainBlockData.mainImageId"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }