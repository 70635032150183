<template>
    <div class="row">
        <div class="col">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                {{ this.title }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <div class="table-responsive">
                        <table class="table table-hover m-table">
                            <tbody>
                            <DetailRow v-for="(element) in this.details" :element="element" :key="element.id"/>
                            </tbody>
                        </table>
                    </div>
                    <a :href="editRoute" class="btn btn-block btn-primary" v-if="this.permits.update" id="edit-route">
                        <i class="fa fa-fw fa-pencil"></i> Editar
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import DetailRow from './DetailRow.vue'

  export default {
    name: 'detail-view',
    components: {
      DetailRow,
    },
    computed: {
      editRoute () {
        return route(`${this.route_prefix}.edit`, [this.entity])
      },
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      entity: {
        type: Object,
        required: true,
      },
      route_prefix: {
        type: String,
        required: true,
      },
      permits: {
        type: Object,
        required: false,
        default: false,
      },
      details: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
  }
</script>

<style type="scss" scoped>
    .tab-content {
        border: 1px solid #ddd;
        border-top: 0;
        padding: 10px;
    }

    .nav-tabs {
        margin-bottom: -1px;
    }
</style>