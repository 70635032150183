<template>
    <div class="m-form portlet__no-padding__top portlet__force-padding__sides">
        <transition name="fade">
            <div class="m-portlet__body">
                <input type="hidden" ref="savePoliciesField" :value="resolvedPermissions" name="permissions">
                <input type="hidden" ref="savePermissionsField" :value="resolvedPolicies" name="policies">

                <permissions-manager
                        v-if="selectedRole || selectedUser"
                        :role="selectedRole"
                        :user="selectedUser"
                        :sections="sections"
                        :entities="entities"
                        :permissions="permissions"
                        :permission-types="permissionTypes"
                        :userRoleInfo="userRoleInfo"
                        :nbReadOnly="nbReadOnly"
                />
            </div>
        </transition>
    </div>
</template>

<script>
  import permissionsManager from './PermissionsManagerComponent.vue'
  import { EventBus } from '@/js/vue/services/event-bus'

  export default {
    name: 'permissions-component',
    data: () => {
      return {
        selectedRole: null,
        selectedUser: null,
        selectedUserRole: null,
        sections: null,
        permissions: null,
        userRoleInfo: null,
        isSaving: false,
      }
    },
    props: {
      roles: Array,
      entities: Array,
      permissionTypes: Array,
      user: Object,
      role: Object,
    },
    computed: {
      resolvedPolicies () {
        $(this.$refs.savePoliciesField).trigger('change')
        return JSON.stringify(this.permissions)
      },
      resolvedPermissions () {
        $(this.$refs.savePermissionsField).trigger('change')
        return JSON.stringify(this.sections)
      },
    },
    components: {
      permissionsManager,
    },
    created () {
      EventBus.$on('sectionUpdated', (sectionId) => {
        function traverseSections (sections, toggleGrants) {
          for (let section of sections) {
            if (section.id === sectionId) {
              toggleGrants(section, true)
              break
            } else {
              if (section.children.length > 0) {
                traverseSections(section.children, toggleGrants)
              }
            }
          }
        }

        traverseSections(this.sections, this.toggleGrants)
      })

      EventBus.$on('permissionUpdated', (entityId, permissionTypeId) => {
        const entityPermits = this.permissions.find(p => p.id === entityId)
        const grants = entityPermits.permits.find(p => p.id === permissionTypeId)
        this.toggleGrants(grants)
      })
    },
    methods: {
      toggleGrants (grants, checkChildren = false) {
        let newStatus = null
        let currentStatus = grants.granted === null ? null : !!grants.granted
        if (currentStatus === true) {
          newStatus = false
        } else if (currentStatus === null) {
          newStatus = true
        }

        grants.granted = newStatus

        if (checkChildren && !grants.granted && grants.children.length > 0) {
          function disableChildren (grants) {
            for (let grant of grants) {
              grant.granted = null

              if (grant.children.length > 0) {
                disableChildren(grant.children)
              }
            }
          }

          disableChildren(grants.children)
        }
      },
      setRole (role) {
        if (role) {
          this.sections = role.authorizations_tree
          this.permissions = role.policies
          this.selectedUser = null
          this.selectedUserRole = null
          this.selectedRole = role
          this.userRoleInfo = null
        }
      },
      setUser (user) {
        if (user) {
          const selectedUser = user
          const selectedUserRole = this.roles.find(r => r.id === selectedUser.role_id)

          this.sections = user.authorizations_tree
          this.permissions = user.policies
          this.selectedUser = selectedUser
          this.selectedRole = null

          this.userRoleInfo = {
            role: selectedUserRole,
            sections: selectedUserRole.authorizations_tree,
            permissions: selectedUserRole.policies,
          }
        }
      },
    },
    mounted () {
      if (this.role) {
        this.setRole(this.role)
      }

      if (this.user) {
        this.setUser(this.user)
      }
    },
  }
</script>


<style lang="scss">
    .fa-circle {
        font-size: 0.4em;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .state-icon {
        cursor: pointer;
        font-size: 1.7em;
        vertical-align: middle;
    }

    .js-no-value {
        opacity: 0.7;
    }

    .role-status {
        position: absolute;
        opacity: 0.7;
        cursor: help;
        font-size: 0.85em;

        &.js-no-value {
            opacity: 0.3;
        }
    }

    .c-pointer {
        cursor: pointer;
    }
</style>
