<template>
    <div>
        <div class="d-flex flex-column">
            <div class="form-group w-100">
                <label for="displayValue"> Nivel de corrección de errores
                    <i class="fa fa-fw fa-info-circle"
                       v-tooltip title="Mayor nivel admite mayor deterioro, pero requiere de más espacio"></i>
                </label>
                <select class="form-select form-select-solid form-select-sm" id="displayValue"
                        :value="activeElement.qr.errorCorrectionLevel"
                        @input="updateQRCode($event, 'errorCorrectionLevel')"
                >
                    <option value="L">L (Tolerancia ~7%)</option>
                    <option value="M">M (Tolerancia ~15%)</option>
                    <option value="Q">Q (Tolerancia ~25%)</option>
                    <option value="H">H (Tolerancia ~30%)</option>
                </select>
            </div>

            <div class="form-group w-100 mb-1">
                <label for="elementContent">Contenido almacenado en QR</label>
                <div class="position-relative">
                    <input id="elementContent" type="text"
                           class="inputField form-control form-control-sm"
                           :disabled="true"
                           value="${codigo_barras}"/>
                </div>
<!--                <ValueSuggester v-on:layoutChange="layoutChange"/>-->
            </div>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters, mapMutations } from 'vuex'
  import ValueSuggester from '@/js/components/ticket_editor/tabs/element-tabs/ValueSuggester'

  export default {
    name: 'QRCodeFieldProperties',
    components: { ValueSuggester },

    computed: {
      ...mapGetters('ticketEditor', {
        activeElement: 'activeElement',
      }),
    },

    methods: {
      ...mapMutations('ticketEditor', {
        updateQRProperties: 'updateQRProperties',
      }),

      updateQRCode (event, prop) {
        this.updateQRProperties({ prop, value: event.target.value })
        this.layoutChange()
      },

      layoutChange () { this.$eventBus.$emit('layoutChange') },
    },
  }
</script>

<style scoped>
    label {
        font-size: 0.9rem !important;
        margin-bottom: 0.4rem !important;
    }
</style>
