import VCalendar from 'v-calendar'
import VuePaginate from 'vue-paginate'
import Vue from 'vue'

export default function loadPlugins () {
  Vue.use(VCalendar, {
    firstDayOfWeek: 2, // Monday
  })

  Vue.use(VuePaginate)
}
