<template>
    <div class="form-group m-form__group col-12 my-4 accordion" id="kt_accordion_1">
        <div v-for="(answer, i) of answers" :key="i" class="accordion-item">
            <h2 class="accordion-header " :id="'kt_accordion_1_header_' + i">
                <button
                    :class="['accordion-button', 'text-dark', 'fs-5', 'fw-semibold', 'p-2', { 'collapsed': (i !== answers.length - 1) }]"
                    type="button" data-bs-toggle="collapse" :data-bs-target="'#kt_accordion_1_body_' + i"
                    aria-expanded="true" aria-controls="kt_accordion_1_body_1">
                    <div class="d-flex justify-content-between w-100">
                        <div class="mt-4">Respuesta {{ i + 1 }}</div>
                        <div class="float-end d-flex justify-content-between">
                            <div class="me-4" v-if="questionType === 3 && !lang">
                                <i @click.stop.prevent="$emit('checkCorrectResponse', answer)"
                                    :class="`fa fa-2x answer-icon ${getCorrectAnswer(answer)}`"></i>
                            </div>
                            <button v-if="!lang" class="btn btn-icon btn-danger btn-sm m-2 me-5"
                                @click.prevent="$emit('removeAnswer', i)"><i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </button>
            </h2>
            <div :id="'kt_accordion_1_body_' + i"
                :class="['accordion-collapse collapse', { 'show': (i === answers.length - 1) }]"
                :aria-labelledby="'kt_accordion_1_header_' + i" data-bs-parent="#kt_accordion_1">
                <div class="accordion-body">
                    <div class="m-4">
                        <div class="form-group mb-4">
                            <label class="form-label">Identificador</label>
                            <input type="text" class="form-control" v-model="answer.numeration" />
                        </div>
                        <div class="form-group mb-4 trumbowyg-small">
                            <label class="form-label">Contenido</label>
                            <html-editor :field="'editor-answer-' + '-' + i" v-model="answer.text" :height="100" />
                            <span v-if="lang" v-html="answer.default_text"></span>
                        </div>
                        <div v-if="questionType !== 3 && !lang" class="form-group mb-4">
                            <label class="form-label">Valor</label>
                            <select class="form-select" v-model="answer.value" required>
                                <option selected value="">Seleccione una opción</option>
                                <option :key="questionValue" v-for="questionValue in questionValues" :value="questionValue"
                                :disabled="answers.map(answer => answer.value).includes(questionValue)">
                                    {{ questionValue }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="(((questionType !== 3) && answers.length < numberAnswersAttitudeAndSkills) ||
            (questionType === 3 && answers.length < 5)) && !lang" 
            type="button" class="btn btn-secondary mt-9 mb-4" @click.stop="$emit('addAnswer')">
                Añadir respuesta
            <i class="fa fa-plus-circle ms-3"></i>
        </button>
    </div>
</template>

<script>
import HtmlEditor from "@/js/components/html_editor/HtmlEditor";

const valuesOptions = [1, 2, 3];

export default {
    name: "AnswersComponent",
    components: {
        HtmlEditor,
    },
    props: {
        lang: String,
        questionType: Number,
        answers: Array,
        numberAnswersAttitudeAndSkills: Number,
    },
    data() {
        return {
            dataAnswers: [],
            questionValues: valuesOptions,
        };
    },
    created() {
        this.dataAnswers = this.answers
    },
    methods: {
        getCorrectAnswer(answer) {
            if (answer.correct) {
                return "fa-check-circle text-success js-checked";
            } else {
                return "fa-times-circle text-danger js-unchecked";
            }
        },
    }
};
</script>

<style>
.answer-icon {
    cursor: pointer;
    font-size: 2.25em;
    vertical-align: middle;
    margin-top: 0.7rem;
}
</style>
