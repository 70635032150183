<template>
    <div>
        <div :class="`click-prevent-layer ${savingLayerClass}`"></div>

        <div class="row">
            <div class="col">
                <p class="permissions-manager__intro" v-if="role">
                    {{ 'permission_component.grantsForRole' | trans }} <strong>{{ role.visible_name }}</strong>
                </p>
                <div v-if="user">
                    <small class="text-muted pull-right">
                        <i class="fa fa-fw fa-info-circle"></i>
                        {{ 'permission_component.showInheritedGroupGrants' | trans }}
                        <strong>{{ userRoleInfo.role.visible_name }}</strong>
                    </small>
                    <p>{{ 'permission_component.grantsForUser' | trans }} <strong>{{ user.name }}</strong></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 permissions-manager__access">
              <strong class="permissions-manager__title">{{ 'permission_component.specialGrants' | trans }}</strong>
              <span>
              <div class="m-input-icon m-input-icon--right position-relative">
                    <input autocomplete="off" type="text" name="special_search"
                           class="pe-10 form-control form-control-solid" placeholder="Buscar"
                           v-model="specialSearch" @input.prevent.stop="performSpecialSearch"
                           id="special-search">
                  <div class="position-absolute translate-middle-y top-50 end-0 me-3">
                      <span class="svg-icon"> <i class="fa fa-fw fa-search fa-lg"></i> </span>
                  </div>
                </div>
              </span>
              <div class="permission-siblings">
                <table class="table table-hover">
                  <special-permission-item
                      v-for="(specialPermission, index) in specialSearchResults"
                      @specialPermissionUpdated="specialPermissionUpdated(specialPermission.id)"
                      :user-role-special-permission="userRoleInfo ? userRoleInfo.specialPermissions[index] : null"
                      :special-permission="specialPermission"
                      :index="`special_0_${index}`"
                      :key="'special-' + specialPermission.id"
                      :depth="0"
                      :nbReadOnly="nbReadOnly"/>
                </table>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { EventBus } from '@/js/vue/services/event-bus'
  import Fuse from "fuse.js";
  import SpecialPermissionItem from "@/js/components/permissions/SpecialPermissionItemComponent";

  export default {
    name: 'special-permissions-manager',
    components: {
      SpecialPermissionItem,
    },
    data () {
      return {
        specialSearch: null,
        specialSearchResults: [],
      }
    },
    mounted () {
      this.specialSearchResults = this.specialPermissions
    },
    methods: {
      specialPermissionUpdated (specialPermissionId) {
        EventBus.$emit('specialPermissionUpdated', specialPermissionId)
      },
      performSpecialSearch () {
        if (!this.specialSearch || !this.specialSearch.length) {
          this.specialSearchResults = this.specialPermissions
        } else {
          this.specialSearchResults = this.searchText(this.specialPermissions, this.specialSearch, ['name', 'method_name'])
        }
      },
      searchText (list, text, keys) {
        return (new Fuse(list, {
          shouldSort: false,
          keys: keys,
          threshold: 0.3,
        })).search(text)
      },
    },
    computed: {
      savingLayerClass () {
        return this.isSaving ? 'prominent' : ''
      },
    },
    props: {
      role: {default: null, validator: value => value instanceof Object,},
      user: {default: null, validator: value => value instanceof Object,},
      specialPermissions: {default: null, validator: value => value instanceof Array,},
      userRoleInfo: {default: null, validator: value => value instanceof Object,},
      isSaving: {default: false, validator: value => typeof value === 'boolean',},
    },
  }
</script>

<style lang="scss" scoped>
a[aria-expanded] {
  text-decoration: none !important;
}

a[aria-expanded="false"] {
  .fa-angle-down {
    display: none !important;
  }

  .fa-angle-right {
    display: inline-block !important;
  }
}

a[aria-expanded="true"] {
  .fa-angle-down {
    display: inline-block !important;
  }

  .fa-angle-right {
    display: none !important;
  }
}

    table th, table td {
        overflow: hidden;
    }

    .list-group.list-group-root .list-group {
        margin-bottom: 0;
    }

    .list-group.list-group-root .list-group-item {
        border-radius: 0;
        border-width: 1px 0 0 0;
    }

    .list-group.list-group-root > .list-group-item:first-child {
        border-top-width: 1px;
    }

    .list-group.list-group-root {
        padding: 0;
        overflow: hidden;
    }

    .click-prevent-layer.prominent {
        background-color: #fff;
        opacity: 0;
        height: 100%;
        width: 100%;
        z-index: 5;
        left: 0;
        position: absolute;
        top: 0;
        cursor: wait;
        pointer-events: all;
    }

    .click-prevent-layer {
        z-index: -1;
    }
</style>
