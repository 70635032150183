<template>
    <span>
        <em :class="customerClass" class="fa fa-user-circle mr-1"
            v-tooltip :title="customerTooltip" v-if="customer"></em>
        <a :href="customerPath" v-if="customer">
            {{customer.fullName}}
        </a>
    </span>
</template>

<script>
    export default {
        name: 'customer-name',
        props: {
            customer: {type: Object},
            section: {type: String, default: 'customers'},
            entityId: {type: Number, default: null}
        },
        computed: {
            customerPath () {
              let edit = this.nbReadOnly ? '' : '/edit'
                if (this.entityId) {
                    return window.location.pathname + '/' + this.entityId +  edit
                }
                return window.location.pathname + '/' + this.customer.id +  edit
            },
            customerUnsubscribed () {
                return this.customer && !this.customer.enabled && this.customer.unsubscription_date
            },
            customerClass () {
                return this.customerUnsubscribed ? 'text-danger' : 'text-success'
            },
            customerTooltip () {
                return this.customerUnsubscribed ? 'Dado de baja' : 'Activo'
            }
        },
    }
</script>

<style lang="scss">
</style>
