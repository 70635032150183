<template>
    <div class="form-group m-form__group col-md-4">

        <label class="form-check form-switch form-check-custom form-check-solid">
            <input type="checkbox" :checked="sameHomeAndBillingAddress"
                   class="form-check-input" name="same_home_and_billing_address"
                   :disabled="nbReadOnly" id="same_home_and_billing_address"
                   v-model="sameHomeAndBillingAddress" value="1" />
            <span class="form-check-label">La dirección de facturación es la misma que la habitual</span>
        </label>

    </div>
</template>

<script>
  export default {
    name: 'customer-addresses-component',
    props: {
      oldSameHomeAndBillingAddress: {
        type: Boolean,
        default: false,
      }
    },
    data () {
      return {
        sameHomeAndBillingAddress: false,
      }
    },
    created () {
      this.sameHomeAndBillingAddress = this.oldSameHomeAndBillingAddress
    },
    watch: {
      sameHomeAndBillingAddress (val) {
        if (val) {
          this.hideBillingAddress()
        } else {
          this.showBillingAddress()
        }
      }
    },
    methods: {
      hideBillingAddress () {
        $('.billing_country_id').hide()
        $('.billing_city').hide()
        $('.billing_postcode').hide()
        $('.billing_address_line_1').hide()
        $('.billing_address_line_2').hide()
      },
      showBillingAddress () {
        $('.billing_country_id').show()
        $('.billing_city').show()
        $('.billing_postcode').show()
        $('.billing_address_line_1').show()
        $('.billing_address_line_2').show()
      }
    }
  }
</script>
