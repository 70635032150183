var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table table-row-bordered" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "tbody",
      [
        _vm._l(_vm.metas, function(tag, i) {
          return _vm.metas.length > 0
            ? _c("tr", { staticClass: "tag-row" }, [
                _c("td", { staticClass: "align-middle" }, [
                  _c("div", { staticClass: "input-group input-group-solid" }, [
                    _c("div", { staticClass: "input-group-prepend" }, [
                      _c(
                        "span",
                        {
                          staticClass: "btn btn-secondary",
                          staticStyle: { "min-width": "80px" },
                          attrs: { title: "Tipo de propiedad" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.toggleType(tag)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(tag.type) +
                              "\n                "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: tag.meta,
                          expression: "tag.meta"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        disabled: _vm.nbReadOnly,
                        title: "Meta",
                        id: "meta-" + i + "-" + tag.meta
                      },
                      domProps: { value: tag.meta },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(tag, "meta", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "align-middle" }, [
                  _c("div", { staticClass: "input-group input-group-solid" }, [
                    !tag.choices
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: tag.value,
                              expression: "tag.value"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            disabled: _vm.nbReadOnly,
                            title: "Valor",
                            id: "tag-choice-" + i + "-" + tag.meta
                          },
                          domProps: { value: tag.value },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(tag, "value", $event.target.value)
                            }
                          }
                        })
                      : _c(
                          "div",
                          { staticClass: "form-control p-0" },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: tag.choices,
                                id: "add-tag-" + i + "-" + tag.meta,
                                disabled: _vm.nbReadOnly,
                                taggable: true
                              },
                              on: {
                                tag: function($event) {
                                  return _vm.addTag(tag, $event)
                                }
                              },
                              model: {
                                value: tag.value,
                                callback: function($$v) {
                                  _vm.$set(tag, "value", $$v)
                                },
                                expression: "tag.value"
                              }
                            })
                          ],
                          1
                        ),
                    _vm._v(" "),
                    !_vm.nbReadOnly
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: {
                              type: "button",
                              id: "add-meta-" + i + "-" + tag.meta
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.addMeta(_vm.metas, i)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-plus-circle" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.nbReadOnly
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            attrs: {
                              type: "button",
                              id: "remove-meta-" + i + "-" + tag.meta
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.removeMeta(_vm.metas, i)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-trash" })]
                        )
                      : _vm._e()
                  ])
                ])
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        !_vm.metas.length
          ? _c("tr", [
              _c(
                "td",
                { staticClass: "text-center", attrs: { colspan: "2" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-block btn-primary",
                      attrs: { href: "#", id: "add-meta-prop" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.addMeta(_vm.metas)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-fw fa-plus-circle" }),
                      _vm._v(" Añadir nueva propiedad\n            ")
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "col-3" }, [_vm._v("Propiedad")]),
        _vm._v(" "),
        _c("th", [_vm._v("Valor")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }