<template>
    <div class="col-12">
        <modal-component
                :theRef="'modalReset'"
                :visible.sync="showModal"
                :backdrop="true"
                :classesFooter="['text-right']"
                :classesModalDialog="['modal-dialog-centered modal-lg']"
        >
            <template #header>
                {{ 'forms_shippings.reset' | trans }}
            </template>
            <template>
                <div
                        class="m-alert m-alert--icon m-alert--outline alert alert-danger alert-dismissible fade show mb-0"
                        role="alert">
                    <div class="m-alert__icon">
                        <i class="la la-warning"></i>
                    </div>
                    <div class="m-alert__text">
                        {{ 'forms_shippings.reset_message' | trans }}
                    </div>
                </div>
            </template>
            <template #footer>
                <button type="button" class="btn btn-danger" @click="sendReset()" id="reset">
                    <i class="fa fa-power-off mr-1"></i>
                    {{ 'forms_shippings.reset_button' | trans }}
                </button>
            </template>
        </modal-component>

        <div class="m--padding-top-15 m--padding-right-25 m--padding-left-25" v-if="resetMessage">
            <div class="m-alert m-alert--icon alert alert-success alert-dismissible fade show mb-0" role="alert">
                <div class="m-alert__icon">
                    <i class="la la-check-circle"></i>
                </div>
                <div class="m-alert__text">
                    {{ 'forms_shippings.reset_success' | trans }}
                </div>
                <div class="m-alert__close">
                    <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close" id="dismiss"
                            @click="hiddeMessage()">
                    </button>
                </div>
            </div>
        </div>
        <div class="m--padding-top-15 m--padding-right-25 m--padding-left-25">
            <div class="row">
                <div class="col-6 text-left">
                    <h4 class="mb-0 mt-2">{{ 'forms_shippings.title' | trans }}</h4>
                </div>
                <div class="col-6 text-right">
                    <button type="button" class="btn" id="reset-ship"
                            :class="{'btn-outline-warning' : resetActive, 'btn-outline-metal' : !resetActive}"
                            :disabled="!resetActive || nbReadOnly" @click="showModal = true">
                        <i class="fa fa-power-off mr-1"></i>
                        {{ 'forms_shippings.reset' | trans }}
                    </button>
                </div>
            </div>
            <div class="m-separator m-separator--dashed m-separator--lg my-3"></div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-responsive m--padding-left-25 m--padding-right-25" id="shippings_list">
                    <table class="table m-table m-table--head-bg-light">
                        <thead>
                        <tr>
                            <th>{{ 'forms_shippings.id' | trans }}</th>
                            <th>{{ 'forms_shippings.sendgrid_campaign' | trans }}</th>
                            <th>{{ 'forms_shippings.sendgrid_list_id' | trans }}</th>
                            <th>{{ 'forms_shippings.from' | trans }}</th>
                            <th>{{ 'forms_shippings.active' | trans }}</th>
                            <th>{{ 'forms_shippings.date_send' | trans }}</th>
                            <th>{{ 'forms_shippings.date_update' | trans }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="shipping in shippings">
                            <td>{{ shipping.id }}</td>
                            <td>{{ shipping.sendgrid_campaign_id }}</td>
                            <td>
                                <span v-if="shipping.sendgrid_list_id">
                                    {{ shipping.sendgrid_list_id }}
                                </span>
                                <span class="m--font-metal" v-if="!shipping.sendgrid_list_id">
                                    {{ 'forms_shippings.not_list' | trans }}
                                </span>
                            </td>
                            <td>
                                <span class="m-badge m-badge--info m-badge--wide" v-if="shipping.from == from.command">
                                    <i class="fa fa-fw fa-clock-o mr-1"></i>{{ 'forms_shippings.from_command' | trans }}
                                </span>
                                <span class="m-badge m-badge--success m-badge--wide" v-if="shipping.from == from.panel">
                                    <i class="fa fa-fw fa-desktop mr-1"></i>{{ 'forms_shippings.from_panel' | trans }}
                                </span>
                                <span class="m-badge m-badge--metal m-badge--wide"
                                      v-if="shipping.from == from.panel_test">
                                    <i class="fa fa-fw fa-at mr-1"></i>{{ 'forms_shippings.from_panel_test' | trans }}
                                </span>
                            </td>
                            <td>
                                <i class="fa fa-fw fa-check m--font-info" v-if="shipping.is_active"></i>
                                <i class="fa fa-fw fa-times-circle m--font-danger" v-if="!shipping.is_active"></i>
                            </td>
                            <td>{{ formatDate(shipping.created_at) }}</td>
                            <td>
                                <span v-if="shipping.updated_at">{{ formatDate(shipping.updated_at) }}</span>
                                <span v-if="!shipping.updated_at" class="m--font-metal">
                                    {{ 'forms_shippings.no_update' | trans }}</span>
                            </td>
                        </tr>
                        <tr v-if="shippings.length <= 0">
                            <td colspan="7" class="text-center bg-light">
                                <div class="alert m-alert--default text-center m--padding-50" role="alert">
                                    {{ 'forms_shippings.list_empty' | trans }}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12" v-show="shippings.length > 0 && paginate.last_page > 1">
                <div class="pull-right">
                    <ul role="navigation" class="pagination">
                        <li class="page-item" :class="{'disabled' : page == 1}">
                            <span class="page-link" @click="setPage(--page)" id="previous-page">‹</span>
                        </li>
                        <li class="page-item" v-for="page in countPaginate"
                            :class="{'active' : page == paginate.current_page}">
                            <span class="page-link" @click="setPage(page)" id="set-page">
                                {{ page }}
                            </span>
                        </li>
                        <li class="page-item" :class="{'disabled' : page == paginate.last_page}">
                            <span class="page-link" @click="setPage(++page)" id="next-page">›</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'shippings',
    props: {
      campaignId: { Number },
      from: {
        Object, default: () => ({
          command: 1,
          panel: 2,
          panel_test: 3
        })
      }
    },
    data () {
      return {
        list: [],
        paginate: {},
        page: 1,
        loadingActive: false,
        resetActive: false,
        resetMessage: false,
        listId: '#shippings_list',
        showModal: false,
      }
    },
    mounted () {
      this.$nextTick(() => {
        setTimeout(this.listShippings, 500)
      })
    },
    computed: {
      shippings () {
        return this.list
      },
      countPaginate () {
        let pages = []

        for (let p = 1; p <= this.paginate.last_page; p++) {
          pages.push(p)
        }

        return pages
      },
    },
    methods: {
      loading () {
        this.loadingActive = true
        mApp.block(this.listId, {})
      },
      unLoading () {
        mApp.unblock(this.listId)
        this.loadingActive = false
      },
      closeModal () {
        this.showModal = false
      },
      hiddeMessage () {
        setTimeout(() => {this.resetMessage = false}, 1000)
      },
      async sendReset () {
        this.resetActive = false
        this.closeModal()
        this.loading()
        let response = await axios.post(route('API.campaigns.reset-shippings'), {
          campaign: this.campaignId
        })
        if (response.data) {
          this.processData(response.data)
        } else {
          this.resetActive = true
        }
        this.unLoading()
      },
      formatDate (date) {
        return moment(date).format('DD/MM/YYYY HH:mm:ss')
      },
      setPage (page) {
        if (this.loadingActive === false) {
          this.page = page
          this.listShippings()
        }
      },
      processData (data) {
        this.list = data.shippings
        this.paginate = data.paginate
        if (data.actives > 0) {
          this.resetActive = true
        }
        if ('reset' in data) {
          this.resetMessage = true
          this.resetActive = false
        }
      },
      async listShippings () {
        this.resetMessage = false
        this.loading()
        let response = await axios.post(route('API.campaigns.shippings'), {
          campaign: this.campaignId,
          page: this.page
        })
        if (response.data) {
          this.processData(response.data)
        }
        this.unLoading()
      }
    }
  }
</script>
