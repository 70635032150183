<template>
    <div class="m-form portlet__no-padding__top portlet__force-padding__sides">
        <transition name="fade">
            <div class="m-portlet__body">
                <input type="hidden" ref="saveSpecialPermissionsField" :value="resolvedSpecialPermissions"
                       name="specialPermissions">

                <special-permissions-manager
                        v-if="selectedRole || selectedUser"
                        :role="selectedRole"
                        :user="selectedUser"
                        :special-permissions="specialPermissions"
                        :userRoleInfo="userRoleInfo"
                        :nbReadOnly="nbReadOnly"
                />
            </div>
        </transition>
    </div>
</template>

<script>
  import SpecialPermissionsManager from './SpecialPermissionsManagerComponent.vue'
  import { EventBus } from '@/js/vue/services/event-bus'

  export default {
    name: 'special-permissions-component',
    data: () => {
      return {
        selectedRole: null,
        selectedUser: null,
        selectedUserRole: null,
        specialPermissions: null,
        userRoleInfo: null,
        isSaving: false,
      }
    },
    props: {
      roles: Array,
      user: Object,
      role: Object,
    },
    computed: {
      resolvedSpecialPermissions () {
        $(this.$refs.saveSpecialPermissionsField).trigger('change')
        return JSON.stringify(this.specialPermissions)
      },
    },
    components: {
      SpecialPermissionsManager,
    },
    created () {
      EventBus.$on('specialPermissionUpdated', (specialPermissionId) => {
        const specialPermit = this.specialPermissions.find(p => p.id === specialPermissionId)
        this.toggleGrants(specialPermit)
      })
    },
    methods: {
      toggleGrants (grants, checkChildren = false) {
        let newStatus = null
        let currentStatus = grants.granted === null ? null : !!grants.granted
        if (currentStatus === true) {
          newStatus = false
        } else if (currentStatus === null) {
          newStatus = true
        }

        grants.granted = newStatus

        if (checkChildren && !grants.granted && grants.children.length > 0) {
          function disableChildren (grants) {
            for (let grant of grants) {
              grant.granted = null

              if (grant.children.length > 0) {
                disableChildren(grant.children)
              }
            }
          }

          disableChildren(grants.children)
        }
      },
      setRole (role) {
        if (role) {
          this.specialPermissions = role.special_permissions
          this.selectedUser = null
          this.selectedUserRole = null
          this.selectedRole = role
          this.userRoleInfo = null
        }
      },
      setUser (user) {
        if (user) {
          const selectedUser = user
          const selectedUserRole = this.roles.find(r => r.id === selectedUser.role_id)
          this.specialPermissions = user.special_permissions
          this.selectedUser = selectedUser
          this.selectedRole = null

          this.userRoleInfo = {
            role: selectedUserRole,
            specialPermissions: selectedUserRole.special_permissions,
          }
        }
      },
    },
    mounted () {
      if (this.role) {
        this.setRole(this.role)
      }

      if (this.user) {
        this.setUser(this.user)
      }
    },
  }
</script>


<style lang="scss">
    .fa-circle {
        font-size: 0.4em;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .state-icon {
        cursor: pointer;
        font-size: 1.7em;
        vertical-align: middle;
    }

    .js-no-value {
        opacity: 0.7;
    }

    .role-status {
        position: absolute;
        opacity: 0.7;
        cursor: help;
        font-size: 0.85em;

        &.js-no-value {
            opacity: 0.3;
        }
    }

    .c-pointer {
        cursor: pointer;
    }
</style>
