<template>
    <div class="form-group m-form__group col-md-6">
        <div class="d-flex flex-row" v-if="!live">
            <input type="hidden" :name="`${name}_checkbox`" :value="customizeCheckbox ? 1 : 0">
            <div class="p-2">
                <label class="m-checkbox">
                    <input type="checkbox" @click="changeCheckBox()" title="Modificable" :disabled="disabledField"
                           v-model="customizeCheckbox" :id="'customize-check-' + prefix + name"> {{label}}
                    <span></span>
                </label>
            </div>
        </div>
        <label v-else>
            {{label}}
        </label>
        <input type="hidden" :name="`${prefix}[${name}]`" v-model="valueJSON" ref="saveField">
        <div class="row">
            <div class="col-12 p-2">
                <div class="form-group m-form__group  col-md-12">
                    <input type="hidden" v-model="selectAllElems" :name="'all-' + prefix + name"
                           :id="'all-' + prefix + name">
                    <span class="m-switch m-switch--icon">
                        <label>
                            <input :id="'all-' + prefix + name" :name="'all-' + prefix + name" type="checkbox"
                                v-model="selectAllElems" :disabled="disabledField">
                            <span></span>
                        </label>
                        <label class="label-hidden">Incluir todo</label>
                    </span>
                    <label :for="'all-' + prefix + name">Incluir todo</label>
                </div>
            </div>
        </div>
        <div class="row" v-if="!selectAllElems">
            <div class="col-4">
                <multiselect v-model="selectedParent" :options="parents" :multiple="false"
                             :disabled="selectAllElems"
                             track-by="name" label="name" :placeholder="`Seleccionar grupo de ${label}`"></multiselect>
            </div>
            <div class="col-8 col-select-one" :class="{'has-selection': data.length}">
                <multiselect v-model="data" :options="children" :multiple="true" placeholder="Buscar..."
                             :limit="1" :limitText="() => ''" track-by="name"
                             :close-on-select="false"
                             :disabled="selectAllElems || disabledField"
                             :clear-on-select="false"
                             :loading="loading"
                             label="name"
                             class="multiselect--search">
                    <span slot="noResult">No existen elementos</span>
                    <template slot="tag">
                        <span class="badge m-badge py-0 px-2" style="margin-bottom: 3px;">
                            {{
                                allIncludes
                                ? 'Todos incluidos'
                                : `${data.length} elementos seleccionados de ${differentSelectedGroupsCount} eventos.`
                            }}
                        </span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <span v-if="!selectedParent">{{ props.option.name }}</span>
                        <span v-else-if="selectedParent.id === props.option.parent_id">{{ props.option.name }}</span>
                    </template>
                    <template slot="beforeList">
                        <div class="d-flex flex-grow-1 align-content-stretch text-nowrap">
                            <button @click="selectAll" class="btn btn-link select-all w-50" type="button"
                                    :disabled="selectAllElems || disabledField">
                                Seleccionar todo
                            </button>
                            <button @click="removeAll" class="btn btn-link clear-all w-50" type="button"
                                    :disabled="disabledField || !data || data.legnth">
                                Deseleccionar todo
                            </button>
                        </div>
                    </template>
                </multiselect>
                <nb-error-block :path="`filters.${name}`"></nb-error-block>
            </div>
            <div class="col-12">
                <div class="m-alert alert-primary m-alert--outline m-alert--air m-alert--square alert fade show mt-1"
                     role="alert">
                    <div class="m-alert__text">
                        <div class="d-flex">
                            <div class="w-50 pr-1">
                                <button type="button" @click="selectAllComplete"
                                        class="btn btn-block btn-sm btn-primary"
                                        :disabled="allIncludes || selectAllElems || disabledField"
                                >
                                    Añadir todas las sesiones
                                </button>
                            </div>
                            <div class="w-50 pl-1">
                                <button type="button" @click="removeAllComplete"
                                        :disabled="!data || !data.length || disabledField"
                                        class="btn btn-block btn-sm btn-primary">
                                    Quitar todas las sesiones
                                </button>
                            </div>
                        </div>
                        <div class="mb-1" v-if="this.data.length">Selección:</div>
                        <ul class="group-box">
                            <li class="group-box__item d-flex flex-wrap" v-for="group of groupedSelection">
                                <span class="group-box__name">{{ groupName(group[0].parent_id)}}</span>
                                <button v-if="!isGroupVisible(group[0].parent_id)" type="button"
                                        class="ml-2 m-badge m-badge--wide group-box__recount-button"
                                        @click="toggleGroupVisibility(group[0].parent_id)">
                                {{ isGroupComplete(group) ? 'Todos incluidos' : `${group.length} elementos` }}
                                    <span class="fa fa-angle-double-right"></span>
                                </button>
                                <template v-else>
                                    <span class="group-box__opening">(</span>
                                    <span v-for="item of group" class="group-box__open-item">
                                        {{ stylizeGroupItem(item.parent_id, item.name) }}
                                        <span class="fa fa-times p-1 group-box__remove-item"
                                            title="Eliminar elemento" v-tooltip
                                            @click="removeItem(item.id)"></span>
                                    </span>
                                </template>

                                <span v-if="!isGroupVisible(group[0].parent_id)" class="group-closing"></span>
                                <span class="group-box__closing-end" v-else>)</span>
                                <span v-if="isGroupVisible(group[0].parent_id)"
                                    @click="toggleGroupVisibility(group[0].parent_id)"
                                    class="group-box__close-button fa fa-angle-double-left"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import { EventBus } from '@/js/vue/services/event-bus'
    import axios from 'axios'

    export default {
        name: 'list-box-groups',
        props: {
            parents: {type: Array, default: []},
            live: {type: Boolean, default: false},
            prefix: {type: String, default: ''},
            name: {type: String, default: ''},
            label: {type: String, default: ''},
            customizable: {type: Boolean, default: false},
            default_values: {type: Array, default: () => ([])},
            id: {type: Number, default: 0},
            selectAllChildrenAutomatically: {type: Boolean, default: false},
            allowOnlySingleParent: {type: Boolean, default: true},
            ignoreReadOnly: {type: Boolean, default: false},
        },
        data() {
            const values = this.default_values.reduce((acc, option) => acc.concat(option.group), [])
            return {
                selectedParent: null,
                data: values,
                customizeCheckbox: this.customizable,
                visibleGroups: [],
                selectAllElems: !values.length,
                loading: false,
                options: [],
                memo: {},
            }
        },
        created() {
            this.filterDataOnlyParent()
        },
        computed: {
            valueJSON() {
                return JSON.stringify(this.selectAllElems ? null : this.data.map(entity => entity.id))
            },
            children() {
                return _.flatten(
                    this.options
                        .filter(o => !this.selectedParent ? true : o.id === this.selectedParent.id)
                        .map(o => o.group)
                )
            },
            differentSelectedGroupsCount() {
                return _.uniq(this.data.map(o => o.parent_id)).length
            },
            groupedSelection() {
                return _.groupBy(this.data, g => g.parent_id)
            },
            allIncludes() {
                return this.data.length === this.parents.reduce((acc, o) => acc + o.groupLength, 0)
            },
            disabledField() {
                return this.ignoreReadOnly ? false : this.nbReadOnly
            },
        },
        methods: {
            changeCheckBox() {
                this.customizeCheckbox = !this.customizeCheckbox
                EventBus.$emit('filterStatusChange', this.id, this.label, !this.customizeCheckbox)
            },
            selectAll() {
                this.data = _.uniqBy([...this.data, ...this.children], 'id')
            },
            async selectAllComplete() {
                const options = await this.getOptions(this.parents.map(p => p.id))
                this.data = _.flatten(options.map(o => o.group))
            },
            removeAll() {
                this.data = this.data.filter(o => !this.children.includes(o))
            },
            removeAllComplete() {
                this.data = []
            },
            groupName(id) {
                return this.parents.find(o => o.id === id).name
            },

            stylizeGroupItem(group, label) {
                return label.replace(this.groupName(group), '...')
            },

            isGroupVisible(id) {
                return this.visibleGroups.includes(id)
            },
            toggleGroupVisibility(id) {
                if (this.isGroupVisible(id)) {
                    this.visibleGroups = this.visibleGroups.filter(g => g !== id)
                } else {
                    this.visibleGroups.push(id)
                }
            },
            removeItem(id) {
                this.data = this.data.filter(d => d.id !== id)
            },
            filterDataOnlyParent() {
                const parents = _.uniqBy(this.data, 'parent_id').map(e => e.parent_id)
                const last = _.last(parents)
                if (parents.length > 1 && this.allowOnlySingleParent) {
                    this.data = this.data.filter(v => v.parent_id === last)
                }
            },
            isGroupComplete(group) {
                const parent = this.parents.find(o => o.id === group[0].parent_id)
                return parent.groupLength === group.length
            },
            async getOptions(parentIds) {
                const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
                let parentIdsFilter = parentIds.filter(id => !this.memo[id])
                if (parentIdsFilter.length) {
                    this.loading = true
                    await axios.post(
                        route('API.get-group-filter-options'),
                        `filterId=${this.id||null}&parentIds=${parentIdsFilter.join(',')}`,
                        { headers }
                    ).then(response => {
                        response.data.map(o => this.memo[o.id] = o)
                    }).finally(() => this.loading = false)
                }
                const options = _.flatten(parentIds.map(id => this.memo[id]).filter(elem => elem))
                return options
            },
        },
        watch: {
            data() {
                this.filterDataOnlyParent()
            },
            async selectedParent() {
                if (!this.selectedParent)
                    return
                this.options = await this.getOptions([this.selectedParent.id])
                if (this.selectAllChildrenAutomatically) {
                    const children = this.data.concat(this.options.find(v => v.id === this.selectedParent.id).group)
                    this.data = _.uniqBy(children, 'id')
                }
            },
        },
    }
</script>

<style lang="scss">


    .multiselect__strong {
        display: none !important;
    }
</style>
