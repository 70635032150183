<template>
    <div :class="getClassBox" id="ticket-editor-html">
        <input type="hidden" name="ticket" id="ticket" :value="ticketSaveData">
        <input type="hidden" name="elements" id="elements" :value="elementsSaveData">

        <ticket-bar :powerUserLoged="powerUserLoged"></ticket-bar>
        <ticket-edition :powerUserLoged="powerUserLoged"></ticket-edition>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import TicketBar from '@/js/components/ticket_editor/tabs/TicketBar'
  import TicketEdition from '@/js/components/ticket_editor/tabs/TicketEdition'

  export default {
    name: 'TicketEditor',
    components: { TicketEdition, TicketBar },
    props: {
      event: { type: Object, default: () => ({}) },
      events: { type: Array, default: () => ([]) },
      data: {
        type: Object, default: () => ({
          width: 13.97,
          height: 6.20,
          elements: [],
        }),
      },
      classBox: { type: String, default: 'col-12' },
      loadedTicket: { type: Object, default: null },
      loadedElements: { type: Array, default: null },
      powerUserLoged: { type: Number, default: 0 }
    },
    mounted () {
      this.resetValues()
      this.loading = false

      let data

      if (this.data) {
        data = { ...this.data, elements: null }
      } else {
        data = {
          width: 13.97,
          height: 6.20,
          elements: null
        }
      }

      this.ticket = data

      if (this.loadedTicket) {
        this.ticket = Object.assign({ ...this.loadedTicket })
      }

      if (this.data) {
        this.elements = [...this.data.elements]
      }

      if (this.loadedElements) {
        this.elements = [...this.loadedElements]
      }

      // Vue draggable intenta hacer un cálculo sobre el elemento padre antes
      // de que esté cargado. Este timeout evita errores de cálculo.
      this.$nextTick(() => {
        setTimeout(() => {
          this.loading = true
        }, 200)
      })

      if (Object.keys(this.event).length) {
        this.scopeEvent = this.event
      } else {
        this.scopeEvents = [...this.events]
      }
    },

    computed: {
      ...mapGetters('ticketEditor', {
        getTicket: 'ticket',
        getElements: 'elements',
        getScopeEvent: 'getScopeEvent',
        getScopeEvents: 'getScopeEvents',
        getLoadingPreview: 'getLoadingPreview',
        getFullScreen: 'getFullScreen',
      }),

      ticket: {
        get () { return this.getTicket},
        set (ticket) { this.setTicket(ticket) },
      },

      elements: {
        get () { return this.getElements},
        set (elements) { this.setElements(elements) },
      },

      scopeEvent: {
        set (scopeEvent) { this.setScopeEvent(scopeEvent) },
        get () { return this.getScopeEvent },
      },

      scopeEvents: {
        set (scopeEvents) { this.setScopeEvents(scopeEvents) },
        get () { return this.getScopeEvents },
      },

      loading: {
        set (loading) { this.setLoadingPreview(loading) },
        get () { return this.getLoadingPreview },
      },

      ticketSaveData () { return JSON.stringify(this.ticket) },
      elementsSaveData () { return JSON.stringify(this.elements) },

      getClassBox () {
        let list = [this.classBox]

        if (this.getFullScreen) {
          list.push('ticket-editor--fullscreen')
        }

        if (this.nbReadOnly) {
          list.push('ticket-editor--disabled')
        }

        return list.join(' ')
      },
    },

    methods: {
      ...mapMutations('ticketEditor', {
        setTicket: 'setTicket',
        setElements: 'setElements',
        setScopeEvent: 'setScopeEvent',
        setScopeEvents: 'setScopeEvents',
        setLoadingPreview: 'setLoadingPreview',
      }),
      ...mapActions('ticketEditor', {
        resetValues: 'resetValues',
      }),
    },
  }
</script>
