<template>
  <div class="form-group m-form__group col-12">
    <div class="mx-2 mt-4">
        <div class="d-sm-flex justify-content-between">
            <h4 class="d-flex align-items-center mb-5">
                <em class="flaticon-alert mr-1"></em>Listado de notificaciones
                <div v-if="loading" class="m-loader m-loader--info" style="width: 30px; display: inline-block;"></div>
            </h4>
        </div>
      <table class="table table-striped table-bordered m-table">
        <thead>
        <tr>
          <th>Asunto</th>
          <th>Fecha</th>
          <th>Observaciones</th>
          <th width="1%"></th>
        </tr>
        </thead>
        <tbody v-if="notifications.length">
        <tr v-for="notification in notifications">
          <td>
            {{ notification.subject }}
          </td>
          <td>
            {{ formatDate(notification.sent_at) }}
          </td>
          <td>
            {{ notification.extra }}
          </td>
          <td class="align-middle text-center">
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="6" class="text-center py-4">
            Este cliente no tiene notificaciones.
          </td>
        </tr>
        </tbody>
      </table>
      <div class="text-right clearfix" v-if="thereIsData && hasPagination">
        <paginator :current-page="currentPage"
                   :links="paginationLinks"
                   :meta="paginationMeta"
                   :loading-data="loading"
                   :loading-page-number="loadingPageNumber"
                   @set-page="setPageNumber"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import axios from 'axios'
import Paginator from '@/js/components/widgets/Paginator'

export default {
  name: 'customer-notifications',
  components: { Paginator },
  props: {
    customerId: { type: Number, required: true }
  },
  data () {
    return {
      loading: false,
      loadingPageNumber: 1,
      notificationsPagination: null,
      notifications: []
    }
  },
  mounted () {
    this.loadPageNotifications(1)
  },
  computed: {
    thereIsData () {
      return this.notificationsPagination !== null && this.notificationsPagination.hasOwnProperty('data')
          && this.notificationsPagination.data.length > 0
    },
    hasPagination () {
      if (!this.notificationsPagination) return false
      return this.notificationsPagination.next_page_url !== null || this.notificationsPagination.prev_page_url !== null
    },
    currentPage () {
      if (!this.notificationsPagination) return 1
      return this.notificationsPagination.current_page
    },
    paginationLinks () {
      if (!this.notificationsPagination) return {}
      return {
        prev: this.notificationsPagination.links.prev,
        next: this.notificationsPagination.links.next,
      }
    },
    paginationMeta () {
      if (!this.notificationsPagination) return {}
      return {
        current_page: this.notificationsPagination.current_page ? this.notificationsPagination.current_page : this.notificationsPagination.meta.current_page,
        last_page: this.notificationsPagination.last_page ? this.notificationsPagination.last_page : this.notificationsPagination.meta.last_page,
      }
    },
  },
  methods: {
    async loadPageNotifications (page = null) {
      this.loading = true
      try {
        const response = await axios.get(`/api/crm/customer/${this.customerId}/notifications`, {
          params: { page: page ? page : 1 }
        })
        if (response.status === 200) {
          this.notificationsPagination = response.data
          this.notifications = this.notificationsPagination.data
        }
      } catch (e) {
        console.dir(e)
      } finally {
        this.loading = false
      }
    },
    setPageNumber (page) {
      this.loadingPageNumber = page
      this.loadPageNotifications(page)
    },
    formatDate(date) {
      return moment(date).format('LLLL')
    }
  }
}
</script>
