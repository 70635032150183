<template>
    <div class="text-center">
        <img :src="data">
    </div>
</template>

<script>
  import QRCode from 'qrcode'

  export default {
    name: 'qrcode',
    props: {
      qrcode: { type: String, default: null },
      uuid: { type: String },
      endpoint: { type: String },
    },
    data () {
      return {
        data: null,
      }
    },
    watch: {
      uuid: function () {
        this.src()
      },
      endpoint: function () {
        this.src()
      },
    },
    mounted () {
      this.src()
    },
    methods: {
      async src () {
        let config = {
          uuid: this.uuid,
          endpoint: `${this.endpoint}/`,
          url: `${this.endpoint}/devices/${this.uuid}`,
        }
        this.data = await new QRCode.toDataURL(JSON.stringify(config))
      }
    }

  }
</script>
