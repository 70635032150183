import clipboard from 'clipboard-polyfill'

export default {
  filters: {
    /**
     * Realizar truncado de texto obtenido en caso de que supere una longitud dada.
     * @param str
     * @param length
     * @param useWordBoundary < si es true no se cortarán palabras incompletas
     * @param ellipsis < si es true se añadirá el simbolo de elipsis al final de la cadena
     * @returns {*}
     */
    truncate: function (str, length = 30, useWordBoundary = true, ellipsis = true) {
      if (str.length <= length) { return str }
      let subString = str.substr(0, length - 1)
      const endString = ellipsis ? '…' : ''
      return (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(' '))
        : subString) + endString
    },
  },
  methods: {
    copyToClipboard (event) {
      try {
        clipboard.writeText(String($(event.currentTarget).html()).trim())
        toastr.options = {
          'closeButton': false,
          'debug': false,
          'newestOnTop': false,
          'progressBar': true,
          'positionClass': 'toast-bottom-right',
          'preventDuplicates': true,
          'onclick': null,
          'showDuration': '150',
          'hideDuration': '500',
          'timeOut': '1500',
          'extendedTimeOut': '500',
          'showEasing': 'swing',
          'hideEasing': 'linear',
          'showMethod': 'fadeIn',
          'hideMethod': 'fadeOut',
        }
        toastr.info('Usa Ctrl+V para pegarlo', 'Copiado a portapeles')
      } catch (exception) {
        console.log(exception)
      }
    },
  },
}
