var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass:
        "accordion-item mb-5 list-unstyled border border-1 d-flex flex-nowrap",
      class: [
        _vm.question.is_open ? "is-open" : "is-closed",
        "c-survey-component__item"
      ]
    },
    [
      !_vm.lang
        ? _c(
            "div",
            {
              directives: [{ name: "handle", rawName: "v-handle" }],
              staticClass:
                "p-2 c-survey-component__item-handler handle cursor-move bg-gray-50 d-flex bg-light text-dark align-items-center justify-content-between"
            },
            [_c("i", { staticClass: "fs-2 bi bi-arrows-move" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "w-100" }, [
        _c("div", { staticClass: "c-survey-component__item-header" }, [
          _c(
            "span",
            {
              staticClass:
                "c-survey-component__item-open-status accordion-button",
              class: [_vm.question.is_open ? "collapsed" : ""],
              on: {
                click: function($event) {
                  _vm.question.is_open = !_vm.question.is_open
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "c-survey-component__item-question" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question.question,
                        expression: "question.question"
                      }
                    ],
                    staticClass: "form-control m-input",
                    domProps: { value: _vm.question.question },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.question, "question", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.question.help_question
                    ? _c("p", { staticClass: "m-form__help" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.question.help_question) +
                            "\n                "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("nb-error-block", {
                    attrs: {
                      path: "questions." + _vm.indexQuestion + ".question"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              !_vm.lang
                ? _c(
                    "div",
                    { staticClass: "c-survey-component__item-required" },
                    [
                      _c("span", { staticClass: "m-switch m-switch--icon" }, [
                        _c("label", {}, [_vm._v("Requerido: ")]),
                        _vm._v(" "),
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.question.is_required,
                                expression: "question.is_required"
                              }
                            ],
                            attrs: {
                              id: "is_published_" + _vm.indexQuestion,
                              disabled: _vm.nbReadOnly,
                              name: "is_published_" + _vm.indexQuestion,
                              type: "checkbox",
                              value: "1"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.question.is_required)
                                ? _vm._i(_vm.question.is_required, "1") > -1
                                : _vm.question.is_required
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.question.is_required,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "1",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.question,
                                        "is_required",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.question,
                                        "is_required",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.question, "is_required", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span")
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "c-survey-component__item-delete btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only",
                  attrs: {
                    type: "button",
                    disabled: _vm.nbReadOnly,
                    id: "delete-question-" + _vm.indexQuestion
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("deleteQuestion", _vm.indexQuestion)
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-fw fa-trash" })]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "accordion-collapse collapse",
            class: [_vm.question.is_open ? "" : "show"]
          },
          [
            _c("question-component", {
              staticClass: "accordion-body c-survey-component__item-content",
              attrs: {
                index: _vm.indexQuestion,
                lang: _vm.lang,
                question: _vm.question,
                question_types: _vm.question_types
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }