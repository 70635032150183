var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getClassBox, attrs: { id: "ticket-editor-html" } },
    [
      _c("input", {
        attrs: { type: "hidden", name: "ticket", id: "ticket" },
        domProps: { value: _vm.ticketSaveData }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "elements", id: "elements" },
        domProps: { value: _vm.elementsSaveData }
      }),
      _vm._v(" "),
      _c("ticket-bar", { attrs: { powerUserLoged: _vm.powerUserLoged } }),
      _vm._v(" "),
      _c("ticket-edition", { attrs: { powerUserLoged: _vm.powerUserLoged } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }