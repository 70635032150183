<template>
    <div>
      <vue-color-picker class-field="form-group"
                        field="frontColor"
                        name="Color de punto"
                        v-model="activeElement.qr.colorDark"
                        :needs-target="true"
                        @input="updateQRCode($event, 'colorDark')"></vue-color-picker>

        <div class="form-group">
            <label>
                <i class="fa fa-fw fa-warning" v-tooltip
                   title="Algunos lectores tienen problemas para leer fondos distintos al blanco."></i>
                Color de fondo
            </label>
          <vue-color-picker class-field=""
                            field="backColor"
                            v-model="activeElement.qr.colorLight"
                            :needs-target="true"
                            @input="updateQRCode($event, 'colorLight')"></vue-color-picker>
        </div>

        <div class="form-group">
            <label for="margin">Margen</label>
            <input type="number" id="margin" class="form-control form-control-sm" min="1"
                   :value="activeElement.qr.margin" @input="updateQRCode($event, 'margin')">
        </div>

        <div class="form-group">
            <label for="size">Tamaño</label>
            <input type="number" id="size" class="form-control form-control-sm" min="1"
                   :value="activeElement.qr.width" @input="updateQRCode($event, 'width')">
        </div>
        <div class="qr-styles-message">
            <i class="fa fa-fw fa-warning"></i> Es importante que el código tenga un contraste y tamaño adecuado para su
            correcta lectura.
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapMutations } from 'vuex'
  import CommonProperties from '@/js/components/ticket_editor/tabs/element-tabs/CommonProperties'
  import StylesMixin from '@/js/components/ticket_editor/tabs/element-tabs/StylesMixin'
  import VueColorPicker from "@/js/components/widgets/VueColorPicker";

  export default {
    name: 'QRCodeFieldStyles',
    mixins: [StylesMixin],
    components: {VueColorPicker, CommonProperties },
    props: {},

    methods: {
      ...mapMutations('ticketEditor', {
        updateQRProperties: 'updateQRProperties',
        updateActiveElement: 'updateActiveElement',
      }),

      updateQRCode (event, prop) {
        this.updateQRProperties({ prop, value: event.target.value })
        this.layoutChange()
      },
    },
  }
</script>

<style scoped lang="scss">
    .qr-styles-message {
        background-color: #F1F1F1;
        padding: 0.8rem;
        border-radius: 3px;
    }

    label {
        font-size: 0.9rem !important;
        margin-bottom: 0.4rem !important;
    }
</style>
