var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group mb-6 col-md-3" },
    [
      _c("input", {
        attrs: { type: "hidden", name: "tests_type_id" },
        domProps: { value: _vm.typeSelect ? _vm.typeSelect.id : null }
      }),
      _vm._v(" "),
      _c("label", { staticClass: "form-label" }, [_vm._v("Tipo de test")]),
      _vm._v(" "),
      _c("multiselect", {
        attrs: {
          options: _vm.choices,
          label: "name",
          "track-by": "id",
          "allow-empty": false,
          defa: ""
        },
        model: {
          value: _vm.typeSelect,
          callback: function($$v) {
            _vm.typeSelect = $$v
          },
          expression: "typeSelect"
        }
      }),
      _vm._v(" "),
      _c("nb-error-block", { attrs: { path: "tests_type_id" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }