<template>
    <div class="form-group m-form__group col-md-6 c-locked" :class="[...lockStatus]">
        <button type="button" class="c-locked__lock"
                @click="lockStatus = 'not-locked'">
            <i class="icon flaticon-lock-1 c-locked__lock-icon-1" v-tooltip title="Bloquear/Desbloquear"/>
            <i class="icon flaticon-lock-1 c-locked__lock-icon-2" v-tooltip title="Bloquear/Desbloquear"/>
        </button>
        <label for="internal_id">ID Integración Web</label>
        <input v-model.number="currentValue"
               name="internal_id"
               type="number"
               min="0"
               id="internal_id"
               class="form-control m-input"
               :disabled="lockStatus === 'is-locked'">
        <nb-error-block path="internal_id"></nb-error-block>
    </div>

</template>

<script>
  import NbErrorBlock from '@/js/components/error_block/nb-error-block'

  export default {
    name: 'internal-id-field',
    components: { NbErrorBlock },
    props: {
      value: { type: Number, default: null },
    },
    data () {
      return {
        currentValue: 0,
        lockStatus: 'is-locked',
      }
    },
    mounted () {
      this.currentValue = this.value
    },
  }
</script>

<style>

</style>
