export const state = {
  dateFilters: null,
  organizationFilters: null,
  registeredCustomers: null,
  unsubscribedCustomers: null,
  dashboardAbsences: null,
  dashboardEvents: null,
}

export const getters = {
  getDateFilters: state => state.dateFilters,
  getOrganizationFilters: state => state.organizationFilters,
  getRegisteredCustomers: state => state.registeredCustomers,
  getUnsubscribedCustomers: state => state.unsubscribedCustomers,
  getDashboardAbsences: state => state.dashboardAbsences,
  getDashboardEvents: state => state.dashboardEvents,
}

export const mutations = {
  setDateFilters: (state, dateFilters) => state.dateFilters = dateFilters,
  setOrganizationFilters: (state, organizationFilters) => state.organizationFilters = organizationFilters,
  setRegisteredCustomers: (state, registeredCustomers) => state.registeredCustomers = registeredCustomers,
  setUnsubscribedCustomers: (state, unsubscribedCustomers) => state.unsubscribedCustomers = unsubscribedCustomers,
  setDashboardAbsences: (state, dashboardAbsences) => state.dashboardAbsences = dashboardAbsences,
  setDashboardEvents: (state, dashboardEvents) => state.dashboardEvents = dashboardEvents,
}

export const actions = {
  async setDateFilters ({ state, commit }, payload) {
    commit('setDateFilters', payload)
  },
  async setOrganizationFilters ({ state, commit }, payload) {
    commit('setOrganizationFilters', payload)
  },
  async setRegisteredCustomers ({ state, commit }, payload) {
    commit('setRegisteredCustomers', payload)
  },
  async setUnsubscribedCustomers ({ state, commit }, payload) {
    commit('setUnsubscribedCustomers', payload)
  },
  async setDashboardAbsences ({ state, commit }, payload) {
    commit('setDashboardAbsences', payload)
  },
  async setDashboardEvents ({ state, commit }, payload) {
    commit('setDashboardEvents', payload)
  },
}

export const Dashboard = { state, mutations, actions, getters }

export default Dashboard
