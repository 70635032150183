var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-md-6" }, [
    !_vm.live
      ? _c("div", { staticClass: "d-flex flex-row" }, [
          _c("input", {
            attrs: { type: "hidden", name: _vm.name + "_checkbox" },
            domProps: { value: _vm.customizeCheckbox ? 1 : 0 }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "p-2" }, [
            _c("label", { staticClass: "m-checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customizeCheckbox,
                    expression: "customizeCheckbox"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  title: "Modificable",
                  disabled: _vm.disabledField,
                  id: "customize-check-" + _vm.prefix + _vm.name
                },
                domProps: {
                  checked: Array.isArray(_vm.customizeCheckbox)
                    ? _vm._i(_vm.customizeCheckbox, null) > -1
                    : _vm.customizeCheckbox
                },
                on: {
                  click: function($event) {
                    return _vm.changeCheckBox()
                  },
                  change: function($event) {
                    var $$a = _vm.customizeCheckbox,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.customizeCheckbox = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.customizeCheckbox = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.customizeCheckbox = $$c
                    }
                  }
                }
              }),
              _vm._v(" " + _vm._s(_vm.label) + "\n                "),
              _c("span")
            ])
          ])
        ])
      : _c("label", [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.valueJSON,
          expression: "valueJSON"
        }
      ],
      ref: "saveField",
      attrs: { type: "hidden", name: _vm.prefix + "[" + _vm.name + "]" },
      domProps: { value: _vm.valueJSON },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.valueJSON = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 p-2" }, [
        _c("div", { staticClass: "form-group m-form__group  col-md-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectAllElems,
                expression: "selectAllElems"
              }
            ],
            attrs: {
              type: "hidden",
              name: "all-" + _vm.prefix + _vm.name,
              id: "all-" + _vm.prefix + _vm.name
            },
            domProps: { value: _vm.selectAllElems },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.selectAllElems = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "m-switch m-switch--icon" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectAllElems,
                    expression: "selectAllElems"
                  }
                ],
                attrs: {
                  id: "all-" + _vm.prefix + _vm.name,
                  name: "all-" + _vm.prefix + _vm.name,
                  type: "checkbox",
                  disabled: _vm.disabledField
                },
                domProps: {
                  checked: Array.isArray(_vm.selectAllElems)
                    ? _vm._i(_vm.selectAllElems, null) > -1
                    : _vm.selectAllElems
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.selectAllElems,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectAllElems = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectAllElems = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectAllElems = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("span")
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "label-hidden" }, [
              _vm._v("Incluir todo")
            ])
          ]),
          _vm._v(" "),
          _c("label", { attrs: { for: "all-" + _vm.prefix + _vm.name } }, [
            _vm._v("Incluir todo")
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    !_vm.selectAllElems
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.parents,
                  multiple: false,
                  disabled: _vm.selectAllElems,
                  "track-by": "name",
                  label: "name",
                  placeholder: "Seleccionar grupo de " + _vm.label
                },
                model: {
                  value: _vm.selectedParent,
                  callback: function($$v) {
                    _vm.selectedParent = $$v
                  },
                  expression: "selectedParent"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-8 col-select-one",
              class: { "has-selection": _vm.data.length }
            },
            [
              _c(
                "multiselect",
                {
                  staticClass: "multiselect--search",
                  attrs: {
                    options: _vm.children,
                    multiple: true,
                    placeholder: "Buscar...",
                    limit: 1,
                    limitText: function() {
                      return ""
                    },
                    "track-by": "name",
                    "close-on-select": false,
                    disabled: _vm.selectAllElems || _vm.disabledField,
                    "clear-on-select": false,
                    loading: _vm.loading,
                    label: "name"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option",
                        fn: function(props) {
                          return [
                            !_vm.selectedParent
                              ? _c("span", [_vm._v(_vm._s(props.option.name))])
                              : _vm.selectedParent.id === props.option.parent_id
                              ? _c("span", [_vm._v(_vm._s(props.option.name))])
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2568926835
                  ),
                  model: {
                    value: _vm.data,
                    callback: function($$v) {
                      _vm.data = $$v
                    },
                    expression: "data"
                  }
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "noResult" }, slot: "noResult" },
                    [_vm._v("No existen elementos")]
                  ),
                  _vm._v(" "),
                  _c("template", { slot: "tag" }, [
                    _c(
                      "span",
                      {
                        staticClass: "badge m-badge py-0 px-2",
                        staticStyle: { "margin-bottom": "3px" }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.allIncludes
                                ? "Todos incluidos"
                                : _vm.data.length +
                                    " elementos seleccionados de " +
                                    _vm.differentSelectedGroupsCount +
                                    " eventos."
                            ) +
                            "\n                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._v(" "),
                  _c("template", { slot: "beforeList" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-grow-1 align-content-stretch text-nowrap"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link select-all w-50",
                            attrs: {
                              type: "button",
                              disabled: _vm.selectAllElems || _vm.disabledField
                            },
                            on: { click: _vm.selectAll }
                          },
                          [
                            _vm._v(
                              "\n                            Seleccionar todo\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link clear-all w-50",
                            attrs: {
                              type: "button",
                              disabled:
                                _vm.disabledField ||
                                !_vm.data ||
                                _vm.data.legnth
                            },
                            on: { click: _vm.removeAll }
                          },
                          [
                            _vm._v(
                              "\n                            Deseleccionar todo\n                        "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ],
                2
              ),
              _vm._v(" "),
              _c("nb-error-block", { attrs: { path: "filters." + _vm.name } })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              {
                staticClass:
                  "m-alert alert-primary m-alert--outline m-alert--air m-alert--square alert fade show mt-1",
                attrs: { role: "alert" }
              },
              [
                _c("div", { staticClass: "m-alert__text" }, [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "w-50 pr-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-block btn-sm btn-primary",
                          attrs: {
                            type: "button",
                            disabled:
                              _vm.allIncludes ||
                              _vm.selectAllElems ||
                              _vm.disabledField
                          },
                          on: { click: _vm.selectAllComplete }
                        },
                        [
                          _vm._v(
                            "\n                                Añadir todas las sesiones\n                            "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-50 pl-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-block btn-sm btn-primary",
                          attrs: {
                            type: "button",
                            disabled:
                              !_vm.data || !_vm.data.length || _vm.disabledField
                          },
                          on: { click: _vm.removeAllComplete }
                        },
                        [
                          _vm._v(
                            "\n                                Quitar todas las sesiones\n                            "
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  this.data.length
                    ? _c("div", { staticClass: "mb-1" }, [_vm._v("Selección:")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "group-box" },
                    _vm._l(_vm.groupedSelection, function(group) {
                      return _c(
                        "li",
                        { staticClass: "group-box__item d-flex flex-wrap" },
                        [
                          _c("span", { staticClass: "group-box__name" }, [
                            _vm._v(_vm._s(_vm.groupName(group[0].parent_id)))
                          ]),
                          _vm._v(" "),
                          !_vm.isGroupVisible(group[0].parent_id)
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "ml-2 m-badge m-badge--wide group-box__recount-button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleGroupVisibility(
                                        group[0].parent_id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.isGroupComplete(group)
                                          ? "Todos incluidos"
                                          : group.length + " elementos"
                                      ) +
                                      "\n                                "
                                  ),
                                  _c("span", {
                                    staticClass: "fa fa-angle-double-right"
                                  })
                                ]
                              )
                            : [
                                _c(
                                  "span",
                                  { staticClass: "group-box__opening" },
                                  [_vm._v("(")]
                                ),
                                _vm._v(" "),
                                _vm._l(group, function(item) {
                                  return _c(
                                    "span",
                                    { staticClass: "group-box__open-item" },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.stylizeGroupItem(
                                              item.parent_id,
                                              item.name
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip"
                                          }
                                        ],
                                        staticClass:
                                          "fa fa-times p-1 group-box__remove-item",
                                        attrs: { title: "Eliminar elemento" },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeItem(item.id)
                                          }
                                        }
                                      })
                                    ]
                                  )
                                })
                              ],
                          _vm._v(" "),
                          !_vm.isGroupVisible(group[0].parent_id)
                            ? _c("span", { staticClass: "group-closing" })
                            : _c(
                                "span",
                                { staticClass: "group-box__closing-end" },
                                [_vm._v(")")]
                              ),
                          _vm._v(" "),
                          _vm.isGroupVisible(group[0].parent_id)
                            ? _c("span", {
                                staticClass:
                                  "group-box__close-button fa fa-angle-double-left",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleGroupVisibility(
                                      group[0].parent_id
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        2
                      )
                    }),
                    0
                  )
                ])
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }