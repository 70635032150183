import { render, staticRenderFns } from "./SelectTestType.vue?vue&type=template&id=404f0104&"
import script from "./SelectTestType.vue?vue&type=script&lang=js&"
export * from "./SelectTestType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('404f0104')) {
      api.createRecord('404f0104', component.options)
    } else {
      api.reload('404f0104', component.options)
    }
    module.hot.accept("./SelectTestType.vue?vue&type=template&id=404f0104&", function () {
      api.rerender('404f0104', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/test/SelectTestType.vue"
export default component.exports