<template>
    <li
        class="accordion-item mb-5 list-unstyled border border-1 d-flex flex-nowrap"
            :class="[question.is_open ? 'is-open' : 'is-closed', 'c-survey-component__item']">

        <!-- para arrastrar -->
        <div v-handle v-if="!lang" class="p-2 c-survey-component__item-handler handle cursor-move bg-gray-50 d-flex bg-light text-dark align-items-center justify-content-between">
            <i class="fs-2 bi bi-arrows-move"></i>
        </div>

        <div class="w-100">
            <!-- header --------------------------------------------------------------------------------------------------->
            <div class="c-survey-component__item-header">
                <span @click="question.is_open = !question.is_open"
                   :class="[question.is_open ? 'collapsed' : '']"
                   class="c-survey-component__item-open-status accordion-button">

                <!-- pregunta -->
                <div class="c-survey-component__item-question">
                    <input
                            class="form-control m-input"
                            v-model="question.question"/>
                    <p v-if="question.help_question" class="m-form__help">
                        {{ question.help_question }}
                    </p>
                    <nb-error-block :path="`questions.${indexQuestion}.question`"></nb-error-block>
                </div>

                <!-- requerido -->
                <div class="c-survey-component__item-required" v-if="!lang">
                    <span class="m-switch m-switch--icon">
                        <label class="">Requerido: </label>
                        <label>
                            <input
                                    :id="'is_published_' + indexQuestion" v-model="question.is_required"
                                    :disabled="nbReadOnly"
                                    :name="'is_published_' + indexQuestion" type="checkbox" value="1">
                            <span></span>
                        </label>
                    </span>
                </div>

                <!-- botoneliminar -->
                <button type="button" :disabled="nbReadOnly"
                        @click="$emit('deleteQuestion', indexQuestion)"
                        class="c-survey-component__item-delete btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only"
                        :id="'delete-question-' + indexQuestion">
                    <i class="fa fa-fw fa-trash"></i>
                </button>
                </span>
            </div>
            <!-- content --------------------------------------------------------------------------------------------------->
            <div class="accordion-collapse collapse"
             :class="[question.is_open ? '' : 'show']">
                <!-- contenido ------------------------------------------------------------------------------------------------>
                <question-component
                    class="accordion-body c-survey-component__item-content"
                    :index="indexQuestion"
                    :lang="lang"
                    :question="question"
                    :question_types="question_types"/>
        </div>
        </div>
    </li>
</template>

<script>

  import { ElementMixin, HandleDirective } from 'vue-slicksort'
  import NbErrorBlock from "@/js/components/error_block/nb-error-block";
  import QuestionComponent from '@/js/components/survey/QuestionComponent'

  export default {
    mixins: [ElementMixin],
    directives: { handle: HandleDirective },
    components: { NbErrorBlock, QuestionComponent },
    props: {
      lang: { type: String, default: null },
      indexQuestion: { type: Number, required: true },
      question: { type: Object, required: true },
      question_types: { type: Array, required: true },
    },
    computed: {
      containsError () {
        let error = this.renderNbError(`questions.${this.indexQuestion}.question`)
        if (error) {
          return error
        }
        this.question.options.forEach((options, index) => {
          let errorValue = this.renderNbError(`questions.${this.indexQuestion}.options.${index}.value`)
          let errorOption = this.renderNbError(`questions.${this.indexQuestion}.options.${index}.option`)
          if (errorValue || errorOption) {
            error = true
          }
        })
        return error
      }
    },
    created () {
      this.question.is_open = this.containsError
    },
  }
</script>
