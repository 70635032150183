import Vue from 'vue'
import Vuex from 'vuex'
import Customer from '@/js/state/modules/Customer'
// TODO: EDITOR VISUAL Generalizar
import ticketEditor from '@/js/state/modules/TicketEditor'
import Repository from '@/js/state/modules/Repository'
import Dashboard from '@/js/state/modules/Dashboard'
import Menu from '@/js/state/modules/Menu'
import Test from '@/js/state/modules/Test'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    storeInitialised: false,
    footerWarning: null,
    nbErrors: [],
  },
  modules: {
    ticketEditor: {
      namespaced: true,
      ...ticketEditor,
    },
    Customer: {
      namespaced: true,
      ...Customer,
    },
    Repository: {
      namespaced: true,
      ...Repository,
    },
    Dashboard: {
      namespaced: true,
      ...Dashboard,
    },
    Menu: {
      namespaced: true,
      ...Menu,
    },
    Test: {
      namespaced: true,
      ...Test,
    },
  },
  getters: {
    getNbErrors: state => state.nbErrors,
    getFooterWarning: state => state.footerWarning,
  },
  mutations: {
    addNbErrors: (state, errors) => {
      Object.keys(errors).forEach((key) => {
        if (state.nbErrors.findIndex(e => e.path === key) === -1)
          state.nbErrors.push({ path: key, errors: errors[key] })
      })
    },
    removeNbErrors: (state, errors) => {
      Object.keys(errors).forEach((key) => {
        state.nbErrors.splice(state.nbErrors.findIndex(e => e.path === key), 1)
      })
    },
    initialiseStore (state) {
      const loadedStore = localStorage.getItem('store')
      // Si existe en local storage lo cargamos reemplazando el state actual
      if (loadedStore) {
        this.replaceState(
          Object.assign(state, JSON.parse(loadedStore)),
        )
        state.storeInitialised = true
      }
    },
    setFooterWarning (state, warning) {
      state.footerWarning = warning
    },
  },
  actions: {
    saveCurrentStateToLocalStorage ({ commit, state }) {
      localStorage.setItem('store', JSON.stringify(state))
    },
  },
})

export default store
