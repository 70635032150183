<template>
    <div>
        <div class="row">
            <div class="col-12">
                <input type="hidden" :name="`${prefix}[${name}][type]`" :value="type">
                <div class="btn-group btn-block m-btn-group" role="group">
                    <button type="button" class="btn m-btn" @click="changeType('operator')" :disabled="nbReadOnly"
                            :class="{'btn-brand': type === 'operator', 'btn-secondary': type !== 'operator'}"
                            id="operator-type">
                        Operador
                    </button>
                    <button type="button" class="btn m-btn" @click="changeType('day')" :disabled="nbReadOnly"
                            :class="{'btn-primary': type === 'day', 'btn-secondary': type !== 'day'}" id="days">
                        Días
                    </button>
                    <button type="button" class="btn m-btn" @click="changeType('range')" :disabled="nbReadOnly"
                            :class="{'btn-brand': type === 'range', 'btn-secondary': type !== 'range'}" id="range">
                        Rangos
                    </button>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="row" v-if="type === 'operator'">
                    <div class="col-2">
                        <input type="hidden" :name="`${prefix}[${name}][operator]`" :value="operator">
                        <select class="form-select form-select-solid" :disabled="nbReadOnly" v-model="operator" id="operator-select">
                            <option :value="key" v-for="(operator, key) in operators"
                                    :key="'op-' + key">
                                {{ operator }}
                            </option>
                        </select>
                    </div>
                    <div class="col-10">
                        <input type="hidden" :name="`${prefix}[${name}][date]`" :value="date">
                        <input type="datetime-local" v-model="date" class="form-control" id="date"/>
                    </div>
                </div>
                <div class="row" v-if="type === 'day'">
                    <div class="col-12 mb-3">
                        <input type="hidden" :name="`${prefix}[${name}][dayOperator]`" :value="dayOperator">
                        <div class="btn-group btn-block m-btn-group" role="group">
                            <button type="button" class="btn m-btn" @click="changeDayOperator(operatorKey)"
                                    :disabled="nbReadOnly" id="operator-date"
                                    :class="getClassDay(dayOperator, operatorKey)"
                                    v-for="(operatorShow, operatorKey) in operatorsTexts"
                                    :key="'operator-' + operatorKey">
                                {{ operatorShow }}
                                <i class="fa fa-fw fa-info-circle" :title="operatorsPlaceholder[operatorKey]"
                                   v-tooltip></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="type === 'day'">
                    <div class="col-4">
                        <input type="hidden" :name="`${prefix}[${name}][day]`" :value="day">
                        <div class="btn-group btn-block m-btn-group" role="group">
                            <button type="button" class="btn m-btn" @click="changeDay(dayKey)" :disabled="nbReadOnly"
                                    :class="{'btn-brand': day === dayKey, 'btn-secondary': day !== dayKey}"
                                    v-for="(dayShow, dayKey) in days" :id="'day-' + dayKey"
                                    :key="'day-key-' + dayKey">
                                {{ dayShow }}
                            </button>
                        </div>
                    </div>
                    <div class="col-2 px-0">
                        <input type="hidden" :name="`${prefix}[${name}][dayNumber]`" :value="dayNumber">
                        <input type="number" v-model="dayNumber" class="form-control" :disabled="dayDisabled()"
                               id="day-number"/>
                    </div>
                    <div class="col-6">
                        <input type="hidden" :name="`${prefix}[${name}][dayGroup]`" :value="dayGroup">
                        <div class="btn-group btn-block m-btn-group" role="group">
                            <button type="button" class="btn m-btn" :id="'day-group-' + dayKey"
                                    @click="changeDayGroup(dayKey)"
                                    :class="{'btn-brand': dayGroup === dayKey, 'btn-secondary': dayGroup !== dayKey}"
                                    :disabled="dayDisabled()"
                                    v-for="(dayShow, dayKey) in daysGroup"
                                    :key="'day-key2-' + dayKey">
                                {{ dayShow }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="type === 'range'">
                    <div class="col-2 text-center">
                        <div class="m-type">
                            Desde
                        </div>
                    </div>
                    <div class="col-4">
                        <input type="hidden" :name="`${prefix}[${name}][startDate]`" :value="startDate">
                        <input type="datetime-local" v-model="startDate" :disabled="nbReadOnly" class="form-control"
                               id="start-date"/>
                    </div>
                    <div class="col-2 text-center">
                        <div class="m-type">
                            Hasta
                        </div>
                    </div>
                    <div class="col-4">
                        <input type="hidden" :name="`${prefix}[${name}][endDate]`" :value="endDate">
                        <input type="datetime-local" v-model="endDate" :disabled="nbReadOnly" class="form-control"
                               id="end-date"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'ranking-date-picker',
    props: {
      prefix: { type: String, default: 'date' },
      name: { type: String, default: '' },
      label: { type: String, default: '' },
      customizable: { type: Boolean, default: false },
      default_value: {
        type: Object,
        default: () => ({
          type: 'operator',
          operator: 'smallerThanOrEqual',
          date: 'now',
          day: 'now',
          dayOperator: 'smallerThanOrEqual',
          dayGroup: 'day',
          dayNumber: 1,
          startDate: 'now',
          endDate: 'now'
        })
      },
      operators: {
        type: Object,
        default: () => ({
          smallerThanOrEqual: '<=',
          greaterThanOrEqual: '>=',
          smallerThan: '<',
          greaterThan: '>',
          same: '=',
        })
      },
      operatorsTexts: {
        type: Object,
        default: () => ({
          same: 'Igual a',
          greaterThanOrEqual: 'Desde',
          smallerThanOrEqual: 'Hasta',
        })
      },
      operatorsPlaceholder: {
        type: Object,
        default: () => ({
          same: 'Igual a la fecha relativa indicada',
          greaterThanOrEqual: 'Desde la fecha relativa indicada',
          smallerThanOrEqual: 'Hasta la fecha relativa indicada',
        })
      },
      days: {
        type: Object,
        default: () => ({
          now: 'Hoy',
          less: 'Menos',
          plus: 'Más'
        })
      },
      daysGroup: {
        type: Object,
        default: () => ({
          day: 'Día/s',
          week: 'Semana/s',
          month: 'Mes/es',
        })
      },
    },
    created: function () {
      this.type = this.default_value.type
      this.operator = this.default_value.operator
      this.day = this.default_value.day
      this.dayOperator = this.default_value.dayOperator
      this.dayGroup = this.default_value.dayGroup
      this.dayNumber = this.default_value.dayNumber
      this.date = this.dateFormat(this.default_value.date)
      this.startDate = this.dateFormat(this.default_value.startDate)
      this.endDate = this.dateFormat(this.default_value.endDate)
      this.customizeCheckbox = this.customizable
    },
    data () {
      return {
        type: 'operator',
        operator: 'smallerThanOrEqual',
        date: 'now',
        day: 'now',
        dayOperator: 'smallerThanOrEqual',
        dayGroup: 'day',
        dayNumber: 1,
        startDate: 'now',
        endDate: 'now',
        dataValue: '',
        customizeCheckbox: false
      }
    },
    computed: {
      data () {
        return { ...this.$data }
      }
    },
    watch: {
      data () {
        this.$emit('updated-date', this.data)
      },
      dayNumber: function (val) {
        if (val < 1) {
          this.dayNumber = 1
        }
      }
    },
    methods: {
      dateFormat (date) {
        if (date === 'now') {
          date = moment().format('YYYY-MM-DD[T]HH:MM')
        }

        return date
      },
      dayDisabled () {
        return this.day === 'now' || this.nbReadOnly
      },
      changeType (type) {
        this.type = type
        if (this.type === 'day' && !this.day) {
          this.day = 'now'
        }
      },
      changeDay (day) {
        this.day = day
      },
      changeDayOperator (operator) {
        this.dayOperator = operator
      },
      changeDayGroup (group) {
        this.dayGroup = group
      },
      changeCheckBox () {
        this.customizeCheckbox = !this.customizeCheckbox
      },
      getClassDay (dayOperator, operatorKey) {
        return dayOperator === operatorKey ? 'btn-brand' : 'btn-secondary'
      }
    }
  }
</script>
