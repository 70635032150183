var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tbody", [
    _c("tr", [
      _c("td", [
        _c("a", { attrs: { id: "section" }, on: { click: _vm.openSection } }, [
          _c(
            "div",
            {
              class: { disabled: _vm.nbReadOnly },
              style: { paddingLeft: _vm.depth * 15 + "px" }
            },
            [
              _vm.section.children.length
                ? _c("i", { class: "c-pointer " + _vm.caret })
                : _c("strong", { staticClass: "fa fa-fw" }, [_vm._v("•")]),
              _vm._v(" "),
              _vm.userRoleSection
                ? _c("i", {
                    class: "fa role-status " + _vm.roleIcon,
                    attrs: {
                      title: _vm.$options.filters.trans(
                        "permission_component.inheritedRoleAuth"
                      )
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("i", {
                class: "fa state-icon " + _vm.icon,
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.checkSection($event)
                  }
                }
              }),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.section.name) +
                  "\n            "
              )
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.section.children.length && _vm.sectionOpen
      ? _c("tr", { staticStyle: { padding: "0", margin: "0" } }, [
          _c("td", { staticStyle: { padding: "0" } }, [
            _vm.section.children.length
              ? _c(
                  "table",
                  {
                    key: "section" + _vm.index,
                    staticClass: "table table-hover",
                    staticStyle: { "margin-bottom": "0" },
                    attrs: { id: "section" + _vm.index }
                  },
                  _vm._l(_vm.section.children, function(
                    childrenSection,
                    childrenIndex
                  ) {
                    return _c("section-item", {
                      key: childrenSection.id,
                      attrs: {
                        userRoleSection: _vm.userRoleSection
                          ? _vm.userRoleSection.children[childrenIndex]
                          : null,
                        section: childrenSection,
                        index: _vm.index + "_" + childrenIndex,
                        depth: _vm.depth + 1,
                        nbReadOnly: _vm.nbReadOnly
                      },
                      on: {
                        sectionUpdated: function($event) {
                          return _vm.sectionUpdated(childrenSection.id)
                        },
                        setSectionPermissionToNull: function($event) {
                          return _vm.setSectionPermissionToNull(
                            childrenSection.id
                          )
                        }
                      }
                    })
                  }),
                  1
                )
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }