var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-8 col-xl-6" }, [
    _c("input", {
      attrs: { type: "hidden", name: _vm.prefix + "[" + _vm.name + "][type]" },
      domProps: { value: _vm.type }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        class: { "is-inactive": _vm.type !== "range" },
        attrs: { id: "dashboard-filter-range", role: "group" },
        on: {
          click: function($event) {
            return _vm.changeType("range")
          }
        }
      },
      [
        _c("label", { staticClass: "form-label" }, [
          _vm._v("Por rango de fechas:")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            attrs: {
              type: "hidden",
              name: _vm.prefix + "[" + _vm.name + "][startDate]"
            },
            domProps: { value: _vm.startDate }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.startDate,
                expression: "startDate"
              }
            ],
            staticClass: "form-control",
            attrs: {
              placeholder: "Desde",
              id: "startDate",
              type: "datetime-local"
            },
            domProps: { value: _vm.startDate },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.startDate = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "input-group-text" }, [_vm._v("-")]),
          _vm._v(" "),
          _c("input", {
            attrs: {
              type: "hidden",
              name: _vm.prefix + "[" + _vm.name + "][endDate]"
            },
            domProps: { value: _vm.endDate }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.endDate,
                expression: "endDate"
              }
            ],
            staticClass: "form-control",
            attrs: {
              placeholder: "Hasta",
              id: "endDate",
              type: "datetime-local"
            },
            domProps: { value: _vm.endDate },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.endDate = $event.target.value
              }
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }