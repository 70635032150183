<template>
    <div class="w-100">
        <input name="options" type="hidden" :value="optionsJSON">
        <input name="question_type_id" type="hidden" :value="selectedType">
        <div v-if="!lang" class="form-group m-form__group">
            <div class="btn-group m-btn-group btn-block" role="group">
                <button type="button" v-for="type in question_types" :disabled="nbReadOnly" @click="selectType(type.id)"
                        class="btn" :id="'question-type-' + type.id" id="type"
                        :class="{'btn-brand': activeType(type.id), 'btn-outline-brand': !activeType(type.id)}">
                    {{ type.name }}
                </button>
            </div>
        </div>
        <div v-else>
            <input type="hidden" name="question_type_id" v-model="selectedType"/>
        </div>
        <div v-if="isWithOptions" class="form-group m-form__group">
            <div class="d-sm-flex justify-content-between">
                <h4 class="d-flex align-items-center mb-5">
                    <em class="flaticon-list-2 mr-1"></em>
                    Opciones de la pregunta
                </h4>
                <button type="button" class="mb-5 btn btn-primary m-btn m-btn--icon" @click="addOption"
                        :disabled="nbReadOnly" id="add-option">
                    <span>
                        <i class="fa fa-plus"></i>
                        <span>Añadir opción</span>
                    </span>
                </button>
            </div>
            <table class="table table-striped table-bordered m-table">
                <thead>
                <tr>
                    <th>Opción</th>
                    <th>Valor</th>
                    <th width="5%" class="text-center" v-if="!lang">Seleccionada</th>
                    <th width="1%" v-if="!lang"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(option, indexOption) in options" class="align-top">
                    <td>
                        <div class="m-form__control">
                            <input v-model="option.option" class="form-control form-control-sm m-input"
                                   placeholder="Opcion" :disabled="nbReadOnly" :id="'option-' + indexOption">
                            <p v-if="hasOptionHelpBlock(indexOption)" class="m-form__help">
                                {{ question_options_help_block[indexOption].option }}
                            </p>
                            <nb-error-block :path="`options.${indexOption}.option`"></nb-error-block>
                        </div>
                    </td>
                    <td>
                        <div class="m-form__control">
                            <input v-model="option.value" class="form-control form-control-sm m-input"
                                   placeholder="Valor a devolver" :disabled="nbReadOnly"
                                   :id="'return-value-' + indexOption">
                            <span v-if="hasOptionHelpBlock(indexOption)" class="m-form__help">
                                {{ question_options_help_block[indexOption].value }}
                            </span>
                        </div>
                    </td>
                    <td v-if="!lang" class="text-center">
                        <label class="m-checkbox m-checkbox--brand pl-3 mt-2">
                            <input type="checkbox" checked="checked" v-model="option.is_selected"
                                   :id="'select-option-' + indexOption"
                                   @change="setOptionSelected(indexOption)" :disabled="nbReadOnly">
                            <span></span>
                        </label>
                    </td>
                    <td v-if="!lang" class="align-middle text-nowrap">
                        <div class="actions text-center">
                            <button type="button" class="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only"
                                    @click="deleteOption(indexOption)" :disabled="nbReadOnly"
                                    :id="'delete-option-' + indexOption">
                                <i class="fa fa-fw fa-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div v-else class="form-group m-form__group">
            <label for="default_value">Respuesta por defecto</label>
            <input type="text" name="default_value" id="default_value" v-model="defValue" :disabled="nbReadOnly"
                   class="form-control m-input">
            <p v-if="question_default_help_block" class="m-form__help">{{ question_default_help_block }}</p>
        </div>
    </div>
</template>

<script>
  import { get, isNil } from 'lodash'

  function getEmptyNewOption () {
    return {
      option: null,
      value: null,
      is_selected: null,
    }
  }

  export default {
    name: 'QuestionComponent',
    props: {
      question_type: {
        default: null,
      },
      question_types: {
        required: true,
        type: Array,
      },
      question_options: {
        type: Array,
        default: () => [],
      },
      question_options_help_block: {
        type: Array,
        default: null,
      },
      question_default: {
        default: '',
      },
      question_default_help_block: {
        type: String,
        default: null,
      },
      lang: {
        type: String,
        default: null,
      },
    },
    data: function () {
      return {
        options: [],
        selectedType: null,
        newOption: {
          option: null,
          value: null,
          is_selected: false,
        },
        defValue: this.question_default,
      }
    },
    created () {
      this.selectedType = this.question_type
      if (this.question_options.length > 0) {
        this.options = this.question_options
      } else {
        this.addOption()
      }
    },
    computed: {
      isSingle: function () {
        return this.selectedType ? this.question_types[this.selectedType - 1].multiple === 1 : false
      },
      isMultiple: function () {
        return this.selectedType ? this.question_types[this.selectedType - 1].multiple === -1 : false
      },
      isWithOptions: function () {
        return this.selectedType ? (this.question_types[this.selectedType - 1].multiple !== 0) : false
      },
      optionsJSON: function () {
        if (this.isWithOptions) {
          return JSON.stringify(this.options)
        }
        return '[]'
      },
    },
    methods: {
      activeType (typeId) {
        return typeId === this.selectedType
      },
      selectType (typeId) {
        this.selectedType = typeId
        this.onChangeType()
      },
      onChangeType () {
        this.selectedType = this.getIdQuestionType(this.selectedType)
        if (this.isSingle) {
          let ch = false
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].is_selected) {
              if (ch) {
                this.options[i].is_selected = false
                continue
              }
              ch = true
            }
          }
        }
      },
      getQuestionTypes (id) {
        return _.first(_.filter(this.question_types, { 'id': id }))
      },
      getIdQuestionType (questionType) {
        return typeof questionType === 'object' ? this.selectedType.id : this.selectedType
      },
      setOptionSelected (index) {
        if (this.options[index].is_selected && !this.isMultiple) {
          for (let i = 0; i < this.options.length; i++) {
            if (i !== index) {
              this.options[i].is_selected = false
            }
          }
        }
      },
      addOption () {
        this.options.push(getEmptyNewOption())

        this.setOptionSelected(this.options.length - 1)
      },
      deleteOption (index) {
        this.options.splice(index, 1)
      },
      hasOptionHelpBlock (index) {
        return !isNil(get(this.question_options_help_block, `[${index}]`))
      },
      customLabel (label) {
        if (!label) return ''

        if (typeof label !== 'object') {
          let customLabel = this.getQuestionTypes(parseInt(label))
          return `${customLabel.name}`
        }
        return `${label.name}`
      },
    },
  }
</script>

<style scoped>

</style>
