<template>
    <div :class="`form-group m-form__group ${containerClass}`">
        <label class="form-label" for="tagger">Tags</label>
        <multiselect v-model="selectedTags" name="tagger" tag-placeholder="Crear nuevo tag" id="tags"
                     placeholder="Elige o crea un tag" label="name" track-by="name" :disabled="nbReadOnly"
                     :options="choices" :multiple="true" :taggable="true" @tag="addTag">
        </multiselect>
        <input id="tagger" name="tagger" ref="saveField" type="hidden" :value="jsonResult">
    </div>
</template>

<script>
  export default {
    name: 'tagger-component',
    data: () => {
      return {
        selectedTags: [],
      }
    },
    computed: {
      jsonResult () {
        $(this.$refs.saveField).trigger('change')
        return JSON.stringify(this.selectedTags)
      },
    },
    props: {
      values: {
        type: Array,
        default: () => [],
      },
      choices: {
        type: Array,
        default: () => [],
      },
      containerClass: {
        type: String,
        default: () => 'col-12',
      },
    },
    methods: {
      addTag (newTag) {
        const tag = {
          name: newTag,
        }
        this.choices.push(tag)
        this.selectedTags.push(tag)
      },
    },
    mounted () {
      this.selectedTags = [...this.values]
    },
  }
</script>

<style scoped>
</style>
