<template>
  <div class="form-group m-form__group col-12 my-4">
    <!-- <input type="hidden" name="sponsorsData" :value="jsonSponsorsData" /> -->
    <div class="form-group mb-4">
      <repository-button
        :allowed-mime-types="allowedMimeTypes"
        :short-version="true"
        v-model="selectedImage"
        class-field="col-md-12 px-0"
        multiple="true"
        field="sponsorsData"
        group-id="1"
        group-type="App\Models\Rep\FileGroup"
      >
      </repository-button>
    </div>
  </div>
</template>

<script>
import RepositoryButton from "@/js/components/repository_explorer/RepositoryButton";
import { mapGetters } from "vuex";

export default {
  name: "SponsorsTab",
  components: { RepositoryButton },
  props: {
    sponsorsImages: Array,
  },
  data() {
    return {
      selectedImage: null,
      listImages: [],
    };
  },
  created() {
    this.selectedImage = this.sponsorsImages;
  },
  computed: {
    ...mapGetters("Repository", {
      allowedMimeTypes: "getMimeTypeImages",
    }),
  },
};
</script>

<style>
</style>
