<template>
    <div class="row">
        <div class="col-6">
            <h5 class="mb-0 mt-2">
                <i :class="evaluator.icon"></i>
                {{ evaluator.name }}
            </h5>
        </div>
        <div class="col-6 text-right pr-5">
            <button id="include"
                    type="button"
                    class="btn m-btn"
                    :disabled="nbReadOnly"
                    :class="{'btn-brand': status}"
                    @click="activeInclude()">
                Si
            </button>
            <button id="no-include"
                    type="button"
                    class="btn m-btn"
                    :disabled="nbReadOnly"
                    :class="{'btn-brand': !status}"
                    @click="inactiveInclude()">
                No
            </button>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'evaluator-include',
    props: {
      evaluator: { type: Object, default: () => {} },
      value: { type: Object, default: () => ({ status: true }) },
    },
    data () {
      return {
        status: true
      }
    },
    created () {
      this.status = this.value.status
    },
    methods: {
      activeInclude () {
        this.status = true
        this.updateModel()
      },
      inactiveInclude () {
        this.status = false
        this.updateModel()
      },
      updateModel () {
        this.$emit('input', { status: this.status })
      }
    }
  }
</script>

<style scoped>

</style>
