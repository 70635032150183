import axios from 'axios'
import store from '@/js/state'
import Vue from 'vue'
import components from '@/js/vue/components'
import configureAxios from '@/js/vue/services/configureAxios'
import initVue from '@/js/vue/initVue'
import buildSelect2 from '@/js/shared/buildSelect2'

// Load Axios Interceptors and configuration
configureAxios(axios)

// Expose Vue into global scope
window.Vue = Vue

if (document.getElementById('app')) {
  initVue()

  window.mainVue = new Vue({
    el: '#rootApp',
    store,
    components,
    mounted () {
      $('.vue-select-2').each((i, el) => {
        buildSelect2.init(el)
      })
    },
  })
}
