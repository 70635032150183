import axios from 'axios'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async GET_CUSTOMER_TYPES () {
    const response = await axios.get('/api/customer-types')
    return response.data
  },
  async GET_AUDIENCE_TYPES () {
    const response = await axios.get('/api/audience-types')
    return response.data
  },
  async POST_CREATE_CUSTOMER (context, customer) {
    const response = await axios.post('/api/customer', customer)
    return response.data.data
  },
}

export const Customer = { state, mutations, actions, getters }

export default Customer
