import Vue from 'vue'

export default function loadFilters () {
  Vue.filter('trans', (term, replacements = {}) => {
    return window.trans.get(term, replacements)
  })

  Vue.filter('transChoice', (term, number, replacements = {}) => {
    return window.trans.choice(term, number, replacements)
  })
}
