var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "fontFamily" } }, [
          _vm._v("Fuente del texto")
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-select form-select-solid form-select-sm",
            attrs: { id: "fontFamily" },
            domProps: { value: _vm.activeElement.style.fontFamily },
            on: {
              input: function($event) {
                return _vm.updateStyle($event, "fontFamily")
              }
            }
          },
          [
            _c("option", { attrs: { value: "Arial, sans-serif" } }, [
              _vm._v("Arial")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Helvetica, sans-serif" } }, [
              _vm._v("Helvetica")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Gill Sans, sans-serif" } }, [
              _vm._v("Gill Sans")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Lucida, sans-serif" } }, [
              _vm._v("Lucida")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Times, serif" } }, [
              _vm._v("Times")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: '"Times New Roman", serif' } }, [
              _vm._v("Times New Roman")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Palatino, serif" } }, [
              _vm._v("Palatino")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Georgia, serif" } }, [
              _vm._v("Georgia")
            ]),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "New Century Schoolbook, serif" } },
              [_vm._v("New Century Schoolbook")]
            ),
            _vm._v(" "),
            _c("option", { attrs: { value: "monospace" } }, [
              _vm._v("Monospace")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Impact, fantasy" } }, [
              _vm._v("Impact")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "fontSize" } }, [
          _vm._v("Tamaño de texto")
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm",
          attrs: {
            type: "number",
            id: "fontSize",
            step: "0.1",
            placeholder: "Tamaño de la fuente"
          },
          domProps: { value: _vm.activeElement.style.fontSize },
          on: {
            input: function($event) {
              return _vm.updateStyle($event, "fontSize")
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "fontWeight" } }, [
          _vm._v("Grosor del texto\n            "),
          _c("span", {
            directives: [{ name: "tooltip", rawName: "v-tooltip" }],
            staticClass: "fa fa-fw fa-info-circle",
            attrs: {
              title: "No todas las fuentes soportan todos los niveles de grosor"
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-select form-select-solid form-select-sm",
            attrs: { id: "fontWeight" },
            domProps: { value: _vm.activeElement.style.fontWeight },
            on: {
              input: function($event) {
                return _vm.updateStyle($event, "fontWeight")
              }
            }
          },
          [
            _c("option", { attrs: { value: "100" } }, [_vm._v("Delgado")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "200" } }, [_vm._v("Muy ligero")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "300" } }, [_vm._v("Ligero")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "400" } }, [_vm._v("Regular")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "500" } }, [_vm._v("Medio")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "600" } }, [_vm._v("Semi-Negrita")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "700" } }, [_vm._v("Negrita")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "800" } }, [
              _vm._v("Extra-Negrita")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "900" } }, [_vm._v("Máximo grosor")])
          ]
        )
      ]),
      _vm._v(" "),
      _c("vue-color-picker", {
        attrs: {
          "class-field": "form-group",
          name: "Color de texto",
          field: "color",
          "needs-target": true
        },
        on: {
          input: function($event) {
            return _vm.updateStyle($event, "color")
          }
        },
        model: {
          value: _vm.activeElement.style.color,
          callback: function($$v) {
            _vm.$set(_vm.activeElement.style, "color", $$v)
          },
          expression: "activeElement.style.color"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { staticClass: "m-checkbox mb-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.activeElement.style.setBackgroundColor,
                  expression: "activeElement.style.setBackgroundColor"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(
                  _vm.activeElement.style.setBackgroundColor
                )
                  ? _vm._i(_vm.activeElement.style.setBackgroundColor, null) >
                    -1
                  : _vm.activeElement.style.setBackgroundColor
              },
              on: {
                change: function($event) {
                  var $$a = _vm.activeElement.style.setBackgroundColor,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.activeElement.style,
                          "setBackgroundColor",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.activeElement.style,
                          "setBackgroundColor",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.activeElement.style, "setBackgroundColor", $$c)
                  }
                }
              }
            }),
            _vm._v("Color del fondo de texto\n            "),
            _c("span")
          ]),
          _vm._v(" "),
          _c("vue-color-picker", {
            attrs: {
              "class-field": "",
              field: "backgroundColor",
              disabled: !_vm.activeElement.style.setBackgroundColor,
              "needs-target": true
            },
            on: {
              input: function($event) {
                return _vm.updateStyle($event, "backgroundColor")
              }
            },
            model: {
              value: _vm.activeElement.style.backgroundColor,
              callback: function($$v) {
                _vm.$set(_vm.activeElement.style, "backgroundColor", $$v)
              },
              expression: "activeElement.style.backgroundColor"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-1" }, [
        _c("label", { attrs: { for: "rotation" } }, [
          _vm._v("Rotación de texto")
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-select form-select-solid form-select-sm",
            attrs: { id: "rotation" },
            domProps: { value: _vm.activeElement.style.rotation },
            on: {
              input: function($event) {
                return _vm.updateStyle($event, "rotation")
              }
            }
          },
          [
            _c("option", { domProps: { value: 0 } }, [_vm._v("0")]),
            _vm._v(" "),
            _c("option", { domProps: { value: 90 } }, [_vm._v("90")]),
            _vm._v(" "),
            _c("option", { domProps: { value: 180 } }, [_vm._v("180")]),
            _vm._v(" "),
            _c("option", { domProps: { value: 270 } }, [_vm._v("270")])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }