<template>
    <div class="form-group m-form__group col-md-6">
        <div class="d-flex flex-row" v-if="!live">
            <input type="hidden" :name="`${name}_checkbox`" :value="customizeCheckbox ? 1 : 0">
            <div class="p-2">
                <label class="m-checkbox">
                    <input type="checkbox" @click="changeCheckBox(true)" title="Modificable" :disabled="nbReadOnly"
                           v-model="customizeCheckbox" :id="'customize-check-' + prefix + name"> {{label}}
                    <span></span>
                </label>
            </div>
        </div>
        <label v-else>
            {{label}}
        </label>
        <input type="hidden" :name="`${prefix}[${name}]`" v-model="usedFilterJSON" ref="saveField">
        <div class="row">
            <div class="col-5 col-select-one">
                <select class="form-select form-select-solid" size="5" @dblclick="moveData()" :disabled="nbReadOnly"
                        v-model="normalFilters" multiple :id="'normal-filters-' + prefix + name">
                    <option v-for="(filter, id) in dataFilters" :value="id">
                        {{filters[id]}}
                    </option>
                </select>
                <div class="row">
                    <div class="col-6">
                        <span class="m-form__help">{{ 'report_groupings.no_include' | trans }}</span>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="m-btn btn btn-sm btn-link" :disabled="nbReadOnly"
                                @click="moveAllData()" :id="'move-all-filters-' + prefix + name">
                            {{ 'report_groupings.include_all' | trans }}
                            <i class="fa fa-angle-double-right"></i>
                        </button>
                    </div>
                </div>
                <nb-error-block :path="`filters.${name}`"></nb-error-block>
            </div>
            <div class="col-2 col-select-center">
                <div class="btn-group-vertical">
                    <button type="button" class="btn btn-outline-metal btn-sm" :disabled="nbReadOnly"
                            @click="moveData()" :id="'move-filters-' + prefix + name">
                        <i class="fa fa-angle-right"></i>
                    </button>
                    <button type="button" class="btn btn-outline-metal btn-sm" :disabled="nbReadOnly"
                            @click="returnData()" :id="'return-filters-' + prefix + name">
                        <i class="fa fa-angle-left"></i>
                    </button>
                </div>
            </div>
            <div class="col-5 col-select-one">
                <select class="form-select form-select-solid" size="5" @dblclick="returnData()" :disabled="nbReadOnly"
                        v-model="usedFilters" multiple :id="'used-filters-' + prefix + name">
                    <option v-for="id in usedFiltersData" :value="id">
                        {{filtersData[id]}}
                    </option>
                </select>
                <div class="row">
                    <div class="col-6">
                        <button type="button" class="m-btn btn btn-sm btn-link" :disabled="nbReadOnly"
                                @click="returnAllData()" :id="'return-all-filters-' + prefix + name">
                            <i class="fa fa-angle-double-left"></i>
                            {{ 'report_groupings.remove_all' | trans }}
                        </button>
                    </div>
                    <div class="col-6 text-right">
                        <span class="m-form__help">{{ 'report_groupings.include' | trans }}</span>
                    </div>
                </div>
                <nb-error-block :path="`filters.${name}`"></nb-error-block>
            </div>
        </div>
    </div>
</template>
<script>
  import { EventBus } from '@/js/vue/services/event-bus'

  export default {
    name: 'list-box-entity',
    props: {
      filters: {
        type: Object,
        default: {}
      },
      live: {
        type: Boolean,
        default: false
      },
      prefix: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      customizable: {
        type: Boolean,
        default: false
      },
      default_values: {
        type: Array,
        default: []
      },
      id: {
        type: Number,
        default: 0
      },
    },
    computed: {
      usedFilterJSON () {
        $(this.$refs.saveField).trigger('change')
        const filters = (this.usedFiltersList || []).filter(elem => elem != '-1')
        if (!filters.length || filters.length === Object.keys(this.filters).length) {
          return JSON.stringify(null)
        }
        return JSON.stringify(filters.map(filter => parseInt(filter)))
      },
      usedFiltersData () {
        if (!this.usedFiltersList || !this.usedFiltersList.length) {
          return ['-1']
        }
        return this.usedFiltersList
      },
      filtersData () {
        const filters = {...this.filters}
        filters['-1'] = 'Todos incluidos'
        return filters
      },
    },
    created: function () {
      if (this.default_values.length > 0) {
        this.usedFiltersList = this.default_values

        this.dataFilters = Object.keys(this.filters)
          .filter(key => !this.usedFiltersList.includes(parseInt(key)))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: this.dataFilters[key]
            }
          }, {})
      } else {
        this.dataFilters = this.filters
      }

      this.customizeCheckbox = this.customizable

      this.changeCheckBox()
    },
    data () {
      return {
        dataFilters: {},
        usedFilters: [],
        usedFiltersList: [],
        normalFilters: [],
        customizeCheckbox: false
      }
    },
    methods: {
      moveAllData () {
        this.usedFiltersList = Object.keys(this.filters)
        this.normalFilters = []
        this.dataFilters = {}
      },
      moveData () {
        this.usedFiltersList = this.usedFiltersList.concat(Array.from(this.normalFilters))
        this.normalFilters = []
        this.dataFilters = Object.keys(this.filters)
          .filter(key => !this.usedFiltersList.map(option => parseInt(option)).includes(parseInt(key)))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: this.dataFilters[key]
            }
          }, {})
      },
      returnAllData () {
        this.usedFiltersList = []
        this.normalFilters = []

        this.dataFilters = Object.keys(this.filters)
          .filter(key => !this.usedFiltersList.includes(parseInt(key)))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: this.dataFilters[key]
            }
          }, {})
      },
      returnData () {
        let dataFilterKeys = Object.keys(this.dataFilters)
        dataFilterKeys = dataFilterKeys.concat(this.usedFilters)
        this.usedFilters = []

        this.dataFilters = Object.keys(this.filters)
          .filter(key => dataFilterKeys.map(option => parseInt(option)).includes(parseInt(key)))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: this.dataFilters[key]
            }
          }, {})

        this.usedFiltersList = Object.keys(this.filters)
          .filter(key => !dataFilterKeys.map(option => parseInt(option)).includes(parseInt(key)))
      },
      changeCheckBox (change = false) {

        if (change) {
          this.customizeCheckbox = !this.customizeCheckbox
          EventBus.$emit('filterStatusChange', this.id, this.label, !this.customizeCheckbox)
        }
      }
    }
  }
</script>
<style scoped>
    .col-select-one {
        flex: 0 0 45%;
        max-width: 45%;
    }

    .col-select-one:first-child {
        padding-right: 0px;
    }

    .col-select-one:last-child {
        padding-left: 0px;
    }

    .col-select-center {
        flex: 0 0 10%;
        max-width: 10%;
        text-align: center !important;
        margin-top: 25px;
    }
</style>
