<template>
    <div class="col-12">
        <div role="alert" class="alert alert-warning alert-dismissible d-flex flex-column flex-sm-row p-5 mb-10 show">
            <span class="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                <i class="alert-warning bi bi-exclamation-circle" style="font-size: 2rem;"></i>
            </span>
            <div class="pe-0 pe-sm-10">
                <h4 class="fw-bold">Los siguientes campos son necesarios para tramitar facturas nominales: </h4>
                <span v-for="field in remainingFields">
                    <span v-if="remainingFields.indexOf(field) < remainingFields.length - 2">
                        {{ 'forms_crm_customers.' + field | trans }},
                    </span>
                    <span v-else-if="remainingFields.indexOf(field) < remainingFields.length - 1">
                        {{ 'forms_crm_customers.' + field | trans }} y
                    </span>
                    <span v-else-if="remainingFields.indexOf(field) === remainingFields.length - 1">
                        {{ 'forms_crm_customers.' + field | trans }}.
                    </span>
                </span>
            </div>
            <button type="button" data-bs-dismiss="alert" aria-label="Close" class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" id="close">
                <span class="svg-icon svg-icon-1 svg-icon-primary">
                    <i class="alert-warning bi bi-x-lg"></i>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'billing-alert',
    props: ['remainingFields'],
  }

</script>
