<template>
  <div class="form-group m-form__group col-md-6">
    <label :for="fieldName">
      <slot></slot>
      <button v-if="create && fieldName !== null" type="button" :disabled="nbReadOnly"
              class="select-with-create__btn btn btn-primary pt-2 pb-2 ml-1"
              :id="'create-' + fieldName" @click="launchModal">
        Crear
        <em class="fa fa-plus-circle"></em>
      </button>
    </label>
    <multiselect :id="'triggers-' + fieldName"
                 v-model="currentSelected"
                 track-by="id"
                 label="name"
                 :options="options"
                 :disabled="nbReadOnly">
      <template slot="option" slot-scope="props">
        <div class="option__desc option__desc--select-with-create">
                    <span class="option__title">
                        {{ props.option.name }}
                    </span>
          <span v-if="props.option.badge" class="badge" :class="props.option.class">
                        {{ props.option.badge }}
                    </span>
        </div>
      </template>
    </multiselect>
    <input :id="fieldName" :disabled="nbReadOnly"
           type="hidden"
           :name="fieldName"
           :value="currentSelected ? currentSelected.id : null">
    <nb-error-block :path="fieldName"></nb-error-block>

    <modal-component id="createModal"
                     :visible.sync="visible"
                     :backdrop="false"
                     :openMaximized="true"
                     :classesModal="['modal--no-paddings', 'modal-iframe']"
                     v-if="fieldName !== null">
      <template #title>
        <slot name="modalTitle"></slot>
      </template>
      <template>
        <iframe :id="'iframe-' + fieldName" :src="pathCreateModel"></iframe>
      </template>
      <template #footer>
        <button type="button" class="btn btn-primary" @click="visible = false">Cerrar</button>
      </template>
    </modal-component>
  </div>
</template>

<script>
export default {
  name: 'select-with-create',
  props: {
    fieldName: {type: String, default: null},
    model: {type: String, default: null},
    route: {type: String, default: null},
    params: {
      type: Object, default: () => {
      }
    },
    initial: {type: Number, default: null},
    initialData: {type: Object, default: null},
    create: {type: Boolean, default: true}
  },
  data() {
    return {
      currentSelected: null,
      options: [],
      visible: false,
      initialLoaded: false,
    }
  },
  computed: {
    pathCreateModel() {
      return `/crm/${this.model}/create?only-content`
    }
  },
  mounted() {
    this.ajaxRefreshData()
  },
  watch: {
    currentSelected: function (val) {
      if (this.initialLoaded) {
        this.$emit('dirty', this.fieldName, val)
      }
    },
    visible: function (val) {
      if (val === false) {
        this.close()
      }
    },
    initial(val) {
      this.ajaxRefreshData()
    }
  },
  methods: {
    launchModal() {
      this.visible = true
    },
    close() {
      this.visible = false
      this.ajaxRefreshData()
    },
    ajaxRefreshData() {
      let context = this
      return axios.post(route(context.route), context.params)
          .then(function (response) {
            context.options = response.data
            let option = context.options.find(o => o.id === context.initial)
            if (option) {
              context.currentSelected = option
            } else {
              context.currentSelected = null
            }
          })
          .catch(function (error) {
            console.log(error)
          })
          .finally(function () {
            context.initialLoaded = true
          })
    },
  },
}
</script>

<style>
.option__desc--select-with-create .badge {
  float: right;
  margin-right: 5px;
  margin-top: 2px;
}
</style>
