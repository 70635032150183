<template>
    <div>

        <div class="d-flex flex-column">
            <div class="form-group w-100">
                <label for="displayValue">Mostrar valor</label>
                <select class="form-select form-select-solid form-select-sm" id="displayValue"
                        :value="activeElement.barcode.displayValue"
                        @input="updateBarcode($event, 'displayValue')">
                    <option :value="true">Si</option>
                    <option :value="false">No</option>
                </select>
            </div>

            <div class="form-group w-100 mb-1">
                <label for="elementContent">Contenido almacenado en código de barras</label>

                <ValueSuggester v-on:layoutChange="layoutChange"/>
            </div>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters, mapMutations } from 'vuex'
  import ValueSuggester from '@/js/components/ticket_editor/tabs/element-tabs/ValueSuggester'

  export default {
    name: 'BarcodeFieldProperties',
    components: { ValueSuggester },

    computed: {
      ...mapGetters('ticketEditor', {
        activeElement: 'activeElement',
      }),
    },

    methods: {
      ...mapMutations('ticketEditor', {
        updateBarcodeProperties: 'updateBarcodeProperties',
      }),

      updateBarcode (event, prop) {
        this.updateBarcodeProperties({ prop, value: event.target.value })
        this.layoutChange()
      },

      layoutChange () { this.$eventBus.$emit('layoutChange') },
    },
  }
</script>

<style scoped>
    label {
        font-size: 0.9rem !important;
        margin-bottom: 0.4rem !important;
    }
</style>
