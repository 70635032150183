<template>
    <div class="card mb-5" :class="{'m-portlet': isNotActiveSelect}">
        <div class="card-header border-0 pt-5 pb-0" v-if="isNotActiveSelect">
            <h3 class="card-title align-items-start flex-column">
                Archivos
            </h3>
            <div class="card-toolbar d-flex align-items-center gap-2 gap-lg-3">
                <a :href="getRouteCreate()" class="m-btn btn btn-primary m-btn--icon" v-if="canCreateFile"
                   id="route-create">
                    <span>
                        <em class="fa fa-plus fa-fw me-1"></em> <span>Crear</span>
                    </span>
                </a>
                <upload-files v-if="isNotActiveSelect && canCreateFile"
                              name="Subir archivos"
                              :types="filesTypes"
                              :type="type"
                              :type-default="typeDefault"
                              :group-id="groupId"
                              :group-type="groupType"
                              :isPrivate="isPrivateUpload"
                              :allowed-mime-types="allowedMimeTypes"
                              @reload="reloadFilesType"
                              @open="shadow = true"
                              @close="shadow = false">
                </upload-files>
                <a :href="getRouteExport()" class="m-btn btn btn-secondary m-btn--icon" id="route-export">
                    <span>
                        <em class="fa fa-cloud-download-alt"></em> <span>Exportar</span>
                    </span>
                </a>
            </div>
        </div>
        <div class="card-body pt-0" :class="{'mb-0 py-0' : isActiveSelect}"
             id="list-files-types">
            <div class="d-sm-flex justify-content-between">
                <div
                        :class="{'col-md-6' : isActiveSelect, 'col-md-12' : isNotActiveSelect, 'box-blur-shadow' : isActiveShadow}">
                    <h4 class="d-flex align-items-center mb-5">
                        <span v-if="type">
                            <a href="javascript:" @click="goToHome()" id="types">Tipos</a>
                            <span v-for="navigation in getNavigation">
                                >
                                <a href="javascript:" @click="selectType(navigation)"
                                   :id="'navigation-' + navigation.name">
                                    {{ navigation.name }}
                                </a>
                            </span>
                        </span>
                        <span v-else>Tipos</span>
                    </h4>
                </div>
                <div class="mb-5" v-if="isActiveSelect">
                    <upload-files v-if="isActiveSelect"
                                  name="Subir archivos"
                                  :types="filesTypes"
                                  :type="type"
                                  :active-select="isActiveShadow"
                                  :group-id="groupId"
                                  :group-type="groupType"
                                  :type-default="typeDefault"
                                  :isPrivate="isPrivateUpload"
                                  :allowedMimeTypes="allowedMimeTypes"
                                  @reload="reloadFilesType"
                                  @open="shadow = true"
                                  @close="shadow = false">
                    </upload-files>
                </div>
            </div>
            <div class="row list" :class="{'box-blur-shadow' : isActiveShadow}">
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12" v-for="type in listTypes">
                    <div class="list-type" @click="selectType(type)">
                        <div class="list-type-icon">
                            <i class="fa fa-folder-open display-4"></i>
                        </div>
                        <div class="list-type-text">
                            {{ type.name }}
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 position-relative" v-if="typeNew">
                    <div class="list-type disabled">
                        <div class="list-type-delete">
                            <button class="btn btn-danger btn-sm" type="button" @click="deleteNewType()"
                                    :id="'delete-type-' + typeNew.id">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                        <div class="list-type-icon">
                            <i class="fa fa-folder display-4"></i>
                        </div>
                        <div class="list-type-text" :class="{'has-danger' : typeNew.error}">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm m-input" maxlength="255"
                                       placeholder="Escriba el nombre" name="name" v-model="typeNew.name"
                                       :id="'type-name-' + typeNew.id">
                                <div class="input-group-append">
                                    <button class="rounded-0 rounded-end btn btn-primary btn-sm" type="button" @click="saveNewType()"
                                            :id="'save-type-' + typeNew.id">
                                        <em class="fa fa-save"></em>
                                    </button>
                                </div>
                            </div>
                            <div class="form-control-feedback" v-if="typeNew.error">Debe escribir el nombre</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12" v-if="canCreateType">
                    <div class="list-type list-type-new" :class="{'disabled' : !canAddNew}" @click="addNewType()">
                        <div class="list-type-icon">
                            <i class="fa fa-plus display-4"></i>
                        </div>
                        <div class="list-type-text">
                            Agregar tipo
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-5" :class="{'box-blur-shadow' : isActiveShadow}" v-if="type">
                <div class="col-12 mb-3">
                    <div class="m-separator m-separator--dashed mt-1"></div>
                </div>
                <div class="col-4 mb-0">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Buscar un archivo..." v-model="search"
                               id="search-files">
                        <div class="input-group-append">
                            <button class="btn btn-primary rounded-0 rounded-end" type="button" @click="searchFiles()"
                                    :disabled="!activeSearch()" id="search">Buscar
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-8 text-right">
                    <div class="btn-group mr-4" role="group" v-if="isActiveSelect && isActiveMultiple()">
                        <button type="button" class="m-btn btn" @click="selectAllFiles()" id="select-all"
                                :class="{'btn-brand' : selectAllActive, 'btn-secondary' : !selectAllActive}">
                            <i class="fa fa-check mr-1"></i>
                            Seleccionar todos
                        </button>
                    </div>
                    <div class="btn-group me-3" role="group">
                        <button type="button" class="border m-btn btn" @click="setOrder(order)" :id="'orders-' + order.id"
                                :class="{'btn-brand' : getOrderActive(order), 'btn-secondary' : !getOrderActive(order)}"
                                v-for="order in orders">
                            <i class="fa mr-1"
                               :class="{'fa-sort-amount-asc' : getClassOrderAsc(order), 'fa-sort-amount-desc' : getClassOrderDesc(order)}"></i>
                            {{ order.text }}
                        </button>
                    </div>
                    <div class="btn-group" role="group">
                        <button type="button" class="m-btn btn border" @click="viewBox = true" id="view"
                                :class="{'btn-brand' : viewBox, 'btn-secondary' : !viewBox}">
                            <i class="fa fa-th-large"></i>
                        </button>
                        <button type="button" class="m-btn btn border" @click="viewBox = false" id="no-view"
                                :class="{'btn-brand' : !viewBox, 'btn-secondary' : viewBox}">
                            <i class="fa fa-list"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row files" :class="{'box-blur-shadow' : isActiveShadow}" v-if="type && viewBox">
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12" v-for="file in getListFiles">
                    <div class="file" :class="{'file-check' : activeFileSelect(file.id)}">
                        <div class="file-background" :class="{'file-background__hover' : isShowable(file)}"
                             :style="getBackgroundImage(file)" @click="showModal(file)">
                            <div class="file-icon" v-if="!isShowable(file)">
                                <i class="la la-file-text"></i>
                            </div>
                        </div>
                        <div class="file-text">
                            {{ file.name }}
                        </div>
                        <div class="file-actions" v-if="(file.edit || file.delete) && isNotActiveSelect">
                            <div class="btn-group m-btn-group btn-block d-flex" role="group">
                                <a :href="file.edit" :id="'file-edit-' + file.id"
                                   class="rounded-top-0 rounded-bottom-0 btn btn-light m-btn m-btn--icon btn-sm m-btn--square"
                                   v-if="file.edit && canUpdateFile">
                                    <em class="fa fa-fw fa-pencil-alt m--font-brand"></em>
                                    Editar
                                </a>
                                <a href="javascript:" :data-url="file.delete" data-event-name="reloadImages"
                                   class="rounded-top-0 rounded-bottom-0 btn btn-light m-btn m-btn--icon btn-sm m-btn--square js-remove-model"
                                   v-if="file.delete && canDeleteFile" :id="'file-delete-' + file.id">
                                    <em class="fa fa-fw fa-trash"></em>
                                    Eliminar
                                </a>
                            </div>
                        </div>
                        <div class="file-checkbox" v-if="isActiveSelect">
                            <label class="m-checkbox">
                                <input type="checkbox" @click="setFileSelect(file)" :id="'file-select-' + file.id"
                                       :checked="activeFileSelect(file.id)">
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" v-if="!files.length">
                    <div class="fs-5 fw-bolder alert text-center my-20" role="alert">
                        <div class="m-loader m-loader--info" v-if="loading"></div>
                        <span v-else>No se han encontrado elementos</span>
                    </div>
                </div>
            </div>
            <div class="row" :class="{'box-blur-shadow' : isActiveShadow}" v-if="type && !viewBox">
                <div class="col-12">
                    <div class="table-responsive" v-if="files.length">
                        <table class="table table-striped m-table">
                            <thead>
                            <tr>
                                <th class="text-center" width="5%" v-if="isActiveSelect">
                                    <label class="m-checkbox m-checkbox--state-brand"
                                           :class="{'m-checkbox--solid m-checkbox--state-brand' : selectAllActive}"
                                           v-if="isActiveMultiple()">
                                        <input type="checkbox" :checked="selectAllActive" @click="selectAllFiles()"
                                               id="select-all-files">
                                        <span></span>
                                    </label>
                                </th>
                                <th width="7%" class="text-center">Miniatura</th>
                                <th>Nombre</th>
                                <th class="text-center" width="5%" v-if="isNotActiveSelect">Acción</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="file in files">
                                <td class="align-middle text-nowrap text-center" v-if="isActiveSelect">
                                    <label class="m-checkbox"
                                           :class="{'m-checkbox--solid m-checkbox--state-brand' : activeFileSelect(file.id)}">
                                        <input type="checkbox" @click="setFileSelect(file)"
                                               :checked="activeFileSelect(file.id)" :id="'file-selected-' + file.id">
                                        <span></span>
                                    </label>
                                </td>
                                <td>
                                    <div class="img-thumbnail" v-if="isShowable(file)">
                                        <img :src="getShowableUrl(file)" alt=""
                                             @click="showModal(file)">
                                    </div>

                                    <div class="img-thumbnail text-center"
                                         v-if="!isShowable(file)">
                                        <h1>
                                            <i class="la la-file-text"></i>
                                        </h1>
                                    </div>
                                </td>
                                <td class="align-middle text-nowrap">
                                    <a :href="file.edit" v-if="file.edit && isNotActiveSelect && canUpdateFile"
                                       :id="'file-' + file.id">
                                        {{ file.name }}
                                    </a>
                                    <span v-else>{{ file.name }}</span>
                                </td>
                                <td class="align-middle text-nowrap text-center" v-if="isNotActiveSelect">
                                    <a href="javascript:" :data-url="file.delete" data-event-name="reloadImages"
                                       class="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only js-remove-model"
                                       :id="'delete-file-' + file.id" v-if="file.delete && canDeleteFile">
                                        <em class="fa fa-fw fa-trash"></em>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="fs-5 fw-bolder alert text-center my-20" role="alert"
                         v-else>
                        <div class="m-loader m-loader--info" v-if="loading"></div>
                        <span v-else>No se han encontrado elementos</span>
                    </div>
                </div>
            </div>
            <div class="row" :class="{'box-blur-shadow' : isActiveShadow}"
                 v-show="files.length > 0 && paginate.last_page > 1">
                <div class="col-12" v-if="paginate.last_page">
                    <div class="pull-right">
                        <ul role="navigation" class="pagination">
                            <li class="page-item" :class="{'disabled' : page === 1}">
                                <span class="page-link" @click="setPage(--page)">‹</span>
                            </li>
                            <li class="page-item" v-for="page in countPaginate"
                                :class="{'active' : page === paginate.current_page, 'disabled' : !isNumber(page)}">
                                <span class="page-link" @click="setPage(page)" v-if="isNumber(page)">
                                    {{ page }}
                                </span>
                                <span class="page-link" v-else>
                                    {{ page }}
                                </span>
                            </li>
                            <li class="page-item" :class="{'disabled' : page === paginate.last_page}">
                                <span class="page-link" @click="setPage(++page)">›</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <modal-component id="repositoryModal"
                             :visible.sync="openModal"
                             :backdrop="true"
                             :keyboard="true">
                <template #title v-if="image">
                    {{ image.name }}
                </template>
                <template>
                    <div class="modal-body-image" v-if="image">
                        <img :src="image.url" :alt="image.name" @error="imgDefault"/>
                    </div>
                </template>
            </modal-component>


        </div>
    </div>
</template>

<script>
  import UploadFiles from '@/js/components/repository_explorer/UploadFiles'
  import ModalComponent from '@/js/components/widgets/ModalComponent'
  import { mapGetters } from 'vuex'

  export default {
    components: { UploadFiles, ModalComponent },
    name: 'repository',
    props: {
      field: { Boolean, default: false },
      activeSelect: { Boolean, default: false },
      routes: { Object, default: () => ({}) },
      types: { Object, default: () => ({}) },
      typeDefault: { Number },
      permits: {
        Object, default: () => ({
          'create': {
            'file': false,
            'type': false
          },
          'update': false,
          'delete': false
        })
      },
      groupId: { Number },
      groupType: { String },
      multiple: { Boolean, default: false },
      selectFiles: { Object, default: () => ([]) },
      clearSelect: { Boolean, default: false },
      allowedMimeTypes: { String, default: '' },
      isPrivateUpload: { type: Boolean, default: false },
    },
    created () {
      document.addEventListener('reloadImages', () => {this.listFiles()}, false)
      this.filesTypes = this.types
    },
    data () {
      return {
        type: null,
        image: null,
        files: {},
        filesSelect: {},
        filesTypes: {},
        page: 1,
        viewBox: true,
        loading: false,
        paginate: {},
        paginateElements: {},
        typeNew: null,
        search: null,
        searchText: null,
        shadow: false,
        selectAll: false,
        orderDefault: {
          field: 'id',
          order: 'desc'
        },
        orders: [
          { field: 'id', text: 'ID', order: 'desc' },
          { field: 'name', text: 'Nombre', order: 'asc' },
        ],
        box: '#list-files-types',
        openModal: false,
      }
    },
    watch: {
      filesSelect: function (val) {
        let list = []
        let value
        let file
        let data

        for (value in val) {
          data = {
            id: value,
            delete: true
          }

          file = val[value]
          if (file) {
            data = {
              id: file.id,
              mime_type: file.mime_type,
              name: file.name,
              url: file.url,
            }
          }

          list.push(data)
        }

        this.$emit('repositoryInput', list)

        if (!this.multiple && this.field) {

          this.$emit('repositorySetOptions', true)
        }
      },
      clearSelect: function (val) {
        if (val) {
          this.clearFileSelect()
        }
      },
      openModal: function (val) {
        if (this.openModal === false) {
          this.shadow = false
          this.image = null
        } else {
          this.shadow = true
        }
      },
    },
    computed: {
      ...mapGetters('Repository', {
        showable: 'getShowable'
      }),
      getListFiles () {
        return this.files
      },
      isActiveSelect () {
        return this.activeSelect
      },
      isNotActiveSelect () {
        return !this.isActiveSelect
      },
      isActiveShadow () {
        return this.shadow
      },
      canAddNew () {
        return this.typeNew === null
      },
      canCreateType () {
        return this.permits.create.type
      },
      canCreateFile () {
        return this.permits.create.file
      },
      canUpdateFile () {
        return this.permits.update
      },
      canDeleteFile () {
        return this.permits.delete
      },
      listTypes () {
        let list = []
        let key
        let type

        for (key in this.filesTypes) {
          type = this.filesTypes[key]
          if (this.type) {
            if (this.type.id === type.father_id) {
              list.push(type)
            }
          } else if (!type.father_id) {
            list.push(type)
          }
        }

        return list
      },
      getNavigation () {
        let list = [this.type]

        list = this.setNavigation(this.type, list)

        return list.reverse()
      },
      countPaginate () {
        let pages = []
        let element
        let key
        let page

        for (key in this.paginateElements) {
          element = this.paginateElements[key]
          if (typeof element === 'string') {
            pages.push(element)
          }

          if (typeof element === 'object') {
            for (page in element) {
              pages.push(parseInt(page))
            }
          }
        }

        return pages
      },
      selectAllActive () {
        return this.selectAll
      },
    },
    methods: {
      imgDefault (event) {
        event.target.src = '/images/logo/logo_default_light.png'
      },
      async listFiles () {
        this.clearFileSelect()
        this.activeLoading()
        try {
            console.log(this.fieldsPost())
          let response = await axios.post(route('repository.listFiles', this.fieldsPost(), false))
          this.filterFiles(response.data)
        } catch (error) {
          console.log(error)
        }
        this.inactiveLoading()
      },
      async addNewTypePost () {
        this.activeLoading()
        try {
          let response = await axios.post(route('API.repositoryTypes.create', this.fieldsNewType(), false))
          this.filterTypes(response.data)
        } catch (error) {
          console.log(error)
        }
        this.inactiveLoading()
      },
      isActiveMultiple () {
        return this.multiple
      },
      addNewType () {
        let fatherId = null

        if (this.type) {
          fatherId = this.type.id
        }

        if (this.canAddNew) {
          this.typeNew = {
            fatherId: fatherId,
            name: '',
            error: false
          }
        }
      },
      saveNewType () {
        this.typeNew.error = false

        if ($.trim(this.typeNew.name) === '') {
          this.typeNew.error = true
          return
        }

        this.addNewTypePost()
      },
      deleteNewType () {
        this.typeNew = null
      },
      selectAllFiles () {
        let file
        let key

        this.selectAll = !this.selectAll

        for (key in this.files) {
          file = this.files[key]
          this.filesSelect[file.id] = this.selectAll ? file : false
        }

        this.filesSelect = Object.assign({}, this.filesSelect)
      },
      activeFileSelect (id) {
        let active = false

        if (this.filesSelect[id]) {
          active = true
        }

        if (this.selectFiles.length > 0 && !this.filesSelect.hasOwnProperty(id)) {
          if (this.activeSelectFile(id)) {
            active = true
          }
        }

        return active
      },
      activeSelectFile (id) {
        if (this.positionSelectFile(id) >= 0) {
          return true
        }

        return false
      },
      positionSelectFile (id) {
        return this.selectFiles.findIndex(file => file.id === id)
      },
      fieldsPost () {
        let type = null

        if (this.type instanceof Object) {
          type = this.type.hasOwnProperty('id') ? this.type.id : null
        }

        let object = {
          type: type,
          group_id: this.groupId,
          group_type: this.groupType,
          page: this.page,
          order_field: this.orderDefault.field,
          order: this.orderDefault.order,
          mime: this.allowedMimeTypes
        }

        if (this.searchText) {
          object = Object.assign(object, { search: this.searchText })
        }

        return object
      },
      fieldsNewType () {
        let object = {
          name: this.typeNew.name
        }

        if (this.typeNew.fatherId) {
          object = Object.assign(object, { father_id: this.typeNew.fatherId })
        }

        return object
      },
      filterTypes (data) {
        this.filesTypes = data.types
        this.deleteNewType()
      },
      filterFiles (data) {
        let list = data.list
        if (this.allowedMimeTypes) {
          const mime = this.allowedMimeTypes.replace(/(\*)|(\.)|(\s*,\s*)/g, (match, any, dot, comma) => {
            if (any) return '.*?'
            else if (dot) return '\\.'
            else if (comma) return '|'
          })
          const regexp = new RegExp(mime)
          list = list.filter(file => file.mime_type && file.mime_type.match(regexp))
        }
        this.files = list
        this.paginate = data.paginator
        this.paginateElements = data.elements
      },
      searchFiles () {
        this.searchText = this.search
        this.page = 1
        this.listFiles()
      },
      setOrder (order) {
        let sort = order.order

        if (this.orderDefault.field === order.field) {
          sort = this.orderDefault.order === 'desc' ? 'asc' : 'desc'
        }

        this.orderDefault = {
          field: order.field,
          order: sort
        }

        this.listFiles()
      },
      reloadFilesType (type) {
        this.type = type
        this.orderDefault = {
          field: 'id',
          order: 'desc'
        }
        this.listFiles()
      },
      clearFileSelect () {
        this.filesSelect = {}
        this.selectAll = false
      },
      activeSearch () {
        if (this.search) {
          return this.search.length >= 3
        }

        return false
      },
      setNavigation (type, list) {
        if (type.father_id) {
          let father = this.filesTypes.filter(item => item.id === type.father_id)
          let element = father.shift()
          list.push(element)
          list = this.setNavigation(element, list)
        }

        return list
      },
      isShowable (file) {
        return this.showable.hasOwnProperty(file.mime_type)
      },
      getClassOrder (order, sort) {
        let orderValue = order.order

        if (this.orderDefault.field === order.field) {
          orderValue = this.orderDefault.order
        }

        if (orderValue === sort) {
          return true
        }

        return false
      },
      getOrderActive (order) {
        return this.orderDefault.field === order.field
      },
      getClassOrderAsc (order) {
        return this.getClassOrder(order, 'asc')
      },
      getClassOrderDesc (order) {
        return this.getClassOrder(order, 'desc')
      },
      getBackgroundImage (file) {
        let url = this.getShowable(file)

        return url !== '' ? { backgroundImage: url } : null
      },
      getShowable (file) {
        if (this.isShowable(file)) {
          return 'url(' + file.url + ')'
        }

        return ''
      },
      getShowableUrl (file) {
        if (this.isShowable(file)) {
          return file.url
        }

        return false
      },
      goToHome () {
        this.type = null
        this.search = null
        this.files = {}
        this.clearFileSelect()
        this.orderDefault = {
          field: 'id',
          order: 'desc'
        }
      },
      selectType (type) {
        this.type = type
        this.page = 1
        this.deleteNewType()
        this.listFiles()
      },
      activeLoading () {
        this.loading = true
        mApp.block(this.box, {})
      },
      inactiveLoading () {
        this.loading = false
        this.searchText = false
        mApp.unblock(this.box)
      },
      getRouteCreate () {
        if (this.routes.create) {
          return this.routes.create
        }

        return ''
      },
      getRouteExport () {
        let url = []
        let urlReturn = ''

        if (this.orderDefault) {
          url.push('sort=' + this.orderDefault.field + '.' + this.orderDefault.order)
        }

        if (this.activeSearch()) {
          url.push('q=' + encodeURI(this.search))
        }

        if (this.type) {
          url.push('type_id=' + this.type.id)
        }

        if (url.length > 0) {
          urlReturn = '?' + url.join('&')
        }

        if (this.routes.export) {
          return this.routes.export + urlReturn
        }

        return ''
      },
      isNumber (value) {
        return typeof value === 'number'
      },
      setPage (page) {
        if (this.loading === false) {
          this.page = page
          this.listFiles()
        }
      },
      setFileSelect (file) {
        let data = file
        let active = false
        let position

        if (this.selectFiles.length > 0) {
          position = this.positionSelectFile(file.id)
          if (position >= 0) {
            active = true
            data = false
            if (this.filesSelect.hasOwnProperty(file.id)) {
              data = !this.filesSelect[file.id] ? file : false
            }
          }
        }

        if (!active) {
          if (this.filesSelect[file.id]) {
            data = false
          }
        }

        this.filesSelect[file.id] = data

        this.filesSelect = Object.assign({}, this.filesSelect)
      },
      showModal (image) {
        if (this.isActiveSelect) {
          this.setFileSelect(image)
          this.openModal = false
          this.shadow = false
          return
        }

        if (this.isShowable(image)) {
          this.openModal = true
          this.image = image
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
    .box-blur-shadow {
        transition: all .5s ease;
        opacity: 0.2;
    }

    .list-type {
        padding: 0px;
        border: 1px solid #F1F1F1;
        border-radius: 0.25rem;
        text-align: center;
        margin-bottom: 1rem;

        &:hover:not(.disabled) {
            cursor: pointer;
            transition: all .3s ease;
            box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.3);
            border-color: white;
            background-color: #F9F9F9;

            .list-type-icon {
                transition: all .5s ease;
                color: #007DB8;
            }

            .list-type-text {
                transition: all .5s ease;
                background-color: white;
                color: #007DB8;
            }
        }

        .list-type-delete {
            position: absolute;
            right: 10px;
            top: -3px;
        }

        .list-type-icon {
            font-size: 4em;
        }

        .list-type-text {
            background-color: #F9F9F9;
            padding: 10px;

            .form-control-feedback {
                text-align: left;
                margin-top: 0.2rem;
                font-size: .85rem;
            }
        }
    }

    .list-type-new {
        .list-type-text {
            background-color: white;
        }
    }

    .img-thumbnail {
        max-width: 100px;

        img {
            transition: all .5s ease;

            &:hover {
                cursor: pointer;
                opacity: 0.5;
            }

            width: 100%;
        }
    }

    .files {
        .file {
            border: 1px solid #F1F1F1;
            margin-bottom: 2rem;
            background-image: url(/images/misc/fondo-cuadros.png);

            .file-background {
                transition: all .5s ease;
                background: center center no-repeat;
                background-size: 100%;
                width: 100%;
                height: 150px;
                .file-icon {
                    text-align: center;
                    font-size: 7rem;
                }
            }

            .file-background__hover {
                &:hover {
                    background-size: 120%;
                    cursor: pointer;
                }
            }

            .file-text {
                background-color: #F9F9F9;
                padding: 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                border-top: 1px solid #F1F1F1;
                border-bottom: 1px solid #F1F1F1;
            }

            .file-actions {
                .btn {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }

            .file-checkbox {
                background: #fff;
                text-align: center;
                height: 32px;

                .m-checkbox {
                    padding-left: 20px;
                }
            }
        }

        .file-check {
            border: 1px solid #007DB8;

        }
    }

    .table-responsive {
        .m-checkbox {
            padding-left: 20px;
            margin-bottom: 16px;
        }
    }

    .modal-body-image {
        text-align: center;

        img {
            max-width: 100%;
        }
    }
</style>
