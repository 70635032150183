var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "ticket-select-elements__list-item",
      class:
        _vm.activeItem && _vm.activeItem.id === _vm.item.id ? "active" : "",
      attrs: { id: "item-" + _vm.item.id },
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.$emit("itemActivated", _vm.item)
        }
      }
    },
    [
      _vm.handleIndicator(_vm.item)
        ? _c("span", { staticClass: "fa fa-fw fa-eye open-eye" })
        : _c("span", { staticClass: "fa fa-fw fa-eye-slash closed-eye" }),
      _vm._v(" "),
      _vm.item.visible === null
        ? _c("span", {
            directives: [{ name: "tooltip", rawName: "v-tooltip" }],
            staticClass: "fa fa-asterisk conditional-tip",
            attrs: {
              title:
                (_vm.handleIndicator(_vm.item) ? "Visible" : "Oculto") +
                " condicionalmente"
            }
          })
        : _vm._e(),
      _vm._v("\n    " + _vm._s(_vm.item.name) + "\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }