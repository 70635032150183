var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group m-form__group col-12" },
    [
      _c("input", {
        attrs: { name: _vm.name, type: "hidden" },
        domProps: { value: _vm.listJSON }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-sm-flex justify-content-between" }, [
        _c(
          "h4",
          { staticClass: "d-flex align-items-center mb-5" },
          [
            _c("em", { staticClass: "flaticon-settings mr-1" }),
            _vm._v(" "),
            "title" in _vm.interfaceTexts
              ? [_vm._v(_vm._s(_vm.interfaceTexts["title"]))]
              : [_vm._v("Listado")]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "mb-5 btn btn-primary m-btn btn-sm m-btn m-btn--icon",
            attrs: { id: "add", type: "button", disabled: _vm.nbReadOnly },
            on: {
              click: function($event) {
                return _vm.add()
              }
            }
          },
          [
            _c("span", [
              _c("i", { staticClass: "fa fa-plus" }),
              _vm._v(" "),
              _c(
                "span",
                [
                  "add-button" in _vm.interfaceTexts
                    ? [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.interfaceTexts["add-button"]) +
                            "\n                    "
                        )
                      ]
                    : [_vm._v("Añadir nueva entrada")]
                ],
                2
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "table table-striped table-bordered m-table" },
        [
          _c("thead", [
            _c(
              "tr",
              [
                _vm._l(_vm.headers, function(header, key) {
                  return _vm.getInputType(key) != "hidden"
                    ? _c("th", [
                        _c("span", { staticClass: "column-no-sort" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.getHeaderName(header)) +
                              "\n                    "
                          )
                        ])
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                !_vm.isEmptyActions()
                  ? _c("th", { staticClass: "text-center" })
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _vm.list.length
            ? _c(
                "paginate",
                {
                  attrs: { name: "list", tag: "tbody", list: _vm.list, per: 10 }
                },
                _vm._l(_vm.paginated("list"), function(value, id) {
                  return _c(
                    "tr",
                    { attrs: { "data-model-id": id } },
                    [
                      _vm._l(Object.keys(value), function(key, metaKey) {
                        return _vm.getInputType(metaKey) != "hidden"
                          ? _c("td", { staticClass: "align-middle" }, [
                              _c(
                                "div",
                                { staticClass: "m-form__control" },
                                [
                                  _vm.getInputType(metaKey) === "checkbox"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: value[key],
                                            expression: "value[key]"
                                          }
                                        ],
                                        staticClass:
                                          "form-control form-control-sm m-input",
                                        attrs: {
                                          id: _vm.name + "-" + id,
                                          disabled: _vm.nbReadOnly,
                                          required: _vm.isRequired(metaKey),
                                          type: "checkbox"
                                        },
                                        domProps: {
                                          checked: Array.isArray(value[key])
                                            ? _vm._i(value[key], null) > -1
                                            : value[key]
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = value[key],
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    value,
                                                    key,
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    value,
                                                    key,
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(value, key, $$c)
                                            }
                                          }
                                        }
                                      })
                                    : _vm.getInputType(metaKey) === "radio"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: value[key],
                                            expression: "value[key]"
                                          }
                                        ],
                                        staticClass:
                                          "form-control form-control-sm m-input",
                                        attrs: {
                                          id: _vm.name + "-" + id,
                                          disabled: _vm.nbReadOnly,
                                          required: _vm.isRequired(metaKey),
                                          type: "radio"
                                        },
                                        domProps: {
                                          checked: _vm._q(value[key], null)
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(value, key, null)
                                          }
                                        }
                                      })
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: value[key],
                                            expression: "value[key]"
                                          }
                                        ],
                                        staticClass:
                                          "form-control form-control-sm m-input",
                                        attrs: {
                                          id: _vm.name + "-" + id,
                                          disabled: _vm.nbReadOnly,
                                          required: _vm.isRequired(metaKey),
                                          type: _vm.getInputType(metaKey)
                                        },
                                        domProps: { value: value[key] },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              value,
                                              key,
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                  _vm._v(" "),
                                  _c("nb-error-block", {
                                    attrs: {
                                      path: _vm.errorPath + "." + id + "." + key
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-middle text-center" }, [
                        "edit" in _vm.actions && _vm.nNewItems <= id
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn-info m-btn m-btn--icon btn-sm m-btn--icon-only mr-1",
                                attrs: {
                                  id: "edit",
                                  title: "",
                                  href: _vm.populateURL(
                                    _vm.actions["edit"],
                                    value
                                  ),
                                  "data-original-title": "Editar"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-fw fa-pencil",
                                  attrs: { "data-v-a89a1b92": "" }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        "delete" in _vm.actions || _vm.nNewItems > id
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.nbReadOnly
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.erase(id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fa fa-times" })]
                            )
                          : _vm._e()
                      ])
                    ],
                    2
                  )
                }),
                0
              )
            : _c("tbody", [
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "text-center py-4",
                      attrs: { colspan: "4" }
                    },
                    [
                      "empty-list" in _vm.interfaceTexts
                        ? [_vm._v(_vm._s(_vm.interfaceTexts["empty-list"]))]
                        : [_vm._v("Lista vacía, añade una nueva entrada.")]
                    ],
                    2
                  )
                ])
              ])
        ],
        1
      ),
      _vm._v(" "),
      _c("paginate-links", {
        attrs: {
          for: "list",
          limit: 4,
          "show-step-links": true,
          "step-links": { prev: "‹", next: "›" },
          "hide-single-page": true,
          classes: { ul: "pagination", li: "page-item", a: "page-link" },
          async: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }