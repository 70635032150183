var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "m-form portlet__no-padding__top portlet__force-padding__sides"
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          { staticClass: "m-portlet__body" },
          [
            _c("input", {
              ref: "saveSpecialPermissionsField",
              attrs: { type: "hidden", name: "specialPermissions" },
              domProps: { value: _vm.resolvedSpecialPermissions }
            }),
            _vm._v(" "),
            _vm.selectedRole || _vm.selectedUser
              ? _c("special-permissions-manager", {
                  attrs: {
                    role: _vm.selectedRole,
                    user: _vm.selectedUser,
                    "special-permissions": _vm.specialPermissions,
                    userRoleInfo: _vm.userRoleInfo,
                    nbReadOnly: _vm.nbReadOnly
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }