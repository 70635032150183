<template>
    <div class="form-group m-form__group col-12">
        <input v-model="evaluatorInstancesJson" type="hidden" ref="evaluator_instances" name="evaluator_instances">

        <modal-component
                :theRef="'evaluatorSearch'"
                :visible.sync="showModal"
                :backdrop="true"
                :classesFooter="['text-right']"
                :classesModalDialog="['modal-dialog-centered modal-xl']"
        >
            <template #header>
                Añadir condición
            </template>
            <template>
                <div class="row mb-3">
                    <div class="col-5">
                        <label>Buscar condición</label>
                        <div class="input-group">
                            <input id="q"
                                   v-model="search"
                                   autocomplete="off"
                                   name="q"
                                   type="text"
                                   class="form-control m-input">
                            <div class="input-group-append">
                                <button id="search" type="button" class="form-control m-input btn btn-primary">
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div v-for="evaluator in evaluatorsList" class="col-6 list-conditions">
                        <div :id="'evaluators-' + evaluator.id"
                             class="m-alert m-alert--icon m-alert--outline alert alert-metal"
                             @click="selectEvaluator(evaluator)">
                            <div class="m-alert__icon">
                                <i :class="evaluator.icon"></i>
                            </div>
                            <div class="m-alert__text">
                                <div><strong>{{ evaluator.name }}</strong></div>
                                <span class="text-muted">{{ evaluator.description }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" v-show="evaluatorsList.length <= 0">
                        <div class="fs-5 fw-bolder alert text-center my-20"
                             role="alert">
                            No se han encontrado condiciones
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <button id="back-history" type="button" class="btn btn-brand btn-sm"
                        @click="backHistory()"
                        v-if="showHistory()">
                    <i class="la la-reply"></i>
                    Volver
                </button>
            </template>
        </modal-component>

        <div class="row mb-4">
            <div class="col-6">
                <h3 class="mb-0">
                    Condiciones
                    <i v-if="check"
                       v-tooltip
                       key="condition-true"
                       class="la la-check-circle m--font-success"
                       title="Las condiciones aplican">
                    </i>
                    <i v-else
                       v-tooltip
                       key="condition-false"
                       class="la la-check-circle m--font-warning"
                       title="Actualmente las condiciones no aplican">
                    </i>
                    <div v-show="loading" class="m-loader m-loader--sm m-loader--brand"></div>
                </h3>
            </div>
            <div class="col-6 text-right">
                <button id="show-modal"
                        type="button"
                        class="btn btn-brand m-btn m-btn--icon btn-sm"
                        :disabled="nbReadOnly"
                        @click="openModal(null)">
                    <span>
                        <i class="fa fa-plus"></i>
                        <span>Añadir condición</span>
                    </span>
                </button>
            </div>
            <div class="col-12">
                <div class="m-separator m-separator--dashed m-separator--lg mt-3 mb-0"></div>
            </div>
        </div>
        <div class="row">
            <div v-for="(evaluator, key) in evaluatorsSelectList" class="col-12">
                <div class="m-alert m-alert--outline list-select alert alert-metal mb-4" role="alert">
                    <component :is="evaluator.component"
                               v-if="evaluator.component"
                               v-model="evaluatorsSelect[key].bindings"
                               v-bind="evaluator.props"
                               :key="'cO' + key"
                               :evaluator="evaluator"
                               :group="group"
                               :nb-read-only="nbReadOnly"
                               :route-prefix="'API.triggers.'"
                               @filter-group="filterGroup"
                               @openModal="openModal">
                    </component><!--route="triggers.json"-->
                    <button :id="'remove-evaluator-' + key"
                            type="button"
                            class="btn btn-danger btn-sm m-btn delete-evaluator"
                            :disabled="nbReadOnly"
                            @click="removeEvaluator(key)">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <div v-show="evaluatorsSelectList.length <= 0" class="col-12">
                <div class="fs-5 fw-bolder alert text-center my-20" role="alert">
                    Añade una o varias condiciones
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import _ from 'lodash'

  export default {
    name: 'trigger-evaluator-instances',
    props: {
      group: { type: String, default: '' },
      name: { type: String, default: '' },
      categories: { type: Object, default: () => { }, },
      evaluators: { type: Array, default: () => [], },
      evaluatorInstances: { type: Array, default: () => [], },

      triggerCategory: { type: String, default: '' },

    },
    data () {
      return {
        search: null,
        count: 0,
        check: true,
        list: [],
        fields: {},
        page: 1,
        paginate: {},
        loading: false,
        categorySelect: null,
        categoriesSelect: {},
        evaluatorsSelect: {},
        history: [],
        child: null,
        groupFilter: false,
        groupEvaluator: null,
        showModal: false,
      }
    },
    created () {
      this.$eventBus.$on('select-evaluator', (child, evaluator) => this.setEvaluator(child, evaluator))
      let evaluator
      let object = {}

      for (evaluator in this.evaluatorInstances) {
        object = { ...this.evaluatorInstances[evaluator].parameters }
        this.selectEvaluator(Object.assign({}, object, { id: this.evaluatorInstances[evaluator].id }))
      }
      this.debouncedCountEvaluators = _.debounce(this.countEvaluators, 500)
    },
    computed: {
      evaluatorInstancesJson () {
        return this.createdEvaluators(true)
      },
      evaluatorsListGeneral () {
        let list = this.evaluators

        if (this.categorySelect && this.categoriesListGeneral.length <= 0) {
          list = list.filter(evaluator => this.categorySelect === evaluator.category)
          return list
        }

        return []
      },
      evaluatorsList () {
        let list = this.evaluators

        if (Object.keys(this.categoriesSelect).length > 0) {
          list = list.filter(evaluator => this.categoriesSelect[evaluator.category])
        } else {
          list = list.filter(evaluator => evaluator.father === true)
        }

        if (this.search !== '' && this.search !== null) {
          list = list.filter(evaluator => evaluator.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
        }

        if (this.groupEvaluator !== null) {
          list = list.filter(evaluator => evaluator.class !== this.groupEvaluator)
        }

        return list
      },
      evaluatorsSelectList () {
        let list = []
        let evaluator
        let number = 0

        for (evaluator in this.evaluatorsSelect) {
          list.push(Object.assign({}, this.evaluatorsSelect[evaluator], { key: number }))
          number++
        }

        return list
      },
      categoriesListGeneral () {
        let categories = []
        let category
        let content

        for (category in this.categories) {
          content = this.categories[category]
          if (this.categorySelect) {
            if (content.father === this.categorySelect) {
              categories.push(content)
            }
          } else if (content.father === null) {
            categories.push(content)
          }
        }

        return categories
      },
      categoriesList () {
        let categories = []
        let category
        let content

        for (category in this.categories) {
          content = this.categories[category]
          content.name = this.nameCategory(content)

          categories.push(this.categories[category])
        }

        return categories
      },
      countPaginate () {
        let pages = []

        for (let p = 1; p <= this.paginate.last_page; p++) {
          pages.push(p)
        }

        return pages
      },
    },
    methods: {
      openModal (element) {
        if (element === null) {
          this.clearGroupEvaluator()
        }

        this.child = element

        this.showModal = true
      },
      showHistory () {
        return this.history.length > 0
      },
      backHistory () {
        let count = this.history.length
        if (count > 0) {
          let position = count - 1
          let category = null
          if (this.history[position]) {
            category = this.history[position]
          }
          this.categorySelect = category
          this.history.splice(-1, 1)
        }
      },
      clearGroupEvaluator () {
        this.groupFilter = false
        this.groupEvaluator = null
        this.clearCategory()
      },
      closeModal () {
        this.showModal = false
        this.clearOneCategory()
      },
      getCountFields () {
        return Object.keys(this.fields).length
      },
      createdEvaluators (returnValue) {
        let instancesToJson = []
        let evaluator
        let value

        for (evaluator in this.evaluatorsSelect) {
          instancesToJson.push(this.evaluatorsSelect[evaluator])
        }

        value = JSON.stringify(instancesToJson)

        this.debouncedCountEvaluators(value)

        if (returnValue === true) {
          return value
        }
        return null
      },
      selectEvaluator (evaluator) {
        let list

        if (this.child !== null) {
          list = this.child.evaluatorsSelect
        } else {
          list = this.evaluatorsSelect
        }

        let numero = Object.keys(list).length
        let object = {}
        object[numero] = { ...evaluator }

        if (this.child !== null) {
          this.child.evaluatorsSelect = Object.assign({}, list, object)
          this.child.updateModel()
        } else {
          this.evaluatorsSelect = Object.assign({}, list, object)
        }

        this.closeModal()
      },
      setEvaluator (child, evaluator) {
        this.child = child
        this.selectEvaluator(evaluator)
      },
      clearCategory () {
        this.categoriesSelect = {}
        this.sendCategoryValues()
      },
      clearOneCategory () {
        this.categorySelect = null
        this.history = []
      },
      selectOneCategory (category, father) {
        this.categorySelect = category
        this.history.push(father)
      },
      selectCategory (category) {
        if (this.categoriesSelect[category]) {
          delete this.categoriesSelect[category]
        } else {
          this.categoriesSelect[category] = true
        }

        this.sendCategoryValues()
      },
      sendCategoryValues () {
        this.categoriesSelect = Object.assign({}, this.categoriesSelect)
      },
      nameCategory (category) {
        let name = category.name
        let father = category.father

        if (father !== null && father !== undefined) {
          father = this.categories[father]
          name = father.name + ' > ' + name
        }

        return name
      },
      setPage (page) {
        if (this.loading === false) {
          this.page = page
          this.createdEvaluators(false)
        }
      },
      loadingListData (active) {
        let data = '#table-data-list'

        if (active) {
          mApp.block(data, {})
        } else {
          mApp.unblock(data)
        }
      },
      updateEvaluators () {
        let evaluator
        let objects = {}
        let number = 0

        for (evaluator in this.evaluatorsSelect) {
          objects[number] = { ...this.evaluatorsSelect[evaluator] }
          number++
        }

        this.evaluatorsSelect = Object.assign({}, objects)
      },
      removeEvaluator (reference) {
        delete this.evaluatorsSelect[reference]

        this.updateEvaluators()
      },

      async countEvaluators (evaluators) {
        let routeName = route('API.triggers.count')

        this.loading = true
        this.loadingListData(true)
        try {
          let response = await axios.post(routeName, {
            group: this.group,
            evaluator_instances: evaluators,
            page: this.page,
            static: 0 //??
          })
          this.contentFinish(response)
        } catch (error) {
          this.contentFinish(error)
        }
      },
      contentFinish (response) {
        if (response.data) {
          this.check = response.data.check
        }
        this.loading = false
        this.loadingListData(false)
      },
      filterGroup (event) {
        this.groupFilter = true
        this.groupEvaluator = event.evaluator
        this.clearCategory()
        this.selectCategory(this.triggerCategory)
        this.selectCategory(event.category)
      }
    },
  }
</script>

<style lang="scss" scoped>
    .list-groups {
        .btn {
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }

    .list-conditions {
        animation: effect-conditions 1s;

        .m-alert {
            &:hover {
                cursor: pointer;
                transition: all .3s ease;
                box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.3);
                border-color: white;
            }

            .m-alert__icon {
                padding: 1rem 0.5rem 1rem 1rem;
            }

            .m-alert__text {
                color: black;
                padding: 1rem 0.5rem;
            }

            .m-alert__actions {
                padding: 1rem;
            }
        }
    }

    @keyframes effect-conditions {
        0% {
            opacity: 0.1;
        }
        100% {
            opacity: 1;
        }
    }

    .list-select {
        .m-alert {
            width: 100%;
        }
    }

    .delete-evaluator {
        position: absolute;
        top: -5px;
        right: -5px;
    }

    .m-loader--brand {
        width: 30px;
        height: 15px;
        display: inline-block;
    }
</style>
