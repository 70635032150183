<template>
  <div>
    <input type="hidden" name="edition_css" :value="jsonCustomCss" />
    <label v-if="label.length" :for="field" class="form-label">
      {{ label }}
    </label>
    <AceEditor
      :name="field"

      v-model="content"
      @init="editorInit"
      lang="css"
      theme="monokai"
      width="100%"
      height="200px"
      :options="{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        fontSize: 14,
        highlightActiveLine: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
        showPrintMargin: false,
        showGutter: true,
      }"
    />
  </div>
</template>

<script>
import AceEditor from "vuejs-ace-editor";
export default {
  name: "CssEditor",
  components: {
    AceEditor,
  },
  props: {
    value: { type: String, default: "" },
    field: { type: String, default: "content" },
    label: { type: String, default: "" },
  },
  mounted() {
    this.content = this.value;
  },
  data() {
    return {
      content: null,
    };
  },
  methods: {
    dataSumit() {
      //code here
    },
    editorInit: function () {
      const ace = require('brace')
      require('brace/ext/language_tools')
      require("brace/mode/css")
      require('brace/snippets/css')
      require("brace/theme/monokai")
      ace.acequire("ace/ext/language_tools")
    },
  },
  computed: {
    jsonCustomCss() {
      return this.content;
    },
  },
};
</script>

<style>
</style>
