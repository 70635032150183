var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.saveQuestion($event)
          }
        }
      },
      [
        _c(
          "modal-component",
          {
            attrs: {
              visible: _vm.showModalHandle,
              classesModalDialog: ["modal-dialog-centered", "modal-xl"],
              buttonClose: false,
              focus: false
            },
            on: {
              "update:visible": function($event) {
                _vm.showModalHandle = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c("h5", { staticClass: "modal-title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.question !== null
                              ? "Editar pregunta"
                              : "Nueva pregunta"
                          ) +
                          "\n        "
                      )
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger outline",
                        on: {
                          click: function($event) {
                            return _vm.$emit("closeShowModal")
                          }
                        }
                      },
                      [_vm._v("\n          Cancelar\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" }
                      },
                      [_vm._v("Guardar")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            _vm._v(" "),
            [
              _c(
                "div",
                { staticClass: "form-group mb-4" },
                [
                  _c("label", [_vm._v("Enunciado")]),
                  _vm._v(" "),
                  _c("html-editor", {
                    attrs: { field: "name", height: 100 },
                    model: {
                      value: _vm.questionData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.questionData, "name", $$v)
                      },
                      expression: "questionData.name"
                    }
                  }),
                  _vm._v(" "),
                  _vm.lang
                    ? _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.questionData.default_name)
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-group mb-4" }, [
                _c("label", [_vm._v("Descripción")]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.questionData.description,
                      expression: "questionData.description"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { rows: "2", maxlength: "255", required: "" },
                  domProps: { value: _vm.questionData.description },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.questionData,
                        "description",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _vm.lang
                  ? _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.questionData.default_description) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group mb-4" },
                [
                  _c("label", [_vm._v("Explicación")]),
                  _vm._v(" "),
                  _c("html-editor", {
                    attrs: { field: "explanation", height: 100 },
                    model: {
                      value: _vm.questionData.explanation,
                      callback: function($$v) {
                        _vm.$set(_vm.questionData, "explanation", $$v)
                      },
                      expression: "questionData.explanation"
                    }
                  }),
                  _vm._v(" "),
                  _vm.lang
                    ? _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.questionData.default_explanation
                          )
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.multimedia !== "image" && !_vm.lang
                ? _c("div", { staticClass: "form-group mb-4" }, [
                    _c("label", [_vm._v("Video")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.video_url,
                            expression: "video_url"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "url", maxlength: "255" },
                        domProps: { value: _vm.video_url },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.video_url = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.removeVideo()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-times" })]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.multimedia !== "video" && !_vm.lang
                ? _c(
                    "div",
                    { staticClass: "form-group mb-4" },
                    [
                      _c("label", [_vm._v("Imagen")]),
                      _vm._v(" "),
                      _c("repository-button", {
                        attrs: {
                          "allowed-mime-types": _vm.allowedMimeTypes,
                          "short-version": true,
                          "class-field": "col-md-12 px-0",
                          "group-id": "1",
                          "group-type": "App\\Models\\Rep\\FileGroup"
                        },
                        on: { "clear-value": _vm.clearValue },
                        model: {
                          value: _vm.selectedImage,
                          callback: function($$v) {
                            _vm.selectedImage = $$v
                          },
                          expression: "selectedImage"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.lang
                ? _c("div", { staticClass: "form-group mb-4" }, [
                    _c("label", [_vm._v("Dominio")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.questionData.competence_domain_id,
                            expression: "questionData.competence_domain_id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { required: "" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.questionData,
                              "competence_domain_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.competenceDomains, function(competenceDomain) {
                        return _c(
                          "option",
                          {
                            key: competenceDomain.id,
                            domProps: { value: competenceDomain.id }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(competenceDomain.name) +
                                "\n            "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.lang
                ? _c("div", { staticClass: "form-group mb-4" }, [
                    _c("label", [_vm._v("Tipo")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.questionData.question_type_id,
                            expression: "questionData.question_type_id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { required: "" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.questionData,
                              "question_type_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.questionTypes, function(questionType) {
                        return _c(
                          "option",
                          {
                            key: questionType.id,
                            domProps: { value: questionType.id }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(questionType.name) +
                                "\n            "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.lang
                ? _c("div", { staticClass: "form-group mb-4" }, [
                    _c("label", [_vm._v("Nivel")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.questionData.level_id,
                            expression: "questionData.level_id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { required: "" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.questionData,
                              "level_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.levels, function(level) {
                        return _c(
                          "option",
                          { key: level.id, domProps: { value: level.id } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(level.name) +
                                "\n            "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group mb-4" },
                [
                  _c("div", [_vm._v("Respuestas")]),
                  _vm._v(" "),
                  _c("AnswersComponent", {
                    attrs: {
                      answers: _vm.questionData.answers,
                      questionType: _vm.questionData.question_type_id,
                      lang: _vm.lang,
                      numberAnswersAttitudeAndSkills:
                        _vm.numberAnswersAttitudeAndSkills
                    },
                    on: {
                      addAnswer: _vm.addAnswer,
                      removeAnswer: _vm.removeAnswer,
                      checkCorrectResponse: _vm.checkCorrectResponse
                    }
                  })
                ],
                1
              )
            ]
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }