<template>
    <tbody>
    <tr>
        <td>
            <a class="" @click="openSection" id="section">
                <div :style="{paddingLeft: (depth * 15) + 'px'}" :class="{'disabled': nbReadOnly}">
                    <i v-if="section.children.length" :class="`c-pointer ${caret}`"></i>
                    <strong class="fa fa-fw" v-else>&#8226;</strong>
                    <i v-if="userRoleSection" :class="`fa role-status ${roleIcon}`"
                       :title="$options.filters.trans('permission_component.inheritedRoleAuth')"></i>
                    <i @click.stop="checkSection"
                       :class="`fa state-icon ${icon}`"></i>
                    {{ section.name }}
                </div>
            </a>
        </td>
    </tr>

    <tr v-if="section.children.length && sectionOpen" style="padding: 0; margin: 0;">
        <td style="padding: 0;">
            <table class="table table-hover" :key="`section${index}`" :id="`section${index}`"
                   v-if="section.children.length" style="margin-bottom: 0;">
                <section-item
                        v-for="(childrenSection, childrenIndex) in section.children"
                        @sectionUpdated="sectionUpdated(childrenSection.id)"
                        @setSectionPermissionToNull="setSectionPermissionToNull(childrenSection.id)"
                        :userRoleSection="userRoleSection ? userRoleSection.children[childrenIndex] : null"
                        :section="childrenSection"
                        :index="`${index}_${childrenIndex}`"
                        :key="childrenSection.id"
                        :depth="depth + 1"
                        :nbReadOnly="nbReadOnly"/>
            </table>
        </td>
    </tr>
    </tbody>

</template>

<script>
  import { EventBus } from '@/js/vue/services/event-bus'

  export default {
    name: 'section-item',

    data: () => {
      return {
        sectionOpen: true,
      }
    },
    computed: {
      isParentChecked () {
        if (this.$parent.section === undefined) {
          return true
        }

        return this.$parent.section.granted
      },
      caret () {
        return this.sectionOpen ? 'fa fa-fw fa-caret-down' : 'fa fa-fw fa-caret-right'
      },
      icon () {
        if (this.section.granted) {
          return 'fa-check-circle text-success js-checked'
        } else {
          if (this.section.granted === null) {
            return 'fa-minus-circle text-muted js-no-value'
          }

          return 'fa-times-circle text-danger js-unchecked'
        }
      },
      roleIcon () {
        if (this.userRoleSection.granted) {
          return 'fa-check-circle text-success js-checked'
        } else {
          if (this.userRoleSection.granted === null) {
            return 'fa-minus-circle js-muted js-no-value'
          }

          return 'fa-times-circle text-danger js-unchecked'
        }
      },
    },
    methods: {
      sectionUpdated (sectionId) {
        EventBus.$emit('sectionUpdated', sectionId)
      },
      checkSection () {
        if (this.nbReadOnly) {
          return
        }

        if (this.isParentChecked) {
          this.$emit('sectionUpdated')
        } else {
          toastr.info(this.$options.filters.trans('permission_component.parentNotSelected'), '', {
            preventDuplicates: true,
          })
        }
      },
      openSection () {
        this.sectionOpen = !this.sectionOpen
      },
    },
    props: {
      section: {
        default: null,
        validator: value => value instanceof Object,
      },
      index: String,
      depth: Number,
      userRoleSection: {
        default: null,
        validator: value => value instanceof Object,
      },
    },
  }
</script>

<style scoped>
    .disabled {
        opacity: 0.5;
    }

    .animated.pulse {
        animation-duration: 500ms;
    }

    .animated.fadeOutUp {
        animation-duration: 200ms;
    }

    .list-group.list-group-root .list-group {
        margin-bottom: 0;
    }

    .list-group.list-group-root .list-group-item {
        border-radius: 0;
        border-width: 1px 0 0 0;
    }

    .list-group-item i {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .list-group.list-group-root > .list-group-item:first-child {
        border-top-width: 0;
    }

    .role-status {
        margin-left: -7px;
        margin-top: -7px;
    }
</style>
