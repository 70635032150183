<template>
  <div class="statistic-chart">
    <VueApexCharts :type="chartType"
                   :options="options"
                   :height="height"
                   :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import _ from 'lodash'

export default {
  name: 'ApexChart',
  components: { VueApexCharts },
  props: {
    resultSet: {
      type: Object,
      required: true,
    },
    chartType: {
      type: String,
      required: true,
    },
    pivotXAxis: {
      type: Array,
      default: () => [],
    },
    pivotYAxis: {
      type: Array,
      default: () => [],
    },
    propOptions: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  data () {
    return {
      chartTypes: {
        bar: 'axis',
        line: 'axis',
        radar: 'axis',
        area: 'axis',
        heatmap: 'axis',
        treemap: 'axis',
        pie: 'nonaxis',
        donut: 'nonaxis',
      },
      pivotObject: {
        x: [],
        y: [],
      },
      options: null,
      series: null,
    }
  },
  watch: {
    pivotXAxis: {
      immediate: true,
      handler (val) {
        if (val) {
          this.pivotObject.x = val.map(pivot => pivot.name)
          this.options = this.getOptions()
          this.series = this.getSeries()
        }
      },
    },
    pivotYAxis: {
      immediate: true,
      handler (val) {
        if (val) {
          this.pivotObject.y = val.map(pivot => pivot.name)
          this.options = this.getOptions()
          this.series = this.getSeries()
        }
      },
    },
    resultSet: {
      immediate: true,
      handler (val) {
        if (val) {
          this.options = this.getOptions()
          this.series = this.getSeries()
        }
      },
    },
    propOptions: {
      deep: true,
      immediate: true,
      handler (val) {
        this.options = this.getOptions()
      },
    },
    chartType () {
      this.options = this.getOptions()
      this.series = this.getSeries()
    },
  },
  created () {
    this.options = this.getOptions()
    this.series = this.getSeries()
  },
  methods: {
    getSeries () {
      // TODO: Revisar estos tipos, no funcionan
      if (this.chartTypes[this.chartType] === 'nonaxis') {
        return this.resultSet.series(this.pivotObject)[0].series.map(serie => serie.value)
      }
      return this.resultSet.series(this.pivotObject).map(serie => ({
        name: this.getTranslation(serie.key),
        data: serie.series.map(data => ({
          x: data.x,
          y: data.value,
        })),
      }))
    },
    getOptions () {
      const newOptions = _.cloneDeep(this.propOptions)
      if (this.chartTypes[this.chartType] === 'nonaxis') {
        return {
          ...newOptions,
          labels: this.resultSet.chartPivot(this.pivotObject).map(record => record.category),
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: this.chartType === 'donut',
                  },
                },
              },
            },
          },
        }
      }

      return {
        ...newOptions,
        xaxis: {
          type: 'category',
          // type: 'numeric',
        },
        // yaxis: {
        //   max: 100,
        // },
      }
    },
    getTranslation (string) {
      // const numbers = string.match(/(\d+)/)
      // if (numbers) {
      //   const number = numbers[0]
      //   return this.$t('dynamicData.layer' + number + '.statistics.' + string)
      // }
      return this.$options.filters.trans(`statistics.${string}`)
    },
  },
}
</script>
