<template>
    <ul class="ticket-select-elements__list-group" ref="listGroup">
        <slot/>
    </ul>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { ContainerMixin } from 'vue-slicksort'

  export default {
    name: 'SortableList',
    mixins: [ContainerMixin],
  }
</script>
