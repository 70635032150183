<template>
    <div  class="form-group m-form__group col-md-6" :class="containerClass">
        <div class="d-flex flex-row" v-if="!live">
            <input type="hidden" :name="`${name}_checkbox`" :value="customizeCheckbox ? 1 : 0">
            <div class="p-2">
                <label class="m-checkbox">
                    <input type="checkbox" @click="changeCheckBox(true)" title="Modificable" :disabled="nbReadOnly"
                           id="change-check" v-model="customizeCheckbox">
                    {{label}}
                    <span></span>
                </label>
            </div>
        </div>
        <label v-else>
            {{label}}
        </label>
        <div class="row">
            <div class="col-12">
                <div class="form-group" v-html="setInput()"></div>
            </div>
        </div>
    </div>
</template>

<script>
  import { EventBus } from '@/js/vue/services/event-bus'

  export default {
    name: 'report-number',
    props: {
      containerClass: {
        type: String,
        default: 'form-group m-form__group col-md-3'
      },
      live: {
        type: Boolean,
        default: false
      },
      prefix: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      customizable: {
        type: Boolean,
        default: false
      },
      attributes: {
        type: Object,
        default: {}
      },
      id: {
        type: Number,
        default: 0
      },
    },
    created: function () {

      if (this.default_value !== '') {
        this.dataValue = this.default_value
      }

      this.customizeCheckbox = this.customizable
      this.changeCheckBox()
    },
    data () {
      return {
        dataValue: '',
        customizeCheckbox: false,
      }
    },
    methods: {
      changeCheckBox (change = false) {

        if (change) {
          this.customizeCheckbox = !this.customizeCheckbox
          EventBus.$emit('filterStatusChange', this.id, this.label, !this.customizeCheckbox)
        }
      },
      setInput () {
        let attribute = '<input type="number" class="form-control" '
        attribute += `name="${this.prefix}[${this.name}]" id="${this.prefix}[${this.name}]" `

        if (this.nbReadOnly) {
          attribute += 'disabled="true"'
        }

        Object.keys(this.attributes).forEach(key => {
          if (key === 'default_value') {
            attribute += `value="${this.attributes[key]}" `
          } else {
            attribute += `${key}="${this.attributes[key]}" `
          }
        })
        attribute += '/>'
        return attribute
      }
    }
  }
</script>

<style scoped>

</style>
