<template>
    <div class="col-12 pl-0 pr-0">

        <!-- Cabecera ------------------------------------------------------------>
        <template v-if="interfaceTexts.title !== null">
            <div class="">
                <div class="col-12">
                    <h4 class="ml-3 mr-3">
                        <em class="flaticon-graphic mr-1"></em>
                        <template v-if="'title' in interfaceTexts">{{ interfaceTexts['title'] }}</template>
                        <template v-else>Listado</template>
                    </h4>
                </div>
                <div class="col-6 text-right">
                    <slot name="actions"></slot>
                </div>
            </div>
        </template>

        <!-- Filtros ------------------------------------------------------------>
        <div v-if="filters.length" class="m-entity-list-search">
            <form class="d-flex align-content-start flex-wrap">
                <div v-for="(element, key) in filters" :key="key" class="col-sm-6 col-md-2 p-2">
                    <template v-if="element[0] === 'select'">
                        <select autocomplete="off"
                                :placeholder="element[2]"
                                :name="element[1]"
                                :id="key"
                                value=""
                                class="form-control m-input"
                                @change="addFilter(key, 'equalFilter', element[1], ...arguments)">
                            <option hidden disabled selected value="">{{ element[2] }}</option>
                            <template v-for="element in options(element[1])">
                                <option :value="element">{{ element }}</option>
                            </template>
                        </select>
                    </template>
                    <template v-else-if="element[0] === 'date_since'">
                        <input autocomplete="off"
                               :placeholder="element[2]"
                               :name="element[1]"
                               :id="key"
                               value=""
                               type="text"
                               onfocus="(this.type='date')"
                               class="form-control m-input"
                               @change="addFilter(key, 'gtFilter', element[1], ...arguments)">
                    </template>
                    <template v-else-if="element[0] === 'date_to'">
                        <input autocomplete="off"
                               :placeholder="element[2]"
                               :name="element[1]"
                               :id="key"
                               value=""
                               type="text"
                               onfocus="(this.type='date')"
                               class="form-control m-input"
                               @change="addFilter(key, 'ltFilter', element[1], ...arguments)">
                    </template>
                    <template v-else-if="element[0] === 'search'">
                        <div class="m-input-icon m-input-icon--right">
                            <label :for="'qSearch-' + key" class="sr-only"></label>
                            <input autocomplete="off"
                                   :placeholder="element[2]"
                                   :name="element[1]"
                                   :id="'qSearch-' + key"
                                   value=""
                                   type="text"
                                   class="form-control m-input"
                                   @input="addFilter(key, 'likeFilter', element[1], ...arguments)">
                            <span class="m-input-icon__icon m-input-icon__icon--right">
                                <span>
                                    <i class="fa fa-fw fa-search"></i>
                                </span>
                            </span>
                        </div>
                    </template>
                    <template v-else-if="element[0] === 'multiselect'">
                        <!-- todo: remplazar tods los multiselect del proyecto -->
                        <multiselect :options="options(element[1])"
                                     :multiple="false"
                                     v-model="multiselectValues[key]"
                                     :placeholder="element[2]"
                                     @input="addFilterMultiselect(key, 'likeFilter', element[1], ...arguments)"
                                     @search-change="addFilterMultiselect(key, 'likeFilter', element[1], ...arguments)"
                                     @select="addFilterMultiselect(key, 'likeFilter', element[1], ...arguments)">
                        </multiselect>
                    </template>
                </div>
                <div class="p-2">
                    <button
                            type="reset"
                            data-reset-form="true"
                            class="m-input btn-reset btn btn-secondary m-btn--icon"
                            @click="resetFilters()">
                        <em class="fa fa-fw fa-trash"></em>
                        Limpiar
                    </button>
                </div>
            </form>
        </div>

        <!-- Lista ------------------------------------------------------------>
        <div class="body-padding pt-0">
            <div class="m-separator m-separator--dashed m-separator--lg my-3"></div>

            <table class="table m-table m-table--head-bg-light">
                <thead>
                <tr>
                    <th v-for="(header, key) in renderableHeaders()" :key="key">
                    <span class="column-no-sort">
                        {{ header[0] }}
                    </span>
                    </th>
                    <th v-if="!isEmptyActions()" class="text-center">
                    </th>
                </tr>
                </thead>

                <paginate v-if="currentList.length" name="currentList" tag="tbody"
                          :list="currentList" :per="elementsPerPage" :key="paginateKey">
                    <tr v-for="(row, rowKey) in currentList" :key="JSON.stringify(row) + '_' + rowKey"
                        :data-model-id="rowKey" v-if="currentList[calculateIndex(rowKey)]">
                        <td v-for="(col, colKey) in renderableHeaders()" :key="colKey">
                        <span v-if="col[1] === 'checkbox'">
                            <span class="m-switch m-switch--icon">
                                <label>
                                    <input type="checkbox"
                                           :checked="currentList[calculateIndex(rowKey)][col[2]]"
                                           @change="$emit('update:' + col[2], currentList[calculateIndex(rowKey)])">
                                    <span></span>
                                </label>
                            </span>
                        </span>
                            <span v-else v-html="addCell(calculateIndex(rowKey), col)"></span>
                        </td>
                        <td v-if="!isEmptyActions()" class="align-middle text-center">
                            <a id="edit"
                               v-if="'edit' in actions"
                               title="Editar"
                               :id="'edit-' + rowKey"
                               :href="populateURL(actions['edit'], row)"
                               class="btn btn-info m-btn m-btn--icon btn-sm m-btn--icon-only mr-1"
                               data-original-title="Editar">
                                <i data-v-a89a1b92="" class="fa fa-fw fa-pencil"></i>
                            </a>

                            <button v-if="'delete' in actions"
                                    type="button"
                                    title="Eliminar"
                                    :id="'delete-' + rowKey"
                                    class="btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only"
                                    :disabled="nbReadOnly"
                                    @click="erase(rowKey)">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                </paginate>

                <tbody v-else>
                <tr>
                    <td :colspan="colspan" class="text-center py-4">
                        <template v-if="'empty-list' in interfaceTexts">{{ interfaceTexts['empty-list'] }}</template>
                        <template v-else>Lista vacía.</template>
                    </td>
                </tr>
                </tbody>
                <tfoot v-if="footers" class="footer">
                <tr>
                    <td v-for="(footer, key) in footers" :key="key" :colspan="colspan">
                        <template v-if="footer[1] === 'eursum'">
                            {{footer[0]}}: {{ addFooter(key) }} €
                        </template>
                    </td>
                </tr>
                </tfoot>
            </table>

            <!-- Paginador javascript ------------------------------------------------------------>
            <paginate-links v-if="ajax === false"
                            class="pull-right"
                            for="currentList"
                            :limit="4"
                            :show-step-links="true"
                            :step-links="{ prev: '‹', next: '›' }"
                            :hide-single-page="true"
                            :classes="{'ul': 'pagination', 'li': 'page-item', 'a': 'page-link'}"
                            :async="true"
                            @change="onPageChange">
            </paginate-links>
        </div>


        <!-- Paginador ajax ------------------------------------------------------------>
        <div v-if="ajax === true && currentList.length && lastPage > 1" class="col-12">
            <paginator :current-page="currentPage"
                       :key="paginateKey"
                       :links="paginationLinks"
                       :meta="paginationMeta"
                       :loading-page-number="currentPage"
                       @set-page="onPageChange"/>
        </div>

    </div>
</template>

<script>
  import moment from 'moment'
  import Paginator from '@/js/components/widgets/Paginator'

  export default ({
    name: 'list-simple',
    components: {Paginator},
    props: {
      name: { type: String, default: '' },
      filters: { type: Array, default: () => [] },
      list: { type: Array, default: () => [] },
      headers: { type: Array, default: () => [] },
      footers: { type: Array, default: () => [] },
      actions: { type: Object, default: () => ({}) },
      interfaceTexts: { type: Object, default: () => [] },
      elementsPerPage: { type: Number, default: 10 },
      ajax: { type: Boolean, default: false },
      ajaxPagination: { type: Object, default: () => {} }, // With follow properties: current_page, last_page
    },
    data: function () {
      return {
        currentPage: 1,
        currentList: [],
        currentFilters: [],
        paginate: ['currentList'],
        multiselectValues: [],
        paginateKey: 0,
      }
    },
    computed: {
      listJSON () {
        return JSON.stringify(this.currentList)
      },

      /**
       * Number of colspan for footers
       */
      colspan () {
        return this.headers.filter(header => header[1] !== 'hidden').length
      },
      paginationLinks () {
        return {
          prev: this.ajaxPagination.links.prev,
          next: this.ajaxPagination.links.next,
        }
      },
      paginationMeta () {
        return {
          current_page: this.ajaxPagination.current_page ? this.ajaxPagination.current_page : this.ajaxPagination.meta.current_page,
          last_page: this.ajaxPagination.last_page ? this.ajaxPagination.last_page : this.ajaxPagination.meta.last_page,
        }
      },
      lastPage () {
        return this.ajaxPagination.last_page ? this.ajaxPagination.last_page : this.ajaxPagination.meta.last_page
      }
    },
    watch: {
      list: function () {
        this.reset()
      }
    },
    created () {
      this.reset()
    },
    methods: {
      /**
       * Event handler, keeps the current page
       * See https://github.com/TahaSh/vue-paginate
       *
       * Its used in ajax paginator too.
       */
      onPageChange (toPage, fromPage) {
        this.currentPage = toPage
        this.$emit('page-change', toPage)
      },

      /**
       * Returns all headers that must be rendered
       */
      renderableHeaders () {
        return this.headers.filter(item => (item[1] !== 'hidden')) // && item[1] != 'rank'
      },

      /**
       * Renders the data of the cell appropiatly
       */
      addCell (row, col) {
        if (!this.currentList[row][col[2]] && col[1] !== 'rank') {
          return null
        }
        const currentVal = this.currentList[row][col[2]]
        switch (col[1]) {
          case 'number':
          case 'string':
            return currentVal
          case 'rank':
            return row + this.elementsPerPage * (this.currentPage - 1) + 1
          case 'eur':
            return ((currentVal).toFixed(2) + ' €')
          case 'link':
            return '<a href="' + currentVal + '">' + currentVal + '</a>'
          case 'link-id':
            const val = this.currentList[row]
            const url = this.populateURL(this.actions.linkIdUrl, val)
            return '<a href="' + url + '">' + currentVal + '</a>'
        }
      },

      /**
       * Populate the url with appropiate row id
       */
      populateURL (url, value) {
        return url.replace('*', value.id)
      },

      /**
       * Lodash method _.isEmpty() does not work in template
       */
      isEmptyActions () {
        return _.isEmpty(this.actions)
      },

      /**
       * Elimina la zona con el index pasado
       * por parametro
       * @param index
       */
      erase (index) {
        this.currentList.splice(index, 1)
      },

      /**
       * Calcula la posición en la lista completa
       */
      calculateIndex (row) {
        if (this.ajax) return row
        return row + this.elementsPerPage * (this.currentPage - 1)
      },

      // Filtros

      /**
       * Devuelve las opciones del filtro con select.
       */
      options (column) {
        let options = []
        this.list.forEach(element => {
          if (!options.includes(element[column]) && element[column]) {
            options.push(element[column])
          }
        })
        return options
      },

      /**
       * Carga la lista original
       */
      reset () {
        this.currentList = this.list
        // Bug de vue-paginate, intenta renderizar la lista antes de actualizarla....
        this.$set(this.paginate.currentList, 'list', this.currentList)
        this.$nextTick(() => this.paginateKey++)
        // emit filters
        this.$emit('set-filters', this.currentFilters)
      },

      /**
       * Limpia los filtros
       */
      resetFilters () {
        this.currentFilters = []
        this.reset()
      },

      /**
       * Aplica desde una lista limpia todos los filtros acumulados.
       */
      applyFilters () {
        this.reset()
        let newList = this.currentList
        this.currentFilters.forEach(filter => {
          const header = this.headers.find(header => header[2] === filter[2])
          switch (filter[1]) {
            case 'equalFilter':
              newList = newList.filter(row => row[filter[2]] === filter[3])
              break
            case 'likeFilter':
              newList = newList.filter(row => {
                const field = (row[filter[2]] !== null ? row[filter[2]] : '').toString().toLowerCase()
                return field.search(filter[3].replace('+', '').toLowerCase()) !== -1
              })
              break
            case 'gtFilter':
              if (header[2] === 'date_since' || header[2] === 'date_to') {
                newList = newList.filter(row => moment(row[filter[2]]) >= moment(filter[3]))
              } else {
                newList = newList.filter(row => row[filter[2]] >= filter[3])
              }
              break
            case 'ltFilter':
              if (header[2] === 'date_since' || header[2] === 'date_to') {
                newList = newList.filter(row => moment(row[filter[2]]) <= moment(filter[3]))
              } else {
                newList = newList.filter(row => row[filter[2]] <= filter[3])
              }
              break
            default:
              break
          }
        })
        // Bug de vue-paginate, intenta renderizar la lista antes de actualizarla....
        this.$set(this.paginate.currentList, 'list', newList)
        this.currentList = newList
      },

      /**
       * Añade un nuevo filtro a la lista acumulativa de filtros
       */
      addFilter (key, filter, column, event) {
        this.currentFilters = this.currentFilters.filter(item => item[0] !== key)
        this.currentFilters.push([key, filter, column, event.target.value])
        this.applyFilters()
      },

      /**
       * Añade un nuevo filtro a la lista acumulativa de filtros
       */
      addFilterMultiselect (key, filter, column, selected) {
        this.currentFilters = this.currentFilters.filter(item => item[0] !== key)
        this.currentFilters.push([key, filter, column, selected ? selected : ''])
        this.applyFilters()
      },

      // Footer

      /**
       * Returns the value of footer row
       */
      addFooter (key) {
        switch (this.footers[key][1]) {
          case 'eursum':
            let sum = 0
            this.currentList.forEach(element => {
              if (element[this.footers[key][2]]) {
                sum += element[this.footers[key][2]]
              }
            })
            return sum.toFixed(2)
        }
      },
    },
  })
</script>

<style scoped>
    .footer {
        font-weight: bold;
        font-size: 12px;
        text-align: right;
    }

    .footer tr {
        background-color: #f9f9f9;
    }
</style>
