<template>
    <div>
        <div id="kt_aside_search" class="flex-column-auto pt-5 pb-1 px-6">
            <div class="btn-custom btn p-0 w-100">
                <span class="btn-label">
                    <section-search></section-search>
                </span>
                <span class="svg-icon btn-icon svg-icon-2"><svg
                    width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Stockholm-icons-/-General-/-Search" stroke="none"
                                                                  stroke-width="1" fill="none" fill-rule="evenodd"><rect
                    id="bound" x="0" y="0" width="24" height="24"></rect> <path
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    id="Path-2" fill="#000000" fill-rule="nonzero" opacity="0.3"></path> <path
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    id="Path" fill="#000000" fill-rule="nonzero"></path></g></svg>
                </span>
            </div>
        </div>
        <div id="kt_aside_favs">
            <div class="m-aside-menu-favs" v-show="Object.entries(favorites).length">
                <div class="aside-menu">
                    <div class="menu menu-column
             menu-title-gray-800
             menu-state-title-primary
             menu-state-icon-primary
             menu-state-bullet-primary
             menu-arrow-gray-500">
                        <div class="menu-item">
                            <div class="menu-content pt-4 pb-2">
                                <span class="menu-section text-muted text-uppercase fs-8 ls-1">Favoritos</span>
                            </div>
                        </div>
                    </div>
                </div>
                <transition name="component-fade" mode="out-in">
                    <transition-group v-if="Object.entries(favorites).length < 5"
                                      class="aside-menu"
                                      name="list" tag="div">
                        <div class="menu menu-column
             menu-title-gray-800
             menu-state-title-primary
             menu-state-icon-primary
             menu-state-bullet-primary
             menu-arrow-gray-500"
                             aria-haspopup="true" v-for="favorite in favorites"
                             :key="favorite.attributes.id">
                            <div class="menu-item">
                                <a :href="getUrl(favorite)"
                                   class="m-aside-menu-favs__link menu-link position-relative"
                                   :id="'favs-menu_' + favorite.attributes.unique_id">
                                    <em class="menu-icon fa-fw fa fa-star"></em>
                                    <span class="menu-title">{{ favorite.title }}</span>
                                </a>
                            </div>
                        </div>
                    </transition-group>
                    <div class="flex-column-auto pt-5 pb-1 px-6" v-else>
                        <div class="btn-custom btn p-0 w-100">
                            <span class="btn-label overflow-visible">
                                <multiselect v-model="selectedFav"
                                             class="m-aside-menu-favs__select is-active"
                                             id="favs"
                                             @input="redirectToSection" :multiple="false"
                                             placeholder="Seleccione una sección"
                                             :options="favoritesSelect" label="title" track-by="id"
                                ></multiselect>
                            </span>
                            <span class="btn-icon">
                                <i class="fa fa-fw fa-star"></i>
                            </span>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="aside-menu flex-column-fluid">
            <div class="hover-scroll-overlay-y" id="kt_aside_menu_wrapper" data-kt-scroll="true"
                 data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto"
                 data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer, #kt_aside_search, #kt_aside_favs"
                 data-kt-scroll-wrappers="#kt_aside_menu"
                 data-kt-scroll-offset="0">
                <div id="#kt_aside_menu"
                     data-kt-menu="true"
                     class="menu menu-column
             menu-title-gray-800
             menu-state-title-primary
             menu-state-icon-primary
             menu-state-bullet-primary
             menu-arrow-gray-500">
                    <div id="m_aside_left" class="m-grid__item m-aside-left m-aside-left--skin-dark">
                        <!-- BEGIN: Aside Menu -->
                        <div id="m_ver_menu"
                             class="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-light"
                             data-menu-vertical="true"
                             data-menu-scrollable="true" data-menu-dropdown-timeout="500">

                            <div class="menu-item">
                                <div class="menu-content pt-4 pb-2">
                                    <span class="menu-section text-muted text-uppercase fs-8 ls-1">Secciones</span>
                                </div>
                            </div>

                            <div class="p-3 text-center m-aside-menu__no-results" v-show="noResults">
                                <img src="/images/misc/tumbleweed.gif" alt="no results"
                                     style="width: 70%; height: 90px; border-radius: 20px">
                                <p>
                                    No se han encontrado secciones que contengan la palabra "{{ getFilter }}".
                                </p>
                            </div>

                            <div
                                class=" menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                                :class="{'js-filtered': getFilter !== null}">
                                <menu-item :item='section' :favorited="isFavorite(section)" :favorites="favorites"
                                           v-for="section in sections" :key="section.attributes.id"></menu-item>
                            </div>
                        </div>
                        <!-- END: Aside Menu -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import MenuItem from '@/js/components/panel/menu-item'
import SectionSearch from '@/js/components/panel/section-search'

export default {
    name: 'menu-sections',
    components: {
        MenuItem,
        SectionSearch,
    },
    props: ['sections', 'dbFavorites', 'flatMenu'],
    data() {
        return {
            selectedFav: '',
        }
    },
    created() {
        this.setMenu(this.sections)
        this.setFavorites(this.dbFavorites)
        this.setFlatMenu(this.flatMenu)
    },
    computed: {
        ...mapGetters('Menu', {
            getFilter: 'getFilter',
            getFilteredItems: 'getFilteredItems',
            favorites: 'getFavorites'
        }),
        favoritesArray() {
            return Object.entries(this.favorites).map(favorite => favorite[1].attributes.slug)
        },
        favoritesSelect() {
            return Object.entries(this.favorites).map(favorite => ({
                id: favorite[1].attributes.id,
                title: favorite[1].title
            }))
        },
        noResults() {
            return this.getFilteredItems.length === 0
        },
    },
    methods: {
        ...mapActions('Menu', {
            setMenu: 'setMenu',
            setFavorites: 'setFavorites',
            setFlatMenu: 'setFlatMenu',
        }),
        isFavorite(section) {
            return this.favoritesArray.includes(section.attributes.slug)
        },
        getUrl(item) {
            return item.url + (item.attributes.query_params !== null ? item.attributes.query_params : '')
        },
        redirectToSection() {
            this.$nextTick(() => {
                window.location.replace(this.favorites[this.selectedFav.id].url)
            })
        },
    }
}
</script>

<style scoped>
.list-item {
    display: inline-block;
    margin-right: 10px;
}

.list-enter-active, .list-leave-active {
    transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
{
    opacity: 0;
    transform: translateX(30px);
}

.component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
}

.component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.m-aside-left--minimize .m-aside-menu-favs {
    display: block !important;
}

.m-aside-left--minimize .m-aside-menu__no-results {
    display: none;
}

.m-aside-left--minimize .m-aside-menu-favs__items {
    display: none;
}

.m-aside-left--minimize .m-aside-menu-favs__select {
    display: none;
}

.m-aside-left--minimize .m-menu__nav--minimized {
    display: block !important;
}

.m-aside-left--minimize .m-menu__subnav--minimized {
    display: block !important;
}

.m-menu__nav--minimized {
    display: none;
}

.m-aside-menu-favs .m-menu__nav .m-menu__submenu {
    display: block;
}
</style>
