<template>
  <div class="event-status">
    <div class="m-portlet__head-btn-container d-inline-flex text-nowrap">
      <span>

        <span class="badge m-badge pl-2 pr-2 campaign-status" v-if="active">
          Activa
          <em class="fa fa-fw fa-clock-o" v-tooltip title="Con programación" v-if="recurrent"/>
          <em class="fa fa-fw fa-hand-o-right" v-tooltip title="Con disparador" v-else-if="triggerEnabled"/>
        </span>
        <span class="badge m-badge pl-2 pr-2 campaign-status" v-else>Inactiva</span>

        <button v-tooltip title="Desactivar campaña" type="button" v-if="active" :disabled="nbReadOnly"
                @click.stop="deactivateCampaign" :id="'deactivate-campaign' + campaignId"
                class="mr-1 btn btn-sm bg-danger m-btn m-btn--icon m-btn--icon-only m-btn--pill text-white">
            <em class="fa fa-fw fa-stop"/>
        </button>

        <button v-tooltip title="Activar campaña" type="button" v-else :disabled="nbReadOnly"
                @click.stop="activateCampaign" :id="'activate-campaign' + campaignId"
                class="mr-1 btn btn-sm bg-success m-btn m-btn--icon m-btn--icon-only m-btn--pill text-white">
            <em class="fa fa-fw fa-play"/>
        </button>

        <button type="button" v-tooltip title="Generar copia" @click="duplicateCampaign"
                :id="'duplicate-campaign-' + campaignId" :disabled="nbReadOnly"
                class="mr-1 btn btn-sm m-btn m-btn--icon m-btn--icon-only m-btn--pill text-white bg-primary">
            <em class="fa fa-fw fa-files-o"/>
        </button>

        <button type="button" v-tooltip title="Borrar" @click="removeCampaign" v-if="!inForm"
                :id="'remove-campaign-' + campaignId" :disabled="nbReadOnly"
                class="mr-1 btn btn-sm m-btn m-btn--icon m-btn--icon-only m-btn--pill text-white bg-danger">
            <em class="fa fa-fw fa-trash"/>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'campaign-status-manager',
  data () {
    return {

    }
  },
  props: {
    inForm: {type: Boolean, default: false},
    campaignId: {type: Number, required: true},
    active: {type: Boolean, default: false},
    recurrent: {type: Boolean, default: false},
    triggerEnabled: {type: Boolean, default: false},
  },
  methods: {
    async deactivateCampaign() {
      const result = await this.$swal.fire({
        title: 'Desactivar campaña',
        text: 'Se desactivará el envío de la campaña a los destinatarios, ¿proceder?',
        confirmButtonText: 'Desactivar campaña',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        showCancelButton: true,
      })

      if (result.value) {
        const response = await axios.post(`/api/crm/campaigns/${this.campaignId}/deactivate`)
        if (response.status === 200) {
          location.reload(true)
        }
      }
    },
    async activateCampaign() {
      const result = await this.$swal.fire({
        title: 'Activar campaña',
        text: 'Se activarán los envíos de la campaña a los destinatarios, ¿proceder?',
        confirmButtonText: 'Activar campaña',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        showCancelButton: true,
      })

      if (result.value) {
        const response = await axios.post(`/api/crm/campaigns/${this.campaignId}/activate`)
        if (response.status === 200) {
          location.reload(true)
        }
      }
    },
    duplicateCampaign() {
      location.replace(this.$route('campaigns.duplicate', this.campaignId))
    },
    async removeCampaign() {
      try {
        const result = await this.$swal.fire({
          title: '¿Deseas eliminar la entidad?',
          text: 'Esta acción es irreversible',
          confirmButtonText: 'Ok',
          cancelButtonText: 'Cancelar',
          type: 'warning',
          showCancelButton: true,
        })
        if (result.value) {
          const response = await axios.delete('campaigns/' + this.campaignId)
          if (response.status === 200) {
            location.reload(true)
          } else {
            await this.$swal.fire('error', response.data.message)
          }
        }
      } catch (e) {
        console.dir(e)
        let errorData = e.response.data
        await this.$swal.fire({
          title: errorData.message,
          text: errorData.exception.code === '23000' ? 'Otros registros dependen de esta entidad.' : null,
          type: 'error'
        })
      }
    },
  },
}
</script>

<style>
.campaign-status {
  font-size: small;
}

.cursor-default {
  cursor: help !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.div-overlay {
  background-color: rgba(255, 248, 0, 0.36);
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

.div-overlay-off {
  background-color: white;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}
</style>
