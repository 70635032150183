var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "form-group m-form__group col-md-6" }, [
          _c("label", { attrs: { for: "sendgrid_sender_id" } }, [
            _vm._v("Desde la dirección")
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sender,
                  expression: "sender"
                }
              ],
              staticClass: "form-select form-select-solid",
              attrs: { disabled: _vm.nbReadOnly, title: "Dirección de origen" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.sender = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.senders, function(sender) {
              return _c("option", { domProps: { value: sender } }, [
                _vm._v("\n          " + _vm._s(sender.name) + "\n        ")
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("input", {
            attrs: {
              type: "hidden",
              name: "sendgrid_sender_id",
              id: "sendgrid_sender_id"
            },
            domProps: { value: _vm.sender ? _vm.sender.id : null }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group m-form__group col-md-6" },
          [
            _c("label", { attrs: { for: "currentSubject" } }, [
              _vm._v(_vm._s(_vm._f("trans")("campaign_component.subject")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentSubject,
                  expression: "currentSubject"
                }
              ],
              staticClass: "form-control m-input",
              attrs: {
                id: "currentSubject",
                type: "text",
                name: "subject",
                disabled: _vm.nbReadOnly
              },
              domProps: { value: _vm.currentSubject },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.currentSubject = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("nb-error-block", { attrs: { path: "subject" } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "select-with-create",
          {
            attrs: {
              "nb-read-only": _vm.nbReadOnly,
              fieldName: "crm_target_id",
              model: "targets",
              route: "API.targets.options",
              initial: _vm.initialSelectedTargetId,
              initialData: _vm.initialSelectedTarget
            },
            on: { dirty: _vm.dirty },
            scopedSlots: _vm._u([
              {
                key: "modalTitle",
                fn: function() {
                  return [_vm._v("Crear nueva lista de la campaña")]
                },
                proxy: true
              }
            ])
          },
          [
            _vm._v("\n      Lista de la campaña\n      "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                staticClass: "m-badge m-badge--brand m-badge--wide ml-2"
              },
              [
                _vm._v(
                  "\n                  " +
                    _vm._s(_vm.targetCount) +
                    " " +
                    _vm._s(_vm._f("trans")("campaign_component.apply")) +
                    "\n              "
                )
              ]
            ),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "m-loader m-loader--sm m-loader--brand mx-4"
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "select-with-create",
          {
            attrs: {
              "nb-read-only": _vm.nbReadOnly,
              fieldName: "crm_template_id",
              model: "templates",
              route: "API.templates.options",
              initial: _vm.initialSelectedTemplateId,
              initialData: _vm.initialSelectedTemplate
            },
            scopedSlots: _vm._u([
              {
                key: "modalTitle",
                fn: function() {
                  return [_vm._v("Crear nueva plantilla")]
                },
                proxy: true
              }
            ])
          },
          [_vm._v("\n      Plantilla\n      ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }