<template>
    <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
        <i class="fa fa-arrow-up"></i>
    </div>
</template>

<script>
  export default {
    name: 'scrolltop-arrow',
    methods: {
      goUp () {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }


</script>

<style scoped>
</style>
