<template>
    <div class="position-relative">
        <textarea :id="id" :title="placeholder"
               class="inputField form-control-sm"
               :class="filteredSuggestions.length ? 'open ' + inputClass : inputClass" :placeholder="placeholder"
               ref="suggester" autocomplete="off"
               :value="value" v-on:input="input" @blur="handleLostFocus" @focus="handleOnFocus"
        />
        <div class="position-absolute suggestions rounded mw-100"
             :class="filteredSuggestions.length ? 'open' : ''"
             style="z-index: 100; max-height: 200px;" ref="suggestionsList"
             v-show="filteredSuggestions.length"
        >
            <ul class="list-group list-group-flush rounded-bottom">
                <li class="list-group-item suggestion px-2 py-1" v-for="(suggestion, i) in filteredSuggestions"
                    :tabindex="i" :ref="'option' + i"
                    @keydown.enter.prevent="insertWordAtCaret(suggestion)"
                    @keydown.space.prevent="insertWordAtCaret(suggestion, true)"
                    @keydown.38.stop.prevent="selectPreviousOption(i)"
                    @keydown.40.stop.prevent="selectNextOption(i)"
                    @blur="handleLostFocus"
                    @mousemove="selectNextOption(i - 1)"
                    @click="insertWordAtCaret(suggestion)">
                    <a @click.stop.prevent="insertWordAtCaret(suggestion)" :id="'suggestion-' + i">
                        <slot v-bind:suggestion="suggestion">
                            {{ suggestion }}
                        </slot>
                    </a>
                </li>
            </ul>
        </div>
    </div>

</template>

<script>
  import uuid from 'uuid/v4'

  const KEYWORD = '$'

  export default {
    name: 'SuggesterText',
    props: {
      id: { type: String, default: uuid() },
      value: { type: String, default: '' },
      suggestions: { type: Array, default: () => ([]) },
      inputClass: { type: String, default: 'form-control' },
      placeholder: { type: String, default: '' },
    },

    data () {
      return {
        currentWordInCursor: null,
        selectionStart: null,
        selectionEnd: null,
      }
    },

    mounted () {
      const input = this.$refs.suggester

      input.addEventListener('keypress', this.showSuggestionBox)
      input.addEventListener('keydown', this.showSuggestionBox)
      input.addEventListener('keyup', this.showSuggestionBox)

      $(this.$refs.suggestionsList).mCustomScrollbar({
        setHeight: 251,
        scrollInertia: 0,
        autoDraggerLength: true,
        autoHideScrollbar: false,
        autoExpandScrollbar: false,
        alwaysShowScrollbar: true,
        snapOffset: 38.5,
        mouseWheel: {
          scrollAmount: 120,
          preventDefault: false,
        },
        advanced: {
          updateOnContentResize: true,
          autoExpandHorizontalScroll: true,
          autoScrollOnFocus: 'li',
        },
        theme: 'minimal-dark',
      })
    },

    computed: {
      filteredSuggestions () {
        if (this.currentWordInCursor === null) return []

        return this.suggestions.filter((suggestion) => suggestion.includes(this.currentWordInCursor))
      },
    },

    methods: {
      showSuggestionBox (e) {
        const { currentTarget: input, which } = e
        const { selectionStart, value } = input

        // Controlar tecla borrar
        if (which === 8) this.handleRemoval(selectionStart)

        // Al pulsar espacio limpiamos palabra de filtro
        if (which === 32) this.resetFilter()

        if (which === 40 && this.filteredSuggestions.length) this.$refs.option0[0].focus()

        this.selectionEnd = selectionStart
        // Al detectar la Keyword iniciamos el filtro de búsqueda
        if (!this.selectionStart && value.charAt(selectionStart - 1) === KEYWORD) {
          this.selectionStart = selectionStart
        }

        if (this.selectionStart) this.currentWordInCursor = value.substring(this.selectionStart, this.selectionEnd)
      },

      insertWordAtCaret (word, addSpace = false) {
        const start = this.selectionStart
        const newValue = this.value.substr(0, start) + word + (addSpace ? ' ' : '') + this.value.substr(this.selectionEnd)
        this.$emit('input', newValue)
        this.$nextTick(() => {
          const position = this.$refs.suggester.value.indexOf(word, start - 1) + word.length + (addSpace ? 1 : 0)
          this.setCaretPosition(this.$refs.suggester, position)
        })

        this.resetFilter()
      },

      setCaretPosition (input, position) {
        if (input === null) return

        // Textarea
        if (input.hasOwnProperty('createTextRange')
          && typeof input.createTextRange === 'function') {
          const range = input.createTextRange()
          range.move('character', position)
          range.select()
        }
        // Textbox
        else {
          input.focus()
          input.selectionStart && input.setSelectionRange(position, position)
        }
      },

      handleRemoval (selectionStart) {
        for (let i = selectionStart; i >= 0; i--) {
          if (this.value.charAt(i) === ' ') {
            this.resetFilter()
            break
          } else if (this.value.charAt(i) === KEYWORD) {
            this.selectionStart = i + 1
            break
          } else this.resetFilter()
        }
      },

      async handleOnFocus (e) {
        const { target: input } = e
        await this.$nextTick()
        const { selectionStart } = input

        for (let i = selectionStart; i >= 0; i--) {
          if (this.value.charAt(i) === ' ') break

          if (this.value.charAt(i) === KEYWORD) {
            this.selectionStart = i + 1
            this.selectionEnd = selectionStart
            this.currentWordInCursor = this.$refs.suggester.value.substring(this.selectionStart, this.selectionEnd)
            break
          }
        }
      },

      handleLostFocus () { this.$nextTick(() => { if (!$(':focus').hasClass('suggestion')) this.resetFilter() }) },

      selectPreviousOption (index) {
        if (this.$refs.hasOwnProperty(`option${index - 1}`)) {
          this.$refs[`option${index - 1}`][0].focus()
        } else {
          this.$refs[`option${this.filteredSuggestions.length - 1}`][0].focus()
        }
      },

      selectNextOption (index) {
        if (this.$refs.hasOwnProperty(`option${index + 1}`)) {
          this.$refs[`option${index + 1}`][0].focus()
        } else {
          this.$refs[`option0`][0].focus()
        }
      },

      resetFilter () {
        this.selectionStart = null
        this.selectionEnd = null
        this.currentWordInCursor = null
      },

      input (e) {
        if (!e.target.value.length) this.resetFilter()

        this.$emit('input', e.target.value)
      },
    },
  }
</script>

<style lang="scss" scoped>
    .inputField.open {
    }

    .suggestions.open {
        background: rgb(254, 255, 255);
        padding: 2px;
        border: solid #f9f5ff 1px;
        margin-top: 1px;
    }

    input:focus + .suggestions {
        border-color: #007DB8;

        .list-group {
            .list-group-item {
                border-color: #007DB8;
            }
        }
    }

    .list-group {
        border: 0;

        .list-group-item {
            border: 0;
            cursor: pointer;
            background-color: rgba(0, 0%, 0%, 0);
            border-top: 0 !important;
            border-color: #ebedf2;
            transition: 0.15s all ease;
            margin-right: 15px; // Margen para facilitar drag en scrollbar

            a {
                display: block;
                padding: 4px 15px 4px 10px;
                font-weight: bold;
                overflow: hidden;
                color: #66757f;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:focus, &:active {
                }
            }

            &:first-child {
                margin-top: 4px;
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }

            &:focus {
                outline: none;
                background-color: hsl(0, 0%, 98%);
                color: #4eb1ff;

                a {
                    color: #0a8cf0;
                }
            }
        }
    }
</style>
