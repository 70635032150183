var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    !_vm.lang
      ? _c(
          "div",
          {
            staticClass:
              "c-survey-component__question-types form-group m-form__group mb-5"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn-group m-btn-group btn-block w-100",
                attrs: { role: "group" }
              },
              _vm._l(_vm.question_types, function(type) {
                return _c(
                  "button",
                  {
                    key: "k-" + type.id,
                    staticClass:
                      "btn btn-outline-primary border border-primary",
                    class: {
                      "btn-brand": _vm.question.question_type_id === type.id,
                      "btn-outline-brand":
                        _vm.question.question_type_id !== type.id
                    },
                    attrs: {
                      type: "button",
                      id: "question-type-" + type.id,
                      disabled: _vm.nbReadOnly
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectType(type.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(type.name) +
                        "\n            "
                    )
                  ]
                )
              }),
              0
            )
          ]
        )
      : _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedType,
                expression: "selectedType"
              }
            ],
            attrs: { type: "hidden", name: "question_type_id" },
            domProps: { value: _vm.selectedType },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.selectedType = $event.target.value
              }
            }
          })
        ]),
    _vm._v(" "),
    _vm.isWithOptions
      ? _c(
          "div",
          {
            staticClass:
              "c-survey-component__question-options form-group m-form__group"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-between mb-3"
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary m-btn m-btn--icon",
                    attrs: {
                      type: "button",
                      disabled: _vm.nbReadOnly,
                      id: "add-option"
                    },
                    on: { click: _vm.addOption }
                  },
                  [_vm._m(2)]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "table table-striped table-bordered m-table m-0" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Opción")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Valor")]),
                    _vm._v(" "),
                    !_vm.lang
                      ? _c(
                          "th",
                          {
                            staticClass: "text-center",
                            attrs: { width: "5%" }
                          },
                          [_vm._v("Seleccionada")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.lang ? _c("th", { attrs: { width: "1%" } }) : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.question.options, function(option, indexOption) {
                    return _c("tr", { staticClass: "align-top" }, [
                      _c("td", { staticClass: "p-2 align-middle" }, [
                        _c(
                          "div",
                          { staticClass: "m-form__control" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: option.option,
                                  expression: "option.option"
                                }
                              ],
                              staticClass:
                                "form-control form-control-sm m-input",
                              attrs: {
                                placeholder: "Opcion",
                                disabled: _vm.nbReadOnly,
                                id: "option-" + indexOption
                              },
                              domProps: { value: option.option },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    option,
                                    "option",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.hasOptionHelpBlock(indexOption)
                              ? _c("p", { staticClass: "m-form__help" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.question.options_help_block[
                                          indexOption
                                        ].option
                                      ) +
                                      "\n                        "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("nb-error-block", {
                              attrs: {
                                path:
                                  "questions." +
                                  _vm.index +
                                  ".options." +
                                  indexOption +
                                  ".option"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "p-2 align-middle" }, [
                        _c(
                          "div",
                          { staticClass: "m-form__control" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: option.value,
                                  expression: "option.value"
                                }
                              ],
                              staticClass:
                                "form-control form-control-sm m-input",
                              attrs: {
                                placeholder: "Valor a devolver",
                                disabled: _vm.nbReadOnly,
                                id: "return-value-" + indexOption
                              },
                              domProps: { value: option.value },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(option, "value", $event.target.value)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.hasOptionHelpBlock(indexOption)
                              ? _c("span", { staticClass: "m-form__help" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.question.options_help_block[
                                          indexOption
                                        ].value
                                      ) +
                                      "\n                            "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("nb-error-block", {
                              attrs: {
                                path:
                                  "questions." +
                                  _vm.index +
                                  ".options." +
                                  indexOption +
                                  ".value"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      !_vm.lang
                        ? _c(
                            "td",
                            { staticClass: "text-center p-2 align-middle" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "m-checkbox m-checkbox--brand pl-3 mt-2"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: option.is_selected,
                                        expression: "option.is_selected"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      checked: "checked",
                                      id: "select-option-" + indexOption,
                                      disabled: _vm.nbReadOnly
                                    },
                                    domProps: {
                                      checked: Array.isArray(option.is_selected)
                                        ? _vm._i(option.is_selected, null) > -1
                                        : option.is_selected
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a = option.is_selected,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  option,
                                                  "is_selected",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  option,
                                                  "is_selected",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(option, "is_selected", $$c)
                                          }
                                        },
                                        function($event) {
                                          return _vm.setOptionSelected(
                                            indexOption
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span")
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.lang
                        ? _c(
                            "td",
                            {
                              staticClass:
                                "align-middle text-nowrap p-2 align-middle"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "actions text-center" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only",
                                      attrs: {
                                        type: "button",
                                        disabled: _vm.nbReadOnly,
                                        id: "delete-option-" + indexOption
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteOption(indexOption)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-fw fa-trash"
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  }),
                  0
                )
              ]
            )
          ]
        )
      : _vm.isCheckbox
      ? _c(
          "div",
          {
            staticClass:
              "c-survey-component__question-options form-group m-form__group"
          },
          [
            _c("span", { staticClass: "m-switch m-switch--icon" }, [
              _c("label", {}, [_vm._v("Valor por defecto")]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.defaultValue,
                      expression: "defaultValue"
                    }
                  ],
                  attrs: { disabled: _vm.nbReadOnly, type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.defaultValue)
                      ? _vm._i(_vm.defaultValue, null) > -1
                      : _vm.defaultValue
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.defaultValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.defaultValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.defaultValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.defaultValue = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span")
              ])
            ])
          ]
        )
      : _c(
          "div",
          {
            staticClass:
              "c-survey-component__question-options form-group m-form__group"
          },
          [
            _c("label", { attrs: { for: "default_value" } }, [
              _vm._v("Respuesta por defecto")
            ]),
            _vm._v(" "),
            _vm.questionType.type === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.defaultValue,
                      expression: "defaultValue"
                    }
                  ],
                  staticClass: "form-control m-input",
                  attrs: {
                    name: "default_value",
                    id: "default_value",
                    disabled: _vm.nbReadOnly,
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.defaultValue)
                      ? _vm._i(_vm.defaultValue, null) > -1
                      : _vm.defaultValue
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.defaultValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.defaultValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.defaultValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.defaultValue = $$c
                      }
                    }
                  }
                })
              : _vm.questionType.type === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.defaultValue,
                      expression: "defaultValue"
                    }
                  ],
                  staticClass: "form-control m-input",
                  attrs: {
                    name: "default_value",
                    id: "default_value",
                    disabled: _vm.nbReadOnly,
                    type: "radio"
                  },
                  domProps: { checked: _vm._q(_vm.defaultValue, null) },
                  on: {
                    change: function($event) {
                      _vm.defaultValue = null
                    }
                  }
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.defaultValue,
                      expression: "defaultValue"
                    }
                  ],
                  staticClass: "form-control m-input",
                  attrs: {
                    name: "default_value",
                    id: "default_value",
                    disabled: _vm.nbReadOnly,
                    type: _vm.questionType.type
                  },
                  domProps: { value: _vm.defaultValue },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.defaultValue = $event.target.value
                    }
                  }
                }),
            _vm._v(" "),
            _vm.question.default_help_block
              ? _c("p", { staticClass: "m-form__help" }, [
                  _vm._v(_vm._s(_vm.question.default_help_block))
                ])
              : _vm._e()
          ]
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "c-survey-component__title mb-5" }, [
      _c("i", { staticClass: "fs-2 bi bi-file-earmark-text" }),
      _vm._v("\n            tipos de preguntas\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "c-survey-component__title m-0" }, [
      _c("i", { staticClass: "fs-2 bi bi-file-earmark-text" }),
      _vm._v("\n                Opciones de la pregunta\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-plus" }),
      _vm._v(" "),
      _c("span", [_vm._v("Añadir opción")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }