var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group w-100 mt-4" }, [
    _c("input", {
      attrs: { type: "hidden", name: "questions", id: "questions" },
      domProps: { value: _vm.questionsJSON }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "table",
          { staticClass: "table table-striped table-bordered m-table" },
          [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text-center", attrs: { width: "10%" } },
                  [_vm._v(_vm._s(_vm._f("trans")("forms_poll.required")))]
                ),
                _vm._v(" "),
                _c("th", [
                  _vm._v(_vm._s(_vm._f("trans")("forms_poll.question")))
                ]),
                _vm._v(" "),
                _c("th", { attrs: { width: _vm.actionsWidth } })
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.questions, function(question, indexQuestion) {
                return _c("tr", [
                  _c("td", { staticClass: "text-center align-middle" }, [
                    _c(
                      "label",
                      { staticClass: "m-checkbox m-checkbox--brand pl-4 pb-2" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: question.is_required,
                              expression: "question.is_required"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            disabled: _vm.nbReadOnly,
                            id: "question-required-" + indexQuestion
                          },
                          domProps: {
                            checked: Array.isArray(question.is_required)
                              ? _vm._i(question.is_required, null) > -1
                              : question.is_required
                          },
                          on: {
                            change: function($event) {
                              var $$a = question.is_required,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      question,
                                      "is_required",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      question,
                                      "is_required",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(question, "is_required", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span")
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-middle" }, [
                    _vm._v(_vm._s(question.questionName))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      {
                        staticClass: "btn-group m-btn-group btn-block",
                        attrs: { role: "group" }
                      },
                      [
                        indexQuestion
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary m-btn m-btn--icon btn-sm m-btn--icon-only",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.nbReadOnly,
                                  id: "move-up-question-" + indexQuestion
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.moveQuestion(indexQuestion, true)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fa fa-arrow-up" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        indexQuestion < _vm.questions.length - 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary m-btn m-btn--icon btn-sm m-btn--icon-only",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.nbReadOnly,
                                  id: "move-down-question-" + indexQuestion
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.moveQuestion(
                                      indexQuestion,
                                      false
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fa fa-arrow-down" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-danger m-btn m-btn--icon btn-sm m-btn--icon-only",
                            attrs: {
                              type: "button",
                              disabled: _vm.nbReadOnly,
                              id: "delete-question-" + indexQuestion
                            },
                            on: {
                              click: function($event) {
                                return _vm.deleteQuestion(indexQuestion)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-fw fa-trash" })]
                        )
                      ]
                    )
                  ])
                ])
              }),
              0
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "table",
          { staticClass: "table table-striped table-bordered m-table" },
          [
            _c("thead", [
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm._f("trans")("forms_poll.question")))
                ]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm._f("trans")("forms_poll.type")))]),
                _vm._v(" "),
                _c("th", { attrs: { width: "10%" } })
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.rep_questions, function(question, indexQuestion) {
                return _c("tr", [
                  _c("td", [_vm._v(_vm._s(question.name))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(question.typeName))]),
                  _vm._v(" "),
                  _c("td", { staticClass: "actions text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary m-btn m-btn--icon btn-sm m-btn--icon-only",
                        attrs: {
                          type: "button",
                          disabled: _vm.isAdd(question.id) || _vm.nbReadOnly,
                          id: "add-question-" + indexQuestion
                        },
                        on: {
                          click: function($event) {
                            return _vm.addQuestion(indexQuestion)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-plus" })]
                    )
                  ])
                ])
              }),
              0
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "mb-4" }, [
      _c("em", { staticClass: "flaticon-add-circular-button mr-1" }),
      _vm._v("\n                Incluidas\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "mb-4" }, [
      _c("em", { staticClass: "flaticon-questions-circular-button mr-1" }),
      _vm._v("\n                Repositorio\n            ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }