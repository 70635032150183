var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "dashboardPlatform",
      staticClass: "card card-xl-stretch",
      class: {
        "is-full-screen position-fixed modal overflow-auto top-0 start-0 bottom-0 end-0 was-full-screen rounded-0 d-block":
          _vm.isFullDashboard,
        "was-full-screen": _vm.wasFullDashboard,
        loading: _vm.loading
      }
    },
    [
      _c("div", { staticClass: "card-header border-0 py-5" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "card-toolbar" }, [
          _c(
            "button",
            {
              staticClass:
                "btn btn-sm btn-icon btn-color-dark btn-active-dark btn-active-color- border-0",
              on: { click: _vm.exportToExcel }
            },
            [_c("i", { staticClass: "fa fa-download" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "btn btn-sm btn-icon btn-color-dark btn-active-dark btn-active-color- border-0 me-n3",
              on: { click: _vm.toggleFullDashboard }
            },
            [_c("i", { staticClass: "fa fa-expand-alt" })]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body d-flex flex-column" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5" }, [
          _c("div", { staticClass: "d-flex flex-stack mb-5" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "badge badge-light fw-bold py-4 px-3" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.customersRecount) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-stack mb-5" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "badge badge-light fw-bold py-4 px-3" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.unsubscribedCustomersRecount) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _vm._m(4)
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "card-title fw-bolder" }, [
      _c("i", { staticClass: "fa fa-fw fa-sliders-h text-muted pe-2" }),
      _vm._v("\n            Uso de la plataforma\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mixed-widget-5-chart card-rounded-top" }, [
      _c("canvas", { attrs: { id: "registeredUsersChart" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center me-2" }, [
      _c("div", [
        _c(
          "a",
          {
            staticClass: "fs-6 text-gray-800 text-hover-primary fw-bolder",
            attrs: { href: "#" }
          },
          [_vm._v("Usuarios registrados")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "fs-7 text-muted fw-bold mt-1" }, [
          _vm._v(
            "\n                            en el periodo\n                        "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center me-2" }, [
      _c("div", [
        _c(
          "a",
          {
            staticClass: "fs-6 text-gray-800 text-hover-primary fw-bolder",
            attrs: { href: "#" }
          },
          [_vm._v("Bajas")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-stack mb-5" }, [
      _c("div", { staticClass: "d-flex align-items-center me-2" }, [
        _c("div", [
          _c(
            "a",
            {
              staticClass: "fs-6 text-gray-800 text-hover-primary fw-bolder",
              attrs: { href: "#" }
            },
            [_vm._v("Ausencias")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "badge badge-light fw-bold py-4 px-3" }, [
        _vm._v("0")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }