<template>
    <div class="row mb-2">
        <div class="form-group col-12">
            <label for="elementName">Nombre</label>
            <input type="text" id="elementName" v-model="activeElement.name"
                   class="form-control form-control-sm" placeholder="Nombre del elemento">
        </div>

        <div class="form-group col-6">
            <label for="elementWidth">Anchura</label>
            <input type="number" id="elementWidth"
                   v-model.number="activeElement.width" @input="layoutChange"
                   class="form-control form-control-sm" placeholder="Ancho">
        </div>

        <div class="form-group col-6">
            <label for="elementHeight">Altura</label>
            <input type="number" id="elementHeight"
                   v-model.number="activeElement.height" @input="layoutChange"
                   class="form-control form-control-sm" placeholder="Ancho">
        </div>

        <div class="form-group col-6">
            <label for="elementX">Posición Eje X</label>
            <input type="number" id="elementX"
                   v-model.number="activeElement.x" @input="layoutChange"
                   class="form-control form-control-sm" placeholder="X">
        </div>

        <div class="form-group col-6">
            <label for="elementY">Posición Eje Y</label>
            <input type="number" id="elementY"
                   v-model.number="activeElement.y" @input="layoutChange"
                   class="form-control form-control-sm" placeholder="Y">
        </div>
        <div class="form-group col-12 text-content-check">
            <label class="m-checkbox mb-0">
                <input type="checkbox" checked="checked" id="elementOverflow"
                       v-model="activeElement.allowOverflow">
                ¿El contenido puede salir del contenedor?
                <span></span>
            </label>
        </div>
        <div class="form-group col-12 text-content-check">
            <label class="m-checkbox mb-0">
                <input type="checkbox" id="elementBlock"
                       :checked="blocked"
                       v-model="blocked"
                       :disabled="powerUserLoged < blockPower">
                Bloquear elemento (Prioridad {{blockPower}})
                <span></span>
            </label>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters } from 'vuex'

  export default {
    name: 'CommonProperties',
    props: ['powerUserLoged'],

    computed: {
      ...mapGetters('ticketEditor', {
        activeElement: 'activeElement',
      }),
      blockPower () {
        return this.activeElement.blockPower
      },
      blocked: {
        get () {
          return this.activeElement.blocked
        },
        set (state) {
          this.activeElement.blocked = state
          this.activeElement.blockPower = state ? this.powerUserLoged : 0
          return state
        }
      }
    },

    methods: {
      layoutChange () { this.$eventBus.$emit('layoutChange') },
    },
  }
</script>

<style scoped>
    label[for] {
        font-size: 0.9rem !important;
        margin-bottom: 0.4rem !important;
    }

    .text-content-check {
        font-size: 0.9rem !important;
        border-top: 1px dashed #F1F1F1;
        border-bottom: 1px dashed #F1F1F1;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        margin-bottom: 0.5rem !important;
    }

    .text-content-check label {
        font-size: 0.9rem !important;
    }
</style>
