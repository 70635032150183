var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field is-marginless" },
    [
      _c("label", { staticClass: "field__span" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("multiselect", {
        attrs: {
          options: _vm.validTags,
          "track-by": "tagName",
          disabled: _vm.disabled,
          label: "tagName",
          multiple: true,
          placeholder: _vm.placeholder
        },
        model: {
          value: _vm.selectedTags,
          callback: function($$v) {
            _vm.selectedTags = $$v
          },
          expression: "selectedTags"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }