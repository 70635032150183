<template>
    <tr :class="{'tr-child': !!entity.parent_id}">
        <td>
            <span class="entity-name">{{ entity.visible_name }}</span>
        </td>
        <permission-item
                v-for="permissionType in permissionTypes"
                :key="`${permissionType.id}_${entity.id}`"
                :showThisPermission="isVisiblePermission(permissionType, entity)"
                :entityId="entity.id"
                :userRoleInfo="userRoleInfo"
                :permissionTypeId="permissionType.id"
                :roleGranted="getRolePermission(entity.id, permissionType.id)"
                :granted="getPermission(entity.id, permissionType.id)"
                :nbReadOnly="nbReadOnly"/>
    </tr>
</template>

<script>
  import permissionItem from './PermissionItemComponent.vue'

  export default {
    name: 'permission-row',
    components: {
      permissionItem,
    },
    props: {
      entity: {
        default: null,
        validator: value => value instanceof Object,
      },
      permissionTypes: {
        default: 0,
        validator: value => value instanceof Object,
      },
      permissions: {
        default: null,
        validator: value => value instanceof Array,
      },
      userRoleInfo: {
        default: null,
        validator: value => value instanceof Object,
      },
    },
    methods: {
      getPermission (entityId, permissionTypeId) {
        const entityPermits = this.permissions.find(p => p.id === entityId)
        const grants = entityPermits.permits.find(p => p.id === permissionTypeId)
        if (grants) {
          return grants.granted === null ? grants.granted : !!grants.granted
        }
        return null
      },
      getRolePermission (entityId, permissionTypeId) {
        if (!this.userRoleInfo) {
          return null
        }
        const entityPermits = this.userRoleInfo.permissions.find(p => p.id === entityId)
        const grants = entityPermits.permits.find(p => p.id === permissionTypeId)
        if (grants) {
          return grants.granted === null ? grants.granted : !!grants.granted
        }
        return null
      },
      isVisiblePermission (permissionType, entity) {
        if (!entity.parent_id) return true

        return [
          MODEL_CONSTANTS.Permission.VIEW,
          MODEL_CONSTANTS.Permission.UPDATE,
        ].includes(permissionType.id)
      }
    },
  }
</script>

<style lang="scss" scoped>
    .entity-name {
    }

    .tr-child {
        > td:first-child {

            &:before {
                content: "\2022 \00a0 \00a0";
            }
        }
    }
</style>
