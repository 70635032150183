<template>
    <div class="form-group m-form__group col-md-8 mb-10">
        <div class="row">
            <div class="col-md-6">
                <label
                    class="form-check form-switch form-check-custom form-check-solid"
                    ><input
                        type="hidden"
                        name="posted_value"
                        id="posted_value"
                        :value="posted ? 1 : 0"
                    />
                    <input
                        type="checkbox"
                        name="posted"
                        id="posted"
                        :value="posted"
                        v-model="posted"
                        class="form-check-input"
                    />
                    <span class="form-check-label">
                        {{traduccionPosted}}
                    </span>
                </label>
            </div>
            <div class="col-md-6">
                <label
                    class="form-check form-switch form-check-custom form-check-solid"
                >
                    <input
                        type="hidden"
                        name="featured_value"
                        id="featured_value"
                        :value="featured ? 1 : 0"
                    />
                    <input
                        type="checkbox"
                        class="form-check-input"
                        name="featured"
                        id="featured"
                        :value="featured"
                        v-model="featured"
                        :disabled="disabled"
                    />
                    <span class="form-check-label">
                        {{traduccionFeatured}}
                    </span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog-post-posted-featured-component",
    props: {
        oldPosted: { default: false },
        oldFeatured: { default: false },
        traduccionPosted:{ type: String, default: '' },
        traduccionFeatured:{ type: String, default: '' },
    },
    data() {
        return {
            posted: false,
            featured: false
        };
    },
    created() {
        this.posted = this.oldPosted || false;
        this.featured = this.oldFeatured || false;
    },
    computed: {
        disabled() {
            return this.posted ? false : true;
        }
    },
    watch: {
        posted(value) {
            this.featured = value ? this.featured : false;
        }
    }
};
</script>
