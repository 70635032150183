var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "col-12 m--padding-top-15 m--padding-right-25 m--padding-left-25 ml-2 mr-2"
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("h4", [
            _c("em", { staticClass: "flaticon-graphic-2 mr-1" }),
            _vm._v(_vm._s(_vm.title))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-separator--lg my-3" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row m-row--no-padding m-row--col-separator-xl" },
        [
          _vm._l(_vm.stats, function(stat) {
            return [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "m-widget24" }, [
                  _c("div", { staticClass: "m-widget24__item" }, [
                    _c("h4", { staticClass: "m-widget24__title" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(stat[0]) +
                          "\n                        "
                      )
                    ]),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "m-widget24__stats m--font-brand",
                        style: _vm.styleData(stat)
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(stat[2]) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "m-widget24__desc" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(stat[1]) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "m--space-10" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "progress m-progress--sm" }, [
                      _c("div", {
                        staticClass: "progress-bar m--bg-brand",
                        style: _vm.styleProgressBar(stat),
                        attrs: {
                          role: "progressbar",
                          "aria-valuenow": "50",
                          "aria-valuemin": "0",
                          "aria-valuemax": "100"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "m-widget24__change" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(stat[3]) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "m-widget24__number" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(stat[4]) +
                          "%\n                        "
                      )
                    ])
                  ])
                ])
              ])
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }