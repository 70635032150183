<template>
    <div class="upload-files">
        <div class="m-btn btn btn-brand m-btn--icon"
             @click="visibleModal = true">
            <span>
                <em class="la la-cloud-upload"></em>
                <span>{{ name }}</span>
            </span>
        </div>

        <modal-component id="quotasModal"
                         tabindex="-1"
                         :visible.sync="visibleModal"
                         :backdrop="true"
                         :keyboard="true">
            <template #title>
                Subir archivos
            </template>
            <template>
                <div class="box-body">
                    <div class="box-upload">
                        <div class="box-upload__title">
                            <strong>Paso 1:</strong> Selecciona los archivo(s)
                        </div>
                        <div v-show="!uploading && !uploaded" class="box-upload-message">
                            <form enctype="multipart/form-data">
                                <input id="files" ref="files" type="file" :multiple="multiple" @change="fileInput"/>
                            </form>
                            <label for="files" class="btn btn-block btn-primary btn-lg">
                                <em class="fa fa-plus-circle fa-fw me-1"></em>
                                Seleccionar los archivos
                            </label>
                        </div>
                        <div class="box-list-files">
                            <div v-show="uploadError"
                                 class="m-alert m-alert--icon m-alert--icon-solid alert alert-danger alert-dismissible fade show">
                                <div class="m-alert__icon">
                                    <i class="fa fa-plus"></i>
                                </div>
                                <div class="m-alert__text text-left">
                                    <strong>ERROR</strong> No se pudieron subir los archivos.
                                </div>
                                <div class="m-alert__actions">
                                    <button id="back-upload"
                                            type="button"
                                            class="btn btn-brand btn-sm m-btn m-btn--pill m-btn--wide"
                                            @click="restartUpload">
                                        Volver a intentarlo
                                    </button>
                                </div>
                            </div>
                            <div v-for="file in files"
                                 class="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-dismissible fade show"
                                 :class="{ 'alert-metal': uploading && !uploadError, 'alert-brand': uploaded, 'alert-danger': uploadError }">
                                <div class="m-alert__icon">
                                    <div v-show="uploading && !uploadError" class="m-loader m-loader--info"></div>
                                    <i v-show="uploaded" class="la la-check-circle"></i>
                                    <i v-show="uploadError" class="fa fa-plus-circle"></i>
                                    <span></span>
                                </div>
                                <div class="m-alert__text text-left">
                                    {{ file.name }}
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedFile && !uploaded">
                            <div class="box-upload__title">
                                <strong>Paso 2:</strong> Subir los archivos seleccionado(s)
                            </div>
                            <button class="btn btn-primary" @click="sendForm"> Subir</button>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <button class="btn btn-danger" @click="closeModal"> Cancelar</button>
            </template>
        </modal-component>
    </div>
</template>

<script>
  import ModalComponent from '@/js/components/widgets/ModalComponent'

  export default {
    name: 'button-upload',
    components: { ModalComponent },
    props: {
      name: { type: String, default: '' },
      uploadPath: { type: String, default: '' },
      multiple: { type: Boolean, default: true }
    },
    data () {
      return {
        uploading: false,
        uploaded: false,
        selectedFile: false,
        uploadError: false,
        files: [],
        formData: null,
        visibleModal: false,
      }
    },
    watch: {
      visibleModal: function () {
        this.$emit(this.visibleModal ? 'open' : 'close')
      }
    },
    methods: {

      /**
       * Callback de cerrar el modal.
       */
      closeModal () {
        this.visibleModal = false
        this.restartUpload()
      },

      /**
       * Callback de la introducción de archivos, crea la lista de archivos en vue
       */
      fileInput () {

        this.files = []

        if (this.$refs.files.files.length > 0) {

          Array.from(this.$refs.files.files).forEach(file => {
            console.log(file)
            this.addFile(file)
          })

        }
        this.selectedFile = true
      },

      /**
       * Añade un archivo a las listas y al FormData
       */
      addFile (file) {
        this.files.push(file)

        if (this.formData === null) {
          this.formData = new FormData()
        }
        this.formData.append('files[]', file, file.name)
      },

      /**
       * Envia los ficheros al servidor.
       */
      sendForm () {
        this.uploading = true
        this.uploaded = false

        axios.post(route(this.uploadPath), this.formData, { //'repository.uploadFiles'
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            this.uploaded = true
            this.uploading = false
            //this.$emit('reload', this.type)
          })
          .catch(response => {
            this.uploadError = true
          })
          .then(() => {
            this.formData = null
          })
      },

      /**
       * Callback de volver a intenar la subida
       */
      restartUpload () {
        this.uploaded = false
        this.uploadError = false
        this.uploading = false
        this.files = []
        document.getElementById('files').value = ''
      },
    }
  }
</script>

<style lang="scss" scoped>
    .alert.m-alert--icon.m-alert--outline.m-alert--icon-solid .m-alert__icon,
    .alert.m-alert--icon .m-alert__text {
        padding: 0;
    }

    .alert.m-alert--icon .m-alert__icon {
        width: 0;
    }

    .alert.m-alert--icon {
        margin-bottom: 5px;
    }

    .alert.m-alert--icon.m-alert--outline.m-alert--icon-solid .m-alert__text {
        padding-right: 15px;
    }

    .alert-danger:not(.m-alert--outline) {
        margin-bottom: 15px;
    }

    .box-blur-shadow {
        transition: all .5s ease;
        opacity: 0.2;
    }

    .upload-files {
        display: inline-block;
    }

    #files {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .box-body {
        overflow: hidden;
        overflow-y: auto;

        .box-upload {
            text-align: left;
            /*height: calc(100%);*/
            &::after {
                display: block;
                clear: both;
                content: "";
            }

            .box-upload-message {
                width: 100%;
                font-size: 20px;
            }
        }


        .box-upload__title {
            font-size: 1.2rem;
            margin-bottom: 15px;

            strong {
                color: #007DB8;
            }
        }

        .box-list-files {
            margin-bottom: 35px;

            .m-loader--info {
                width: 30px;
                display: inline-block;
            }
        }
    }
</style>
