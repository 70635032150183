<template>
    <div class="row">
        <div class="col-12">
            <h5 class="mt-1">
                <i :class="evaluator.icon"></i>
                {{ evaluator.name }}
            </h5>
        </div>
        <div class="col-12" v-if="this.multiple">
            <div class="row">
                <div class="col-12">
                    <multiselect id="evaluators"
                                 v-model="listMultiple"
                                 no-options-placeholder="S"
                                 placeholder="Selecciona una opción"
                                 label="name"
                                 track-by="name"
                                 :options="options"
                                 :searchable="true"
                                 :multiple="true"
                                 :internal-search="false"
                                 :loading="loading"
                                 :show-no-results="false"
                                 :limit="50"
                                 :show-labels="false"
                                 :disabled="nbReadOnly"
                                 @search-change="findOptions"
                                 @input="changeMultiple">
                    </multiselect>
                </div>
                <div class="col-12">
                    <button id="remove-all"
                            type="button"
                            class="btn btn-link btn-sm m-btn"
                            :disabled="nbReadOnly"
                            @click="removeAll()">
                        Quitar todos
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12" v-else>
            <div class="row">
                <div class="col-12">
                    <multiselect id="evaluators-list" v-if="evaluator.list"
                            v-model="listSelect"
                            class="form-control"
                            size="5"
                            label="name"
                            track-by="name"
                            :disabled="nbReadOnly"
                            :multiple="true"
                            :searchable="true"
                            :internal-search="false"
                            :loading="loading"
                            :show-no-results="false"
                            :limit="50"
                            :show-labels="false"
                            :options="evaluator.list"
                            @input="updateModel()">
                    </multiselect>
                </div>
                <div class="col-12">
                    <button id="delete-evaluators"
                            type="button"
                            class="btn btn-link btn-sm m-btn"
                            :disabled="nbReadOnly"
                            @click="removeAll()">
                        Quitar todos
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'evaluator-select',
    props: {
      routePrefix: { type: String, default: '' },
      group: { type: String, default: '' },
      evaluator: { type: Object, default: () => {} },
      value: { type: Array, default: () => [] },
    },
    data () {
      return {
        listMultiple: null,
        listSelect: [],
        loading: false,
        multiple: false,
        options: [],
      }
    },
    mounted () {
      if (!this.checkArray(this.evaluator.list)) {
        this.multiple = true
      }

      let list = []

      for (let option of this.value) {
        list.push({ ...option })
      }

      if (this.multiple) {
        this.listMultiple = list
        this.findOptions('')
      } else {
        this.listSelect = list
      }
    },
    methods: {
      async findOptions (query) {
        this.loading = true
        try {
          let response = await axios.post(route(this.routePrefix + 'list'), {
            group: this.group,
            query: query,
            evaluator: this.evaluator.class,
          })
          this.optionsResponse(response)
        } catch (error) {
          this.optionsResponse(error)
        }
      },
      optionsResponse (response) {
        if (response.data) {
          this.options = response.data.options
        }
        this.loading = false
      },
      changeMultiple (value) {
        let object = value
        this.listMultiple = object
        this.updateModel()
      },
      checkArray (value) {
        return Array.isArray(value)
      },
      removeAll () {
        this.listSelect = []
        this.listMultiple = null
        this.updateModel()
      },
      updateModel () {
        if (this.multiple && this.listMultiple) {
          this.$emit('input', this.listMultiple)
        } else {
          this.$emit('input', this.listSelect)
        }
      },
    },
  }
</script>
