import Multiselect from 'vue-multiselect'

export default {
  name: 'multiselect-es',
  extends: Multiselect,
  props: {
    /**
     * String to show when pointing to an option
     * @default 'Press enter to select'
     * @type {String}
     */
    selectLabel: {
      type: String,
      default: '«',
    },
    /**
     * String to show next to selected option
     * @default 'Selected'
     * @type {String}
     */
    selectedLabel: {
      type: String,
      default: 'Opción seleccionada',
    },
    /**
     * String to show when pointing to an alredy selected option
     * @default 'Press enter to remove'
     * @type {String}
     */
    deselectLabel: {
      type: String,
      default: '×',
    },
    /**
     * Equivalent to the placeholder attribute on a <select> input.
     * @default 'Select option'
     * @type {String}
     */
    placeholder: {
      type: String,
      default: '',
    },
  },
}
