<template>
  <div class="form-group m-form__group col-12 mt-4">
    <input type="hidden" name="questionsData" :value="jsonQuestions" />
    <div>
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-between">
          <button
            v-if="!lang"
            class="btn btn-danger"
            :disabled="actionDisabled"
            @click.prevent="remove"
          >
            <i class="fa fa-trash"></i> Eliminar
          </button>
          <div class="dropdown ms-2" v-if="!lang">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              :disabled="actionDisabled"
            >
              Publicar
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" @click="publishQuestions">Publicar</a>
              </li>
              <li>
                <a class="dropdown-item" @click="unpublishQuestions"
                  >Despublicar</a
                >
              </li>
            </ul>
          </div>
        </div>
          <div class="float-end">
          <button v-if="testId"
            class="btn btn-primary"
            @click.prevent="importQuestion"
          >
            <i class="fa fa-plus"></i>
            Importar preguntas
          </button>
          <button
            v-if="!lang"
            class="btn btn-primary"
            @click.prevent="addQuestion"
          >
            <i class="fa fa-plus"></i>
            Nueva pregunta
          </button>
        </div>
      </div>
    </div>
    <bootstrap-table
      ref="table"
      class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 table-disable-hover"
      :columns="columns"
      :data="questions"
      :options="options"
      @on-check="onSelect"
      @on-uncheck="onSelect"
      @on-check-all="onSelect"
      @on-uncheck-all="onSelect"
    ></bootstrap-table>
    <EditQuestionModal
      v-if="showModal"
      :lang="lang"
      :showModal="showModal"
      :question="currentQuestion"
      :questionPosition="questionPosition"
      :questionTypes="questionTypes"
      :competenceDomains="competenceDomains"
      :levels="levels"
      :questions="questions"
      @closeShowModal="closeShowModal"
      @editQuestion="editQuestion"
      @newQuestion="newQuestion"
    />
    <ImportQuestionModal
        v-if="showModalImport"
        :showModalImport="showModalImport"
        :test-id="testId"

        @closeImportModal="closeImportModal"
    />
  </div>
</template>

<script>
import "bootstrap-table/dist/bootstrap-table.min.js";
// import "bootstrap-table/dist/bootstrap-table.min.css"; se importa directamente al css compilado

import BootstrapTable from "bootstrap-table/dist/bootstrap-table-vue.esm.js";
import EditQuestionModal from "./EditQuestionModal.vue";
import ImportQuestionModal from "./ImportQuestionModal.vue";

export default {
  name: "TableComponent",
  components: {
    "bootstrap-table": BootstrapTable,
    EditQuestionModal, ImportQuestionModal
  },
  props: {
    questionsArray: Array,
    questionTypes: Array,
    competenceDomains: Array,
    levels: Array,
    lang: String,
    testId: Number,
  },
  data() {
    return {
      showModal: false,
      showModalImport: false,
      currentQuestion: null,
      questionPosition: null,
      questions: [],
      selections: [],
      actionDisabled: true,
      columns: [
        {
          field: "state",
          checkbox: true,
          'class':"check-column",
        },
        {
          title: "Id",
          sortable: true,
          field: "id",
        },
        {
          title: "Pregunta",
          field: "name",
          'class': "rowCustom",
          formatter: (value, row) => {
            if (row.name !== null)
            return `
                <div class="editorCustom">${row.name}</div>`;
          },
        },
        {
          title: "Pregunta Idioma por defecto",
          field: "default_name",
          sortable: true,
          visible: this.lang,
          'class': "rowCustom",
          formatter: (value, row) => {
            return `
                <div class="editorCustom">${row.default_name}</div>`;
          },
        },
        {
          title: "Dominio",
          sortable: true,
          field: "competence_domain_name",
        },
        {
          title: "Tipo",
          sortable: true,
          field: "question_type_name",
        },
        {
          title: "Nivel",
          sortable: true,
          field: "level_name",
        },
        {
          title: "Fecha de creación",
          field: "date_added",
          sortable: true,
        },
        {
          title: "Activo",
          sortable: true,
          clickToSelect: false,
          visible: !this.lang,
          formatter: (cell, row) => {
            return `
              <div class="custom-control custom-switch">
                  <label class="form-check form-switch form-check-custom form-check-solid">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="inputActive"
                      name="inputActive"
                      ${row.is_active ? "checked" : ""}
                    />
                  </label>
              </div>
            `;
          },
          events: {
            "change #inputActive": (e, value, row, index) => {
              this.changeActive(index);
            },
          },
        },
        {
          align: "center",
          clickToSelect: false,
          formatter: () => {
            return `
              <button id="edit" type="button" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                <i class="fa fa-edit"></i>
              </button>
              <button id="remove" type="button" class="btn btn-icon btn-light-danger btn-sm me-1" ${
                this.lang !== null ? "disabled" : ""
              }>
                <i class="fa fa-trash"></i>
              </button>
              `;
          },
          events: {
            "click #edit": (e, value, row, index) => {
              this.editQuestionModalHandle(row);
            },
            "click #remove": (e, value, row, index) => {
              this.removeQuestion(index);
            },
          },
        },
      ],
      options: {
        search: true,
        clickToSelect: true,
        showColumns: true,
        pagination: true,
        sidePagination: "client",
        pageList: [10, 25, 50, 100],
        pageSize: 5,
      },
    };
  },
  created() {
    this.questions = this.questionsArray;
  },
  computed: {
    jsonQuestions() {
      return JSON.stringify(this.questions);
    },
  },
  methods: {
    closeShowModal() {
      this.showModal = false;
      this.currentQuestion = null;
      this.questionPosition = null;
    },
    closeImportModal() {
      this.currentQuestion = null;
      this.showModalImport = false;
    },
    addQuestion() {
      this.showModal = true;
    },
    importQuestion() {
      this.showModalImport = true;
    },
    editQuestionModalHandle(row) {
      this.currentQuestion = row;
      this.questionPosition = row.position;
      this.showModal = true;
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
    editQuestion(question, index) {
      this.showModal = false;
      let tempQuestions = [
        ...this.questions.map(q => (q.position === index ? question : q)),
      ];
      this.questions = tempQuestions;
      this.currentQuestion = null;
      this.questionPosition = null;
    },
    newQuestion(question) {
      question.is_active = true;
      question.position = 0
      if (this.questions.length) {
        question.position =
            this.questions[this.questions.length - 1].position + 1;
      }
      this.questions.push(question);
      this.showModal = false;
    },
    changeActive(index) {
      let tempQuestions = [
        ...this.questions.map((q, i) =>
          i === index ? { ...q, is_active: !q.is_active } : q
        ),
      ];
      this.questions = tempQuestions;
    },
    onSelect() {
      this.selections = this.$refs.table
        .getSelections()
        .map((row) => row.position);
      this.actionDisabled = !this.selections.length;
    },
    remove() {
      let tempQuestions = this.questions.filter(
        (q) => !this.selections.includes(q.position)
      );
      this.questions = tempQuestions;
      this.selections = [];
    },
    publishQuestions() {
      let tempQuestions = [
        ...this.questions.map((q) =>
          this.selections.includes(q.position) ? { ...q, is_active: true } : q
        ),
      ];

      this.questions = tempQuestions;
      this.selections = [];
    },
    unpublishQuestions() {
      let tempQuestions = [
        ...this.questions.map((q) =>
          this.selections.includes(q.position) ? { ...q, is_active: false } : q
        ),
      ];

      this.questions = tempQuestions;
      this.selections = [];
    },
  },
};
</script>

<style lang="scss">
.rowCustom {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
}
.editorCustom {
    height: 20px !important;
}
</style>
