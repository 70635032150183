var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        { staticClass: "form-group w-100 mb-1" },
        [
          _c("label", { attrs: { for: "elementContent" } }, [
            _vm._v("Contenido")
          ]),
          _vm._v(" "),
          _c("ValueSuggester", { on: { layoutChange: _vm.layoutChange } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }