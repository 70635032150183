<template>
  <div class="form-group m-form__group col-md-12 mb-4">
    <div class="row">
      <div class="col-md-6">
        <input
          type="hidden"
          name="professionalProfileData"
          :value="jsonProfessionalProfileData"
        />
        <label class="form-label">Perfiles profesionales</label>
        <multiselect
          v-model="professionalProfilesSelect"
          :options="professionalProfilesData"
          label="name"
          track-by="name"
          :multiple="true"
        >
        </multiselect>
        <nb-error-block path="professionalProfiles"></nb-error-block>
      </div>

      <div class="col-md-6">
        <label class="form-label">Especialidades</label>
        <multiselect
          v-model="specialtiesSelect"
          :options="specialtiesData"
          label="name"
          track-by="name"
          :multiple="true"
        >
        </multiselect>
        <nb-error-block path="specialties"></nb-error-block>

        <input type="hidden" name="specialtyData" :value="jsonSpecialtyData" />
      </div>
    </div>
  </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";

export default {
  name: "ProfessionalSpecialtiesSelector",
  components: { NbErrorBlock },
  props: {
    professionalProfiles: Array,
    professionalProfilesOld: Array,
    specialtiesOld: Array,
  },
  data() {
    return {
      professionalProfilesSelect: [],
      specialtiesSelect: [],
      professionalProfilesData: [],
      specialtiesData: [],
    };
  },
  created() {
    this.professionalProfilesData = this.professionalProfiles;
    this.specialtiesSelect = this.specialtiesOld;
    this.professionalProfilesSelect = this.professionalProfilesOld;
  },
  watch: {
    professionalProfilesSelect(value) {
      let specialtiesArray = [];
      value.map((item) => {
        item.specialties.map((specialty) => {
          specialtiesArray.push(specialty);
        });
      });

      this.specialtiesData = specialtiesArray;

      let specialtiesTemp = this.specialtiesSelect.filter((specialty) =>
        this.specialtiesData.find(
          (specialtyData) => specialtyData.id === specialty.id
        )
      );

      this.specialtiesSelect = specialtiesTemp;
    },
  },
  computed: {
    jsonProfessionalProfileData() {
      return JSON.stringify(this.professionalProfilesSelect);
    },
    jsonSpecialtyData() {
      return JSON.stringify(this.specialtiesSelect);
    },
  },
};
</script>
