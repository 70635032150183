import { render, staticRenderFns } from "./MessageComponent.vue?vue&type=template&id=6db8476e&"
import script from "./MessageComponent.vue?vue&type=script&lang=js&"
export * from "./MessageComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6db8476e')) {
      api.createRecord('6db8476e', component.options)
    } else {
      api.reload('6db8476e', component.options)
    }
    module.hot.accept("./MessageComponent.vue?vue&type=template&id=6db8476e&", function () {
      api.rerender('6db8476e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/campaign/MessageComponent.vue"
export default component.exports