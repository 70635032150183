var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: "click-prevent-layer " + _vm.savingLayerClass }),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.role
          ? _c("p", { staticClass: "permissions-manager__intro" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("trans")("permission_component.grantsForRole")
                  ) +
                  " "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.role.visible_name))])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.user
          ? _c("div", [
              _c("small", { staticClass: "text-muted pull-right" }, [
                _c("i", { staticClass: "fa fa-fw fa-info-circle" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm._f("trans")(
                        "permission_component.showInheritedGroupGrants"
                      )
                    ) +
                    "\n                    "
                ),
                _c("strong", [
                  _vm._v(_vm._s(_vm.userRoleInfo.role.visible_name))
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm._f("trans")("permission_component.grantsForUser")
                  ) + " "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.user.name))])
              ])
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12 permissions-manager__access" }, [
        _c("strong", { staticClass: "permissions-manager__title" }, [
          _vm._v(_vm._s(_vm._f("trans")("permission_component.specialGrants")))
        ]),
        _vm._v(" "),
        _c("span", [
          _c(
            "div",
            {
              staticClass: "m-input-icon m-input-icon--right position-relative"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.specialSearch,
                    expression: "specialSearch"
                  }
                ],
                staticClass: "pe-10 form-control form-control-solid",
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  name: "special_search",
                  placeholder: "Buscar",
                  id: "special-search"
                },
                domProps: { value: _vm.specialSearch },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.specialSearch = $event.target.value
                    },
                    function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.performSpecialSearch($event)
                    }
                  ]
                }
              }),
              _vm._v(" "),
              _vm._m(0)
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "permission-siblings" }, [
          _c(
            "table",
            { staticClass: "table table-hover" },
            _vm._l(_vm.specialSearchResults, function(
              specialPermission,
              index
            ) {
              return _c("special-permission-item", {
                key: "special-" + specialPermission.id,
                attrs: {
                  "user-role-special-permission": _vm.userRoleInfo
                    ? _vm.userRoleInfo.specialPermissions[index]
                    : null,
                  "special-permission": specialPermission,
                  index: "special_0_" + index,
                  depth: 0,
                  nbReadOnly: _vm.nbReadOnly
                },
                on: {
                  specialPermissionUpdated: function($event) {
                    return _vm.specialPermissionUpdated(specialPermission.id)
                  }
                }
              })
            }),
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "position-absolute translate-middle-y top-50 end-0 me-3" },
      [
        _c("span", { staticClass: "svg-icon" }, [
          _c("i", { staticClass: "fa fa-fw fa-search fa-lg" })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }