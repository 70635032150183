<template>
    <div class="row">
        <div class="col-12">
            <h5 class="">
                <i :class="evaluator.icon"></i>
                {{ evaluator.name }}
            </h5>
        </div>
        <div class="col-12">
            <span class="mr-3" v-for="customer in status">
            {{ customer.name }}
            </span>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'evaluator-id',
    props: {
      evaluator: { type: Object, default: () => {} },
      value: { type: Object, default: () => ({ status: true }) },
    },
    data () {
      return {
        status: []
      }
    },
    created () {
      this.status = this.value.status
    },
    methods: {
      updateModel () {
        this.$emit('input', { status: this.status })
      }
    }
  }
</script>

<style scoped>

</style>
