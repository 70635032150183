<template>
  <div class="form-group m-form__group col-12 mt-4">
    <input type="hidden" name="competenceDomainsData" :value="jsonCompetenceDomains" />
    <table class="table table-row-bordered">
      <thead>
        <tr>
          <th class="col-3">Dominio Competencial *</th>
          <th v-for="questionType in questionTypes" class="col-1" v-bind:key="questionType.id">
            {{ questionType.name }}
          </th>
          <th class="col-3">Cursos</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="data.length > 0">
        <tr v-for="(competenceDomain, i) of data" class="tag-row" v-bind:key="i">
          <td class="align-middle">
            <div class="input-group input-group-solid">
              <input type="text" v-model="competenceDomain.name" class="form-control"
                :id="'competenceDomain-' + i + '-' + competenceDomain.name" />
              <nb-error-block :path="`competenceDomainsData.${i}.name`" />
            </div>
            <nb-error-block :path="`competenceDomainsData.${i}.questionTypes`" />
            <span v-if="lang">
              {{ competenceDomain.default_name }}
            </span>
          </td>
          <td v-for="questionType in questionTypes" class="text-center text-nowrap" v-bind:key="questionType.id">
            <label class="form-check form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" :id="'competenceDomain-' + i + '-' + questionType.id"
                @input="
                  updateCompetenceDomains(questionType, i)
                  " :disabled="lang !== null ? true : false"
                :checked="findCompetenceDomain(competenceDomain, questionType.id)" />
              <input :id="'competenceDomain-' + i + '-' + questionType.id + '-weight'"
                v-if="getTestType.id == TestType.SuperEspecialista" class="form-control w-50 ms-1 p-1" type="number"
                :value="getWeight(competenceDomain, questionType.id)"
                :disabled="!findCompetenceDomain(competenceDomain, questionType.id)"
                @input="updateCompetenceDomainsWeight(questionType.id, i, $event.target.value)" />
            </label>
          </td>
          <td class="align-middle">
            <div class="input-group input-group-solid">
              <div class="form-control p-0">
                <multiselect v-model="competenceDomain.courses" :options="courses" :multiple="true"
                  :close-on-select="false" :clear-on-select="false" label="name" track-by="name"
                  :disabled="lang !== null ? true : false">
                </multiselect>
              </div>
            </div>
          </td>
          <td>
            <button v-if="!lang" type="button" class="btn btn-danger" @click.stop="removeCompetenceDomain(data, i)"
              :id="'remove-competence-domain-' + i + '-' + competenceDomain.id">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <button v-if="!lang" type="button" class="btn btn-secondary" @click="addCompetenceDomain">
      <i class="fa fa-plus-circle"></i>
    </button>
  </div>
</template>

<script>
import NbErrorBlock from '@/js/components/error_block/nb-error-block'
import { mapGetters, mapState } from 'vuex'
import { TestType } from '@/js/enums/TestType'

export default {
  name: "DynamicTable",
  components: { NbErrorBlock },
  data: () => {
    return {
      data: [],
      TestType,
    };
  },
  props: {
    questionTypes: Array,
    competenceDomains: Array,
    courses: Array,
    lang: String,
  },
  created() {
    this.data = this.competenceDomains;
  },
  methods: {
    updateCompetenceDomains(questionType, index) {
      this.data.map((cd, i) => {
        if (i === index) {
          let newType = cd.questionTypes.find((q) => q.id === questionType.id);

          if (newType) {
            cd.questionTypes = cd.questionTypes.filter(
              (q) => q.id !== questionType.id
            );
          } else {
            questionType.weight = 0;
            cd.questionTypes.push(questionType);
          }
        }
      });
    },
    addCompetenceDomain() {
      this.data.push({
        courses: [],
        name: "",
        questionTypes: [],
      });
    },
    removeCompetenceDomain(competenceDomains, index) {
      competenceDomains.splice(index, 1);
    },
    findCompetenceDomain(competenceDomain, id) {
      return competenceDomain.questionTypes.length > 0 &&
        competenceDomain.questionTypes.find(
          (q) => q.id === id
        )
    },
    getWeight(competenceDomain, id) {
      let item = this.findCompetenceDomain(competenceDomain, id)
      return item ? item.weight : 0
    },
    updateCompetenceDomainsWeight(questionTypeId, index, weight) {
      let dataArray = this.data.map((cd, i) => {
        if (i === index) {
          let type = cd.questionTypes.find((q) => q.id === questionTypeId);
          if (type) {
            cd.questionTypes = cd.questionTypes.map((q) =>
              q.id === questionTypeId ? { ...q, weight: weight } : q
            );
          }
        }
        return cd
      });

      this.data = dataArray
    },
  },
  computed: {
    jsonCompetenceDomains() {
      return JSON.stringify(this.data);
    },
    ...mapGetters('Test', {
      getTestType: 'getTestType',
    }),
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  padding: 20px 0 0 0;
}

.nav-tabs {
  margin-bottom: 0;
}
</style>
