<template>
  <div class="alert alert-danger alert-dismissible fade show"
       style="padding-right: 30px !important;"
       role="alert" v-if="visible && footerWarning">
    <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close"
            style="margin-right: -10px;"
            @click.prevent.stop="visible = false">
    </button>
    <strong style="margin-right: 20px;">{{ footerWarning }}</strong>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FooterWarning',
  data () {
    return {
      visible: false
    }
  },
  watch: {
    footerWarning (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.visible = true
      }
    }
  },
  computed: {
    ...mapGetters({
      getFooterWarning: 'getFooterWarning',
    }),
    footerWarning: {
      get: function () {
        return this.getFooterWarning
      },
    }
  },
}
</script>

<style scoped>
  .alert {
    background-color: #a5004f;
  }
</style>
