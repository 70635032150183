var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-12 mt-4" }, [
    _c("input", {
      ref: "jsonMetas",
      attrs: { type: "hidden", name: "metas" },
      domProps: { value: _vm.jsonMetas }
    }),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content", attrs: { id: "tabsContent" } }, [
      _c(
        "div",
        {
          staticClass: "tab-pane fade show active",
          attrs: { id: "main", role: "tabpanel", "aria-labelledby": "main-tab" }
        },
        [
          _c("metas-table", {
            attrs: { nbReadOnly: _vm.nbReadOnly, metas: _vm.data.main }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tab-pane fade",
          attrs: {
            id: "social",
            role: "tabpanel",
            "aria-labelledby": "social-tab"
          }
        },
        _vm._l(Object.keys(_vm.data.social), function(socialNetwork) {
          return _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c("h4", { staticClass: "mb-3" }, [
                _c("em", { staticClass: "fa fa-share-alt mr-1" }),
                _vm._v(" " + _vm._s(socialNetwork))
              ]),
              _vm._v(" "),
              _c("metas-table", {
                attrs: {
                  nbReadOnly: _vm.nbReadOnly,
                  metas: _vm.data.social[socialNetwork]
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tab-pane fade",
          attrs: {
            id: "schema",
            role: "tabpanel",
            "aria-labelledby": "schema-tab"
          }
        },
        [
          _c("schema-table", {
            attrs: {
              nbReadOnly: _vm.nbReadOnly,
              schema: _vm.data.schema,
              "type-schema": _vm.data.typeSchema
            },
            on: {
              "update:schema": function($event) {
                return _vm.$set(_vm.data, "schema", $event)
              },
              "update:typeSchema": function($event) {
                return _vm.$set(_vm.data, "typeSchema", $event)
              },
              "update:type-schema": function($event) {
                return _vm.$set(_vm.data, "typeSchema", $event)
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticClass: "nav nav-tabs",
        attrs: { id: "metaTabs", role: "tablist" }
      },
      [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link active",
              attrs: {
                id: "main-tab",
                "data-bs-toggle": "tab",
                href: "#main",
                role: "tab",
                "aria-controls": "main",
                "aria-selected": "true"
              }
            },
            [
              _c("em", { staticClass: "fa fa-globe mr-1" }),
              _vm._v("\n                General\n            ")
            ]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                id: "social-tab",
                "data-bs-toggle": "tab",
                href: "#social",
                role: "tab",
                "aria-controls": "social",
                "aria-selected": "false"
              }
            },
            [
              _c("em", { staticClass: "fa fa-hashtag mr-1" }),
              _vm._v("\n                Social\n            ")
            ]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                id: "schema-tab",
                "data-bs-toggle": "tab",
                href: "#schema",
                role: "tab",
                "aria-controls": "schema",
                "aria-selected": "false"
              }
            },
            [
              _c("em", { staticClass: "fa fa-th-list mr-1" }),
              _vm._v("\n                Esquemas\n            ")
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }