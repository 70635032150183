var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classField },
    [
      _c("input", {
        attrs: { type: "hidden", name: _vm.field, id: _vm.field },
        domProps: { value: _vm.getValue }
      }),
      _vm._v(" "),
      _vm.name ? _c("label", [_vm._v(_vm._s(_vm.name))]) : _vm._e(),
      _vm._v(" "),
      _c("multiselect", {
        attrs: {
          options: _vm.options,
          searchable: true,
          "internal-search": false,
          placeholder: _vm.getPlaceHolder,
          loading: _vm.loading,
          "show-no-results": false,
          limit: 50,
          disabled: _vm.nbReadOnly,
          multiple: true,
          "show-labels": false,
          label: "name",
          "track-by": "name",
          id: "managers-" + _vm.field
        },
        on: { open: _vm.open, "search-change": _vm.find },
        model: {
          value: _vm.managers,
          callback: function($$v) {
            _vm.managers = $$v
          },
          expression: "managers"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }