<template>
    <table class="table table-row-bordered">
        <thead>
        <tr>
            <th class="col-3">Propiedad</th>
            <th>Valor</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(tag, i) of metas" class="tag-row" v-if="metas.length > 0">
            <td class="align-middle">
                <div class="input-group input-group-solid">
                    <div class="input-group-prepend">
                        <!--El ancho min. para otros usos habria que ajustarlo-->
                        <span class="btn btn-secondary" style="min-width: 80px;"
                              title="Tipo de propiedad"
                              @click.stop="toggleType(tag)">
                        {{ tag.type }}
                    </span>
                    </div>
                    <input type="text" v-model="tag.meta" :disabled="nbReadOnly" title="Meta" class="form-control"
                           :id="'meta-' + i + '-' + tag.meta">
                </div>
            </td>
            <td class="align-middle">
                <div class="input-group input-group-solid">
                    <input v-if="!tag.choices" type="text" v-model="tag.value" :disabled="nbReadOnly" title="Valor"
                           class="form-control" :id="'tag-choice-' + i + '-' + tag.meta">
                    <div class="form-control p-0" v-else>
                        <multiselect  :options="tag.choices" v-model="tag.value"
                                     :id="'add-tag-' + i + '-' + tag.meta"
                                     :disabled="nbReadOnly"
                                     :taggable="true" @tag="addTag(tag, $event)"></multiselect>
                    </div>

                    <button type="button" class="btn btn-secondary" @click.stop="addMeta(metas, i)"
                            :id="'add-meta-' + i + '-' + tag.meta" v-if="!nbReadOnly">
                        <i class="fa fa-plus-circle"></i>
                    </button>
                    <button type="button" class="btn btn-danger" @click.stop="removeMeta(metas, i)"
                            :id="'remove-meta-' + i + '-' + tag.meta" v-if="!nbReadOnly">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </td>
        </tr>
        <tr v-if="!metas.length">
            <td colspan="2" class="text-center">
                <a class="btn btn-block btn-primary" href="#" @click.stop="addMeta(metas)" id="add-meta-prop">
                    <i class="fa fa-fw fa-plus-circle"></i> Añadir nueva propiedad
                </a>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'metas-table',
    methods: {
        addTag(tag, event) {
            tag.choices.push(event)
            tag.value = event
        },
        addMeta(metas, index = -1) {
            metas.splice(index + 1, 0, {'meta': '', 'length': null, 'type': 'meta', 'choices': null, 'value': null})
        },
        removeMeta(metas, index) {
            metas.splice(index, 1)
        },
        toggleType(tag) {
            if (this.nbReadOnly) {
                return
            }

            tag.type = this.availableTypes[this.availableTypes.indexOf(tag.type) + 1]
                ? this.availableTypes.slice(this.availableTypes.indexOf(tag.type) + 1)[0]
                : this.availableTypes.slice(0)[0]
        },
    },
    data: () => {
        return {
            availableTypes: ['og', 'tag', 'meta'],
        }
    },
    props: {
        metas: {type: Array, default: () => []},
    },
    created() {
    },
}
</script>

<style lang="scss" scoped>

.tag-row td + td {

    .btn {
        display: none;
    }

    &:hover .btn {
        display: block;
    }
}
</style>
