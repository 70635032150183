<template>
    <div class="ticket-select-elements">
        <div class="ticket-select-elements__title">
            <em class="fa fa-fw fa-object-group"></em>
            Elementos
        </div>
        <SortableList lockAxis="y" v-model="elements" @input="updateIndex" :distance="5">
            <SortableItem @itemActivated="setActiveItem" v-for="(item, index) in elements" :index="index" :key="index"
                          :item="item" :active-item="activeElement" v-if="elements.length > 0"></SortableItem>
            <li @click="activeBar()" v-if="elements.length <= 0" class="ticket-select-elements__add-message"
                id="add-item">
                <em class="fa fa-fw fa-plus-circle"></em>
                Añade un elemento a la plantilla
            </li>
        </SortableList>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters, mapMutations } from 'vuex'
  import SortableList from '@/js/components/ticket_editor/tabs/SortableList'
  import SortableItem from '@/js/components/ticket_editor/tabs/SortableItem'

  export default {
    name: 'ticket-select-elements',
    components: { SortableList, SortableItem },
    computed: {
      ...mapGetters('ticketEditor', {
        getElements: 'elements',
        activeElement: 'activeElement',
      }),
      elements: {
        set (elements) { this.setElements(elements) },
        get () { return this.getElements },
      },
    },
    methods: {
      ...mapMutations('ticketEditor', {
        setActiveBar: 'setActiveBar',
        setElements: 'setElements',
        setActiveElement: 'setActiveElement',
        setActiveTabProperties: 'setActiveTabProperties',
      }),
      updateIndex () {
        this.elements.forEach((value, i) => { this.elements[i].zIndex = this.elements.length - i })
        this.$eventBus.$emit('layoutChange')
      },
      setActiveItem (item) {
        if (this.activeElement === item) {
          item = null
        }
        this.setActiveElement(item)
        this.setActiveTabProperties('properties')
      },
      activeBar () {
        this.setActiveBar(true)
        let timeActiveBar = setTimeout(() => {
          this.setActiveBar(false)
          clearTimeout(timeActiveBar)
        }, 5000)
      },
    }
  }
</script>
