<template>
    <div>
        <div class="form-group">
            <label for="rotation">Rotación de imagen</label>
            <input type="number" id="rotation" :value="activeElement.style.rotation"
                   @input="updateStyle($event, 'rotation')"
                   class="form-control form-control-sm" placeholder="Rotación de la imagen">
        </div>

        <div class="form-group">
            <label for="width">Ancho de imagen</label>
            <input type="number" id="width" :value="activeElement.style.imageWidth"
                   @input="updateStyle($event, 'imageWidth')"
                   class="form-control form-control-sm" placeholder="Ancho de imagen">
        </div>

        <div class="form-group">
            <label for="height">Altura de imagen</label>
            <input type="number" id="height" :value="activeElement.style.imageHeight"
                   @input="updateStyle($event, 'imageHeight')"
                   class="form-control form-control-sm" placeholder="Altura de imagen">
        </div>

        <div class="image-styles-message">
            <i class="fa fa-fw fa-info-circle"></i>
            Sin valor para que la imagen se adapte al espacio definido en la pestaña propiedades.
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import StylesMixin from '@/js/components/ticket_editor/tabs/element-tabs/StylesMixin'

  export default {
    name: 'ImageFieldStyles',
    mixins: [StylesMixin],
    props: {},
  }
</script>

<style scoped lang="scss">
    .image-styles-message {
        background-color: #F1F1F1;
        padding: 0.8rem;
        border-radius: 3px;
    }

    label {
        font-size: 0.9rem !important;
        margin-bottom: 0.4rem !important;
    }
</style>
