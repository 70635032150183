var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column" }, [
      _c("div", { staticClass: "form-group w-100" }, [
        _c("label", { attrs: { for: "displayValue" } }, [
          _vm._v(" Nivel de corrección de errores\n                    "),
          _c("i", {
            directives: [{ name: "tooltip", rawName: "v-tooltip" }],
            staticClass: "fa fa-fw fa-info-circle",
            attrs: {
              title:
                "Mayor nivel admite mayor deterioro, pero requiere de más espacio"
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-select form-select-solid form-select-sm",
            attrs: { id: "displayValue" },
            domProps: { value: _vm.activeElement.qr.errorCorrectionLevel },
            on: {
              input: function($event) {
                return _vm.updateQRCode($event, "errorCorrectionLevel")
              }
            }
          },
          [
            _c("option", { attrs: { value: "L" } }, [
              _vm._v("L (Tolerancia ~7%)")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "M" } }, [
              _vm._v("M (Tolerancia ~15%)")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Q" } }, [
              _vm._v("Q (Tolerancia ~25%)")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "H" } }, [
              _vm._v("H (Tolerancia ~30%)")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group w-100 mb-1" }, [
        _c("label", { attrs: { for: "elementContent" } }, [
          _vm._v("Contenido almacenado en QR")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "position-relative" }, [
          _c("input", {
            staticClass: "inputField form-control form-control-sm",
            attrs: {
              id: "elementContent",
              type: "text",
              disabled: true,
              value: "${codigo_barras}"
            }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }