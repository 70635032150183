<template>
    <div class="form-group m-form__group col-md-8 mr-0">
        <input type="hidden" :name="fieldName" :id="fieldName" :value="returnData">

        <label>{{ title }}</label>
        <div class="d-flex flex-row">
            <div class="w-100" style="width: 40%">
                <div class="d-flex flex-column justify-content-between">
                    <select v-model="selectedGroup" class="form-control mb-1" title="" :disabled="nbReadOnly">
                        <option v-for="group in currentData" :value="group.id" :key="group.id">{{ group.name }}</option>
                    </select>
                    <select multiple v-model="selectedToAdd" title="" class="form-control" :disabled="nbReadOnly">
                        <option v-for="element in currentData[selectedGroup].elements" :value="element.id"
                                :key="element.id ">
                            {{ element.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="px-4 align-self-center">
                <div class="d-flex flex-column justify-content-between">
                    <button id="add-button" type="button" @click="add()"
                            class="btn btn-primary m-btn m-btn--sm btn-sm m-btn--icon m-btn--icon-right text-nowrap mb-1"
                            :disabled="nbReadOnly || addButtonDisabled">
                        Añadir <i class="fa fa-fw fa-caret-right"></i>
                    </button>
                    <button id="remove-button" type="button" @click="remove()"
                            class="btn btn-danger m-btn m-btn--sm btn-sm m-btn--icon text-nowrap"
                            :disabled="nbReadOnly || removeButtonDisabled">
                        <i class="fa fa-fw fa-caret-left"></i> Quitar
                    </button>
                </div>
            </div>
            <div class="w-100" style="width: 40%">
                <div class="h-100">
                    <select multiple v-model="selectedToRemove" title="" class="form-select h-100" :disabled="nbReadOnly">
                        <template v-for="group in currentChosen">
                            <option v-for="element in group.elements"
                                    :value="group.id.toString()+'-'+element.id.toString()"
                                    :key="group.id.toString()+'-'+element.id.toString()">
                                {{group.name}} - {{ element.name }}
                            </option>
                        </template>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'DualMultiselect',
    props: {
      title: { type: String, required: false },
      fieldName: { type: String, required: false },
      /**
       * Object of objects structure for initialChosen
       * {
       *   id:{name: Nombre}, id2: {name: Nombre}
       * }
       */
      initialChosen: { type: Object, default: () => {} },
      /**
       * Object of objects structure for initialData, with unique nested ids.
       * {
       *  group_id: {
       *   id1:{name: Nombre}, id2: {name: Nombre}
       *  }
       *  group_id: {
       *   id3:{name: Nombre}, id4: {name: Nombre}
       *  }
       * }
       */
      initialData: { type: Object, default: () => {} },
    },
    data () {
      return {
        selectedToAdd: [],
        selectedToRemove: [],
        selectedGroup: null,
        currentChosen: {},
        currentData: {},
      }
    },
    created () {
      // Initialize an filter data
      this.currentData = _.cloneDeep(this.initialData)
      this.currentChosen = _.cloneDeep(this.initialData)
      for (let key in this.currentChosen) {
        for (let skey in this.currentChosen[key].elements) {
          if (this.initialChosen[skey] === undefined) {
            this.$delete(this.currentChosen[key].elements, skey)
          } else {
            this.$delete(this.currentData[key].elements, skey)
          }
        }
      }
      // Default selected group
      this.selectedGroup = Object.keys(this.currentData)[0]
    },
    methods: {
      add () {
        this.selectedToAdd.forEach(id => {
          this.$set(this.currentChosen[this.selectedGroup].elements, id, this.currentData[this.selectedGroup].elements[id])
          this.$delete(this.currentData[this.selectedGroup].elements, id)
        })
      },
      remove () {
        this.selectedToRemove.forEach(id => {
          let keys = id.split('-')
          this.$set(this.currentData[keys[0]].elements, keys[1], this.currentChosen[keys[0]].elements[keys[1]])
          this.$delete(this.currentChosen[keys[0]].elements, keys[1])
        })
      },
      buttonDisabled (listToCheck) {
        return listToCheck === undefined || listToCheck.length === 0
      }
    },
    computed: {
      addButtonDisabled () {
        return this.buttonDisabled(this.selectedToAdd)
      },
      removeButtonDisabled () {
        return this.buttonDisabled(this.selectedToRemove)
      },
      returnData () {
        let output = []
        for (let key in this.currentChosen) {
          for (let skey in this.currentChosen[key].elements) {
            output.push(skey.toString())
          }
        }
        return output
      }
    },
  }
</script>
