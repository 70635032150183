<template>
    <div
            class="col-12  c-survey-component__items-container"
            :useDragHandle="true"
            :lockToContainerEdges="true">
        <ul class="c-survey-component__items ps-0">
            <slot/>
        </ul>
    </div>
</template>

<script>
  import { ContainerMixin } from 'vue-slicksort'

  export default {
    mixins: [ContainerMixin],
  }
</script>
