<template>
    <div class="report-preview" style="width: 100%; height: 100vh; border:none;" id="reportContainer">
        <div @click="toggleFullScreen" class="text-center">
            <a class="btn btn-primary m-btn--icon" href="javascript:" role="button"
               id="full-screen-report" aria-haspopup="true" aria-expanded="false">
                <i class="mr-2 fa fa-fw" :class="[isFullScreen ? 'fa-window-restore' : 'fa-window-maximize']"></i>
                Previsualizar informe a pantalla completa
            </a>
        </div>

        <div style="width: 100%; height: 100vh; border:none;">
            <iframe title="Visor de Informe" class="js-report-iframe"
                    style="width: 100%; height: 100vh; border:none;"
                    :class="[isFullScreen ? 'full-screen-report' : '']">
            </iframe>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isFullScreen: false
            }
        },
        methods: {
            toggleFullScreen() {
                this.isFullScreen = !document.body.classList.contains('report-full-screen')
                document.body.classList.toggle('report-full-screen')

                if (this.isFullScreen) {
                    window.scrollTo(0, 0);
                } else {
                    window.scrollTo(0, document.getElementById('reportContainer').getBoundingClientRect().top - 170)
                }
            }
        },
    }
</script>

<style lang="scss">
    body.report-full-screen {
    }

    $distanceTop: 18px;
    $yAxis: $distanceTop * 2;
    $distanceLeft: 18px;
    $scrollWidth: 5px;
    $xAxis: ($distanceLeft + $scrollWidth) * 2;

    body.report-full-screen .report-preview {
        border: solid 2px #1c3d5a !important;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        top: $distanceTop;
        left: $distanceLeft;
        height: calc(100vh - #{$yAxis}) !important;
        width: calc(100vw - #{$xAxis}) !important;
        background: white;
        z-index: 101;
        margin: 0;
        overflow-y: scroll;
    }
</style>