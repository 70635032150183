<template>
    <tr>
        <td class="text-nowrap"><span :class="this.labelClasses" v-html="this.formattedLabel"></span></td>
        <td class="col"><span :class="this.valueClasses" v-html="this.formattedValue"></span></td>
        <td class="tools">
            <div class="btn-group btn-group-sm">
                <button type="button" class="btn btn-link" id="clipboard" @click="copyToClipboard">
                    <i class="fa fa-fw fa-copy"></i>
                </button>
            </div>
        </td>
    </tr>
</template>

<script>
  import clipboard from 'clipboard-polyfill'

  export default {
    name: 'detail-row',
    computed: {
      formattedLabel () {
        return this.element.label
      },
      formattedValue () {
        const type = this.element.options.type

        switch (type) {
          case 'color-field':
            return `<span class="m-badge" style="background-color: ${this.element.value}"></span> ${this.element.value}`
          case 'email-field':
            return `<a href="mailto:${this.element.value}">${this.element.value}</a>`
          case 'url-field':
            return `<a href="${this.element.value}" target="_blank">${this.element.value}</a>`
          default:
            return this.element.value
        }
      },
      labelClasses () {
        const labelClass = this.element.options.label_class
        return labelClass && typeof labelClass === 'string' ? labelClass : ''
      },
      valueClasses () {
        const valueClass = this.element.options.class
        return valueClass && typeof valueClass === 'string' ? valueClass : ''
      },
    },
    methods: {
      copyToClipboard () {
        try {
          clipboard.writeText(this.element.value)
          toastr.options = {
            'closeButton': false,
            'debug': false,
            'newestOnTop': false,
            'progressBar': true,
            'positionClass': 'toast-bottom-right',
            'preventDuplicates': true,
            'onclick': null,
            'showDuration': '150',
            'hideDuration': '500',
            'timeOut': '1500',
            'extendedTimeOut': '500',
            'showEasing': 'swing',
            'hideEasing': 'linear',
            'showMethod': 'fadeIn',
            'hideMethod': 'fadeOut',
          }
          toastr.info('Usa Ctrl+V para pegarlo', 'Copiado a portapeles')
        } catch (exception) {
          console.log(exception)
        }
      },
    },
    props: {
      element: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
    .btn-link:hover {
        text-decoration: none;
    }

    td.tools {
        opacity: 0.3;

        &:hover {
            opacity: 1;
        }
    }

    tr:hover > td.tools {
        opacity: 0.7;
    }
</style>
