var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", { staticClass: "text-nowrap" }, [
      _c("span", {
        class: this.labelClasses,
        domProps: { innerHTML: _vm._s(this.formattedLabel) }
      })
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "col" }, [
      _c("span", {
        class: this.valueClasses,
        domProps: { innerHTML: _vm._s(this.formattedValue) }
      })
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "tools" }, [
      _c("div", { staticClass: "btn-group btn-group-sm" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-link",
            attrs: { type: "button", id: "clipboard" },
            on: { click: _vm.copyToClipboard }
          },
          [_c("i", { staticClass: "fa fa-fw fa-copy" })]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }