<template>
    <div>
        <div class="ticket-edit-bar__relations-text" v-if="event">
            <label class="m-checkbox mb-0">
                <input type="checkbox" v-model="activeScope" id="enable-select-ticket">
                Activar el ambito: <strong>{{ event.name }}</strong>
                <span></span>
            </label>
        </div>
        <div class="ticket-edit-bar__relations-list" v-else>
            <multiselect :options="scopes" group-values="options" group-label="type" :group-select="false" track-by="id"
                         label="name" v-model="selectedScope" class="mt-1 multiselect-sm m-1"
                         style="width: 300px; white-space: nowrap; z-index: 10;" id="scope-select-ticket"
                         @input="applyScope" placeholder="Selector de ámbito"></multiselect>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'ticket-relations',
    data () {
      return {
        scopes: [],
        activeScope: false,
      }
    },
    computed: {
      ...mapGetters('ticketEditor', {
        getSelectedScope: 'getSelectedScope',
        getScopeData: 'getScopeData',
        getScopeEvent: 'getScopeEvent',
        getScopeEvents: 'getScopeEvents',
      }),
      selectedScope: {
        set (scope) { this.setSelectedScope(scope) },
        get () { return this.getSelectedScope },
      },
      scopeData: {
        set (scopeData) { this.setScopeData(scopeData) },
        get () { return this.getScopeData },
      },
      event () {
        return this.getScopeEvent
      },
    },
    watch: {
      activeScope: function (newScope) {
        let dataScope = null

        if (newScope) {
          dataScope = {
            type: 'event',
            id: this.event.id
          }
        }

        this.selectedScope = dataScope
        this.applyScope()
      }
    },
    mounted () {
      this.$nextTick(() => {
        if (!this.getScopeEvent) {
          this.scopes.push({
            'type': 'Eventos',
            'options': [...this.getScopeEvents],
          })
        }
      })
    },
    methods: {
      ...mapMutations('ticketEditor', {
        setSelectedScope: 'setSelectedScope',
        setScopeData: 'setScopeData',
      }),
      ...mapActions('ticketEditor', {
        onEditorLoading: 'onEditorLoading',
        offEditorLoading: 'offEditorLoading',
      }),
      async applyScope () {
        this.scopeData = null

        if (this.selectedScope) {
          this.onEditorLoading()

          try {
            const scopeRequest = await axios.get(`/api/template-scope/${this.selectedScope.id}/${this.selectedScope.type}`)
            this.scopeData = scopeRequest.data
          } catch (exception) {
            this.scopeData = null
          }

          this.offEditorLoading()
        }

        this.$eventBus.$emit('layoutChange')
      },
    }
  }
</script>
