<template>
    <div style="display: inline-block;">
        <div @click="changeVisible" class="btn btn-secondary ">
            <span>
                <em class="fa fa-user fa-fw me-1"></em>
                <span>Combinar</span>
            </span>
        </div>

        <modal-component
                :refreshing="refreshing"
                :backdrop="true"
                :classesModalDialog="['modal-xl modal-dialog-scrollable']"
                :visible.sync="visible">

            <template #title>
                Combinar clientes
            </template>

            <template>
                <div class="m-form">
                    <div role="alert" class="m-alert m-alert--square alert alert-dismissible fade show"
                         v-if="message" :class="[successMessage ? 'alert-success' : 'alert-danger']">
                        <div class="m-alert__text">
                            {{ message }}
                        </div>
                        <div class="m-alert__close">
                            <button type="button" data-bs-dismiss="alert" aria-label="Close" class="close"></button>
                        </div>
                    </div>

                    <list-simple
                            @update:combinate="model => negateCombine(model)"
                            @page-change="setPage"
                            @set-filters="setFilters"
                            :headers="headers"
                            :list="customers"
                            :filters="filters"
                            :elements-per-page="perPage"
                            :ajax="true"
                            :ajax-pagination="ajaxPagination"
                            :interface-texts="{
                            'title': null,
                            'empty-list': 'No existen clientes',
                        }"
                    ></list-simple>

                    <button class="btn m-btn m-btn--icon  m-input btn-brand" type="button" @click="combine()">
                            <span><i class="fa fa-upload"></i>
                                <span>Combinar</span>
                            </span>
                    </button>
                </div>
            </template>
        </modal-component>

    </div>
</template>

<script>


  import ModalComponent from '@/js/components/widgets/ModalComponent'
  import ListSimple from '@/js/components/widgets/ListSimple'

  export default {
    name: 'CustomerCombine',
    components: { ModalComponent, ListSimple },
    props: {
      perPage: {type: Number, default: 5}
    },
    data () {
      return {
        visible: false,
        refreshing: false,
        message: null,
        page: 1,
        emittedFilters: [],
        successMessage: null,
        loadingCustomers: [],
        loadingCustomersData: [],
        headers: [
          ['Combinar', 'checkbox', 'combinate'],
          ['Nombre', 'string', 'name'],
          ['Apellidos', 'string', 'surname'],
          ['Email', 'string', 'email'],
          ['DNI', 'string', 'dni'],
          ['Teléfono', 'string', 'telephone'],
          ['Código postal', 'string', 'postcode'],
          ['Ciudad', 'string', 'city'],
          ['País', 'string', 'country'],
        ],
        filters: [
          ['search', 'name', 'Nombre'],
          ['search', 'surname', 'Apellidos'],
          ['search', 'email', 'Email'],
          ['search', 'dni', 'DNI'],
          ['search', 'telephone', 'Teléfono'],
          ['search', 'postcode', 'Código postal'],
          ['search', 'city', 'Ciudad'],
          ['search', 'country', 'País'],
        ],
      }
    },

    computed: {
      customers () {
        return this.loadingCustomersData.map(customer => {
          customer = Object.assign({}, customer)
          this.$set(customer, 'combinate', false)
          return customer
        })
      },
      ajaxPagination () {
        return {links: this.loadingCustomers.links, meta: this.loadingCustomers.meta}
      }
    },

    watch: {
      visible () {
        this.loadData()
      },
      async page (value) {
        await this.loadData(value)
      },
      async emittedFilters (value) {
        await this.loadData()
      }
    },

    methods: {
      changeVisible () {
        if (this.nbReadOnly) return
        this.visible = true
      },

      negateCombine (model) {
        model.combinate = !model.combinate
      },

      setMessage (msg, success) {
        this.$nextTick(() => {
          this.message = msg
          this.successMessage = success
        })
      },

      async combine () {
        const ids = this.customers.filter(customer => customer.combinate).map(customer => customer.id)
        if (!ids.length) {
          this.setMessage('Selecciona al menos dos clientes para combinarlos', false)
          return
        }

        this.refreshing = true
        try {
          let response = await axios.post(route('combine-customers'), { ids })
          this.setMessage('Clientes combinados', true)
          setTimeout(() => location.reload(), 1000)
        } catch (error) {
          this.setMessage('Error', false)
        }
        this.refreshing = false
      },

      async loadData (page = 1) {
        this.refreshing = true
        this.setMessage(null, null)
        try {
          let response = await axios.post(route('API.customers.clients'), {
            page: page,
            filters: this.emittedFilters
          })
          this.loadingCustomers = response.data
          this.loadingCustomersData = response.data.data
        } catch (error) {
          console.dir(error)
          this.setMessage('Ha ocurrido un error al cargar los clientes', false)
        }
        this.refreshing = false
      },

      setPage (page) {
        this.page = page
      },

      setFilters: _.debounce(async function (filters) {
        this.emittedFilters = filters
      }, 1000)
    },
  }
</script>
