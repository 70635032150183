<template>
  <div class="form-group m-form__group col-md-12 mb-2">
    <input type="hidden" name="countryData" :value="jsonDataCountry" />
    <div class="row">
      <div class="col-md-4">
        <label class="form-label">País *</label>
        <multiselect
          v-model="countrySelect"
          :options="countries"
          label="name"
          track-by="name"
          @input="countryChanged"
        >
        </multiselect>
        <nb-error-block path="country_id"></nb-error-block>
      </div>

      <div class="col-md-4">
        <label class="form-label">Provincia *</label>
        <multiselect
          v-if="countrySelect !== null && countrySelect.id === spainId"
          v-model="provinceSelect"
          :options="provinces"
          label="name"
          track-by="name"
          :disabled="countrySelect !== null ? false : true"
          @input="provinceChanged"
        >
        </multiselect>
        <input
          v-if="countrySelect === null || countrySelect.id !== spainId"
          type="text"
          v-model="provinceName"
          class="form-control"
        />
        <nb-error-block path="province_id"></nb-error-block>
      </div>

      <div class="col-md-4">
        <label class="form-label">Ciudad *</label>
        <multiselect
          v-if="countrySelect !== null && countrySelect.id === spainId"
          v-model="citySelect"
          :options="citiesData"
          label="name"
          track-by="name"
          :disabled="provinceSelect !== null ? false : true"
          @input="cityChanged"
        >
        </multiselect>

        <input
          v-if="countrySelect === null || countrySelect.id !== spainId"
          type="text"
          v-model="cityName"
          class="form-control"
        />
        <nb-error-block path="city_id"></nb-error-block>

      </div>

      <div class="col-md-4 mt-4">
        <label class="form-label">Centro de trabajo *</label>
        <multiselect
          v-if="countrySelect !== null && countrySelect.id === spainId"
          v-model="subsidiarySelect"
          :options="subsidiaryData"
          label="name"
          track-by="name"
          :disabled="citySelect !== null ? false : true"
        >
        </multiselect>

        <input
          v-if="countrySelect === null || countrySelect.id !== spainId"
          type="text"
          v-model="subsidiaryName"
          class="form-control"
        />
        <nb-error-block path="subsidiary_id"></nb-error-block>

      </div>
    </div>
  </div>
</template>

<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";

export default {
  name: "CountrySelectComponent",
  components: { NbErrorBlock },

  props: {
    countries: Array,
    provinces: Array,
    cities: Array,
    subsidiaries: Array,
    country: Number,
    province: Number,
    subsidiary: Number,
    city: Number,
    province_name: String,
    city_name: String,
    subsidiary_name: String,
  },
  data() {
    return {
      countrySelect:
        this.countries.find((country) => country.id === this.country) ||
        this.countries[0],
      provinceSelect:
        this.provinces.find((province) => province.id == this.province) ||
        null,
      citySelect: this.cities.find((city) => city.id == this.city) || null,
      subsidiarySelect:
        this.subsidiaries.find(
          (subsidiary) => subsidiary.id == this.subsidiary
        ) || null,
      provinceName: this.province_name,
      cityName: this.city_name,
      subsidiaryName: this.subsidiary_name,
      spainId: 73111,
    };
  },
  computed: {
    citiesData () {
        if(!this.provinceSelect) {
            return []
        }

        return this.cities.filter((city) => city.province_id == this.provinceSelect.id)
    },
    subsidiaryData () {
        if (!this.citySelect) {
            return []
        }
        return this.subsidiaries.filter((subsidiary) => subsidiary.city_id == this.citySelect.id)
    },
    jsonDataCountry() {
      let dataCountry = {
        country_id: this.countrySelect !== null ? this.countrySelect.id : null,
        province_id:
          this.provinceSelect !== null ? this.provinceSelect.id : null,
        city_id: this.citySelect !== null ? this.citySelect.id : null,
        subsidiary_id:
          this.subsidiarySelect !== null ? this.subsidiarySelect.id : null,
        province_name: this.provinceName,
        city_name: this.cityName,
        subsidiary_name: this.subsidiaryName,
      };
      return JSON.stringify(dataCountry);
    },
  },
  methods: {
    countryChanged () {
        if (this.countrySelect !== this.spainId) {
        this.provinceSelect = null;
        this.citySelect = null;
        this.subsidiarySelect = null;
      } else {
        this.provinceName = null;
        this.cityName = null;
        this.subsidiaryName = null;
      }
    },
    provinceChanged () {
        this.citySelect = null;
        this.subsidiarySelect = null;
    },
    cityChanged () {
        this.subsidiarySelect = null;
    }
  }

};
</script>
