var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "form-group mb-1" }, [
        _c("label", { attrs: { for: "elementsVisibility" } }, [
          _vm._v("Visibilidad del campo")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-radio-list", attrs: { id: "elementsVisibility" } },
          [
            _c("label", { staticClass: "m-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activeElement.visible,
                    expression: "activeElement.visible"
                  }
                ],
                attrs: {
                  type: "radio",
                  name: "element-visibility",
                  id: "element-visibility"
                },
                domProps: {
                  value: 1,
                  checked: _vm._q(_vm.activeElement.visible, 1)
                },
                on: {
                  click: function($event) {
                    return _vm.changeVisibility(true)
                  },
                  change: function($event) {
                    return _vm.$set(_vm.activeElement, "visible", 1)
                  }
                }
              }),
              _vm._v("\n                Visible\n                "),
              _c("span")
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "m-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activeElement.visible,
                    expression: "activeElement.visible"
                  }
                ],
                attrs: {
                  type: "radio",
                  name: "element-visibility",
                  id: "no-element-visibility"
                },
                domProps: {
                  value: 0,
                  checked: _vm._q(_vm.activeElement.visible, 0)
                },
                on: {
                  click: function($event) {
                    return _vm.changeVisibility(false)
                  },
                  change: function($event) {
                    return _vm.$set(_vm.activeElement, "visible", 0)
                  }
                }
              }),
              _vm._v("\n                Oculto\n                "),
              _c("span")
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "m-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activeElement.visible,
                    expression: "activeElement.visible"
                  }
                ],
                attrs: {
                  type: "radio",
                  name: "element-visibility",
                  id: "null-element-visibility"
                },
                domProps: {
                  value: null,
                  checked: _vm._q(_vm.activeElement.visible, null)
                },
                on: {
                  click: function($event) {
                    return _vm.changeVisibility(null)
                  },
                  change: function($event) {
                    return _vm.$set(_vm.activeElement, "visible", null)
                  }
                }
              }),
              _vm._v("\n                Condicional\n                "),
              _c("span")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.activeElement.visible === null
        ? _c("div", { staticClass: "form-group mt-3 mb-2" }, [
            _c("label", { attrs: { for: "showIfConditionsMet" } }, [
              _vm._v("Si la condición se cumple:")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "m-radio-inline",
                attrs: { id: "showIfConditionsMet" }
              },
              [
                _c("label", { staticClass: "m-radio" }, [
                  _c("input", {
                    attrs: {
                      type: "radio",
                      name: "showIfConditionsMet",
                      id: "conditions-met"
                    },
                    domProps: {
                      checked:
                        _vm.activeElement.visibility.showIfConditionsMet ===
                        true,
                      value: true
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateVisibility(true, "showIfConditionsMet")
                      }
                    }
                  }),
                  _vm._v(" Mostrar\n                "),
                  _c("span")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "m-radio" }, [
                  _c("input", {
                    attrs: {
                      type: "radio",
                      name: "showIfConditionsMet",
                      id: "no-conditions-met"
                    },
                    domProps: {
                      checked:
                        _vm.activeElement.visibility.showIfConditionsMet ===
                        false,
                      value: false
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateVisibility(
                          false,
                          "showIfConditionsMet"
                        )
                      }
                    }
                  }),
                  _vm._v(" Ocultar\n                "),
                  _c("span")
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeElement.visible === null
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "requireAllConditions" } }, [
              _vm._v("¿Cuantas condiciones deben cumplirse?")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "m-radio-inline",
                attrs: { id: "requireAllConditions" }
              },
              [
                _c("label", { staticClass: "m-radio" }, [
                  _c("input", {
                    attrs: {
                      type: "radio",
                      name: "requireAllConditions",
                      id: "all-conditions"
                    },
                    domProps: {
                      checked:
                        _vm.activeElement.visibility.requireAllConditions ===
                        true,
                      value: true
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateVisibility(
                          true,
                          "requireAllConditions"
                        )
                      }
                    }
                  }),
                  _vm._v(" Todas\n                "),
                  _c("span")
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "m-radio" }, [
                  _c("input", {
                    attrs: {
                      type: "radio",
                      name: "requireAllConditions",
                      id: "no-all-conditions"
                    },
                    domProps: {
                      checked:
                        _vm.activeElement.visibility.requireAllConditions ===
                        false,
                      value: false
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateVisibility(
                          false,
                          "requireAllConditions"
                        )
                      }
                    }
                  }),
                  _vm._v(" Solo una\n                "),
                  _c("span")
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeElement.visible === null ? _c("VisibilityConditions") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }