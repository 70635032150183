<template>
    <div class="form-group m-form__group col-12">
        <input type="hidden" name="url_banner" v-model="urlData">
        <div class="input-group mb-10">
            <div class="input-group-prepend">
                <span class="input-group-text">Enlace</span>
            </div>
            <input type="text" aria-label="First name" class="form-control">
            <input type="text" aria-label="Last name" class="form-control">
        </div>
        <table class="table border gx-4 table-row-bordered">
            <thead>
            <tr>
                <th width="250">{{ 'cms_selector_type.linkType' | trans }}</th>
                <th>
                    <span v-if="linkType.id === 1">{{ 'cms_selector_type.link' | trans }}</span>
                </th>
                <th class="text-center" v-if="linkType.id === 2" width="150">
                    {{ 'cms_selector_type.hideOnFinish' | trans }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <multiselect class="ms-3" v-model="linkType" :options="selectorData" track-by="id" label="name" :multiple="false"
                                 :disabled="nbReadOnly" id="basic-addon2" @input="setToNull">
                    </multiselect>
                </td>
                <td>
                    <template v-if="linkType.id === 1">
                        <div class="me-3">
                            <label for="type_url_data" class="sr-only"></label>
                            <input id="type_url_data" type="url" class="form-control-solid me-2 form-control m-input"
                                   placeholder="Url" aria-describedby="basic-addon2"
                                   :disabled="nbReadOnly" v-model="urlData">
                            <nb-error-block path="url_banner"></nb-error-block>
                        </div>
                    </template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
  // TODO: Si no va a haber más tipos que link, seguramente se puede quitar el componente y dejar un campo bádico

  import NbErrorBlock from '@/js/components/error_block/nb-error-block'

  export default {
    name: 'CmsSelectorType',
    components: {NbErrorBlock},
    props: {
      url_banner: {
        type: String,
        default: null
      },
    },
    data () {
      return {
        selectorData: [
          { id: 1, name: 'Url' },
        ],
        linkType: null,
        urlData: null,
      }
    },
    created () {
      this.setLinkType(1)
      this.urlData = this.url_banner
    },
    methods: {
      setToNull () {
        if (this.linkType === null) {
          this.setLinkType(1)
        }
      },
      setLinkType (id) {
        this.linkType = this.selectorData.find(data => data.id === id)
      },
    }
  }
</script>

<style scoped>

</style>
