<template>
    <!--Item padre (Lo dividimos así, que si no se quedaba todo lleno de v-ifs por todos sitios)-->
    <div v-if="item.children.length && (passesFilter || !filtering)" class="menu-item"
         :class="{'m-menu__item--submenu menu-accordion' : true,
        'm-menu__item--active' : item.isActive || !showArrow,
        'm-menu__item--expanded' : item.isActive || !showArrow,
        'm-menu__item--open' : item.isActive || !showArrow || childrenPassesFilter,
        'show' : item.isActive || !showArrow || childrenPassesFilter,
        'is-found': passesFilter}"
         :data-menu-section-id="item.attributes.id"
         data-menu-submenu-toggle="hover"
         aria-haspopup="true"

         data-kt-menu-sub="accordion"
         data-kt-menu-trigger="click"
    >
        <a class="menu-link m-menu__toggle" href="javascript:"
           :id="'menu-items_' + item.attributes.unique_id">
            <em v-if="item.attributes.icon" class="menu-icon m-menu__link-icon" :class="item.attributes.icon"></em>
            <span v-if="!item.attributes.icon" class="menu-bullet">
                <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">
                {{ item.title }}
            </span>
            <span v-if="showArrow" class="menu-arrow"></span>
        </a>

        <div class="m-menu__submenu menu-sub menu-sub-accordion menu-active-bg">
            <span class="m-menu__arrow"></span>
            <menu-item :item="child" :favorited="isFavorite(child)" :favorites="favorites"
                       v-for="child in item.children" :key="child.id" :show-arrow="showArrow"></menu-item>
        </div>

    </div>
    <!--Item padre hijo-->
    <div v-else-if="passesFilter || !filtering" class="menu-item position-relative"
         :class="{'m-menu__item--submenu menu-accordion' : false,
        'm-menu__item--active' : item.isActive,
        'm-menu__item--expanded' : item.isActive,
        'm-menu__item--open' : item.isActive || childrenPassesFilter,
        'show' : item.isActive || childrenPassesFilter,
        'is-found': passesFilter,
        'fst-italic' : item.attributes.has_tenant}"
         :data-menu-section-id="item.attributes.id"
         data-menu-submenu-toggle="hover"
         aria-haspopup="true"
    >
        <em class="m-menu__link-star position-absolute top-50 end-0 translate-middle-y px-7 py-4 fs-6 fa-star text-inverse-light text-hover-inverse-dark cursor-pointer"
            :class="{'fas rotate-left' : isFavorited,
            'far rotate-right' : !isFavorited}"
            @click.prevent="isFavorited ? unfavorite(item) : favorite(item)">
        </em>

        <a :href="getUrl(item)" id="item-children" :id="'menu-item_' + item.attributes.unique_id" class="menu-link">
            <em class="menu-icon m-menu__link-icon fa-fw" :class="item.attributes.icon"
                v-if="item.attributes.icon"></em>
            <span v-if="!item.attributes.icon" class="menu-bullet">
                <span class="bullet bullet-dot"></span>
            </span>

            <span class="menu-title">
                {{ item.title }}
            </span>
        </a>
    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'menu-item',
    props: {item: {}, favorited: {}, showArrow: {type: Boolean, default: true}},
    data() {
        return {
            isFavorited: '',
            processing: false,
        }
    },
    created() {
        this.isFavorited = this.favorited
    },
    computed: {
        ...mapGetters('Menu', {
            getFilteredItems: 'getFilteredItems',
            favorites: 'getFavorites',
            filter: 'getFilter'
        }),
        favoritesArray() {
            return Object.entries(this.favorites).map(favorite => favorite[1].attributes.slug)
        },
        filtering() {
            return this.filter !== null
        },
        passesFilter() {
            if (!this.filtering) return false

            return this.getFilteredItems.filter(item => item.attributes.id === this.item.attributes.id).length > 0
        },
        childrenPassesFilter() {
            return this.passesFilter && this.item.children.length > 0
        },
    },
    methods: {
        ...mapActions('Menu', {
            setMenu: 'setMenu',
            addFavoriteItem: 'addFavoriteItem',
            deleteFavoriteItem: 'deleteFavoriteItem',
        }),
        isFavorite(section) {
            return this.favoritesArray.includes(section.attributes.slug)
        },
        favorite(item) {
            if (this.processing) return

            this.processing = true
            this.addFavoriteItem(item)
            this.isFavorited = true
            this.processing = false
        },
        unfavorite(item) {
            if (this.processing) return

            this.processing = true
            this.deleteFavoriteItem(item)
            this.isFavorited = false
            this.processing = false
        },
        getUrl(item) {
            return item.url + (item.attributes.query_params !== null ? item.attributes.query_params : '')
        },
    },
}
</script>

<style>
</style>
