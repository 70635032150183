<template>
    <div>
        <div class="d-flex">
            <div class="form-group w-100 mb-1">
                <label for="elementContent">Contenido</label>

                <ValueSuggester v-on:layoutChange="layoutChange"/>
            </div>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import ValueSuggester from '@/js/components/ticket_editor/tabs/element-tabs/ValueSuggester'

  export default {
    name: 'TextFieldProperties',
    components: { ValueSuggester },

    methods: {
      layoutChange () { this.$eventBus.$emit('layoutChange') },
    },
  }
</script>

<style scoped>
    label {
        font-size: 0.9rem !important;
        margin-bottom: 0.4rem !important;
    }
</style>
