<template>
    <div class="row">
        <div class="col-12">
            <h5 class="mb-3">
                <i :class="evaluator.icon"></i>
                {{ evaluator.name }}
            </h5>
        </div>
        <div v-for="(evaluator, key) in evaluatorsSelectList" class="col-12">
            <div class="m-alert m-alert--outline alert alert-metal list-select-sub mb-4" role="alert">
                <component :is="evaluator.component"
                           v-if="evaluator.component"
                           :key="'cO' + key"
                           v-model="evaluatorsSelect[key].bindings"
                           :evaluator="evaluator"
                           :group="group"
                           :disabled="nbReadOnly"
                           :route-prefix="routePrefix"
                           @input="updateModel()"
                           @openModal="openModal">
                </component>
                <button :id="'evaluators-' + key"
                        type="button"
                        class="btn btn-danger btn-sm m-btn c-evaluator__subevaluators-delete"
                        :disabled="nbReadOnly"
                        @click="removeEvaluator(key)">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
        </div>
        <div v-show="evaluatorsSelectList.length <= 0" class="col-12">
            <div class="fs-5 fw-bolder alert text-center my-20" role="alert">
                Añade una o varias condiciones
            </div>
        </div>
        <div class="col-12">
            <button id="add-condition"
                    type="button"
                    class="btn btn-brand btn-sm"
                    :disabled="nbReadOnly"
                    @click="openModal()"
                    @openModal="openModal">
                <i class="fa fa-plus"></i>
                Añadir condición
            </button>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'subevaluators',
    props: {
      routePrefix: { type: String, default: '' },
      group: { type: String, default: '' },
      evaluator: { type: Object, default: () => { } },
      value: { type: Array, default: () => [] },
    },
    data () {
      return {
        id: null,
        created: false,
        evaluatorsSelect: {},
      }
    },
    mounted () {
      this.id = this._uid
    },
    created () {
      let evaluator
      let object = {}

      for (evaluator in this.value) {
        object = { ...this.value[evaluator] }
        this.selectEvaluator(Object.assign({}, object))
      }

      this.created = true
    },
    computed: {
      evaluatorsSelectList () {
        let list = []
        let evaluator
        let number = 0
        let object

        for (evaluator in this.evaluatorsSelect) {
          object = Object.assign({}, this.evaluatorsSelect[evaluator], { key: number })
          list.push(object)
          number++
        }

        return list
      },
    },
    methods: {
      openModal (element) {
        if (!element) {
          element = this
        }
        this.$emit('filter-group', { evaluator: this.evaluator.class, category: this.evaluator.category })
        this.$emit('openModal', element)
      },
      selectEvaluator (evaluator) {
        this.$eventBus.$emit('select-evaluator', this, evaluator)
        this.updateModel()
      },
      removeEvaluator (reference) {
        delete this.evaluatorsSelect[reference]

        this.updateEvaluators()
        this.updateModel()
      },
      updateEvaluators () {
        let evaluator
        let objects = {}
        let number = 0

        for (evaluator in this.evaluatorsSelect) {
          objects[number] = { ...this.evaluatorsSelect[evaluator] }
          number++
        }

        this.evaluatorsSelect = Object.assign({}, objects)
      },
      updateModel () {
        this.$emit('input', Object.values(this.evaluatorsSelect))
      },
    },
  }
</script>
