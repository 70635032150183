<template>
    <div class="upload-files">
        <div class="m-btn btn btn-secondary m-btn--icon"
             :class="{'box-blur-shadow' : activeSelect}"
             @click="visibleModal = true">
            <span>
                <em class="fa fa-cloud-upload-alt"></em>
                <span>{{ name }}</span>
            </span>
        </div>

        <modal-component id="uploadModal"
                         tabindex="-1"
                         :visible.sync="visibleModal"
                         :backdrop="true"
                         :keyboard="true">
            <template #title>
                Subir archivos
            </template>
            <template>
                <div class="box-body">
                    <div class="box-upload">
                        <div class="box-upload-message" v-show="!upload && !uploadFinish">
                            <div class="form-group mb-6" v-if="!type">
                                <label for="type_file" class="form-label">Tipo</label>
                                <select id="type_file" ref="type" class="form-control">
                                    <option v-for="type in getTypes" :value="type.id"
                                            :selected="typeDefault == type.id">{{ type.name }}
                                    </option>
                                </select>
                            </div>
                            <input type="file" id="files" multiple ref="files"
                                   @change="fileInput" :accept="allowedMimeTypes"/>
                            <div class="text-center">
                                <label for="files" class="btn btn-block btn-primary btn-lg">
                                    <em class="fa fa-plus-circle fa-fw me-1"></em>
                                    Seleccionar los archivos
                                </label>
                            </div>
                        </div>
                        <div class="box-list-files">
                            <div v-for="file in files"
                                 class="d-flex m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-dismissible fade show"
                                 v-bind:class="{ 'alert-metal': upload && !uploadError, 'alert-brand': uploadFinish, 'alert-danger': uploadError }">
                                <div class="m-alert__icon">
                                    <div class="m-loader m-loader--info" v-show="upload && !uploadError"></div>
                                    <i class="la la-check-circle fa-fw me-1 alert-danger" v-show="uploadFinish"></i>
                                    <i class="fa fa-times-circle fa-fw me-1 alert-danger" v-show="uploadError"></i>
                                    <span></span>
                                </div>
                                <div class="m-alert__text text-left">
                                    {{ file.name }}
                                </div>
                            </div>
                            <div v-show="uploadError">
                                <div class="d-flex m-alert m-alert--icon m-alert--icon-solid alert alert-danger alert-dismissible fade show">
                                    <div class="m-alert__icon">
                                        <i class="fa fa-times-circle fa-fw me-1 alert-danger"></i>
                                    </div>
                                    <div class="m-alert__text text-left">
                                        <strong>ERROR!</strong> No se pudieron subir los archivos.
                                    </div>
                                </div>
                                <div class="m-alert__actions text-center">
                                    <button type="button" id="back-upload"
                                            class="btn btn-block btn-primary btn-lg"
                                            @click="backUpload">
                                        <em class="fa fa-angle-left fa-fw me-1"></em>
                                        Volver a intentarlo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </modal-component>
    </div>
</template>

<script>
  import ModalComponent from '@/js/components/widgets/ModalComponent'

  export default {
    name: 'upload-files',
    components: { ModalComponent },
    props: {
      activeSelect: { type: Boolean, default: false },
      name: { type: String, default: '' },
      type: { type: Object, default: null },
      types: { type: Array, default: () => [] },
      typeDefault: { type: Number, default: 0 },
      groupId: { type: Number },
      groupType: { type: String },
      isPrivate: { type: Boolean, default: false },
      allowedMimeTypes: { type: String, default: '*/*' },
    },
    data () {
      return {
        upload: false,
        uploadFinish: false,
        uploadError: false,
        files: [],
        formData: null,
        visibleModal: false,
      }
    },
    computed: {
      getTypes () {
        let list = []
        let key
        let type

        for (key in this.types) {
          type = this.types[key]
          list.push({
            id: type.id,
            name: this.getNameType(type)
          })
        }

        return list
      },
    },
    watch: {
      visibleModal: function () {
        this.$emit(this.visibleModal ? 'open' : 'close')
      }
    },
    methods: {
      getNameType (type) {
        let name = type.name
        if (type.father_id) {
          let result = this.types.find(typeRef => typeRef.id === type.father_id)
          if (result) {
            name = this.getNameType(result) + ' > ' + name
          }
        }

        return name
      },
      fileInput () {
        this.sendFiles(this.$refs.files.files)
      },
      sendFiles (files) {
        this.files = []
        let type

        if (this.type) {
          type = this.type.id
        } else {
          type = this.$refs.type.value
        }

        if (files.length > 0) {
          let file

          for (file in files) {
            if (typeof files[file] === 'object') {
              this.addFile(files[file])
            }
          }

          this.formData.append('type', type)
          this.sendForm()
        }
      },
      addFile (file) {
        this.files.push(file)

        this.addForm(file)
      },
      addForm (file) {
        if (this.formData === null) {
          this.formData = new FormData()
          this.formData.append('is_private', this.isPrivate ? 1 : 0)
        }

        this.formData.append('files[]', file, file.name)
      },
      clearForm () {
        this.formData = null
        this.visibleModal = false
        this.uploadError = false
        this.files = []
        this.uploadFinish = false
        this.upload = false
      },
      sendForm () {
        this.upload = true
        this.uploadFinish = false

        this.formData.append('group_id', this.groupId)
        this.formData.append('group_type', this.groupType)

        axios.post(route('repository.uploadFiles', {}, false), this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
          .then(response => {
            if (this.type) {
              this.updateUpload()
            }
            setTimeout(() => this.clearForm(), 500)
          })
          .catch(response => this.errorUpload())
      },
      errorUpload () {
        this.uploadError = true
        this.formData = null
      },
      updateUpload () {
        this.uploadFinish = true
        this.upload = false
        this.$emit('reload', this.type)
      },
      backUpload () {
        this.uploadFinish = false
        this.uploadError = false
        this.upload = false
        this.files = []
        this.clearInputFile()
      },
      clearInputFile () {
        document.getElementById('files').value = ''
      }
    }
  }
</script>

<style lang="scss" scoped>
    .box-blur-shadow {
        transition: all .5s ease;
        opacity: 0.2;
    }

    .upload-files {
        display: inline-block;
    }

    #files {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .box-body {
        overflow: hidden;
        overflow-y: auto;

        .box-upload {
            text-align: left;
            /*height: calc(100%);*/
            &::after {
                display: block;
                clear: both;
                content: "";
            }

            .box-upload-message {
                width: 100%;
                font-size: 20px;
            }
        }

        .box-list-files {
            .m-loader--info {
                width: 30px;
                display: inline-block;
            }
        }
    }
</style>
