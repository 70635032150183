<template>
    <div>
        <div class="m-entity-list-search form-group m-form__group col-12">
            <div class="row mt-4">
                <div class="col-6">
                    <ranking-date-picker @updated-date="updatedDate"
                                         id="date" name="date"/>
                </div>
                <div class="col-6 row pr-0">
                    <div class="col-4">
                        <multiselect placeholder="Ordenar por" select-label="<" deselect-label="×"
                                     selected-label="×" v-model="orderType" track-by="id" label="name"
                                     :options="orders" id="order" name="order"/>
                    </div>

                    <div class="col-4">
                        <multiselect placeholder="Ascendente/Descendente" select-label="<" deselect-label="×"
                                     selected-label="×" v-model="asc" track-by="id" label="name"
                                     :options="orderAscDesc" id="asc-desc" name="asc-desc"/>
                    </div>

                    <div class="col-4 pr-0">
                        <input v-model="quantity" name="quantity"
                               type="number" id="quantity" class="form-control m-input" min="1"
                               placeholder="Cantidad de elementos a mostrar">
                    </div>

                    <div class="col-12 pr-0">
                        <multiselect class="mt-4" placeholder="Categorías" select-label="<" deselect-label="×"
                                     :multiple="true"
                                     selected-label="×" v-model="categoriesSelected" track-by="id" label="name"
                                     :options="categories" id="categories" name="categories"/>
                    </div>

                </div>

            </div>

            <div class="row py-2 mt-4">
                <div class="col-12 text-right">
                    <button type="button" class="btn btn-brand btn m-btn--icon"
                            @click="requestRanking" id="add-condition" :disabled="refreshing">
                        <span>
                            <i class="la la-refresh"></i>
                            <span>Ver Ranking</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>

        <div class="row m-form__section mt-0 body-padding pt-0">
            <div class="col-12">
                <div class="m-separator m-separator--dashed m-separator--lg my-3"></div>
                <div v-if="refreshing" class="alert m-alert--default text-center py-4">
                    Consultando datos.
                </div>
                <div v-else-if="isFirst" class="alert m-alert--default text-center py-4">
                    Introduzca un criterio de búsqueda.
                </div>
                <div v-else-if="customers.length" class="table-responsive">
                    <table class="table table-striped m-table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Apellidos</th>
                            <th>Email</th>
                            <th>Teléfono</th>
                            <th>Nº Operaciones</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="customer in customers" :key="'customer-' + customer.customer_id">
                            <td>{{ customer.customer_id }}</td>
                            <td>{{ customer.name }}</td>
                            <td>{{ customer.surname }}</td>
                            <td>{{ customer.email }}</td>
                            <td>{{ customer.telephone }}</td>
                            <td class="text-right">{{ customer.times }}</td>
                            <td class="text-right">{{ customer.total }} &euro;</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="fs-5 fw-bolder alert m-alert--default text-center my-20">No existen compras con esos criterios.</div>
            </div>
        </div>
    </div>
</template>

<script>

  const ASC = 1
  const DESC = 2

  export default {
    props: {
      categories: { type: Array, required: true },
      quantity: { type: Number, default: undefined },
      orders: { type: Array, default: () => [] },
    },
    data () {
      return {
        clients: [],
        categoriesSelected: [],
        orderType: null,
        customers: [],
        date: null,
        isFirst: true,
        refreshing: false,
        asc: null,
        orderAscDesc: [{ id: ASC, name: 'Ascendente' }, { id: DESC, name: 'Descendente' }],
      }
    },
    created () {
      this.requestRanking()
    },
    methods: {
      async requestRanking () {
        this.isFirst = false
        this.refreshing = true
        const categories = this.categoriesSelected.map(category => category.id)

        try {
          const data = await axios.post(route('Ranking'), {
            orderActivity: this.orderType ? this.orderType.id : this.orders[0].id,
            quantity: this.quantity || 10,
            date: this.date,
            categories: categories.length ? categories : null,
            asc: this.asc === null ? false : this.asc.id === ASC,
          })
          this.customers = data.data.data
        } finally {
          this.refreshing = false
        }
      },
      updatedDate (date) {
        this.date = date
      },
    },
  }
</script>
