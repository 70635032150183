<template>
    <div class="ticket-properties">
        <div class="ticket-properties__tabs-container">
            <ul class="ticket-properties__tabs">
                <li class="ticket-properties__tab" @click="activeTab = 'properties'"
                    :class="{'active': activeTab === 'properties'}" v-tooltip
                    title="Propiedades" id="tab-properties">
                    <em class="fa fa-fw fa-cogs"></em>
                    <span class="ticket-properties__tab-text">Propiedades</span>
                </li>
                <li class="ticket-properties__tab" @click="activeTab = 'styles'"
                    :class="{'active': activeTab === 'styles'}" v-tooltip
                    title="Apariencia" id="tab-styles">
                    <em class="fa fa-fw fa-desktop"></em>
                    <span class="ticket-properties__tab-text">Apariencia</span>
                </li>
                <li class="ticket-properties__tab" @click="activeTab = 'visibility'"
                    :class="{'active': activeTab === 'visibility'}" v-tooltip
                    title="Visibilidad" id="tab-visibility">
                    <em class="fa fa-fw fa-eye"></em>
                    <span class="ticket-properties__tab-text">Visibilidad</span>
                </li>
            </ul>
        </div>
        <div class="ticket-properties__properties-options">
            <template v-if="activeElement && activeElement.blockPower <= powerUserLoged">
                <div v-show="activeTab === 'properties'">
                    <CommonProperties :powerUserLoged="powerUserLoged"></CommonProperties>
                    <TextFieldProperties v-if="activeElement.type === 'text'"></TextFieldProperties>
                    <ImageFieldProperties v-if="activeElement.type === 'image'"></ImageFieldProperties>
                    <BarcodeFieldProperties v-if="activeElement.type === 'barcode'"></BarcodeFieldProperties>
                    <QRCodeFieldProperties v-if="activeElement.type === 'qr'"></QRCodeFieldProperties>
                </div>
                <div v-show="activeTab === 'styles'">
                    <TextFieldStyles v-if="activeElement.type === 'text'"></TextFieldStyles>
                    <ImageFieldStyles v-if="activeElement.type === 'image'"></ImageFieldStyles>
                    <BarcodeFieldStyles v-if="activeElement.type === 'barcode'"></BarcodeFieldStyles>
                    <QRCodeFieldStyles v-if="activeElement.type === 'qr'"></QRCodeFieldStyles>
                </div>
                <VisibilityTab v-show="activeTab === 'visibility'"></VisibilityTab>
            </template>
            <div class="ticket-properties__properties-message" v-else>
                Selecciona un elemento
            </div>
        </div>
    </div>
</template>

<script>
  // TODO: EDITOR VISUAL Generalizar
  import { mapGetters, mapMutations } from 'vuex'
  import TextFieldProperties from '@/js/components/ticket_editor/tabs/element-tabs/TextFieldProperties'
  import ImageFieldProperties from '@/js/components/ticket_editor/tabs/element-tabs/ImageFieldProperties'
  import TextFieldStyles from '@/js/components/ticket_editor/tabs/element-tabs/TextFieldStyles'
  import ImageFieldStyles from '@/js/components/ticket_editor/tabs/element-tabs/ImageFieldStyles'
  import BarcodeFieldStyles from '@/js/components/ticket_editor/tabs/element-tabs/BarcodeFieldStyles'
  import BarcodeFieldProperties from '@/js/components/ticket_editor/tabs/element-tabs/BarcodeFieldProperties'
  import QRCodeFieldProperties from '@/js/components/ticket_editor/tabs/element-tabs/QRCodeFieldProperties'
  import QRCodeFieldStyles from '@/js/components/ticket_editor/tabs/element-tabs/QRCodeFieldStyles'
  import VisibilityTab from '@/js/components/ticket_editor/tabs/element-tabs/VisibilityTab'
  import CommonProperties from '@/js/components/ticket_editor/tabs/element-tabs/CommonProperties'

  export default {
    name: 'ticket-properties',
    components: {
      TextFieldProperties,
      TextFieldStyles,
      ImageFieldProperties,
      ImageFieldStyles,
      BarcodeFieldProperties,
      BarcodeFieldStyles,
      QRCodeFieldProperties,
      QRCodeFieldStyles,
      VisibilityTab,
      CommonProperties
    },
    props: ['powerUserLoged'],
    computed: {
      ...mapGetters('ticketEditor', {
        activeElement: 'activeElement',
        activeTabProperties: 'activeTabProperties',
      }),
      activeTab: {
        set (tab) { this.setActiveTabProperties(tab) },
        get () { return this.activeTabProperties },
      },
    },
    methods: {
      ...mapMutations('ticketEditor', {
        setActiveTabProperties: 'setActiveTabProperties',
      }),
    },

  }
</script>
