import Semaphore from 'semaphore'

export const state = {
  showable: {
    'image/jpeg': 'jpg',
    'image/gif': 'gif',
    'image/png': 'png',
  },
  mimeTypes: {
    images: 'image',
  },
  fileTypes: null,
}

export const getters = {
  getShowable: state => state.showable,
  getMimeTypeImages: state => state.mimeTypes.images,
}

export const mutations = {
  setFileTypes: (state, types) => {
    state.fileTypes = types
  },
}

const semaphore = new Semaphore(1)
const lock = async () =>
  new Promise((resolve) => {
    semaphore.take(() => {
      resolve(() => {
        semaphore.leave();
      });
    });
  });

export const actions = {
  async getFileTypes ({ commit, state }) {
    const unlock = await lock()

    if (!state.fileTypes) {
      const fileTypesResponse = await axios.get(route('API.repository.types', {}, false))
      const fileTypes = fileTypesResponse.data

      commit('setFileTypes', fileTypes)
    }

    unlock()

    return state.fileTypes
  },
}

export const Repository = { state, mutations, actions, getters }

export default Repository
