var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return 1 === 0
    ? _c(
        "li",
        { staticClass: "m-nav__item m-topbar__actions m-topbar__actions--img" },
        [
          _c(
            "a",
            {
              staticClass: "m-nav__link",
              attrs: { href: "javascript:", id: "update" },
              on: { click: _vm.updateActions }
            },
            [_vm._m(0)]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "m-quick-panel",
              class: { "m-quick-panel--on": _vm.active }
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "m-quick-panel__close btn btn-sm btn-outline-dark btn-icon",
                  attrs: { href: "#", id: "m_quick_panel_close_btn" },
                  on: {
                    click: function($event) {
                      _vm.active = false
                    }
                  }
                },
                [_c("i", { staticClass: "la la-close" })]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "m-quick-panel__content" }, [
                _c("h4", { staticClass: "m-quick-panel__content--title" }, [
                  _vm._v("Histórico de sucesos")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "kt-notification kt-notification--no-link" },
                  _vm._l(_vm.actions, function(action) {
                    return _c(
                      "a",
                      {
                        staticClass: "kt-notification__item",
                        attrs: { href: "#", id: "actions" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "kt-notification__item-icon" },
                          [
                            _c("i", {
                              staticClass: "kt-font-success",
                              class: action["icon"]
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "kt-notification__item-details" },
                          [
                            _c(
                              "div",
                              { staticClass: "kt-notification__item-title" },
                              [
                                _c("span", [_vm._v(_vm._s(action["type"]))]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(action["entity"]))])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "kt-notification__item-time" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(action["timestamp"]) +
                                    "\n                        "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "m-nav__link-icon" }, [
      _c("em", { staticClass: "flaticon-squares" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }