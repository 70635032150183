<template>
    <div class="col-12 m--padding-top-15 m--padding-right-25 m--padding-left-25 ml-2 mr-2">
        <div class="row">
            <div class="col-12">
                <h4><em class="flaticon-graphic-2 mr-1"></em>{{ title }}</h4>
            </div>
        </div>

        <div class="m-separator--lg my-3"></div>

        <div class="row m-row--no-padding m-row--col-separator-xl">

            <template v-for="stat in stats">
                <div class="col">
                    <div class="m-widget24">
                        <div class="m-widget24__item">
                            <h4 class="m-widget24__title">
                                {{stat[0]}}
                            </h4><br>
                            <span class="m-widget24__stats m--font-brand" :style="styleData(stat)">
                                {{stat[2]}}
                            </span>
                            <span class="m-widget24__desc">
                                {{stat[1]}}
                            </span>
                            <div class="m--space-10"></div>
                            <div class="progress m-progress--sm">
                                <div class="progress-bar m--bg-brand"
                                     role="progressbar"
                                     aria-valuenow="50"
                                     aria-valuemin="0"
                                     aria-valuemax="100"
                                     :style="styleProgressBar(stat)">
                                </div>
                            </div>
                            <span class="m-widget24__change">
                                {{stat[3]}}
                            </span>
                            <span class="m-widget24__number">
                                {{stat[4]}}%
                            </span>
                        </div>
                    </div>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
  export default {
    name: 'campaign-statistics',
    props: {
      title: { type: String, default: 'Estadísticas' },
      stats: { type: Array, default: () => [] },
    },
    methods: {
      styleProgressBar (stat) {
        return { width: stat[4] + '%', background: stat[5] + ' !important' }
      },
      styleData (stat) {
        return { color: stat[5] + ' !important' }
      },
    }
  }
</script>

<style scoped>
    .m-widget24 .m-widget24__item .m-widget24__stats {
        float: right;
        margin-right: 1.8rem;
        margin-top: -1.43rem;
        font-size: 1.75rem;
        font-weight: 600;
    }

    .m-widget24 .m-widget24__item .m-widget24__title {
        color: #575962;
    }

    .m-widget24 .m-widget24__item .m-widget24__title {
        margin-left: 1.8rem;
        margin-top: 3.21rem;
        display: inline-block;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .m-widget24 .m-widget24__item .m-widget24__desc {
        color: #9699a2;
    }

    .m-widget24 .m-widget24__item .m-widget24__desc {
        margin-left: 1.8rem;
        font-size: 1rem;
        font-weight: 300;
    }

    .m-widget24 .m-widget24__item .m-widget24__stats {
        float: right;
        margin-right: 1.8rem;
        margin-top: -1.43rem;
        font-size: 1.75rem;
        font-weight: 600;
    }

    .m--space-10 {
        display: block;
        height: 0;
        margin-bottom: 10px;
    }

    .m-widget24 .m-widget24__item .progress {
        margin-top: 2.14rem;
        margin-left: 1.8rem;
        margin-right: 1.8rem;
        margin-bottom: 0.5rem;
        height: 0.5rem;
    }

    .m-widget24 .m-widget24__item .m-widget24__change {
        color: #9699a2;
    }

    .m-widget24 .m-widget24__item .m-widget24__change {
        margin-left: 1.8rem;
        display: inline-block;
        margin-bottom: 2.86rem;
        font-size: 1rem;
        font-weight: 300;
    }

    .m-widget24 .m-widget24__item .m-widget24__number {
        color: #9699a2;
    }

    .m-widget24 .m-widget24__item .m-widget24__number {
        float: right;
        margin-right: 1.8rem;
        display: inline-block;
        margin-bottom: 2.86rem;
        font-size: 1rem;
        font-weight: 300;
    }
</style>>
