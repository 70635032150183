<template>
    <div class="persistent" v-click-outside="hideResults">
        <!-- lupa -->
        <div class="d-flex align-items-center" data-kt-search-element="toggle" id="kt_header_search_toggle">
            <div class="btn btn-icon btn-active-light-primary" @click="showResults">
                <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                          transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                    <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"></path>
                    </svg>
                </span>
            </div>
        </div>


        <div class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg w-md-375px" style="z-index: 101; display: block; outline: none;"
             tabindex="0" @focus.stop="showResults" v-if="visibleDropdown">
            <div  class="p-5 quick-search quick-search-dropdown" id="kt_quick_search_dropdown">

                <form class="w-100 position-relative mb-3" v-on:submit.stop.prevent="performSearch">
                        <span
                            class="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                 height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path
                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                        fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                    <path
                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                        fill="#000000" fill-rule="nonzero"/>
                                </g>
                            </svg>
                        </span>
                        <input autocomplete="off" type="text" name="q"
                               class="form-control form-control-flush ps-10" placeholder="Buscar"
                               v-model="searchValue" @input.prevent.stop="performSearch"
                               @focus.stop="showResults" id="search-query"
                        >
                </form>

                <!--begin::Separator-->
                <div class="separator border-gray-200 mb-6"></div>
                <!--end::Separator-->
                <!--begin::Empty-->
                <div class="text-center"
                     v-if="!results.length && searchValue">
                     <!-- TODO: Dejar aquí algún ejemplo de uso genérico -->
                    <!--begin::Icon-->
                    <div class="pt-10 pb-10">
                        <!--begin::Svg Icon | path: icons/duotone/Interface/File-Search.svg-->
                        <span class="svg-icon svg-icon-4x opacity-50">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.25"
                                      d="M3 4C3 2.34315 4.34315 1 6 1H15.7574C16.553 1 17.3161 1.31607 17.8787 1.87868L20.1213 4.12132C20.6839 4.68393 21 5.44699 21 6.24264V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V4Z"
                                      fill="#12131A"/>
                                <path
                                    d="M15 1.89181C15 1.39927 15.3993 1 15.8918 1V1C16.6014 1 17.2819 1.28187 17.7836 1.78361L20.2164 4.21639C20.7181 4.71813 21 5.39863 21 6.10819V6.10819C21 6.60073 20.6007 7 20.1082 7H16C15.4477 7 15 6.55228 15 6V1.89181Z"
                                    fill="#12131A"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M13.032 15.4462C12.4365 15.7981 11.7418 16 11 16C8.79086 16 7 14.2091 7 12C7 9.79086 8.79086 8 11 8C13.2091 8 15 9.79086 15 12C15 12.7418 14.7981 13.4365 14.4462 14.032L16.7072 16.293C17.0977 16.6835 17.0977 17.3167 16.7072 17.7072C16.3167 18.0977 15.6835 18.0977 15.293 17.7072L13.032 15.4462ZM13 12C13 13.1046 12.1046 14 11 14C9.89543 14 9 13.1046 9 12C9 10.8954 9.89543 10 11 10C12.1046 10 13 10.8954 13 12Z"
                                      fill="#12131A"/>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Icon-->
                    <!--begin::Message TODO: LANG Poner con traducción-->
                    <div class="pb-15 fw-bold">
                        <h3 class="text-gray-600 fs-5 mb-2">Sin resultados</h3>
                        <div class="text-muted fs-7">Please try again with a different query</div>
                    </div>
                    <!--end::Message-->
                </div>
                <!--end::Empty-->


                <div v-if="!searchValue">
                    <div class="d-flex flex-stack fw-bold mb-4">
                        <!--  TODO: LANG Poner con traducción -->
                        <span class="text-muted fs-6 me-2">Realizar búsqueda</span>
                    </div>
                    <div v-for="purchase of lastPurchases"
                         class="m-list-search__result-item-text is-owner">
                        <a :href="purchase.link" class="m-list-search__result-item"
                           :id="'purchase-' + purchase.id">
                                <span class="m-list-search__result-item-icon">
                                    <i class="flaticon-profile-1"></i>
                                </span>
                            <span class="m-list-search__result-item-text">
                                {{ purchase.display }}
                            </span>
                        </a>
                    </div>
                </div>
                <template v-else>
                    <template v-if="purchases.length">
                        <span class="m-list-search__result-category">
                            Expedientes
                        </span>
                        <div v-for="result of purchases" class="m-list-search__result-item-text"
                             :class="{'is-owner': result.ownership}">
                            <a :href="result.link" class="m-list-search__result-item"
                               :id="'purchases-' + result.id">
                                <span class="m-list-search__result-item-icon">
                                    <i class="flaticon-profile-1"></i>
                                </span>
                                <span class="m-list-search__result-item-text">
                                    {{ result.display }}
                                </span>
                            </a>
                        </div>
                    </template>

                    <template v-if="orders.length">
                        <span class="m-list-search__result-category">
                            Pedidos
                        </span>
                        <div v-for="result of orders" class="m-list-search__result-item-text"
                             :class="{'is-owner': result.ownership}">
                            <a :href="result.link" class="m-list-search__result-item"
                               :id="'orders-' + result.id">
                                <span class="m-list-search__result-item-icon">
                                    <i class="flaticon-business"></i>
                                </span>
                                <span class="m-list-search__result-item-text">
                                    {{ result.display }}
                                </span>
                            </a>
                        </div>
                    </template>
                </template>

            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'quick-search',
    data() {
        return {
            searchValue: '',
            visibleDropdown: false,
            onFocus: false,
            results: [],
            lastPurchases: [],
        }
    },
    async created() {
    },
    computed: {
        purchases() {
            if (!this.results) return []
            return this.results.filter(r => r.type === 'purchase')
        },

        orders() {
            if (!this.results) return []
            return this.results.filter(r => r.type === 'order')
        },
    },
    directives: {
        'click-outside': {
            bind: function (el, binding, vNode) {
                // Provided expression must evaluate to a function.
                if (typeof binding.value !== 'function') {
                    const compName = vNode.context.name
                    let warn = `Expression '${binding.expression}' is not a function, but has to be.`
                    if (compName) {
                        warn += `Found in component '${compName}'`
                    }

                    console.warn(warn)
                }
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler, {passive: true})
            },

            unbind: function (el, binding) {
                // Remove Event Listeners
                document.removeEventListener('click', el.__vueClickOutside__)
                el.__vueClickOutside__ = null
            },
        },
    },
    methods: {
        performSearch: _.debounce(async function () {
            if (!this.searchValue) return
            try {
                const searchRequest = await axios.get(`/api/quick-search/${this.searchValue}`)
                this.results = Object.values(searchRequest.data)
            } catch (e) {
                this.results = []
            }
        }, 1500),

        async showResults() {
            this.onFocus = true
            this.visibleDropdown = true

            $('#app').one('click', this.hideResults)
        },

        hideResults(clearSearch = false) {
            this.onFocus = false
            this.visibleDropdown = false
            if (clearSearch === true) {
                this.searchValue = ''
            }
        },
    },
}
</script>

<style scoped>
.triangle {
    background: #FFF;
    height: 20px;
    width: 20px;
    position: absolute;
    left: calc(50% - 10px);
    transform: rotate(45deg);
    top: 0;
    z-index: 50;
}

.closeSearchBox {
    position: absolute;
    top: 50%;
    right: 10px;
    cursor: pointer;
    margin-top: -7px;
}

.closeSearchBox em {
    display: block;
}
</style>
