<template>
    <div class="form-group m-form__group col-md-2 px-1 mt-auto">
      <a class="btn btn-secondary dropdown-toggle col-12" href="#" role="button"
         id="exportDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Exportar
      </a>

      <div class="dropdown-menu" aria-labelledby="exportDropdown">
        <a class="dropdown-item" href="#" @click="exportReport('pdf', $event)">PDF</a>
        <a class="dropdown-item" href="#" @click="exportReport('csv', $event)">CSV</a>
        <a class="dropdown-item" href="#" @click="exportReport('xlsx', $event)">XLSX</a>
      </div>
    </div>
</template>

<script>
import * as queryString from 'query-string'

export default {
  methods: {
    exportReport (extension, ev) {
      let blockLoading = '#app form'
      let loading = function () {
        mApp.block(blockLoading, {})
      }

      let unLoading = function () {
        mApp.unblock(blockLoading)
      }

      ev.preventDefault()
      ev.stopPropagation()

      let reportId = $('.js-report-report').val()
      let organization = $('.js-report-organization')
      let organizationId = organization.val()
      let errorOrganization = organization.data('error')
      let errorClass = '.form-control-feedback'
      const reportConfiguration = queryString.parse($('#tab_generateTab :input').serialize())
      let father = organization.parent()

      if (organizationId.length <= 0) {

        father.addClass(hasDangerClass)

        if (!father.find(errorClass).length) {
          father.append('<span class="form-control-feedback">' + errorOrganization + '</span>')
        }

        return
      }

      loading()


      const type = window.location.href.match(/my-reports/) ? 'my-report' : 'report'

      const routeData = route(type + '.export', {
        report: reportId,
        organization: organizationId,
      }, false)
      axios.post(
        routeData,
        $('#tab_generateTab :input').serialize(),
        { responseType: 'blob', params: {extension} }
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        const filename = response.headers['content-disposition'].match(/filename=("?)((?:\\"|.)+)\1/)[2]
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        setTimeout(() => document.body.removeChild(link), 500)
      }).catch(() => {
        Swal.fire({
          type: 'error',
          title: 'El informe no contiene datos'
        })
      }).finally(unLoading)
    }
  },
}
</script>
