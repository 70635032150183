<template>
    <div class="form-group m-form__group col-12 mt-4">
        <input type="hidden" name="metas" ref="jsonMetas" :value="jsonMetas">
        <ul class="nav nav-tabs" id="metaTabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="main-tab" data-bs-toggle="tab" href="#main" role="tab" aria-controls="main"
                   aria-selected="true">
                    <em class="fa fa-globe mr-1"></em>
                    General
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="social-tab" data-bs-toggle="tab" href="#social" role="tab" aria-controls="social"
                   aria-selected="false">
                    <em class="fa fa-hashtag mr-1"></em>
                    Social
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="schema-tab" data-bs-toggle="tab" href="#schema" role="tab" aria-controls="schema"
                   aria-selected="false">
                    <em class="fa fa-th-list mr-1"></em>
                    Esquemas
                </a>
            </li>
        </ul>
        <div class="tab-content" id="tabsContent">
            <div class="tab-pane fade show active" id="main" role="tabpanel" aria-labelledby="main-tab">
                <metas-table :nbReadOnly="nbReadOnly" :metas="data.main"></metas-table>
            </div>
            <div class="tab-pane fade" id="social" role="tabpanel" aria-labelledby="social-tab">
                <div class="mb-4" v-for="socialNetwork of Object.keys(data.social)">
                    <h4 class="mb-3"><em class="fa fa-share-alt mr-1"></em> {{ socialNetwork }}</h4>
                    <metas-table :nbReadOnly="nbReadOnly" :metas="data.social[socialNetwork]"></metas-table>
                </div>
            </div>
            <div class="tab-pane fade" id="schema" role="tabpanel" aria-labelledby="schema-tab">
                <schema-table :nbReadOnly="nbReadOnly" :schema.sync="data.schema" :type-schema.sync="data.typeSchema"></schema-table>
            </div>
        </div>
    </div>
</template>

<script>
  import MetasTable from './MetasTable'
  import SchemaTable from './SchemaTable'
  import template from '@/js/components/metas/templates/standard'

  export default {
    name: 'metas-generator',
    components: {
      MetasTable,
      SchemaTable,
    },
    methods: {},
    data: () => {
      return {
        'template': template,
        'data': null,
      }
    },
    props: {
      savedMetas: { type: Object, default: () => ({}) },
      schema: { type: String, default: () => null },
      schemaValue: { type: Array, default: () => [] },
    },
    computed: {
      jsonMetas () {
        $(this.$refs.jsonMetas).trigger('change')
        return JSON.stringify(this.data)
      },
    },
    created () {
      this.data = {
        typeSchema: this.schema,
        schema: this.schemaValue,
        ...this.template,
        ...this.savedMetas,
      }
    },
  }
</script>

<style lang="scss" scoped>
    .tab-content {
        padding: 20px 0 0 0;
    }

    .nav-tabs {
        margin-bottom: 0;
    }
</style>
