<template>
    <div class="form-group mb-6 col-md-3">
        <input type="hidden" name="tests_type_id" :value="typeSelect ? typeSelect.id : null" />
        <label class="form-label">Tipo de test</label>
        <multiselect v-model="typeSelect" :options="choices" label="name" track-by="id" :allow-empty="false" defa>
        </multiselect>
        <nb-error-block path="tests_type_id" />
    </div>
</template>
  
<script>
import NbErrorBlock from "@/js/components/error_block/nb-error-block";
import { mapActions } from 'vuex'

export default {
    name: "SelectTestType",
    components: { NbErrorBlock },
    props: {
        choices: Array,
        test_type_id: Number
    },
    data() {
        return {
            typeSelect: null
        };
    },
    created() {
        this.typeSelect = this.test_type_id ? this.choices.find((type) => type.id === Number(this.test_type_id)) : this.choices[0]
    },
    methods: {
        ...mapActions('Test', {
            setTestType: 'setTestType',
        }),
    },
    watch: {
        typeSelect: {
            handler(newVal) {
                this.setTestType(newVal);
            },
            deep: true
        }
    },
};
</script>
  