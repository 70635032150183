<template>
  <div class="form-group m-form__group col-12">
    <ul class="nav nav-tabs" id="homeTabs" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="main-tab"
          data-bs-toggle="tab"
          href="#main"
          role="tab"
          aria-controls="main"
          aria-selected="true"
        >
          <em class="fa fa-globe mr-1"></em>
          Bloque Principal
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="infoTest-tab"
          data-bs-toggle="tab"
          href="#infoTest"
          role="tab"
          aria-controls="infoTest"
          aria-selected="false"
        >
          <em class="fa fa-hashtag mr-1"></em>
          Presentación de Tests
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="generalFeatures-tab"
          data-bs-toggle="tab"
          href="#generalFeatures"
          role="tab"
          aria-controls="generalFeatures"
          aria-selected="false"
        >
          <em class="fa fa-th-list mr-1"></em>
          Destacados
        </a>
      </li>
      <li class="nav-item" v-if="!lang">
        <a
          class="nav-link"
          id="sponsors-tab"
          data-bs-toggle="tab"
          href="#sponsors"
          role="tab"
          aria-controls="sponsors"
          aria-selected="false"
        >
          <em class="fa fa-th-list mr-1"></em>
          Patrocinadores
        </a>
      </li>
    </ul>
    <div class="tab-content" id="tabsContent">
      <div
        class="tab-pane fade show active"
        id="main"
        role="tabpanel"
        aria-labelledby="main-tab"
      >
        <main-block-tab
          :lang="lang"
          :mainBlock="mainBlock"
          :mainBlockDefault="mainBlockDefault"
          :tests="tests"
        />
      </div>
      <div
        class="tab-pane fade"
        id="infoTest"
        role="tabpanel"
        aria-labelledby="infoTest-tab"
      >
        <test-info-tab
          :lang="lang"
          :testInfoHeader="testInfoHeader"
          :testInfoHeaderDefault="testInfoHeaderDefault"
          :testInfo="testInfo"
          :testInfoDefault="testInfoDefault"
          :tests="tests"
        />
      </div>
      <div
        class="tab-pane fade"
        id="generalFeatures"
        role="tabpanel"
        aria-labelledby="generalFeatures-tab"
      >
        <general-features-tab
          :lang="lang"
          :generalFeatures="generalFeatures"
          :generalFeaturesDefault="generalFeaturesDefault"
        />
      </div>
      <div
        v-if="!lang"
        class="tab-pane fade"
        id="sponsors"
        role="tabpanel"
        aria-labelledby="sponsors-tab"
      >
        <sponsors-tab :sponsorsImages="sponsorsImages" />
      </div>
    </div>
  </div>
</template>

<script>
import MainBlockTab from "./MainBlockTab";
import TestInfoTab from "./TestInfoTab";
import GeneralFeaturesTab from "./GeneralFeaturesTab";
import SponsorsTab from "./SponsorsTab";

export default {
  name: "congiguration-home",
  components: {
    MainBlockTab,
    TestInfoTab,
    GeneralFeaturesTab,
    SponsorsTab,
  },
  props: {
    lang: String,
    tests: Array,
    mainBlock: Object,
    mainBlockDefault: Object,
    testInfoHeader: String,
    testInfoHeaderDefault: String,
    testInfo: Array,
    testInfoDefault: Array,
    generalFeatures: Array,
    generalFeaturesDefault: Array,
    sponsorsImages: Array,
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  padding: 20px 0 0 0;
}

.nav-tabs {
  margin-bottom: 0;
}
</style>
