var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group m-form__group col-md-6" }, [
    _c("div", { staticClass: "d-flex flex-row" }, [
      !_vm.generateTab
        ? _c("input", {
            attrs: { type: "hidden", name: _vm.name + "_checkbox" },
            domProps: { value: _vm.customizeCheckbox ? 1 : 0 }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.prefix + "[" + _vm.name + "]" },
        domProps: { value: _vm.active ? 1 : 0 }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "p-2" }, [
        !_vm.live
          ? _c("label", { staticClass: "m-checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customizeCheckbox,
                    expression: "customizeCheckbox"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  disabled: _vm.generateTab || _vm.nbReadOnly,
                  id: "customize-check-" + _vm.name
                },
                domProps: {
                  checked: Array.isArray(_vm.customizeCheckbox)
                    ? _vm._i(_vm.customizeCheckbox, null) > -1
                    : _vm.customizeCheckbox
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.customizeCheckbox,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.customizeCheckbox = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.customizeCheckbox = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.customizeCheckbox = $$c
                    }
                  }
                }
              }),
              _vm._v(" " + _vm._s(_vm.label) + "\n                "),
              _c("span")
            ])
          : _c("label", [
              _vm._v(
                "\n                " + _vm._s(_vm.label) + "\n            "
              )
            ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group m-form__group  col-md-4" }, [
      _c("div", [
        _c("span", { staticClass: "m-switch m-switch--icon" }, [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.active,
                  expression: "active"
                }
              ],
              attrs: { type: "checkbox", value: "1" },
              domProps: {
                checked: Array.isArray(_vm.active)
                  ? _vm._i(_vm.active, "1") > -1
                  : _vm.active
              },
              on: {
                change: function($event) {
                  var $$a = _vm.active,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = "1",
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.active = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.active = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.active = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span")
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "label-hidden" }, [
            _vm._v("Activar filtro")
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }